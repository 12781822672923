import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { nameLabelPair } from 'core/shapes'
import withProps from 'hoc/withProps'
import { Zap } from 'react-feather'

const SmallZap = styled(withProps(Icon, { children: <Zap />, variant: 'sm' }), {
  name: 'PacingGuide_SmallZap',
})(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    color: palette.pacingGuide.skillDefault,
    lineHeight: 0,
    marginLeft: rem(0.5),
    marginRight: rem(0.5),
    svg: {
      fill: 'currentColor',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      verticalAlign: 'text-bottom',
    },
  }),
)

const SkillTypography = styled(
  withProps(Typography, { component: 'span', variant: 'body1-semibold' }),
)(({ theme: { palette } }) => ({
  color: palette.pacingGuide.skillDefault,
}))

const SkillWrapper = ({ name, label }) => (
  <>
    <SmallZap />
    <SkillTypography>{label}</SkillTypography>: {name}
  </>
)

SkillWrapper.propTypes = nameLabelPair

export default SkillWrapper
