import Container from '@mui/system/Container'
import RecentlyAdded from './RecentlyAdded'

const Carousels = () => (
  <Container>
    <RecentlyAdded />
  </Container>
)

export default Carousels
