import {
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_VIDEO,
} from 'core/consts'
import { produce } from 'immer'
import { lighten } from 'polished'

/** ****************************************************************************
 *
 *                               !!!!!!!!!!!!!!!!!!
 *                               !!!   NOTICE   !!!
 *                               !!!!!!!!!!!!!!!!!!
 *
 * This is not the complete list of available colors.  Many are inherited and/or
 * derived from Material.
 *
 * For the complete list, see:
 * http://content.studysync.com.s3-website-us-east-1.amazonaws.com/dev/docs/traverse-sb/?path=/story/design-system--palette
 *
 **************************************************************************** */

const grey = {
  0: '#000',
  1: '#333',
  2: '#4F4F4F',
  3: '#4F4F4F',
  4: '#4F4F4F',
  5: '#F2F2F2',
  contrastText: '#fff',
  hover: '#4F4F4F',
  gradient: 'linear-gradient(100.87deg, #485563 0%, #29323C 101.71%)',
  gradientDark: 'linear-gradient(100.87deg, #29323C 0%, #485563 101.71%)',
  gradientLight: 'linear-gradient(99.85deg, #5D6874 0%, #D9D9D9 100%)',
  gradientStart: 'rgb(79, 79, 79, 0.8)',
  gradientEnd: 'rgba(51, 51, 51, 0.9)',
  name: 'grey',
}

const cobalt = {
  0: '#1C5BFF',
  1: '#1C5BFF',
  2: '#799FFC',
  3: '#799FFC',
  4: '#D2DFFE',
  hover: '#799FFC',
  contrastText: '#fff',
  gradient: 'linear-gradient(101.07deg, #6EE2F5 0%, #1C5BFF 102.07%)',
  gradientStart: 'rgba(121, 159, 252, 0.8)',
  gradientEnd: 'rgb(28, 91, 255, 0.9)',
  name: 'cobalt',
}

const emerald = {
  0: '#036167',
  1: '#2F8186',
  2: '#5AA2A7',
  3: '#85C2C7',
  4: '#BDE5E9',
  contrastText: '#fff',
  gradient: 'linear-gradient(100.87deg, #036167 0%, #036167 101.71%)',
  gradientStart: 'rgb(42, 159, 167, 0.8)',
  gradientEnd: 'rgb(3, 97, 103, 0.9)',
  name: 'emerald',
}

const tangerine = {
  0: '#1C5BFF',
  1: '#1C5BFF',
  2: '#799FFC',
  3: '#799FFC',
  4: '#D2DFFE',
  hover: '#799FFC',
  contrastText: '#fff',
  gradient: 'linear-gradient(101.07deg, #1C5BFF 0%, #6EE2F5 102.07%)',
  gradientEnd: '#6EE2F5',
  gradientStart: '#1C5BFF',
  name: 'tangerine',
}

const babyBlue = {
  0: '#80B7F0',
  1: '#9ECBFA',
  2: '#B8DBFF',
  3: '#CFE7FF',
  4: '#E5F2FF',
  contrastText: '#fff',
  gradient: 'linear-gradient(99deg, #80B7F0 0%, #CFE7FF 100%)',
  gradientEnd: '#CFE7FF',
  gradientStart: '#80B7F0',
  name: 'babyBlue',
}

const teal = {
  0: '#16C8C8',
  1: '#4DD3D2',
  2: '#77DEDD',
  3: '#A4E9E8',
  4: '#D1F4F4',
  contrastText: '#fff',
  gradient: 'linear-gradient(99deg, #16C8C8 0%, #90F7EC 100%)',
  gradientEnd: '#90F7EC',
  gradientStart: '#16C8C8',
  name: 'teal',
}

const canary = {
  0: '#1C5BFF',
  1: '#4C80FB',
  2: '#799FFC',
  3: '#A5BFFD',
  4: '#D2DFFE',
  contrastText: '#fff',
  gradient: 'linear-gradient(101.07deg, #6EE2F5 0%, #1C5BFF 102.07%)',
  gradientEnd: '#1C5BFF',
  gradientStart: '#6EE2F5',
  name: 'canary',
}

const violet = {
  0: '#C8A6FF',
  1: '#D0B3FF',
  2: '#D9C1FF',
  3: '#E4D3FF',
  4: '#EFE4FF',
  contrastText: grey[0],
  gradient: 'linear-gradient(99deg, #7367F0 0%, #C8A6FF 100%)',
  gradientEnd: 'rgb(200, 166, 255, 0.9)',
  gradientStart: 'rgb(115, 103, 240, 0.6)',
  name: 'violet',
}

const primary = {
  50: '#e7e7e7',
  100: '#c2c2c2',
  200: '#999999',
  300: '#707070',
  400: '#525252',
  500: '#333333',
  600: '#2e2e2e',
  700: '#272727',
  800: '#202020',
  900: '#141414',
  A100: '#ffffff',
  A200: '#f5f6ff',
  A400: '#c2c9ff',
  A700: '#a8b2ff',
  contrastDefaultColor: 'light',
  contrastDarkColors: ['50', '100', '200', 'A100', 'A200'],
  contrastLightColors: [
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    'A400',
    'A700',
  ],
}

const paperSecondary = grey[0]

const secondaryLight = '#799FFC'
const secondary = '#1C5BFF'
const secondaryDark = primary[700]

const common = {
  black: '#000',
  blue: cobalt[0],
  darkRed: '#71062A',
  green: '#2F7B34',
  grey: grey[1],
  lightGrey: grey[5],
  orange: '#ff7f0e',
  purple: '#8E49F0',
  red: '#71062A',
  teal: teal[0],
  yellow: '#f9c642',
  white: '#fff',
}

const sectionAccents = ['cobalt', 'emerald', 'grey']

const wordCloud = [cobalt[0], emerald[0], grey[1]]

const charting = {
  'baby blue': babyBlue[0],
  babyblue: babyBlue[0],
  babyBlue: babyBlue[0],
  canary: canary[1],
  cobalt: cobalt[1],
  emerald: emerald[1],
  grey: grey[0],
  tangerine: tangerine[1],
  teal: teal[1],
  violet: violet.gradientEnd,
  white: common.white,
}

const carousel = [
  { start: cobalt.gradientEnd, end: cobalt.gradientEnd },
  { start: emerald.gradientEnd, end: emerald.gradientEnd },
  { start: grey.gradientEnd, end: grey.gradientEnd },
]

const chartDefaults = [
  charting.babyBlue,
  charting.cobalt,
  charting.teal,
  charting.emerald,
  charting.canary,
  charting.tangerine,
  charting.violet,
]

const pacingGuide = {
  skillDefault: emerald[0],
}

const palette = {
  accent: {
    dark: primary[700],
    light: primary[500],
    main: primary[500],
  },
  babyBlue,
  background: {
    default: '#f9f9f9',
    paperSecondary,
    inverted: grey[0],
    dark: common.black,
  },
  border: grey,
  boxshadowPrimary: cobalt[0],
  canary,
  carousel,
  chartDefaults,
  charting,
  cobalt,
  common,
  contrastText: grey[0],
  drawer: '#1b1b1b',
  disabled: {
    background: '#e0e0e0',
    border: grey[3],
  },
  echo: {
    background: grey[1],
    decorative: grey[5],
    selected: grey[5],
    subsection: cobalt[0],
    text: '#fff',
    toc: '#fff',
    tocDark: grey[0],
  },
  emerald,
  error: {
    contrastText: '#fff',
    dark: '#71062A',
    light: '#ED2756',
    main: '#ED2756',
  },
  focused: cobalt[0],
  grey,
  navigation: {
    toc: cobalt[0],
    tocLight: cobalt[3],
    main: '#fff',
    icon: grey[5],
  },
  pacingGuide,
  pagination: cobalt[0],
  primary,
  secondary: {
    dark: secondaryDark,
    light: secondaryLight,
    main: secondary,
  },
  sectionAccents,
  selected: primary[900],
  selection: '#02bfe7',
  shadow: '#111', // Used only as a seed for /shadows.js
  slider: {
    main: '#fff',
    border: grey[0],
    disabled: grey[3],
  },
  success: {
    contrastText: '#fff',
    dark: '#14694F',
    light: '#02BF86',
    main: '#02BF86',
  },
  tableCell: grey[5],
  tangerine,
  teal,
  teacherEdition: cobalt[0],
  text: {
    alt: '#202020',
    dimmer: '#888',
    greyed: grey,
    hover: primary[500],
    inverted: '#222',
    primary: '#e1e1e1',
    primaryDisabled: grey,
    secondary: '#f2f2f2',
    secondaryDisabled: grey,
    selected: '#f1f1f1',
  },
  video: {
    main: cobalt[0],
    contrast: '#fff',
    bar: grey[0],
  },
  violet,
  wordCloud,
}

export default palette

// MARK: assembly
export const postProcess = produce(draft => {
  draft.palette.accentColor = draft.accentColor

  const accent = draft.palette[draft.accentColor]
  draft.palette.accent = {
    dark: accent[0],
    light: accent[3],
    main: accent[0],
    ...accent,
  }

  const builtGrey = draft.palette.grey
  draft.palette.important = draft.palette.error.main
  draft.palette.border[0] = builtGrey[3]
  draft.palette.border[1] = builtGrey[4]
  draft.palette.border[2] = builtGrey[4]
  draft.palette.border[3] = lighten(0.22, builtGrey[3])
  draft.palette.border[4] = lighten(0.1, builtGrey[4])
  draft.palette.border[5] = lighten(0.1, builtGrey[5])
  draft.palette.border.light = builtGrey[2]
  draft.palette.border.dark = builtGrey[3]
  draft.palette.border.interactive = builtGrey[3]

  draft.palette.text.primary = grey[0]
  draft.palette.text.secondary = grey[1]
  draft.palette.text.disabled = grey[3]
  draft.palette.primary.contrastText = grey.contrastText

  draft.palette.annotations = [
    { colorId: 1, value: common.red, label: 'Red' },
    { colorId: 2, value: common.orange, label: 'Orange' },
    { colorId: 3, value: common.yellow, label: 'Yellow' },
    { colorId: 4, value: common.green, label: 'Green' },
    { colorId: 5, value: common.teal, label: 'Teal' },
    { colorId: 6, value: common.blue, label: 'Blue' },
  ]

  draft.palette.gradebook = [
    {
      colorId: 1,
      value: common.purple,
      label: 'Purple',
      textColor: common.white,
    },
    { colorId: 2, value: common.blue, label: 'Blue', textColor: common.white },
    { colorId: 3, value: common.red, label: 'Red', textColor: common.white },
    { colorId: 4, value: common.green, label: 'Green', textColor: grey[3] },
    {
      colorId: 5,
      value: common.black,
      label: 'Black',
      textColor: common.lightGrey,
    },
    { colorId: 8, value: grey[3], label: 'Gray', textColor: grey[0] },
  ]

  draft.palette.pictureThisTextBackground = [
    {
      colorId: 1,
      value: common.black,
      contrastText: common.white,
      label: 'Black',
    },
    { colorId: 2, value: common.red, contrastText: common.black, label: 'Red' },
    {
      colorId: 3,
      value: common.orange,
      contrastText: common.black,
      label: 'Orange',
    },
    {
      colorId: 4,
      value: common.yellow,
      contrastText: common.black,
      label: 'Yellow',
    },
    {
      colorId: 5,
      value: common.green,
      contrastText: common.black,
      label: 'Green',
    },
    {
      colorId: 6,
      value: common.blue,
      contrastText: common.black,
      label: 'Blue',
    },
    {
      colorId: 7,
      value: common.purple,
      contrastText: common.black,
      label: 'Purple',
    },
  ]

  draft.palette.libraryItems = {
    AcademicVocabulary: emerald[0],
    Assessment: cobalt[0],
    [INTERACTIVE_TYPE_AUDIO]: emerald[0],
    [CONTENT_TYPE_CHAPTER]: cobalt[0],
    [CONTENT_TYPE_ECHO]: grey[0],
    [CONTENT_TYPE_INTERACTIVE]: grey[0],
    [CONTENT_TYPE_SECTION]: cobalt[0],
    [CONTENT_TYPE_SOURCE]: grey[0],
    [CONTENT_TYPE_SUBSECTION]: grey[0],
    DBI: cobalt[0],
    Document: cobalt[0],
    [INTERACTIVE_TYPE_IMAGE]: grey[0],
    PictureThis: grey[0],
    Skill: emerald[0],
    Text: cobalt[0],
    [INTERACTIVE_TYPE_VIDEO]: emerald[0],
    VocabularyPreview: emerald[0],
  }
})
