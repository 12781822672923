import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { styled, useTheme } from '@mui/material/styles'
import ThumbHC from 'apps/hss/images/themes/theme-hc.svg'
import ThumbHSS from 'apps/hss/images/themes/theme.svg'
import Headline from 'common/text/Headline'
import { ABILITY_CONTENT_EDITING } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useCurrentUser from 'hooks/useCurrentUser'
import { border } from 'polished'
import { useDispatch } from 'react-redux'

const themes = {
  hss: { label: 'Default', Thumb: ThumbHSS },
  'hss-hc': { label: 'High Contrast', Thumb: ThumbHC },
}

const Container = styled(FormControl, { name: 'Accessibility-Themes' })(
  ({
    theme: {
      breakpoints,
      mixins: { borderS, rem },
      palette,
    },
  }) => ({
    marginTop: 0,
    '[role=radiogroup]': {
      display: 'flex',
      flexFlow: 'row wrap',

      [breakpoints.down('md')]: {
        flexDirection: 'column',
      },

      label: {
        alignItems: 'flex-start',
        'span:first-of-type': {
          maxWidth: 60,
          position: 'relative',
        },
        '.MuiFormControlLabel-label': {
          position: 'relative',
          div: {
            marginTop: 5,
            marginBottom: 24,
          },
          svg: {
            ...borderS(palette.grey[4]),
            marginLeft: -36,
            width: 275,
            [breakpoints.down('lg')]: {
              width: 230,
              marginBottom: rem(2.5),
            },
          },
        },
      },
    },
  }),
)

const Themes = withAbilityCheck(() => {
  const { actions, user } = useCurrentUser()
  const { theme = 'hss' } = user
  const { palette } = useTheme()
  const dispatch = useDispatch()
  const handleChange = ({ target: { value } }) => {
    // show theme change immediately? or wait until save
    dispatch(actions.changeTheme({ user, theme: value }))
  }

  return (
    <Headline
      mb={4}
      size={4}
      title="Themes">
      <Container component="fieldset">
        <RadioGroup
          aria-label="theme"
          name="theme"
          onChange={handleChange}
          value={theme}>
          {Object.keys(themes).map(key => {
            const { Thumb } = themes[key]
            return (
              <FormControlLabel
                control={<Radio value={key} />}
                key={key}
                label={
                  <>
                    <div>{themes[key].label}</div>
                    <Thumb
                      style={
                        theme === key
                          ? { ...border(3, 'solid', palette.grey[0]) }
                          : null
                      }
                    />
                  </>
                }
              />
            )
          })}
        </RadioGroup>
      </Container>
    </Headline>
  )
}, ABILITY_CONTENT_EDITING)

export default Themes
