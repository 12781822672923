import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TableCell from '@mui/material/TableCell'
import { nameLabelPairShape, pacingOptionsShape } from 'core/shapes'
import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import PacingOptionCells from './PacingOptionCells'
import SkillWrapper from './SkillWrapper'
import useFeatureGroupPalette from './useFeatureGroupPalette'

const ChapterFeatureGroupCells = ({
  features = [],
  name,
  pacingOptions,
  skill,
}) => {
  const { featureGroupNameColor } = useFeatureGroupPalette(name)

  return (
    <>
      <TableCell>
        <span style={{ color: featureGroupNameColor }}>{name}</span>
        {Boolean(skill) && (
          <SkillWrapper
            label={skill.label}
            name={skill.name}
          />
        )}
        <br />
        <List variant="standard">
          {features.map(feature => {
            const featureName = feature.name || feature
            return (
              <ListItem key={featureName}>
                {featureName}
                {Boolean(feature.skill) && (
                  <SkillWrapper
                    label={get('skill.label')(feature)}
                    name={get('skill.name')(feature)}
                  />
                )}
              </ListItem>
            )
          })}
        </List>
      </TableCell>

      <PacingOptionCells
        featureGroupName={name}
        pacingOptions={pacingOptions}
      />
    </>
  )
}

ChapterFeatureGroupCells.propTypes = {
  features: PropTypes.array,
  name: PropTypes.string,
  pacingOptions: pacingOptionsShape,
  skill: nameLabelPairShape,
}

export default ChapterFeatureGroupCells
