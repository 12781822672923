import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import { Trash } from 'react-feather'

const BulkDeletionToolButton = props => {
  const { disabled = false, label } = props
  return (
    <Tooltip title={disabled ? '' : label}>
      <IconButton
        aria-label={label}
        color="secondary"
        data-bodyvariant="body1"
        disabled={disabled}
        style={{
          width: 48,
          height: 48,
        }}
        variant="default">
        <Trash />
      </IconButton>
    </Tooltip>
  )
}

BulkDeletionToolButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

export default BulkDeletionToolButton
