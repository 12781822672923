import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Html from 'common/text/Html'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useContext } from 'react'

const FeatureBlastStaticRenderer = () => {
  const { completed, interactionData, isGrading, reviewed } =
    useContext(interactiveContext)
  const { palette } = useTheme()

  return (
    <Box
      margin={2}
      {...(reviewed
        ? {
            borderLeft: `.2rem solid ${palette.border[0]}`,
            paddingLeft: 1,
            paddingBottom: 0,
          }
        : null)}>
      {!completed && isGrading ? (
        '(no response)'
      ) : (
        <Typography
          color={reviewed ? 'text.secondary' : 'text.primary'}
          component="div">
          <Html body={interactionData.response} />
        </Typography>
      )}
    </Box>
  )
}

FeatureBlastStaticRenderer.propTypes = {}

export default FeatureBlastStaticRenderer
