import Tab from '@mui/material/Tab'
import TabList from 'common/layout/TabList'
import { type SyntheticEvent, useContext } from 'react'

import { additionalContext } from 'common/formControls/Form/additionalContext'
import ContainerQuery from 'common/layout/ContainerQuery'
import { useAuthorContext } from '../AuthorProvider'

export type TabValue = '#attributes' | '#content' | '#preview'

export const isTabValue = (value: string): value is TabValue => {
  return ['#attributes', '#content', '#preview'].includes(value)
}

type Props = {
  setCurrentTab: (newValue: TabValue) => void
}

const Tabs = (props: Props) => {
  const { setCurrentTab } = props

  const { split } = useAuthorContext()

  const { setSuppressNextDirtyNavigationWarning } =
    useContext(additionalContext)

  const handleTabChange = (_: SyntheticEvent, newValue: TabValue) => {
    setSuppressNextDirtyNavigationWarning(true)
    setTimeout(() => setCurrentTab(newValue), 100)
  }

  return (
    <ContainerQuery>
      <TabList
        aria-label="content and attributes"
        data-variant="secondary"
        minSize="xs"
        onChange={handleTabChange}
        scrollButtons="auto"
        variant="scrollable">
        <Tab
          label="Attributes"
          value="#attributes"
        />

        <Tab
          label="Content"
          value="#content"
        />

        {!split && (
          <Tab
            label="Preview"
            value="#preview"
          />
        )}
      </TabList>
    </ContainerQuery>
  )
}

export default Tabs
