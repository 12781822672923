import Carousel from 'common/carousels/Carousel'
import { paginatedSmallSlides } from 'common/carousels/configurations'
import Headline from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import { assert, alter } from 'core/store/search/squery'
import useDataset from 'hooks/useDataset'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import { getSearchableLibraryContentTypes } from 'selectors/library'
import LibraryCard from '../LibraryCard'
import LinkToContent from '../LinkToContent'

const RecentlyAdded = () => {
  const SEARCHABLE_TYPES = useSelector(getSearchableLibraryContentTypes)

  const squery = compose(
    alter.set.limit(10),
    alter.set.orderBy('name', 'asc'),
    alter.set.where('contentType').in(SEARCHABLE_TYPES),
    alter.set.orderBy('dateCreated', 'desc'),
    alter.set.modifier('forLibrary').is(true),
  )(assert())

  const { data } = useDataset({
    actionType: actionTypes.CONTENT_FETCH_ASSORTED,
    restEndpoint: restEndpoint.content,
    squery,
  })

  return (
    <>
      <HeadlineLevelOffset>
        <Headline
          size={6}
          title="Recently Added"
        />
      </HeadlineLevelOffset>

      <Carousel configuration={paginatedSmallSlides}>
        {data.map(item => (
          <div key={item.id}>
            <LinkToContent content={item}>
              <LibraryCard
                content={item}
                contentCreator={false}
              />
            </LinkToContent>
          </div>
        ))}
      </Carousel>
    </>
  )
}

export default RecentlyAdded
