import TextField from 'common/formControls/textInputs/TextField'
import Headline from 'common/text/Headline'
import { get, set } from 'fp/objects'
import { when } from 'fp/utils'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import SpeechSynthesisWarning from './SpeechSynthesisWarning'

const SpeechSettings = ({ onChange, preferences }) => {
  const rate = get('audioDescriptionRate')(preferences)
  const volume = get('audioDescriptionVolume')(preferences)

  const handleChange =
    propName =>
    ({ target }) => {
      onChange(set(propName, target.value))
    }

  const browserSpeechEnabled = get('useBrowserSpeech')(preferences)

  useEffect(() => {
    if (browserSpeechEnabled) {
      when(!rate, onChange, set('audioDescriptionRate', 1))
      when(!volume, onChange, set('audioDescriptionVolume', 1))
    }
  }, [onChange, rate, browserSpeechEnabled, volume])

  return (
    <>
      <SpeechSynthesisWarning browserSpeechEnabled={!!browserSpeechEnabled} />

      <Headline
        disabled={!browserSpeechEnabled}
        mb={2}
        mt={4}
        size={5}
        textAlign="left"
        title="Browser Speech Settings">
        <TextField
          disabled={!browserSpeechEnabled}
          inputProps={{
            max: 5,
            min: 0.1,
            step: 0.1,
          }}
          label="Speech Rate"
          margin="normal"
          name="speechRate"
          onChange={handleChange('audioDescriptionRate')}
          type="number"
          value={rate}
          variant="outlined"
        />

        <TextField
          disabled={!browserSpeechEnabled}
          inputProps={{
            max: 1,
            min: 0.1,
            step: 0.1,
          }}
          label="Speech Volume"
          margin="normal"
          name="speechVolume"
          onChange={handleChange('audioDescriptionVolume')}
          type="number"
          value={volume}
          variant="outlined"
        />
      </Headline>
    </>
  )
}

SpeechSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default SpeechSettings
