import Headline from 'common/text/Headline'
import withProps from 'hoc/withProps'

const FeatureHeadline = withProps(Headline, {
  hr: true,
  mb: 4,
  mt: 2,
  textTransform: 'uppercase',
})

export default FeatureHeadline
