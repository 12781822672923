import TableCell from '@mui/material/TableCell'
import { get, isObject } from 'fp/objects'
import { pacingOptionKeys } from './consts'
import useFeatureGroupPalette from './useFeatureGroupPalette'

const PacingOptionCells = ({ featureGroupName, pacingOptions }) => {
  const { getColorForPacingOption } = useFeatureGroupPalette(featureGroupName)

  return pacingOptionKeys.map(key => {
    const pacingOption = get(key)(pacingOptions)
    const cellValue = isObject(pacingOption)
      ? pacingOption.startDay
        ? !pacingOption.endDay || pacingOption.startDay === pacingOption.endDay
          ? `Day ${pacingOption.startDay}`
          : `Day ${pacingOption.startDay}–${pacingOption.endDay}`
        : null
      : pacingOption

    return (
      <TableCell
        className="day-column"
        key={key}
        sx={{ background: getColorForPacingOption(key) }}>
        {cellValue}
      </TableCell>
    )
  })
}

export default PacingOptionCells
