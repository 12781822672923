import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import Centered from 'common/layout/Centered'
import {
  ABILITY_ASSIGNMENT_CREATION,
  ABILITY_CONTENT_EDITING,
} from 'core/consts'
import { contentTypeShape } from 'core/shapes'
import { incrementByKey } from 'fp/arrays'
import { get, set } from 'fp/objects'
import useAbilityChecker from 'hooks/useAbilityChecker'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { getCurrentViewContent } from 'selectors/contentViewer'
import { isTestEnv } from 'selectors/index'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import ChartResult, { StyledResults } from './ChartResult'

const ChartContainer = ({ contentType, ...rest }) => {
  // Jest requires both dimensions else the legend will not render
  const width = isTestEnv() ? 1000 : undefined
  const height = contentType === 'echo' ? 700 : 600
  return (
    <ResponsiveContainer
      {...{
        ...rest,
        height,
        width,
      }}
    />
  )
}
ChartContainer.propTypes = {
  contentType: contentTypeShape.isRequired,
}

const renderLegend =
  ({ selectedValue }, isMentor) =>
  ({ payload: payloads }) => (
    <StyledResults>
      {payloads.map(({ payload }, index) => (
        <ChartResult
          isAssignmentCreator={isMentor}
          item={payload}
          key={index}
          selectedValue={selectedValue || 'na'}
        />
      ))}
    </StyledResults>
  )

const ChartResults = withQuestionPrompt(({ onResetClick }) => {
  const {
    completed,
    interactionData,
    interactiveData: { options: optionsRaw },
    peerInteractions,
  } = useContext(interactiveContext)

  /**
   * Options should have ids in the form "GEN-xxxxxxxx", however some older polls
   * have integer ids.  Since the interactions always store the id as a string,
   * we can just cast the integer ids to strings and things will work fine.
   * There is no rubric associated with polls so I don't think we need to sweat
   * the numeric ids.
   */
  const options = optionsRaw.map(option => ({
    ...option,
    id: String(option.id),
  }))

  const {
    palette: { chartDefaults },
  } = useTheme()
  const has = useAbilityChecker()
  const isAssignmentCreator = has(ABILITY_ASSIGNMENT_CREATION)
  const isContentEditor = has(ABILITY_CONTENT_EDITING)

  const { contentType } = useSelector(getCurrentViewContent) || {
    contentType: 'detached',
  }

  const results = useMemo(
    () =>
      peerInteractions
        .map(get('interactionData'))
        .concat(isAssignmentCreator ? [] : [interactionData])
        .map(get('selectedValue'))
        .reduce(
          (acc, selectedValue) =>
            incrementByKey(String(selectedValue), 'votes')(acc),
          options.map(set('votes', 0)),
        ),
    [interactionData, isAssignmentCreator, options, peerInteractions],
  )

  const showResults =
    completed || (isAssignmentCreator && !!peerInteractions.length)

  return (
    <Box
      className={`poll-within-${contentType}`}
      m="0 auto"
      maxWidth={featuredContentMaxWidth}
      textAlign="center">
      {!!showResults && (
        <ChartContainer contentType={contentType}>
          <PieChart>
            <Pie
              cx="50%"
              cy="50%"
              data={results}
              dataKey="votes"
              labelLine={false}
              nameKey="label">
              {results.map((_, index) => (
                <Cell
                  fill={chartDefaults[index % 7]}
                  key={`cell-${index}`}
                />
              ))}
            </Pie>

            <Tooltip />

            <Legend
              content={renderLegend(interactionData, isAssignmentCreator)}
              layout="vertical"
              verticalAlign="bottom"
            />
          </PieChart>
        </ChartContainer>
      )}

      {!showResults && (
        <Centered sx={{ minHeight: 150 }}>
          There are currently no results to display
        </Centered>
      )}

      {Boolean(isContentEditor && !completed) && (
        <Button
          color="secondary"
          onClick={onResetClick}
          sx={{ mb: 4, mt: -1.5 }}
          variant="secondary">
          Back to Poll
        </Button>
      )}
    </Box>
  )
})

ChartResults.propTypes = {
  onResetClick: PropTypes.func.isRequired,
}

export default ChartResults
