import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { identity, isDefined } from 'fp/utils'
import PropTypes from 'prop-types'

const Attribute = ({ name, render = identity, value }) => (
  <>
    <Typography
      component="div"
      variant="attribute-name">
      {name}
    </Typography>

    {isDefined(value) ? render(value) : '—'}
  </>
)
Attribute.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func,
  value: PropTypes.any,
}

const Details = ({ children, summary = 'Details', ...rest }) => (
  <Box
    component="details"
    {...rest}>
    <summary>{summary}</summary>
    {children}
  </Box>
)
Details.propTypes = {
  children: PropTypes.node.isRequired,
  summary: PropTypes.string,
}

const AttributeColumns = ({
  attributes,
  minColumnWidth = '18rem',
  ...rest
}) => {
  const isSmall = useContainerQuery().down('sm')
  const Wrapper = isSmall ? Details : Box

  return attributes.length ? (
    <Wrapper
      {...(isSmall ? { summary: 'Assignment Details' } : null)}
      {...rest}>
      <Box
        component="ul"
        display="grid"
        gap={1}
        gridTemplateColumns={`repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`}
        sx={{
          listStyle: 'none',
          margin: 0,
          paddingInlineStart: 0,
        }}>
        {attributes.map(attribute => (
          <Box
            component="li"
            key={attribute.name}>
            <Attribute {...attribute} />
          </Box>
        ))}
      </Box>
    </Wrapper>
  ) : null
}

AttributeColumns.propTypes = {
  attributes: PropTypes.array.isRequired,
  minColumnWidth: PropTypes.string,
}

export default AttributeColumns
