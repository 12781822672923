import Container from '@mui/material/Container'
import Footer from 'routing/shells/NavShell/Footer'
import Subsequent from './Subsequent'
import GetToKnowTraverse from './Subsequent/GetToKnowTraverse'

const Home = () => (
  <div>
    {/* TODO: when do we show first/subsequent? For MVP show the returning page */}
    {/* {firstRender
      ? <First />
      : <Subsequent />} */}
    <Subsequent />
    <GetToKnowTraverse />

    <Container>
      <Footer />
    </Container>
  </div>
)

export default Home
