import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { containerQueryContext } from 'common/layout/ContainerQuery'
import { assignmentSettingsShape } from 'core/shapes'
import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import EnhancedContentHtml from '../Block/EnhancedContentHtml'
import LinkToEditor from '../Block/LinkToEditor'
import MaybeHasContentSubType from '../Block/MaybeHasContentSubType'

const WrappedContentBlocks = ({
  assignmentSettings,
  blockBundle,
  fullWidth,
  previewing,
}) => {
  const variant = get('0.block.data.variant')(blockBundle)

  const { size } = useContext(containerQueryContext)

  if (!variant) {
    throw new Error(`
      !!!!!! Invariant state detected !!!!!!

      A run of blocks were bundled together, but there is no variant defined on
      the first one.

      It should not be possible to create this scenario through the editor. It's
      possible that the body html was modified by hand.
    `)
  }

  const Component = fullWidth ? Box : Container

  return (
    <Component
      className="wrapped-content-blocks"
      data-variant={variant}
      data-viewport={size}>
      {blockBundle.map(
        ({
          block,
          block: {
            contentType,
            id,
            data: { body },
          },
        }) => (
          <MaybeHasContentSubType
            block={block}
            key={id}>
            <EnhancedContentHtml
              assignmentSettings={assignmentSettings}
              body={body || ''}
              childrenMetadata={[]}
              contained={false}
              data-contentid={id}
              data-contenttype={contentType}
              key={id}
              variant={variant}>
              {!previewing && <LinkToEditor content={block} />}
            </EnhancedContentHtml>
          </MaybeHasContentSubType>
        ),
      )}
    </Component>
  )
}

WrappedContentBlocks.propTypes = {
  assignmentSettings: assignmentSettingsShape,
  blockBundle: PropTypes.arrayOf(PropTypes.object).isRequired,
  fullWidth: PropTypes.bool.isRequired,
  previewing: PropTypes.bool.isRequired,
}

export default WrappedContentBlocks
