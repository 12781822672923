import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Link from 'common/navigation/links/Link'
import Headline, { StyledHeadline } from 'common/text/Headline'
import {
  INTERACTION_SUBTYPE_IMAGE,
  INTERACTION_SUBTYPE_TEXT,
  INTERACTION_SUBTYPE_VIDEO,
} from 'core/consts'
import { contentShape, interactionShape } from 'core/shapes'
import useContent from 'hooks/useContent'
import { formatSec } from 'locale/i18n'
import { buildContentUrl } from 'projections/content'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getUserAssignment } from 'selectors/userAssignments'
import ImageAnnotation from './ImageAnnotation'

const DisplayAnnotation = ({ annotation }) => {
  const { contentId, interactionData, interactionSubType } = annotation

  switch (interactionSubType) {
    case INTERACTION_SUBTYPE_TEXT:
      return (
        <>
          <Typography variant="footnote">
            {interactionData.selectionData.selectedText}
          </Typography>
          <br />
          {interactionData.annotation}
        </>
      )
    case INTERACTION_SUBTYPE_IMAGE:
    case INTERACTION_SUBTYPE_VIDEO:
      return (
        <>
          {/* both video and image annotations display an image  */}
          <ImageAnnotation contentId={contentId} />
          {interactionSubType === INTERACTION_SUBTYPE_IMAGE ? (
            <span>{interactionData.data.text}</span>
          ) : (
            <span>
              {formatSec(interactionData.time)} - {interactionData.text}
            </span>
          )}
        </>
      )
    default:
      throw new Error(`Invalid interactionSubType: ${interactionSubType}`)
  }
}

DisplayAnnotation.propTypes = {
  annotation: interactionShape.isRequired,
}

const SubsectionAnnotations = ({
  annotations,
  isLast,
  content,
  marginTop = 4,
}) => {
  const location = useLocation()
  const userAssignment = useSelector(getUserAssignment) || {}
  const url = buildContentUrl('current')(
    content,
    null,
    null,
    null,
    location,
    userAssignment,
  )
  const section = useContent({ contentId: content.parent.id })
  const tabbedSection = useContent({ contentId: section.parent.id })
  const sectionName = section.data?.tabbed
    ? `${tabbedSection.name} - ${section.name}`
    : section.name

  return (
    <Headline
      component={
        <StyledHeadline
          mt={marginTop}
          size={6}
          sx={{ a: { color: 'text.primary' } }}
          textTransform="capitalize">
          <Link to={url}>{`${sectionName}: ${content.name}`}</Link>
        </StyledHeadline>
      }>
      <ul>
        {annotations.map(annotation => (
          <li key={annotation.id}>
            <DisplayAnnotation annotation={annotation} />
          </li>
        ))}
      </ul>
      {!isLast && <Divider light />}
    </Headline>
  )
}

SubsectionAnnotations.propTypes = {
  annotations: PropTypes.arrayOf(interactionShape).isRequired,
  content: contentShape.isRequired,
  isLast: PropTypes.bool.isRequired,
  marginTop: PropTypes.number,
}

export default SubsectionAnnotations
