import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Carousel from 'common/carousels/Carousel'
import { largeSingleSlides } from 'common/carousels/configurations'
import Headline from 'common/text/Headline'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useCurrentUser from 'hooks/useCurrentUser'
import backgroundImage from 'hss/images/home/background-graphic.webp'
import HeroSlide from 'hss/views/Library/Hero/HeroSlide'
import Bookshelf from './Bookshelf'
import Echoes from './Echoes'
import RecentlyVisited from './RecentlyVisited'
import SlideContent from './SlideContent'
import articles from './articles'

const WelcomeMessage = () => {
  const {
    user: { firstName },
  } = useCurrentUser()
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)
  const { palette } = useTheme()

  return (
    <>
      Welcome back,{' '}
      <Typography
        component="span"
        textTransform="capitalize"
        variant="h2">
        {firstName}
      </Typography>
      !{' '}
      {Boolean(isContentCreator) && (
        <Typography
          component="span"
          style={{
            color: palette.text.secondary,
          }}
          variant="small">
          (System&nbsp;Administrator)
        </Typography>
      )}
    </>
  )
}

export const sx = {
  '.dash-pagination': {
    marginTop: -10,
    zIndex: 2,
    '.swiper-pagination-bullet, .swiper-pagination-bullet-active': {
      backgroundColor: 'white',
    },
  },

  '.carousel-nav': {
    background: 'none',
    border: 1,
    color: 'primary.contrastText',
    '& svg': {
      color: 'primary.contrastText',
    },
  },
  '.swiper-wrapper': { paddingTop: 0, width: 0 },
}

const Subsequent = () => (
  <div
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: 'top left 280px',
      backgroundRepeat: 'no-repeat',
      overflow: 'hidden',
      backgroundSize: 'cover',
    }}>
    <Box
      mb={2}
      mt={5}>
      <Headline
        gutterBottom
        height={1}
        mt={0}
        pl={5}
        size={2}
        style={{
          overflow: 'hidden',
          display: 'block',
          verticalAlign: 'unset',
        }}
        swatch
        textTransform="none"
        title={<WelcomeMessage />}
        weight={300}
      />
    </Box>

    <Carousel
      configuration={largeSingleSlides}
      sx={{
        ...sx,
      }}>
      {articles.map((article, index) => (
        <HeroSlide
          content={article}
          index={index}
          key={article.id}
          SlideContainerProps={{ width: '85%' }}>
          <SlideContent content={article} />
        </HeroSlide>
      ))}
    </Carousel>
    <Container>
      <RecentlyVisited />
      <Bookshelf />
      <Echoes />
    </Container>
  </div>
)

export default Subsequent
