import { contentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import BlockWrapperStyleTarget from './BlockWrapperStyleTarget'
import SkillsRollup from './SkillsRollup'
import StandardsRollup from './StandardsRollup'
import VocabRollup from './VocabRollup'

const rollupComponents = {
  'skills-rollup': SkillsRollup,
  'vocab-rollup': VocabRollup,
  'standards-rollup': StandardsRollup,
}
const MaybeHasContentSubType = props => {
  const { block, children, previewing = false } = props

  const RollupComponent = rollupComponents[block.contentSubType]

  return (
    <BlockWrapperStyleTarget block={block}>
      {RollupComponent ? (
        <RollupComponent {...{ block, previewing }} />
      ) : (
        children
      )}
    </BlockWrapperStyleTarget>
  )
}

MaybeHasContentSubType.propTypes = {
  block: contentShape.isRequired,
  children: PropTypes.node.isRequired,
  previewing: PropTypes.bool,
}

export default MaybeHasContentSubType
