import {
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { Outlet } from 'react-router-dom'
import Route404 from 'routing/Route404'
import AnswerKey from './AnswerKey'
import Echo from './Chapter/Echo'
import Subsection from './Chapter/Subsection'
import TabbedSectionOrContent from './Chapter/TabbedSectionOrContent'
import Source from './Source'

export const subsectionRouteConfig = {
  subsection: {
    path: `${CONTENT_TYPE_SUBSECTION}/:subsectionId`,
    pinnable: true,
    element: <TabbedSectionOrContent contentType={CONTENT_TYPE_SUBSECTION} />,
  },
}

export const chapterChildrenRouteConfig = {
  answerKey: {
    element: <AnswerKey />,
    hideFromStudent: true,
    path: 'answerKey',
  },
  echo: {
    path: `${CONTENT_TYPE_ECHO}/:echoId`,
    element: <Echo />,
  },
  source: {
    path: `${CONTENT_TYPE_SOURCE}/:contentId`,
    pinnable: true,
    element: <TabbedSectionOrContent contentType={CONTENT_TYPE_SOURCE} />,
  },
  multiPageSource: {
    path: `${CONTENT_TYPE_SOURCE}/:sourceId/${CONTENT_TYPE_SUBSECTION}/:subsectionId/*`,
    pinnable: true,
    element: <Subsection />,
  },
  ...subsectionRouteConfig,
}

const routeConfig = {
  chapter: {
    path: `${CONTENT_TYPE_CHAPTER}/:contentId/*`,
    element: <Outlet />,
    pinnableChildren: true,
    children: Object.values(chapterChildrenRouteConfig),
  },

  echo: {
    path: `${CONTENT_TYPE_ECHO}/:echoId`,
    element: <Echo />,
  },

  multiPageSource: {
    path: `${CONTENT_TYPE_SOURCE}/:sourceId/${CONTENT_TYPE_SUBSECTION}/:subsectionId/*`,
    pinnable: true,
    element: <Subsection />,
  },

  source: {
    path: `${CONTENT_TYPE_SOURCE}/:contentId`,
    pinnable: true,
    pinnableChildren: true,
    element: <Source />,
  },

  404: {
    path: '*',
    element: <Route404 collectionName="ContentViewer" />,
  },
}

export default routeConfig
