import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { SCORING_RESULT_CORRECT } from 'core/consts'
import { groupAndSortGroupShape } from 'core/shapes'
import { filter, flatten, map, orderBy } from 'fp/arrays'
import { matches } from 'fp/utils'
import {
  SCORING_MODE_NONE,
  interactiveVariants,
  scoringModesWhereGroupOrderMatters,
} from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useContext, useEffect, useId, useMemo, useState } from 'react'
import { compose } from 'redux'
import AddToGroupDialog from './AddToGroupDialog'
import GroupContent from './GroupContent'
import { context } from './context'

const getAnsweredOptions = {
  [interactiveVariants.NORMAL]: (
    groupId,
    options,
    answers,
    itemsWithGroupPositions,
    orderMatters,
  ) => {
    const filteredOptions = compose(
      orderBy('sortOrder'),
      filter(matches('droppedId', groupId)),
    )(options)

    return filteredOptions.map(option => {
      const isCorrect = answers[option.id] === SCORING_RESULT_CORRECT

      const itemInGroup = itemsWithGroupPositions.find(
        item => item.itemId === option.id,
      )

      const expectedGroup =
        option.groupId !== option.droppedId ? `${itemInGroup?.group}` : ''
      const expectedAnswer =
        expectedGroup +
        (orderMatters
          ? `${expectedGroup ? ', ' : ''}Position: ${itemInGroup?.position}`
          : '')

      return {
        actualAnswer: option.label,
        isCorrect,
        ...option,
        label: isCorrect ? option.label : expectedAnswer,
      }
    })
  },
  [interactiveVariants.GROUP_AND_SORT_VOCAB_FAMILIARITY]: (
    groupId,
    options,
  ) => {
    const groupOptions = compose(
      orderBy('displayIndex'),
      filter(matches('droppedId', groupId)),
    )(options)
    return groupOptions.map(option => ({
      ...option,
      actualAnswer: option.label,
    }))
  },
}

const StyledBox = styled(Box)(
  ({
    theme: {
      mixins: { borderS },
      palette,
      typography: { variants },
    },
  }) => ({
    ...borderS(palette.border[0]),
    ...variants['nav-item-uppercase-semibold'],
    color: palette.text.secondary,
    background: palette.background.paper,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    marginTop: -1,
    marginLeft: -1,
    flex: 0,
  }),
)

const Group = ({ group, idx }) => {
  const {
    completed,
    showGroupTotals,
    state: { options },
  } = useContext(context)
  const {
    interactive: {
      data: { groups, items } = {},
      scoring: { mode },
    } = {},
    interactiveData: { variant: interactiveVariant } = {},
    scoreData: {
      result: { byItemId: answers } = {},
    } = {},
  } = useContext(interactiveContext) || {}
  const variant = interactiveVariant || interactiveVariants.NORMAL
  const [groupOptions, setGroupOptions] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const itemsWithGroupPositions = useMemo(
    () =>
      compose(
        flatten,
        map(g =>
          compose(
            map((i, index) => ({
              group: g.heading,
              itemId: i.id,
              position: index + 1,
            })),
            filter(matches('groupId', g.id)),
          )(items),
        ),
      )(groups),
    [groups, items],
  )

  useEffect(() => {
    const scoringMode = mode || SCORING_MODE_NONE
    const orderMatters =
      scoringModesWhereGroupOrderMatters.includes(scoringMode)
    const newOptions =
      completed && scoringMode !== SCORING_MODE_NONE
        ? // get all of the original answers with the user answers included where applicable
          getAnsweredOptions[variant](
            group.id,
            options,
            answers || {},
            itemsWithGroupPositions,
            orderMatters,
          )
        : // get the items that have been dropped on this group
          options.filter(option => option.droppedId === group.id)

    setGroupOptions(newOptions)
  }, [
    answers,
    completed,
    group.id,
    itemsWithGroupPositions,
    mode,
    options,
    variant,
  ])

  const headingId = useId()

  const groupTotals = `(${groupOptions.length} of ${group.totalItems})`

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%">
        <StyledBox
          height="100%"
          id={headingId}
          textAlign="center">
          <Typography
            variant="nav-item-uppercase-semibold"
            width="100%">
            {group.heading}
            {Boolean(showGroupTotals && !completed) && (
              <span>{` ${groupTotals}`}</span>
            )}
          </Typography>
        </StyledBox>

        <GroupContent
          group={group}
          groupIdx={idx}
          groupOptions={groupOptions}
          headingId={headingId}
          setDialogOpen={setDialogOpen}
        />
      </Box>

      <AddToGroupDialog
        dialogOpen={dialogOpen}
        group={group}
        setDialogOpen={setDialogOpen}
      />
    </>
  )
}

Group.propTypes = {
  group: groupAndSortGroupShape.isRequired,
  idx: PropTypes.number.isRequired,
}

export default Group
