import { INTERACTIVE_TYPE_FUN_FACTS } from 'core/consts'
import Content from './Content'

// this is really only for the interactive preview and sample course, Fun Facts are
// an inline style within the RTE
const FunFacts = () => <Content />

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_FUN_FACTS,
}

export default FunFacts
