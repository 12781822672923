import { arraySequence } from 'fp/arrays'
import { sum } from 'fp/numbers'
import { mapValues } from 'fp/objects'
import { padding } from './config'
import {
  getLeftInnerX,
  getLeftOuterX,
  getRightInnerX,
  getRightOuterX,
} from './utils'

const range = (min, max) => ({
  min,
  max,
  width: max - min,
  mid: (min + max) / 2,
})

// This finds the approximate geometric center of each section,
// so when there's no response yet, we can put a call to action in the "middle" of it.
const findCentroid = (yMin, yMax, getXBoundsAtY, fontSize) => {
  // Calculate some points along the curved lines, clockwise.
  // This gives us an imaginary polygon that approximates the curved shapes.
  const yStart = yMin + fontSize
  const points = (numPoints => {
    const yRange = arraySequence(numPoints).map(
      offset => yMin + ((yMax - yStart) * (offset + 1)) / numPoints,
    )
    const xBounds = yRange.map(y => getXBoundsAtY(y))
    const topPoint = { x: getXBoundsAtY(yStart).mid, y: yStart }
    const rightPoints = xBounds.map((x, index) => ({
      x: x.max,
      y: yRange[index],
    }))
    const bottomPoint = { x: getXBoundsAtY(yMax).mid, y: yMax }
    const leftPoints = xBounds
      .map((x, index) => ({
        x: x.min,
        y: yRange[index],
      }))
      .reverse()
    return [topPoint, ...rightPoints, bottomPoint, ...leftPoints]
  })(10)

  // Calculate the centroid. Thank you, wikipedia. https://en.wikipedia.org/wiki/Centroid#Centroid_of_a_polygon
  const centroidPoints = points.map((point, index) => {
    const nextPoint = points[(index + 1) % points.length]
    return { self: point, next: nextPoint }
  })
  const area =
    0.5 *
    sum(...centroidPoints.map(p => p.self.x * p.next.y - p.next.x * p.self.y))

  return {
    middleX:
      sum(
        ...centroidPoints.map(
          p =>
            (p.self.x + p.next.x) * (p.self.x * p.next.y - p.next.x * p.self.y),
        ),
      ) /
      (6 * area),
    middleY:
      sum(
        ...centroidPoints.map(
          p =>
            (p.self.y + p.next.y) * (p.self.x * p.next.y - p.next.x * p.self.y),
        ),
      ) /
      (6 * area),
  }
}

export const twoCircleTextPositioning = positioning => {
  const [leftCircle, rightCircle] = positioning.circles
  const { intersections } = positioning
  const getMiddle = (xMin, xMax, yMin, yMax) => ({
    middleX: (xMin + xMax) / 2,
    middleY: (yMin + yMax) / 2,
  })

  const positions = {
    left: {
      align: 'left',

      yMin: leftCircle.center.y - leftCircle.radius + padding,
      yMax: leftCircle.center.y + leftCircle.radius - padding,

      xMin: leftCircle.center.x - leftCircle.radius,
      xMax: rightCircle.center.x - rightCircle.radius,

      getXBoundsAtY: y =>
        range(
          getLeftInnerX(y, leftCircle, positioning.fontSize),

          Math.min(
            getLeftOuterX(y, rightCircle, positioning.fontSize),
            getRightInnerX(y, leftCircle, positioning.fontSize),
          ),
        ),
    },

    right: {
      align: 'left',

      yMin: rightCircle.center.y - rightCircle.radius + padding,
      yMax: rightCircle.center.y + rightCircle.radius - padding,

      xMin: leftCircle.center.x + leftCircle.radius,
      xMax: rightCircle.center.x + rightCircle.radius,
      getXBoundsAtY: y =>
        range(
          Math.max(
            getRightOuterX(y, leftCircle, positioning.fontSize),
            getLeftInnerX(y, rightCircle, positioning.fontSize),
          ),
          getRightInnerX(y, rightCircle, positioning.fontSize),
        ),
    },

    'left-right': {
      align: 'middle',

      yMin: intersections.top.y + padding,
      yMax: intersections.bottom.y - padding,

      xMin: rightCircle.center.x - rightCircle.radius,
      xMax: leftCircle.center.x + leftCircle.radius,

      getXBoundsAtY: y =>
        range(
          getLeftInnerX(y, rightCircle, positioning.fontSize),
          getRightInnerX(y, leftCircle, positioning.fontSize),
        ),
    },
  }
  return mapValues(p => ({
    ...p,
    ...getMiddle(p.xMin, p.xMax, p.yMin, p.yMax, positioning.fontSize),
  }))(positions)
}

export const threeCircleTextPositioning = positioning => {
  const [leftCircle, rightCircle, bottomCircle] = positioning.circles
  const { intersections } = positioning

  const positions = {
    left: {
      align: 'left',
      yMin: leftCircle.center.y - leftCircle.radius + padding,
      yMax: intersections.leftBottom.y - padding,

      getXBoundsAtY: y =>
        range(
          getLeftInnerX(y, leftCircle, positioning.fontSize),
          Math.min(
            getLeftOuterX(y, rightCircle, positioning.fontSize),
            getLeftOuterX(y, bottomCircle, positioning.fontSize) ||
              Number.POSITIVE_INFINITY,
            getRightInnerX(y, leftCircle, positioning.fontSize),
          ),
        ),
    },

    right: {
      align: 'left',
      yMin: rightCircle.center.y - rightCircle.radius + padding,
      yMax: intersections.rightBottom.y - padding,

      getXBoundsAtY: y =>
        range(
          Math.max(
            getRightOuterX(y, leftCircle, positioning.fontSize),
            getRightOuterX(y, bottomCircle, positioning.fontSize) ||
              Number.NEGATIVE_INFINITY,
            getLeftInnerX(y, rightCircle, positioning.fontSize),
          ),
          getRightInnerX(y, rightCircle, positioning.fontSize),
        ),
    },

    'left-right': {
      align: 'middle',
      yMin: intersections.leftRightTop.y + padding,
      yMax: bottomCircle.center.y - bottomCircle.radius - padding,

      getXBoundsAtY: y =>
        range(
          getLeftInnerX(y, rightCircle, positioning.fontSize),
          getRightInnerX(y, leftCircle, positioning.fontSize),
        ),
    },

    bottom: {
      align: 'middle',
      yMin:
        Math.max(
          leftCircle.center.y + leftCircle.radius,
          rightCircle.center.y + rightCircle.radius,
        ) + padding,
      yMax: bottomCircle.center.y + bottomCircle.radius - padding,

      getXBoundsAtY: y =>
        range(
          getLeftInnerX(y, bottomCircle, positioning.fontSize),
          getRightInnerX(y, bottomCircle, positioning.fontSize),
        ),
    },

    'bottom-left': {
      align: 'middle',
      yMin: intersections.middleLeft.y + padding,
      yMax: leftCircle.center.y + leftCircle.radius - padding,

      getXBoundsAtY: y =>
        range(
          Math.max(
            getLeftInnerX(y, bottomCircle, positioning.fontSize),
            getLeftInnerX(y, leftCircle, positioning.fontSize),
          ),
          Math.min(
            getLeftOuterX(y, rightCircle, positioning.fontSize),
            getRightInnerX(y, leftCircle, positioning.fontSize),
          ),
        ),
    },

    'bottom-right': {
      align: 'middle',
      yMin: intersections.middleRight.y + padding,
      yMax: rightCircle.center.y + rightCircle.radius - padding,

      getXBoundsAtY: y =>
        range(
          Math.max(
            getRightOuterX(y, leftCircle, positioning.fontSize),
            getLeftInnerX(y, rightCircle, positioning.fontSize),
          ),
          Math.min(
            getRightInnerX(y, bottomCircle, positioning.fontSize),
            getRightInnerX(y, rightCircle, positioning.fontSize),
          ),
        ),
    },

    middle: {
      align: 'middle',

      yMin: intersections.middleLeft.y,
      yMax: intersections.middleBottom.y - positioning.fontSize,

      getXBoundsAtY: y =>
        range(
          getLeftInnerX(y, rightCircle, positioning.fontSize),
          getRightInnerX(y, leftCircle, positioning.fontSize),
        ),
    },
  }
  return mapValues(p => ({
    ...p,
    ...findCentroid(p.yMin, p.yMax, p.getXBoundsAtY, positioning.fontSize),
  }))(positions)
}
