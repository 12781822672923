import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Select from 'common/formControls/selects/HFSelect'
import {
  SCORING_FEEDBACK_CONTENT_ALL,
  SCORING_FEEDBACK_CONTENT_NONE,
  SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES,
  SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END,
  SCORING_FEEDBACK_VISIBILITY_AFTER_REVIEW,
  SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION,
  SCORING_FEEDBACK_VISIBILITY_IMMEDIATE,
} from 'core/consts'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const AnswerKey = () => {
  const { setValue } = useFormContext()
  const requestPeerReviews = useWatch({ name: 'requestPeerReviews' })
  const scoringFeedbackContent = useWatch({
    name: 'data.settings.scoringFeedbackContent',
  })
  const scoringFeedbackVisibility = useWatch({
    name: 'data.settings.scoringFeedbackVisibility',
  })
  const [includePeerReviewOption, setIncludePeerReviewOption] =
    useState(requestPeerReviews)

  useEffect(() => {
    if (
      !requestPeerReviews &&
      scoringFeedbackVisibility === SCORING_FEEDBACK_VISIBILITY_AFTER_REVIEW
    ) {
      setValue(
        'data.settings.scoringFeedbackVisibility',
        SCORING_FEEDBACK_VISIBILITY_IMMEDIATE,
      )
    }

    setIncludePeerReviewOption(requestPeerReviews)
  }, [requestPeerReviews, setValue, scoringFeedbackVisibility])

  return (
    <Stack mt={2}>
      <Select
        fullWidth
        label="After assignment submission, allow students to see:"
        name="data.settings.scoringFeedbackContent">
        <MenuItem value={SCORING_FEEDBACK_CONTENT_ALL}>
          per-question score, responses, and explanations
        </MenuItem>
        <MenuItem value={SCORING_FEEDBACK_CONTENT_NO_EXPLANATIONS}>
          per-question score and responses
        </MenuItem>
        <MenuItem value={SCORING_FEEDBACK_CONTENT_TOTAL_SCORE_WITH_RESPONSES}>
          total score and responses
        </MenuItem>
        <MenuItem value={SCORING_FEEDBACK_CONTENT_TOTAL_SCORE}>
          total score only
        </MenuItem>
        <MenuItem value={SCORING_FEEDBACK_CONTENT_NONE}>nothing</MenuItem>
      </Select>

      <Select
        disabled={scoringFeedbackContent === SCORING_FEEDBACK_CONTENT_NONE}
        fullWidth
        label="Show scoring to students:"
        name="data.settings.scoringFeedbackVisibility">
        <MenuItem value={SCORING_FEEDBACK_VISIBILITY_IMMEDIATE}>
          immediately after submission
        </MenuItem>
        {Boolean(includePeerReviewOption) && (
          <MenuItem value={SCORING_FEEDBACK_VISIBILITY_AFTER_REVIEW}>
            after peer-review completion
          </MenuItem>
        )}
        <MenuItem value={SCORING_FEEDBACK_VISIBILITY_DAY_AFTER_SUBMISSION}>
          the day after submission
        </MenuItem>
        <MenuItem value={SCORING_FEEDBACK_VISIBILITY_AFTER_ASSIGNMENT_END}>
          after the assignment end time
        </MenuItem>
      </Select>
    </Stack>
  )
}

AnswerKey.formFields = [
  'data.settings.scoringFeedbackContent',
  'data.settings.scoringFeedbackVisibility',
]

export default AnswerKey
