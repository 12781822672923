import TroubleshootRounded from '@mui/icons-material/TroubleshootRounded'
import DataTable from 'common/tables/DataTable'
import {
  LinkToTableCell,
  RowDetailsToggle,
  RowSelector,
  SimplePercentageCell,
  asDetail,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import {
  avgAssessmentScoreCell,
  avgInteractiveScoreCell,
  avgPeerScoreCell,
  completedDateCell,
  endDateCell,
  progressCell,
  scoreCell,
  statusCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { prefix } from 'fp/strings'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import { useParams } from 'react-router-dom'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import { classroomAssignmentsUrl } from 'routing/consts'

const tableId = 'clStudentsReport'

const buildColumns = has => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  return [
    postMVP &&
      RowSelector({
        singleLabel: 'Select Assignment',
        pluralLabel: 'Select all Assignments',
      }),

    asPrimary({
      accessorFn: get('assignment.name'),
      cell: LinkToTableCell(
        compose(prefix(`${classroomAssignmentsUrl}/`), get('assignmentId')),
      ),
      header: 'Name',
      id: 'assignmentId',
    }),

    asDetail(completedDateCell),

    progressCell,

    postMVP && [
      scoreCell,

      asDetail(statusCell),

      asDetail(avgInteractiveScoreCell),

      SimplePercentageCell({ header: 'My Review', id: 'teacherReviewScore' }),
    ],

    asDetail(endDateCell),

    postMVP && [
      asDetail(avgAssessmentScoreCell),

      asDetail(avgPeerScoreCell),
    ],

    RowDetailsToggle(),
  ]
}

const searchConfig = (has, limit) => studentId => ({
  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.modifier('addAssignment').is(TroubleshootRounded),
    alter.set.where('userId').is(studentId),
  )(assert()),

  pluralLabel: 'Assignments',

  restEndpoint: restEndpoint.userAssignments,

  searchFields: ['assignment.name'],

  singleLabel: 'Assignment',
})

const withSearchProvider = WrappedComponent => {
  const Enhanced = initialTestingState => {
    const { studentId } = useParams()

    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)(studentId)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table">
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
