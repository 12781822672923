import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const GradeIndicator = forwardRef(
  ({ children, numStudents, color, ...rest }, ref) => (
    <Stack
      direction="row"
      mr="10px !important"
      ref={ref}
      spacing={1}
      {...rest}>
      <Box
        color={color}
        display="inline-flex">
        {children}
      </Box>
      <span>{numStudents}</span>
    </Stack>
  ),
)

GradeIndicator.propTypes = {
  children: componentShape.isRequired,
  numStudents: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export default GradeIndicator
