import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledGroups = styled(Box, { name: 'GroupAndSort-Groups' })(
  ({
    theme: {
      mixins: { rem },
      breakpoints,
    },
  }) => ({
    marginTop: rem(2.4),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    [breakpoints.up('sm')]: {
      justifyContent: 'center',
      '> div': {
        width: '100%',
      },
    },
  }),
)

export default StyledGroups
