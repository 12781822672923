import { styled } from '@mui/material/styles'
import Stack from '@mui/system/Stack'

const StyledContainer = styled(Stack, { name: 'Timeline-Page-Stack' })(
  ({
    theme: {
      palette,
      typography,
      mixins: { interTextSizeR, rem },
    },
  }) => ({
    height: '100%',
    '&.padding-left .interactive-image': {
      figure: { display: 'flex', justifyContent: 'flex-end' },
    },
    '> div': {
      flex: 1,
      p: interTextSizeR(1.4, 1.8),
      '.content-heading, p': { marginLeft: 0 },
      '& .caption p': {
        ...typography.caption,
        lineHeight: 1.2,
      },
      '.image-embedded': { marginBottom: 0 },
    },

    '&.small-screen > div': {
      '&[data-variant="interactive"]': {
        '*:not(button):not(.image-annotations-overlay):not(.image-annotations-overlay *)':
          { height: '100%' },
        minHeight: 0,
        img: { width: 'unset' },
      },
      '&:last-child': { flex: 'none', marginTop: rem(2), width: '100%' },
    },

    '.MuiBackdrop-root': {
      button: {
        height: '5%',
      },
    },

    figure: {
      display: 'inline',
    },

    '.interactive-image': {
      background: 'none',
      margin: 0,
      padding: 0,
      figure: {
        background: 'transparent',
        display: 'flex',
        '> div.image-wrapper': {
          maxWidth: '90%',
          display: 'flex',
          alignItems: 'center',
          button: {
            display: 'none',
          },
        },
      },
    },

    '&.bleed-width': {
      'figure, img': {
        maxWidth: '100%',
        width: 'unset',
        objectFit: 'contain',
        'div.image-wrapper': {
          maxWidth: '100% !important',
        },
      },
    },

    '&.bleed-height': {
      '>div[data-variant="interactive"]': {
        height: '100%',
        minWidth: '40%',
        overflow: 'hidden',
        '[data-contentsubtype="image"], .block-full-width, .expandable, .interactive-image, .interactive-image>div, .image-no-gutters, .image-no-gutters >div, figure, img':
          {
            height: '100%',
          },
        'figure,img': {
          textAlign: 'center',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          position: 'relative',
          margin: '0 auto',
          marginTop: 2,
          display: 'inline-block',
          alignContent: 'center',
          background: 'none',
          'div.image-wrapper': {
            display: 'inline-block',
            height: 'auto',
            maxWidth: 'unset',
            width: 'unset',
          },
        },
      },
    },

    '&.timelineEcon .content-heading,  &:not(.timelineEcon) h3:not(.content-heading)':
      {
        ...typography.h1,
        color: palette.secondary.dark,
        fontWeight: 700,
      },

    '&.normal .content-heading': {
      marginTop: rem(2),
      fontSize: rem(2.4),
    },

    '&.timelineEcon': {
      ' >div:first-of-type': {
        flex: 3,
      },
      '.content-heading': {
        fontSize: rem(4),
        marginTop: 0,
      },
      h3: {
        fontSize: rem(3),
      },
    },
  }),
)

export default StyledContainer
