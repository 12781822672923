import Divider from '@mui/material/Divider'
import MenuButton from 'common/menus/MenuButton'
import { User } from 'react-feather'
import InsertPinMenuItem from 'routing/TraverseRoute/Pinning/InsertPinMenuItem'
import LogoutMenuItems from 'routing/shells/LogoutMenuItems'

const SimplifiedUserMenu = props => (
  <MenuButton
    data-testid="user-menu"
    icon={<User />}
    label="Viewer options menu"
    {...props}>
    <InsertPinMenuItem />

    <Divider />

    <LogoutMenuItems />
  </MenuButton>
)

export default SimplifiedUserMenu
