import { contentShape } from 'core/shapes'
import { useChapterSkillVocabPhrases } from 'hooks/useChapterVocabPhrases'
import CommonVocabRollup from '../CommonVocabRollup'

const SkillsRollup = ({ block }) => {
  const { contentType, id: contentId } = block
  const contentVocabPhrases = useChapterSkillVocabPhrases({
    contentId,
    contentType,
  })

  return <CommonVocabRollup vocabPhrases={contentVocabPhrases} />
}

SkillsRollup.propTypes = {
  block: contentShape.isRequired,
}

export default SkillsRollup
