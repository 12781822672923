import { styled } from '@mui/material/styles'

const Container = styled('div')(
  ({
    theme: {
      palette,
      mixins: { transition },
    },
  }) => ({
    position: 'relative',
    '.readOnly': {
      cursor: 'default',
    },
    color: 'white',

    svg: {
      strokeWidth: 0.1,
      stroke: 'black',
      a: {
        fill: 'white',
      },
      circle: {
        pointerEvents: 'all',
        ...transition(),
        '&:hover': {
          fill: palette.cobalt[3],
        },

        '&:not(.readOnly).outline': {
          pointerEvents: 'none',
          strokeWidth: 1,
          fill: 'transparent',
        },
      },
      text: {
        pointerEvents: 'none',
      },
    },
  }),
)

export default Container
