import AddIcon from '@mui/icons-material/AddRounded'
import { Button } from '@mui/material'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import useToggleState from 'hooks/useToggleState'
import { contentBuilderUrl } from 'routing/consts'
import SelectAssetTypeDialog, {
  type SelectedAssetTypeArg,
} from './SelectAssetTypeDialog'

const CreateContent = () => {
  const [open, toggleOpen] = useToggleState(false)
  const { navigate } = useNavigation()

  const handleCreate = ({
    contentType,
    contentSubType,
  }: SelectedAssetTypeArg) => {
    navigate(
      contentType === CONTENT_TYPE_RUBRIC
        ? `${contentBuilderUrl}/${contentType}/create`
        : contentSubType
          ? `${contentBuilderUrl}/${contentType}/${contentSubType}/create`
          : `${contentBuilderUrl}/curriculum/${contentType}/create`,
    )
  }

  return (
    <>
      <Button
        color="secondary"
        onClick={toggleOpen}
        startIcon={<AddIcon />}
        variant="primary">
        Add to Library
      </Button>

      <SelectAssetTypeDialog
        onClose={toggleOpen}
        onProceed={handleCreate}
        open={open}
      />
    </>
  )
}
const Wired = withAbilityCheck(CreateContent, ABILITY_CONTENT_CREATION)

export default Wired
