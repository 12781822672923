import DataTable from 'common/tables/DataTable'
import {
  LanguageSelectTableCell,
  LinkToTableCell,
  ProficiencySelectTableCell,
  RowDetailsToggle,
  RowSelector,
  SimpleCell,
  asDetail,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import {
  firstNameCell,
  lastNameCell,
  lastOnlineCell,
  nextDueDateCell,
  numGroupsCell,
  numTeacherIdsCell,
  openAssignmentsCell,
  proficiencyCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import withSaving from 'common/tables/DataTable/Cells/withSaving'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import { get, set } from 'fp/objects'
import { prefix } from 'fp/strings'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import { useParams } from 'react-router-dom'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import { studentsUrl } from 'routing/consts'

const tableId = 'clGroupsReport'

const buildColumns = has => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  return [
    postMVP &&
      RowSelector({
        singleLabel: 'Select Student',
        pluralLabel: 'Select all Students',
      }),

    asPrimary(
      set(
        'cell',
        LinkToTableCell(compose(prefix(`${studentsUrl}/`), get('id'))),
      ),
    )(firstNameCell()),

    asPrimary(lastNameCell()),

    set('cell', ProficiencySelectTableCell)(proficiencyCell),

    lastOnlineCell,

    asDetail(openAssignmentsCell),

    asDetail(numGroupsCell),

    postMVP &&
      asDetail(
        SimpleCell({
          cell: withSaving('users')(LanguageSelectTableCell),
          header: 'Supplemental Language',
          id: 'languageId',
        }),
      ),

    asDetail(nextDueDateCell),

    asDetail(numTeacherIdsCell),

    RowDetailsToggle(),
  ]
}

const searchConfig = (has, limit) => groupId => ({
  columns: buildColumns(has),

  initialSquery: compose(
    alter.set.modifier('withMetadata').is(true),
    setDefaultLimit(limit),
    alter.set.modifier('studentsInGroupId').is(groupId),
  )(assert()),

  pluralLabel: 'Students',

  restEndpoint: restEndpoint.users,

  searchFields: ['firstName', 'lastName'],

  singleLabel: 'Student',
})

const withSearchProvider = WrappedComponent => {
  const Enhanced = initialTestingState => {
    const { groupId } = useParams()

    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)(groupId)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table">
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
