import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { maybeParseJSON } from 'fp/strings'
import withProps from 'hoc/withProps'
import { useContext } from 'react'
import { interactiveContext } from '../../../Interactive/InteractiveProvider'
import Legend from '../Legend'
import { PacingOptionLabel } from '../PacingOptionLabel'
import usePacingGuideTheme from '../usePacingGuideTheme'
import { groupings, pacingOptions } from './consts'
import useChapterPacingGuideTheme from './useChapterPacingGuideTheme'

const GroupingLabel = styled(
  withProps(Typography, { variant: 'paragraph-semibold' }),
)({
  display: 'inline-block',
  marginRight: '0.5rem',
})

const PacingOptionTableCell = styled(TableCell)({
  width: '10rem',
})

const ChapterPacingGuide = () => {
  const {
    interactiveData: { config },
  } = useContext(interactiveContext)
  const { sections } = maybeParseJSON(config)
  const { pacingOptionHeaderStyle } = usePacingGuideTheme()
  const { dayCellValueStyle, groupingColor } = useChapterPacingGuideTheme()
  const isSmallScreen = useContainerQuery().down('lg')

  return (
    <>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        gap={isSmallScreen ? 0 : 8}>
        <Legend
          mb={4}
          title="Pacing Options">
          {pacingOptions.map(({ key, value }) => (
            <ListItem key={key}>
              <PacingOptionLabel>{key}</PacingOptionLabel>: {value}
            </ListItem>
          ))}
        </Legend>

        <Legend
          mb={2}
          title="Suggested Groupings">
          {groupings.map(({ key, value }) => (
            <ListItem key={key}>
              <Typography
                sx={{ color: groupingColor(key) }}
                variant="paragraph-semibold">
                {key}
              </Typography>
              : {value}
            </ListItem>
          ))}
        </Legend>
      </Stack>

      <Table variant="pacing-guide">
        <TableHead>
          <TableRow>
            <TableCell
              rowSpan={2}
              scope="col"
              width="25%">
              Section
            </TableCell>
            <TableCell
              rowSpan={2}
              scope="col">
              Features
            </TableCell>
            <TableCell
              colSpan={3}
              scope="colgroup">
              Days
            </TableCell>
          </TableRow>
          <TableRow>
            {pacingOptions.map(({ key }, index) => (
              <PacingOptionTableCell
                key={key}
                scope="col"
                sx={pacingOptionHeaderStyle(index)}>
                <PacingOptionLabel>{key}</PacingOptionLabel>
              </PacingOptionTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {sections.map(
            ({
              name: sectionName,
              features,
              pacingOptions: sectionPacingOptions,
            }) => (
              <TableRow key={sectionName}>
                <TableCell>{sectionName}</TableCell>

                <TableCell>
                  <List variant="standard">
                    {features.map(
                      ({ grouping, name: featureName, subItems = [] }) => (
                        <ListItem key={featureName}>
                          {Boolean(grouping) && (
                            <GroupingLabel
                              style={{ color: groupingColor(grouping) }}>
                              {grouping}
                            </GroupingLabel>
                          )}
                          {featureName}
                          {Boolean(subItems.length) && (
                            <List variant="standard">
                              {subItems.map(subItem => (
                                <ListItem key={subItem}>{subItem}</ListItem>
                              ))}
                            </List>
                          )}
                        </ListItem>
                      ),
                    )}
                  </List>
                </TableCell>

                {pacingOptions.map(({ key }) => {
                  const cellValue = sectionPacingOptions[key]
                  return (
                    <TableCell
                      className="day-column"
                      key={key}
                      sx={dayCellValueStyle(cellValue)}>
                      {cellValue}
                    </TableCell>
                  )
                })}
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default ChapterPacingGuide
