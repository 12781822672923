import List from '@mui/material/List'
import Tooltip from '@mui/material/Tooltip'
import TE from 'common/indicators/TE'
import Link from 'common/navigation/links/Link'
import { componentShape, contentTypeShape } from 'core/shapes'
import useContent from 'hooks/useContent'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { resolvePath } from 'react-router-dom'
import { getLocation } from 'selectors/routing'

export const DIRECTIVE_NEEDS_SUB_CONTENT_TYPE = 'DNSCT'

const LinkWithSubContent = forwardRef(
  ({ contentId, contentType, url, ...rest }, ref) => {
    const { contentSubType } = useContent({ contentId, contentType }) || {}

    return contentSubType ? (
      <Link
        ref={ref}
        {...rest}
        to={url.replace('__subContentType__', contentSubType)}
        variant="breadcrumbs"
      />
    ) : (
      <Link
        ref={ref}
        {...rest}
        style={{ pointerEvents: 'none' }}
        to={url}
      />
    )
  },
)

LinkWithSubContent.propTypes = {
  children: componentShape.isRequired,
  contentId: PropTypes.string.isRequired,
  contentType: contentTypeShape.isRequired,
  url: PropTypes.string.isRequired,
}

const BreadcrumbsRenderer = ({ breadcrumbs }) => {
  const { pathname } = useSelector(getLocation)
  return (
    <List
      aria-label="Breadcrumbs"
      variant="breadcrumbs">
      {breadcrumbs
        .map(breadcrumb => ({ ...breadcrumb, url: breadcrumb.key }))
        .map(breadcrumb => {
          const { key, location } = breadcrumb
          if (key === location?.pathname) {
            return {
              ...breadcrumb,
              url: resolvePath(location),
            }
          }
          return breadcrumb
        })
        .map(({ breadcrumb, key, teacherEdition, title = '', url }) => {
          const indicator = teacherEdition ? <TE /> : null

          return (
            <li key={key}>
              <Tooltip title={title}>
                {key.directive === DIRECTIVE_NEEDS_SUB_CONTENT_TYPE ? (
                  <LinkWithSubContent {...key}>
                    {indicator} {breadcrumb}
                  </LinkWithSubContent>
                ) : (
                  <Link
                    disabled={pathname === url.pathname}
                    to={url}
                    variant="breadcrumbs">
                    {indicator} {breadcrumb}
                  </Link>
                )}
              </Tooltip>
            </li>
          )
        })}
    </List>
  )
}

BreadcrumbsRenderer.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      breadcrumb: componentShape.isRequired,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      location: PropTypes.object,
      match: PropTypes.object,
      title: PropTypes.string,
    }),
  ).isRequired,
}

export default BreadcrumbsRenderer
