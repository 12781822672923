import MenuItem from '@mui/material/MenuItem'
import TE from 'common/indicators/TE'
import {
  ABILITY_TEACHER_INTERFACE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'

const TeacherEditionMenuItem = withAbilityCheck(() => {
  const { displayingTeContent, teContentAvailable, toggleTeContent } =
    useContext(contentViewerContext)

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  return teContentAvailable ? (
    <MenuItem
      disabled={presenterModeEnabled}
      onClick={toggleTeContent}>
      <TE
        color="navigation.toc"
        mr={1}
      />
      {displayingTeContent ? 'Hide' : 'Show'} Teacher Edition
    </MenuItem>
  ) : null
}, ABILITY_TEACHER_INTERFACE)

export default TeacherEditionMenuItem
