import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from 'common/dialogs/Dialog'
import useNavigation from 'hooks/useNavigation'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { contentAuthorUrl } from 'routing/consts'
import AuthoringTypeSelector, { authoringTypes } from './AuthoringTypeSelector'

const CreationDialog = ({
  onClose,
  open,
}: { onClose: VoidFunction; open: boolean }) => {
  const { navigate } = useNavigation()
  const dispatch = useDispatch()
  const [selectedType, setSelectedType] = useState('singlePageSource')

  const handleContinue = () => {
    onClose()

    const { contentSubType, contentType } = authoringTypes[selectedType]

    dispatch({
      type: 'AUTHOR_CREATE_NEW_CONTENT',
      kind: selectedType,
      contentType,
      contentSubType,
    })

    navigate(
      `${contentAuthorUrl}/create/${contentType}${contentSubType ? `/type/${contentSubType}` : ''}`,
    )
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      showCloseButton
      swatch
      title="Select Content Type to Create"
      variant="maximized-vertically">
      <DialogContent>
        <AuthoringTypeSelector {...{ selectedType, setSelectedType }} />
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          variant="secondary">
          Cancel
        </Button>

        <Button
          color="secondary"
          onClick={handleContinue}
          variant="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CreationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CreationDialog
