import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import Headline from 'common/text/Headline'
import {
  ABILITY_ASSIGNMENT_CREATION,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS,
} from 'core/consts'
import { get, set } from 'fp/objects'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useAbilityCheck from 'hooks/useAbilityCheck'
import PropTypes from 'prop-types'
import Divider from '../Divider'

const Assignments = withAbilityCheck(({ onChange, preferences }) => {
  // const { updateOpen } = urlParams
  // const assignmentPrefsChanged = () => {
  //   const { assignmentPrefs: { gdocs: gdocsOrig, nicknames: nicknamesOrig } } = originalUser
  //   return nicknamesOrig !== nicknames || gdocsOrig !== gdocs
  // }
  const hasGoogleDocsFlag = useAbilityCheck(
    FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS,
  )

  const handleChange = propName => () => {
    const value = get(propName)(preferences)
    onChange(set(propName, !value))
  }

  return (
    <Headline
      gutterBottom
      mt={1}
      size={4}
      title="Assignments">
      <SwitchFormControl
        checked={get('assignmentStudentNicknames')(preferences)}
        color="secondary"
        helperText="Show student nicknames to students in all future assignments"
        label="Student Nicknames"
        onChange={handleChange('assignmentStudentNicknames')}
      />

      {Boolean(hasGoogleDocsFlag) && (
        <SwitchFormControl
          checked={get('allowGoogleDocs')(preferences)}
          color="secondary"
          helperText="Allow students to use Google documents to submit assignments in all future assignments"
          label="Allow Google Docs"
          onChange={handleChange('allowGoogleDocs')}
        />
      )}

      {/* {Boolean(assignmentPrefsChanged()) && (
        <SwitchFormControl
          checked={updateOpen}
          helperText="Update all open assignments to reflect new assignment options"
          label="Update Open Assignments"
          onChange={() => dispatch({ type: 'assignments/updateOpen', payload: !updateOpen })}
          summary
        />
      )} */}

      <Divider />
    </Headline>
  )
}, ABILITY_ASSIGNMENT_CREATION)

Assignments.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default Assignments
