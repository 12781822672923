import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import { assert, alter } from 'core/store/search/squery'
import { isDefined } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import useLocalSetting from 'hooks/useLocalSetting'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import { useParams } from 'react-router-dom'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import PercentagesView from './PercentagesView'
import ScoresView from './ScoresView'
import StandardsMasteryView from './StandardsMasteryView'
import StandardsView from './StandardsView'

const viewComponents = {
  percentages: PercentagesView,
  scores: ScoresView,
  standards: StandardsView,
  standardsMastery: StandardsMasteryView,
}

const tableId = 'clAssignmentsList'

const searchConfig = (_, limit, groupId, studentId) => ({
  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.modifier('includeInactive').is(false),
    alter.set.orderBy('dateCreated', 'desc'),
    alter.set.modifier('addContentType').is(true),
    alter.set.modifier('addScoreableContent').is(true),
    alter.set
      .modifier(isDefined(groupId) ? 'forGroupId' : 'forStudentId')
      .is(groupId || studentId),
  )(assert()),

  pluralLabel: 'Grades',

  restEndpoint: restEndpoint.assignments,

  searchFields: ['name'],

  singleLabel: 'Grade',
})

const withSearchProvider = WrappedComponent => {
  const Enhanced = initialTestingState => {
    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const { groupId, studentId } = useParams()
    const [view] = useLocalSetting('gradebookView', 'scores')

    const config = searchConfig(has, limit, groupId, studentId)
    return (
      <SearchProvider
        hideViewToggle
        initialTestingState={initialTestingState}
        ItemRenderer={viewComponents[view]}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}>
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
