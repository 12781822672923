import HFCheckbox from 'common/formControls/switches/HFCheckbox'
import FormSection from 'common/layout/FormSection'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import { useSelector } from 'react-redux'
import { getDecoratedUser } from 'selectors/users'

type UserState = {
  district: {
    name: string
  }
  school: {
    name: string
  }
}

const SharingControls = () => {
  const { district, school } = useSelector(getDecoratedUser) as UserState

  return (
    <HeadlineStyleOffset offset={1}>
      <FormSection
        title="Share With"
        variant="contained">
        <HFCheckbox
          label={school.name}
          name="sharedWithSchool"
        />

        <HFCheckbox
          label={district.name}
          name="sharedWithDistrict"
        />
      </FormSection>
    </HeadlineStyleOffset>
  )
}

export default SharingControls
