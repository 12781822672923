import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { createRef, useEffect } from 'react'

const AudioVisualizer = ({ audioData, height = 100, width = 530 }) => {
  const canvasRef = createRef()
  const { palette } = useTheme()
  const strokeStyle = palette.cobalt[0]

  useEffect(() => {
    const draw = () => {
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      let x = 0
      const sliceWidth = (width * 1.0) / audioData.length

      context.lineWidth = 2
      context.strokeStyle = strokeStyle
      context.clearRect(0, 0, width, height)

      context.beginPath()
      context.moveTo(0, height / 2)
      let y
      audioData.forEach(item => {
        y = (item / 255.0) * height
        context.lineTo(x, y)
        x += sliceWidth
      })
      context.lineTo(x, height / 2)
      context.stroke()
    }

    draw()
  }, [audioData, canvasRef, height, strokeStyle, width])

  return (
    <canvas
      height={height}
      ref={canvasRef}
      width={width}
    />
  )
}

AudioVisualizer.propTypes = {
  audioData: PropTypes.object.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default AudioVisualizer
