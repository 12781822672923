import MenuItem from '@mui/material/MenuItem'
import SwatchSelect, {
  BorderedSwatch,
} from 'common/formControls/selects/SwatchSelect'
import { set } from 'fp/objects'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useCallback, useContext } from 'react'
import { px, rem } from 'styling/theming/base/mixins'

const fontSizes = {
  small: rem(1.4),
  medium: rem(1.8),
  large: rem(2.4),
}

const renderSwatch = ({ size, value }) => (
  <BorderedSwatch
    {...{ size, value }}
    sx={{
      fontSize: fontSizes[value],
      fontWeight: 'bold',
      lineHeight: px(size),
      textAlign: 'center',
      verticalAlign: 'middle',
      backgroundColor: 'background.default',
    }}>
    Aa
  </BorderedSwatch>
)

const FontSizeSelection = () => {
  const { interactionData, onInteract } = useContext(interactiveContext)

  const handleChange = useCallback(
    ({ target }) => {
      onInteract(set('interactionData.fontSize', target.value))
    },
    [onInteract],
  )

  return (
    <SwatchSelect
      defaultValue="medium"
      label="Text Sizing"
      name="fontSize"
      onChange={handleChange}
      renderSwatch={renderSwatch}
      value={interactionData.fontSize}>
      <MenuItem value="small">Small</MenuItem>
      <MenuItem value="medium">Medium</MenuItem>
      <MenuItem value="large">Large</MenuItem>
    </SwatchSelect>
  )
}

export default FontSizeSelection
