import MenuItem from '@mui/material/MenuItem'
import { CONTENT_TYPE_CHAPTER, CONTENT_TYPE_ECHO } from 'core/consts'
import { contentShape } from 'core/shapes'
import useLocalSetting from 'hooks/useLocalSetting'
import useNavigation from 'hooks/useNavigation'
import { labels } from 'hss/ContentBuilder/consts'
import { useSelector } from 'react-redux'
import { SETTING_KEY_CONTENT_VIEWER_EXIT } from 'reducers/localSettings'
import { appUrl } from 'routing/consts'
import { getViewerTopLevelContent } from 'selectors/contentViewer'

const ExitContentViewMenuItem = ({ content }) => {
  const { navigate } = useNavigation()
  const [{ to }] = useLocalSetting(SETTING_KEY_CONTENT_VIEWER_EXIT, {
    to: appUrl,
  })

  const { contentType: topLevelContentType } =
    useSelector(getViewerTopLevelContent) || {}
  const isEchoWithinNarrativeContent = Boolean(
    content.contentType === CONTENT_TYPE_ECHO &&
      topLevelContentType === CONTENT_TYPE_CHAPTER,
  )

  const labelText = isEchoWithinNarrativeContent
    ? labels[CONTENT_TYPE_CHAPTER]
    : labels[content.contentType]

  return <MenuItem onClick={() => navigate(to)}>Exit {labelText}</MenuItem>
}

ExitContentViewMenuItem.propTypes = {
  content: contentShape.isRequired,
}

export default ExitContentViewMenuItem
