import RichTextEdit from 'common/formControls/textInputs/RichTextEdit'
import Headline from 'common/text/Headline'
import Html from 'common/text/Html'
import withProps from 'hoc/withProps'
import { ECHO_FEATURE_BLASTY_BLAST } from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import QuestionPrompt from 'hss/sections/contentBlocks/Interactive/QuestionPrompt'
import { Fragment, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { isStudent } from 'selectors/users'
import { echoMaxPaneWidth, echoTabSize } from 'styling/theming/base'
import CollapsibleInstructions from '../CollapsibleInstructions'
import FeatureFootnotes from '../FeatureFootnotes'
import FeatureHeadline from '../FeatureHeadline'
import SubmitButton from '../SubmitButton'
import FeatureBlastStaticRenderer from './FeatureBlastStaticRenderer'

const feature = ECHO_FEATURE_BLASTY_BLAST

const FeatureBlast = () => {
  const {
    completed,
    interactionData: { response: initialResponse = '' },
    interactiveData: {
      characterLimit,
      interactiveInstructions,
      prompt,
      studentInstructions,
    },
    isGrading,
    onInteract,
    submittable,
  } = useContext(interactiveContext)

  const userIsStudent = useSelector(isStudent)

  const [response, setResponse] = useState(initialResponse)

  const onChange = ({ target }) => {
    const newValue = target?.value
    setResponse(newValue)
    onInteract({ response: newValue })
  }

  const InstructionsWrapper = isGrading ? CollapsibleInstructions : Fragment
  const ResponseWrapper = isGrading
    ? withProps(Headline, { my: 4, title: 'Student Response' })
    : Fragment

  return (
    <>
      <FeatureHeadline title="Respond">
        <InstructionsWrapper>
          <Html body={interactiveInstructions} />

          <QuestionPrompt body={prompt} />

          <Html body={studentInstructions} />
        </InstructionsWrapper>
      </FeatureHeadline>

      {isGrading ? (
        <ResponseWrapper>
          <FeatureBlastStaticRenderer />
        </ResponseWrapper>
      ) : submittable ? (
        <>
          <RichTextEdit
            characterLimit={characterLimit}
            excludeToolButtons={['*']}
            InputProps={{ disabled: completed }}
            // 64 is to account for padding and margins
            // adjust it if ever the echo panel styling changes
            maxWidth={echoMaxPaneWidth - echoTabSize - 64}
            minimized
            onChange={onChange}
            showStatus
            showWordCount
            value={response}
          />

          <SubmitButton disabled={completed || !userIsStudent} />
        </>
      ) : (
        <Html body={response || '(no response)'} />
      )}

      <FeatureFootnotes feature={feature} />
    </>
  )
}

export default FeatureBlast
