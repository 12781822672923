import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import { identity, when } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { isPastMaxSubmitDate } from 'hss/utils'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { createConfirmation } from 'react-confirm'
import { Lock } from 'react-feather'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { getContextualAssignment } from 'selectors/assignments'
import { getUserAssignmentById } from 'selectors/userAssignments'
import { getUserById } from 'selectors/users'
import { interactiveContext } from '../../InteractiveProvider'
import ReopenButton from './ReopenButton'

const confirm = createConfirmation(ConfirmationDialog, 0)

const ReopenButtonForSingleStudent = ({ reopenAssignment = false }) => {
  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}
  const { completed, markIncomplete } = useContext(interactiveContext) || {}

  const { submittedDate, userId } =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const { firstName, lastName } = useSelector(getUserById({ userId })) || {}
  const assignment = useSelector(getContextualAssignment) || {}
  const studentName = `${firstName} ${lastName}`

  const canBeReopened = Boolean(
    (completed || submittedDate) && !isPastMaxSubmitDate(assignment),
  )

  const reopenUserAssignment = useReduxPromise(
    actionTypes.USER_ASSIGNMENT_REOPEN,
  )

  const handleClick = useCallback(() => {
    if (reopenAssignment) {
      confirm({
        primaryText: `Reopen assignment for ${studentName}?`,
        secondaryText:
          'After selecting "Reopen Student Assignment", the student will regain access to modify their responses.',
        proceed: proceed => {
          if (proceed) {
            reopenUserAssignment({ userAssignmentId })
          }
        },
        cancel: identity,
        confirmIcon: <Lock />,
        confirmLabel: 'Reopen Student Assignment',
        cancelLabel: 'Cancel',
        showCloseButton: true,
      })
    } else if (submittedDate) {
      confirm({
        primaryText: 'Reopen Student Assignment?',
        secondaryText: `Before reopening this interactive for ${firstName} ${lastName}, their submitted assignment must also be reopened. Any other previously unsubmitted interactives will also become submittable again.`,
        proceed: proceed => {
          if (proceed) {
            reopenUserAssignment({ userAssignmentId }).then(() => {
              when(completed, markIncomplete)
            })
          }
        },
        cancel: identity,
        confirmLabel: 'Reopen Student Assignment',
        cancelLabel: 'Cancel',
      })
    } else {
      markIncomplete()
    }
  }, [
    completed,
    firstName,
    lastName,
    markIncomplete,
    reopenAssignment,
    reopenUserAssignment,
    studentName,
    submittedDate,
    userAssignmentId,
  ])

  return (
    canBeReopened && (
      <ReopenButton
        {...{
          handleClick,
          reopenAssignment,
        }}
        studentName={`${firstName} ${lastName}`}
      />
    )
  )
}

ReopenButtonForSingleStudent.propTypes = {
  reopenAssignment: PropTypes.bool,
}

export default ReopenButtonForSingleStudent
