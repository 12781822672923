import Stack from '@mui/material/Stack'
import { contentShape } from 'core/shapes'
import { noop } from 'fp/utils'
import withAbilityCheck from 'hoc/withAbilityCheck'
import withProps from 'hoc/withProps'
import Card from 'hss/views/Card'
import PosterImage from '../../Card/PosterImage'
import Actions from './Actions'
import Header from './Header'

const viewMode = 'grid'
const width = '100%'

const ContentRenderer = ({ content }) => {
  const Renderer = withAbilityCheck(Stack, undefined, content)

  return (
    <Renderer
      style={{
        width: '100%',
        height: '100%',
        minHeight: '24rem',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Header content={content} />
      <Actions content={content} />
      {/* TODO: CardFooter area for students to show progress/due date */}
    </Renderer>
  )
}

ContentRenderer.propTypes = {
  content: contentShape.isRequired,
}

const CourseCard = withProps(Card, {
  contentCreator: false,
  ContentRenderer,
  gridModeDirection: 'ltr',
  gridModeImageAspectRatio: '16/9',
  handlePreview: noop,
  ImageRenderer: withProps(PosterImage, {
    includeLinkToContent: true,
    viewMode,
  }),
  listModeDirection: 'rtl',
  listModeImageAspectRatio: '16/9',
  viewMode,
  width,
})

CourseCard.propTypes = {
  content: contentShape.isRequired,
}

export default CourseCard
