const getIntersectionPoint = (circle0, circle1, flip) => {
  // Thank you Stack Overflow. https://stackoverflow.com/a/3349134
  const { radius: r0 } = circle0
  const { radius: r1 } = circle1

  const rise = circle0.center.y - circle1.center.y
  const run = circle0.center.x - circle1.center.x
  const d = Math.sqrt(rise * rise + run * run)

  const a = (r0 * r0 - r1 * r1 + d * d) / (2 * d)
  const h = Math.sqrt(r0 * r0 - a * a)
  const p2 = {
    x: circle0.center.x + (a * (circle1.center.x - circle0.center.x)) / d,
    y: circle0.center.y + (a * (circle1.center.y - circle0.center.y)) / d,
  }
  const direction = flip ? -1 : 1

  return {
    x: p2.x + (direction * h * (circle1.center.y - circle0.center.y)) / d,
    y: p2.y - (direction * h * (circle1.center.x - circle0.center.x)) / d,
  }
}
const createClipPath = radius => (startingPoint, arcs) => {
  const moveStartingPoint = `M ${startingPoint.x} ${startingPoint.y}`
  const clippedArcs = arcs.map(
    arc =>
      `A ${radius} ${radius} 0 ${arc.largeArc ? 1 : 0} ${arc.clockwise ? 1 : 0} ${arc.to.x} ${arc.to.y}`,
  )
  return `${moveStartingPoint} ${clippedArcs.join(' ')} Z`
}

export const padding = 15
export const lineHeight = 1.4 // to be multiplied by fontSize
export const paragraphSpacing = 0.6 // to be multiplied by lineHeight

export const orderedSections = [
  { key: 'left', circleIds: [1], description: 'Left Side' },
  { key: 'left-right', circleIds: [1, 2], description: 'Left & Right Overlap' },
  { key: 'right', circleIds: [2], description: 'Right Side' },
  {
    key: 'bottom-left',
    circleIds: [1, 3],
    description: 'Bottom & Left Overlap',
  },
  { key: 'middle', circleIds: [1, 2, 3], description: 'Middle' },
  {
    key: 'bottom-right',
    circleIds: [2, 3],
    description: 'Bottom & Right Overlap',
  },
  { key: 'bottom', circleIds: [3], description: 'Bottom' },
]

// This defines, for each section, which of the circles in the Venn diagram
//  should be clipped to form the section's clickable region.
const [left, right, bottom] = [0, 1, 2]
export const hoverRegions = {
  2: {
    left,
    right,
    'left-right': left,
  },
  3: {
    left,
    right,
    'left-right': left,
    bottom,
    'bottom-left': left,
    'bottom-right': right,
    middle: left,
  },
}

export const twoCirclePositioning = () => {
  const viewBox = {
    width: 800,
    height: 510,
  }
  const radius = 250
  const fontSize = 16

  const leftCircle = {
    radius,
    center: {
      x: viewBox.width * 0.333,
      y: viewBox.height / 2,
    },
  }
  const rightCircle = {
    radius,
    center: {
      x: viewBox.width * 0.667,
      y: viewBox.height / 2,
    },
  }

  const intersections = {
    top: getIntersectionPoint(leftCircle, rightCircle, false),
    bottom: getIntersectionPoint(leftCircle, rightCircle, true),
  }

  const clipPathTwoCircle = createClipPath(radius)
  return {
    viewBox,
    radius,
    fontSize,
    circles: [leftCircle, rightCircle],
    intersections,
    clipPaths: {
      'left-clip': clipPathTwoCircle(intersections.top, [
        { to: intersections.bottom, largeArc: true },
        { to: intersections.top, clockwise: true },
      ]),
      'right-clip': clipPathTwoCircle(intersections.top, [
        { to: intersections.bottom, largeArc: true, clockwise: true },
        { to: intersections.top },
      ]),
      'left-right-clip': clipPathTwoCircle(intersections.top, [
        { to: intersections.bottom, clockwise: true },
        { to: intersections.top, clockwise: true },
      ]),
    },
    ariaDescription:
      'Two overlapping circles: one on the left, one on the right.',
  }
}
export const threeCirclePositioning = () => {
  const viewBox = {
    width: 800,
    height: 740,
  }
  const radius = 240
  const upperCirclesY = 250
  const lowerCircleY = 490
  const fontSize = 16

  const leftCircle = {
    radius,
    center: {
      x: viewBox.width * 0.333,
      y: upperCirclesY,
    },
  }
  const rightCircle = {
    radius,
    center: {
      x: viewBox.width * 0.667,
      y: upperCirclesY,
    },
  }

  const bottomCircle = {
    radius,
    center: {
      x: viewBox.width * 0.5,
      y: lowerCircleY,
    },
  }
  const clipPathThreeCircle = createClipPath(radius)
  const intersections = {
    leftBottom: getIntersectionPoint(leftCircle, bottomCircle, true),
    rightBottom: getIntersectionPoint(rightCircle, bottomCircle, false),
    leftRightTop: getIntersectionPoint(leftCircle, rightCircle, false),
    middleBottom: getIntersectionPoint(leftCircle, rightCircle, true),
    middleLeft: getIntersectionPoint(rightCircle, bottomCircle, true),
    middleRight: getIntersectionPoint(leftCircle, bottomCircle, false),
  }
  return {
    viewBox,
    fontSize,
    circles: [leftCircle, rightCircle, bottomCircle],
    deleteButton: {
      x: bottomCircle.center.x + radius * 0.9,
      y: bottomCircle.center.y + radius * 0.9,
    },
    intersections,
    clipPaths: {
      'left-clip': clipPathThreeCircle(intersections.leftRightTop, [
        { to: intersections.leftBottom, largeArc: true },
        { to: intersections.middleLeft, clockwise: true },
        { to: intersections.leftRightTop, clockwise: true },
      ]),
      'right-clip': clipPathThreeCircle(intersections.leftRightTop, [
        { to: intersections.rightBottom, largeArc: true, clockwise: true },
        { to: intersections.middleRight },
        { to: intersections.leftRightTop },
      ]),
      'left-right-clip': clipPathThreeCircle(intersections.leftRightTop, [
        { to: intersections.middleLeft },
        { to: intersections.middleRight, clockwise: true },
        { to: intersections.leftRightTop },
      ]),
      'bottom-clip': clipPathThreeCircle(intersections.rightBottom, [
        { to: intersections.leftBottom, largeArc: true, clockwise: true },
        { to: intersections.middleBottom },
        { to: intersections.rightBottom },
      ]),
      'bottom-left-clip': clipPathThreeCircle(intersections.middleLeft, [
        { to: intersections.leftBottom },
        { to: intersections.middleBottom },
        { to: intersections.middleLeft, clockwise: true },
      ]),
      'bottom-right-clip': clipPathThreeCircle(intersections.middleRight, [
        { to: intersections.rightBottom, clockwise: true },
        { to: intersections.middleBottom, clockwise: true },
        { to: intersections.middleRight },
      ]),
      'middle-clip': clipPathThreeCircle(intersections.middleLeft, [
        { to: intersections.middleRight, clockwise: true },
        { to: intersections.middleBottom, clockwise: true },
        { to: intersections.middleLeft, clockwise: true },
      ]),
    },
    ariaDescription:
      'Three overlapping circles: one on the left, one on the right, one underneath.',
  }
}
