import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import ImageSlide from './ImageSlide'

const Slide = styled(
  ({ className, slide }) => (
    <div className={className}>
      <div>
        {slide.type === 'img' ? (
          <ImageSlide slide={slide} />
        ) : (
          <div>Unknown slide type</div>
        )}
      </div>
    </div>
  ),
  {
    name: 'Flipbook-Slide',
  },
)(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    height: '100%',
    '> div': {
      fontSize: rem(1.2),
      height: '100%',
      lineHeight: 1.2,
      margin: 0,
      minHeight: 500,
      minWidth: 280,
      position: 'relative',
      'h2, h3': {
        fontSize: rem(3.5),
        fontWeight: 600,
        margin: rem(0, 0, 1.7),
      },
      h2: {
        color: palette.accent.main,
      },
    },
  }),
)

Slide.propTypes = {
  slide: PropTypes.object.isRequired,
}

export default Slide
