import IconButton from '@mui/material/IconButton'
import Html from 'common/text/Html'
import { entityIdShape } from 'core/shapes'
import { set } from 'fp/objects'
import withCanSubmitAssignment from 'hss/ContentViewer/withCanSubmitAssignment'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { XCircle } from 'react-feather'
import TableTextField from './TableTextField'

const CellRenderer = withCanSubmitAssignment(props => {
  const {
    canSubmit,
    heading,
    item,
    onChange,
    onRemoveRow,
    rowIdx,
    showRemoveRowIcon,
  } = props

  const focusRef = useRef()
  const handleCellClick = () => {
    focusRef.current?.focus()
  }

  const handleChange = ({ target: { value } }) => {
    const newItem = set('value', value)(item)
    onChange({ newItem })
  }
  const { displayText = false, value } = item

  return (
    // Normally an onClick on a td would be an a11y red flag, but in this case the click handler is just a funnel
    // to help get focus onto the text field. If present, it will be the only thing in the cell.
    // And of course the text field is interactive and does have key events.

    <td
      data-title={heading}
      onClick={handleCellClick}>
      {displayText ? (
        <Html body={value} />
      ) : (
        <TableTextField
          inputProps={{
            'aria-label': `Write your response for ${heading},  Row ${rowIdx + 1}`,
            disabled: !canSubmit,
          }}
          name={`item-${item.id}`}
          onChange={handleChange}
          placeholder={canSubmit ? 'Write your response...' : '(no response)'}
          ref={focusRef}
          value={value}
          readOnly={!canSubmit}
        />
      )}
      {Boolean(showRemoveRowIcon && canSubmit) && (
        <IconButton
          onClick={onRemoveRow}
          size="small"
          style={{
            position: 'absolute',
            right: -40,
          }}>
          <XCircle />
        </IconButton>
      )}
    </td>
  )
})

CellRenderer.propTypes = {
  heading: PropTypes.string.isRequired,
  item: PropTypes.shape({
    displayText: PropTypes.bool,
    id: entityIdShape.isRequired,
    column: entityIdShape.isRequired,
    row: entityIdShape.isRequired,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveRow: PropTypes.func.isRequired,
  rowIdx: PropTypes.number.isRequired,
  showRemoveRowIcon: PropTypes.bool.isRequired,
}

export default CellRenderer
