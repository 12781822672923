import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { isDefined, when } from 'fp/utils'
import { useChapterVocabPhrases } from 'hooks/useChapterVocabPhrases'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { transformUIData } from 'hss/ContentBuilder/interactives/GroupAndSort/utils'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { isTestEnv } from 'selectors/index'
import { SCORING_MODE_GNS_SORT_GIVEN_GROUPS } from 'shared/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import reducer, { getNewSelections, setupInitialState } from './reducer'

const context = createContext()

const VOCAB_GROUP_ID_FAMILIAR = 'familiar-column'
const VOCAB_GROUP_ID_UNFAMILIAR = 'unfamiliar-column'

const unfamiliarColumn = {
  id: VOCAB_GROUP_ID_UNFAMILIAR,
  heading: 'Unfamiliar',
}
const familiarColumn = {
  id: VOCAB_GROUP_ID_FAMILIAR,
  heading: 'Familiar',
}
export const vocabColumns = [unfamiliarColumn, familiarColumn]

const GroupAndSortProvider = ({ children, testingProps }) => {
  const {
    completed,
    contentId,
    interactionData: { selections },
    interactive: {
      scoring: { mode: scoringMode },
    },
    interactiveData,
    interactiveData: {
      columns,
      showGroupTotals = false,
      variant = interactiveVariants.NORMAL,
    },
    isGrading,
    onInteract,
  } = useContext(interactiveContext)
  const [selectionsReturned, setSelectionsReturned] = useState(false)

  const provideGrouping = scoringMode === SCORING_MODE_GNS_SORT_GIVEN_GROUPS

  const { groups: initialGroups, items: initialItems } = columns
    ? transformUIData(interactiveData)
    : interactiveData

  const contentVocabPhrases = useChapterVocabPhrases({
    contentId,
    contentType: CONTENT_TYPE_INTERACTIVE,
  })

  const { groups, items } = useMemo(
    () =>
      variant === interactiveVariants.GROUP_AND_SORT_VOCAB_FAMILIARITY
        ? contentVocabPhrases?.length
          ? (() => ({
              groups: [
                {
                  ...unfamiliarColumn,
                  totalItems: contentVocabPhrases.length,
                },
                familiarColumn,
              ],
              items: contentVocabPhrases.map(({ id, name }) => ({
                id,
                cellText: name,
                groupId: VOCAB_GROUP_ID_UNFAMILIAR,
              })),
            }))()
          : {
              groups: [],
              items: [],
            }
        : { groups: initialGroups, items: initialItems },
    [variant, contentVocabPhrases, initialGroups, initialItems],
  )

  // For the teacher's UI, the state of group/items/selections will not be changeable.
  // But we do need to make sure to update them whenever the current interaction
  // (i.e. selected student) changes. That's what readOnlyState is for.
  const showAnswerKey = useIsInAnswerKeyContext()
  const readOnlyState = useMemo(
    () =>
      setupInitialState(groups, items, selections, showAnswerKey, isGrading),
    [groups, isGrading, items, selections, showAnswerKey],
  )

  /**
   * NOTE:
   *    The inline comments that follows THIS comment block are from the original
   *    implementation of this interactive.  It is no longer accurate and the reducer
   *    could actually be factored out.  It's been left in place for now just
   *    because it doesn't warrant the extra work, however the interactive context
   *    can take it's place (.eg `interactiveData`, `onInteract`).
   *
   *    If this component is ever revisited, the reducer should be factored out.
   *
   */

  // For students completing the activity, we have this reducer that is used
  // to maintain local state changes so there's no flicker caused by waiting for
  // the api/saga/reducer roundtrips.
  const reducerInitialState = {
    showGroupTotals,
    ...readOnlyState,
    ...(isTestEnv() ? testingProps : null),
  }
  const [reducerState, dispatch] = useReducer(reducer, reducerInitialState)
  const [changesMade, setChangesMade] = useState(false)

  const localDispatch = useCallback(action => {
    setChangesMade(true)
    dispatch(action)
  }, [])

  useEffect(() => {
    if (isDefined(selections) && !selectionsReturned) {
      setSelectionsReturned(true)
      dispatch({
        type: 'SETUP',
        ...readOnlyState,
        ...(isTestEnv() ? testingProps : null),
      })
    }
  }, [readOnlyState, selections, selectionsReturned, testingProps])

  // Teachers don't really _grade_ Group & Sorts, but we can use `isGrading` to determine
  // whether to use the read-only state (for teachers viewing an assignment)
  // or the dynamic reducer state (for students and assignment previews).
  const state = isGrading ? readOnlyState : reducerState

  useDeepCompareEffect(() => {
    when(changesMade && !isGrading, onInteract, {
      selections: getNewSelections(reducerState.options),
    })
  }, [changesMade, isGrading, onInteract, reducerState.options])

  const value = useMemo(() => {
    const handleDrop = (id, columnId, displayIndex) => {
      localDispatch({
        type: 'DROP-ON-GROUP',
        displayIndex,
        droppedId: id,
        droppedOnId: columnId,
      })
    }

    return {
      completed,
      dispatch: localDispatch,
      handleDrop,
      onInteract,
      provideGrouping,
      scoringMode,
      showGroupTotals,
      state,
    }
  }, [
    completed,
    localDispatch,
    onInteract,
    provideGrouping,
    scoringMode,
    showGroupTotals,
    state, // will only do a shallow compare
  ])

  return <context.Provider value={value}>{children}</context.Provider>
}

GroupAndSortProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testingProps: PropTypes.object,
}

export { context, GroupAndSortProvider }
