import { styled } from '@mui/material/styles'
import Centered from 'common/layout/Centered'

const Pagination = styled(Centered, { name: 'FunFact-Pagination' })(
  ({
    theme: {
      palette,
      mixins: { transition },
    },
  }) => ({
    '.swiper-pagination-bullet': {
      borderRadius: 0,
      height: 2,
      width: 18,
      color: palette.grey[3],
      strokeLinecap: 'square',
      ...transition(),
    },

    '.swiper-pagination-bullet-active': {
      height: 4,
      color: palette.pagination,
      width: 24,
    },
  }),
)

export default Pagination
