import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from 'common/dialogs/Dialog'
import { useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import { navDialogsContext } from 'routing/shells/NavShell/NavDialogsProvider'
import {
  getNewProfileUserAlerts,
  getNewProfileUserAlertsCount,
} from 'selectors/userAlerts'
import ProfileUserAlert from './ProfileUserAlert'

const ProfileUserAlertsDialog = () => {
  const { profileUserAlertsIsOpen, toggleProfileUserAlertsIsOpen } =
    useContext(navDialogsContext)

  const profileAlerts = useSelector(getNewProfileUserAlerts)
  const alertCount = useSelector(getNewProfileUserAlertsCount) || 0
  const containerRef = useRef()

  return (
    <Dialog
      onClose={toggleProfileUserAlertsIsOpen}
      open={profileUserAlertsIsOpen}
      showCloseButton
      variant="maximized-vertically">
      <DialogTitle>Notifications</DialogTitle>
      <DialogContent>
        {alertCount > 0 ? (
          profileAlerts.map((alert, index) => (
            <div
              key={alert.id}
              ref={containerRef}
              style={{ overflow: 'hidden' }}>
              <ProfileUserAlert
                alert={alert}
                containerRef={containerRef}
                shouldAnimate
              />

              {Boolean(index !== alertCount - 1) && <hr />}
            </div>
          ))
        ) : (
          <p>No new notifications.</p>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ProfileUserAlertsDialog
