import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { componentShape } from 'core/shapes'
import useContent from 'hooks/useContent'
import { labels } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const PreviewInteractive = ({ children, contentid: contentId }) => {
  const { contentSubType } =
    useContent({ contentId, contentType: CONTENT_TYPE_INTERACTIVE }) || {}

  const [label, setLabel] = useState('interactive')

  useEffect(() => {
    if (contentSubType) {
      setLabel(`${labels[contentSubType]} interactive`)
    }
  }, [contentSubType])

  return (
    <>
      [{label}] {children}
    </>
  )
}

PreviewInteractive.propTypes = {
  children: componentShape.isRequired,
  contentid: PropTypes.string,
}

export default PreviewInteractive
