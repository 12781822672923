import MuiCard from '@mui/material/Card'
import { CONTENT_TYPE_VOCABULARY } from 'core/consts'
import { componentShape, contentShape, directionShape } from 'core/shapes'
import { noop } from 'fp/utils'
import useWidthBreakpoint from 'hooks/useWidthBreakpoint'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { viewModeShape } from '../Search/consts'
import PosterImage from './PosterImage'

const noImageContentTypes = [CONTENT_TYPE_VOCABULARY]

const Card = forwardRef((props, ref) => {
  const {
    ContentRenderer = () => <div>Missing ContentRenderer</div>,
    ImageRenderer = PosterImage,
    content,
    contentCreator,
    gridFlexVariant = 'vertical-grid',
    gridModeDirection = 'ltr',
    gridModeImageAspectRatio = '1/1',
    handlePreview = noop,
    listModeDirection = 'ltr',
    listModeImageAspectRatio = '1/1',
    viewMode: requestedViewMode = 'list',
  } = props

  const breakpoint = useWidthBreakpoint()

  const styleAttrs = {
    list: {
      aspectRatio: listModeImageAspectRatio,
      direction: listModeDirection,
      flexFlow: 'row nowrap',
      variant: 'horizontal',
    },
    grid: {
      aspectRatio: gridModeImageAspectRatio,
      direction: gridModeDirection,
      flexFlow: 'column nowrap',
      variant: gridFlexVariant,
    },
  }

  const viewMode = requestedViewMode

  const { aspectRatio, direction, flexFlow, variant } = styleAttrs[viewMode]

  const contentProps = {
    content,
    contentCreator,
    handlePreview,
    viewMode,
  }

  const imageProps = {
    aspectRatio,
    content,
    viewMode,
  }

  const imageViewable =
    !noImageContentTypes.includes(content.contentType) &&
    (viewMode === 'grid' || !['xs', 'sm'].includes(breakpoint))

  return (
    <MuiCard
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow,
      }}
      variant={variant}>
      {direction === 'ltr' ? (
        <>
          {!!imageViewable && <ImageRenderer {...imageProps} />}
          <ContentRenderer {...contentProps} />
        </>
      ) : (
        <>
          <ContentRenderer {...contentProps} />
          {!!imageViewable && <ImageRenderer {...imageProps} />}
        </>
      )}
    </MuiCard>
  )
})

Card.propTypes = {
  content: contentShape,
  contentCreator: PropTypes.bool.isRequired,
  ContentRenderer: componentShape,
  gridFlexVariant: PropTypes.string,
  gridModeDirection: directionShape,
  gridModeImageAspectRatio: PropTypes.string,
  handlePreview: PropTypes.func,
  ImageRenderer: componentShape,
  listModeDirection: directionShape,
  listModeImageAspectRatio: PropTypes.string,
  viewMode: viewModeShape,
}

export default Card
