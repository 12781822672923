import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import TabList from 'common/layout/TabList'
import { CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { find } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo, matches } from 'fp/utils'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import useNavigation from 'hooks/useNavigation'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import {
  getCurrentSection,
  getCurrentViewerContentId,
} from 'selectors/contentViewer'

const Tabs = () => {
  const { navigate } = useNavigation()

  const isPinned = useIsPinnedContent()
  const { children } = useSelector(getCurrentSection({ isPinned }))
  const subsectionId = useSelector(
    getCurrentViewerContentId({ isPinned, preferTeCopy: true }),
  )

  const handleChange = (_, id) => {
    const contentType = compose(
      fallbackTo(CONTENT_TYPE_SUBSECTION),
      get('contentType'),
      find(matches('id', id)),
    )(children)

    const url = `../${contentType}/${id}`
    navigate(url)
  }

  return (
    <TabContext value={subsectionId}>
      <TabList
        aria-label="Sections"
        contained
        onChange={handleChange}>
        {children.map(({ id, name }) => (
          <Tab
            key={`tab${id}`}
            label={name}
            value={id}
          />
        ))}
      </TabList>
    </TabContext>
  )
}

export default Tabs
