import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Icon from '@mui/material/Icon'
import Centered from 'common/layout/Centered'
import { BUSY, ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { AlertTriangle, Send } from 'react-feather'

const ErrorTryAgainPrompt = ({ onSubmit, onClose, status }) => {
  const cancelButtonRef = useRef()
  const handleSubmit = () => {
    cancelButtonRef.current.focus() // because we shouldn't leave focus on a disabled button
    onSubmit()
  }
  return (
    <>
      <DialogContent>
        <Centered>
          <Icon
            sx={{ color: 'error.main' }}
            variant="xl">
            <AlertTriangle />
          </Icon>
        </Centered>
        <Centered component="p">
          There was a problem submitting the assignment.
        </Centered>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          ref={cancelButtonRef}
          variant="secondary">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={status === BUSY}
          onClick={handleSubmit}
          startIcon={<Send />}
          variant="primary">
          Resubmit
        </Button>
      </DialogActions>
    </>
  )
}

ErrorTryAgainPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.oneOf([BUSY, ERROR, IDLE, SUCCESS]).isRequired,
}

export default ErrorTryAgainPrompt
