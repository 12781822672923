import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Html from 'common/text/Html'
import { INTERACTION_TYPE_NOTEBOOK } from 'core/consts'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getInteractionByType } from 'selectors/interactions'

const NotebookTab = () => {
  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}
  const notebookInteraction = useSelector(
    getInteractionByType({
      type: INTERACTION_TYPE_NOTEBOOK,
      userAssignmentId,
    }),
  )

  return (
    <>
      <Typography
        style={{ marginTop: '40px', textTransform: 'uppercase' }}
        variant="h3">
        Student Notebook
      </Typography>
      <Box mt={4}>
        {notebookInteraction?.interactionData?.value ? (
          <Html body={notebookInteraction?.interactionData?.value} />
        ) : (
          <p>This student has not made any notebook entries yet.</p>
        )}
      </Box>
    </>
  )
}

export default NotebookTab
