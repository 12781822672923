import { isDefined } from 'fp/utils'
import { config } from 'react-spring'

const wobbleBase = {
  config: config.wobbly,
  loop: { reverse: true },
}

const animations = {
  wobbleLeft: isHoveredOrFocused => ({
    ...wobbleBase,
    from: {
      transform: 'rotate(-5deg)',
    },
    to: {
      scale: isHoveredOrFocused ? 1.5 : 1,
      transform: 'rotate(-18deg)',
    },
  }),

  wobbleRight: isHoveredOrFocused => ({
    ...wobbleBase,
    from: {
      transform: 'rotate(5deg)',
    },
    to: {
      scale: isHoveredOrFocused ? 2 : 1,
      transform: 'rotate(18deg)',
    },
  }),
}

export const animationExists = name => isDefined(animations[name])
export const animationByName = (name, isHoveredOrFocused) =>
  animations[name](isHoveredOrFocused) || {}
