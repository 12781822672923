import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CorrectnessIcon from 'common/indicators/CorrectnessIcon'
import Centered from 'common/layout/Centered'
import IconCallout from 'common/text/IconCallout'
import { MANUAL_SUBMITTABLE_INTERACTIVES } from 'core/consts'
import { filter, first } from 'fp/arrays'
import { callWith } from 'fp/call'
import { asFloatWithoutTrailingZeros } from 'fp/numbers'
import { get } from 'fp/objects'
import { pluralize } from 'fp/strings'
import { fallbackTo, isDefined } from 'fp/utils'
import withProps from 'hoc/withProps'
import { getScore } from 'projections/interactions'
import { Fragment, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getCurrentAssignmentSubmitted } from 'selectors/userAssignments'
import { isStudent } from 'selectors/users'
import { interactiveGradingContext } from './InteractiveGradingProvider'
import { interactiveContext } from './InteractiveProvider'
import Subheader from './Subheader'

const defaultBackgroundColor = 'cobalt.4'
const scoreMessage = (maxPoints, points) =>
  ` You scored ${points} out of ${pluralize('point')(maxPoints)}. See details below.`
const DecorativeBold = withProps(Typography, { variant: 'body1-semibold' })

const studentFeedback = {
  correct: {
    backgroundColor: 'success.light',
    Component: withProps(IconCallout, { Icon: <CorrectnessIcon isCorrect /> }),
    includePoints: true,
    Message: () => (
      <>
        Your answer is <DecorativeBold>correct</DecorativeBold>!
      </>
    ),
  },
  incorrect: {
    Component: withProps(IconCallout, {
      Icon: <CorrectnessIcon isCorrect={false} />,
    }),
    includePoints: true,
    Message: () => (
      <>
        Your answer is <DecorativeBold>incorrect</DecorativeBold>.
      </>
    ),
  },
  partiallyCorrect: {
    Component: withProps(IconCallout, {
      Icon: <CorrectnessIcon isCorrect={false} />,
    }),
    includePoints: true,
    Message: () => (
      <>
        Your answer is <DecorativeBold>partially correct</DecorativeBold>.
      </>
    ),
  },
  requiresGrading: {
    Component: withProps(IconCallout, {
      Icon: <CorrectnessIcon needsScoring />,
    }),
    Message: () => (
      <>
        <DecorativeBold>Submitted.</DecorativeBold>&nbsp;Your answer has not yet
        been scored.
      </>
    ),
  },
  hidden: {
    Message: () => (
      <>
        <DecorativeBold>Submitted.</DecorativeBold>&nbsp;Scoring is not
        available at this time.
      </>
    ),
  },
  pastDue: {
    Message: () => (
      <>
        <DecorativeBold>Past Due.</DecorativeBold>&nbsp;No changes can be made.
      </>
    ),
  },
  submitted: {
    Message: () => (
      <>
        <DecorativeBold>Submitted.</DecorativeBold>&nbsp;No changes can be made.
      </>
    ),
  },
}

const StudentAnswerFeedback = () => {
  const { allowManualScoring, maxScoreToUse } = useContext(
    interactiveGradingContext,
  )
  const {
    completed,
    interaction,
    interactive: { contentSubType },
    submittableInteractive,
    isPastSubmissionDate,
  } = useContext(interactiveContext)

  const isAssignmentSubmitted = useSelector(getCurrentAssignmentSubmitted)

  const { hidden, requiresGrading } = compose(
    fallbackTo({}),
    get('scoreData'),
  )(interaction)
  const score = getScore(interaction?.scoreData)
  const isCurrentUserStudent = useSelector(isStudent)

  const isSubmitted =
    isAssignmentSubmitted || (submittableInteractive && completed)

  const currentFeedback =
    isCurrentUserStudent &&
    MANUAL_SUBMITTABLE_INTERACTIVES.includes(contentSubType) &&
    (isSubmitted || isPastSubmissionDate) &&
    compose(
      callWith(studentFeedback),
      get,
      first,
      filter(Boolean),
    )([
      // Order matters -- it's using the first truthy value
      hidden && 'hidden',
      isPastSubmissionDate && !isSubmitted && 'pastDue',
      !allowManualScoring && 'submitted',
      requiresGrading && 'requiresGrading',
      score === 0 && 'incorrect',
      score === 1 && 'correct',
      isDefined(score) && 'partiallyCorrect',
      'submitted',
    ])

  const answerFeedbackRef = useRef()
  const wasInitiallySubmitted = useRef(isSubmitted)
  useEffect(() => {
    if (currentFeedback && isSubmitted && !wasInitiallySubmitted.current) {
      answerFeedbackRef.current.focus()
    }
  }, [currentFeedback, isSubmitted])

  const {
    Component = Fragment,
    Message,
    backgroundColor = defaultBackgroundColor,
    includePoints,
  } = currentFeedback || {}

  return (
    currentFeedback && (
      <Box
        backgroundColor="grey.5"
        pb={3}>
        <Subheader backgroundColor={backgroundColor}>
          <Centered
            data-testid="student-answer-feedback"
            ref={answerFeedbackRef}
            tabIndex={-1}>
            <Component>
              <Message />
              {Boolean(includePoints) &&
                scoreMessage(
                  maxScoreToUse,
                  asFloatWithoutTrailingZeros(score * maxScoreToUse, 2),
                )}
            </Component>
          </Centered>
        </Subheader>
      </Box>
    )
  )
}

export default StudentAnswerFeedback
