import { useCallback } from 'react'
import config from '../config'
import ActionPoints from './ActionPoints'

const RisingAction = props => {
  const offsetX = useCallback(
    height => -(config.slantedNoteOffsetX + (0.5 * height) / config.rise.slope),
    [],
  )

  const ActionNoteProps = {
    textAnchor: 'end',
    verticalAnchor: 'middle',
  }

  const TitleProps = {
    transform: `rotate(-${config.rise.angle} ${config.exposition.endX} ${config.baselineY})`,
    x: config.exposition.endX + 0.5 * config.rise.length,
    y: config.baselineY + config.labelBelowOffsetY,
  }

  return (
    <ActionPoints
      fieldName="risingAction"
      offsetX={offsetX}
      offsetXDirection={-1}
      slope={config.rise.slope}
      title="Rising Action"
      TitleProps={TitleProps}
      {...ActionNoteProps}
      {...props}
    />
  )
}

export default RisingAction
