import Box from '@mui/material/Box'
import OldMultiSelectReplaceMe from 'common/formControls/selects/OldMultiSelectReplaceMe'
import { alter } from 'core/store/search/squery'
import { dedupeById, filter, map } from 'fp/arrays'
import { hasProperty } from 'fp/objects'
import { identity, pipe } from 'fp/utils'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { useContext, useState } from 'react'
import { compose } from 'redux'

const TargetFilter = () => {
  const {
    dataset: { data },
    setSquery,
    squery,
  } = useContext(searchContext)
  const [target, setTarget] = useState(null)

  const targetList = dedupeById(
    data.map(x => ({ ...x.target, targetTypeId: x.targetTypeId })),
    'id',
  )

  const groups = pipe(
    filter(hasProperty('name')),
    map(t => ({ id: t.id, label: t.name })),
  )(targetList)

  const students = pipe(
    filter(hasProperty('lastName')),
    map(t => ({ id: t.id, label: `${t.lastName}, ${t.firstName}` })),
  )(targetList)

  const options = [
    { label: 'Groups', options: groups },
    { label: 'Students', options: students },
  ]

  const handleChange = value => {
    setTarget(value)
    compose(
      setSquery,
      value ? alter.set.where('targetId').is(value.id) : identity,
      alter.remove.where('targetId'),
    )(squery)
  }

  return (
    <Box
      maxWidth={30 * 8}
      minWidth={20 * 8}
      mt="-28px"
      width="100%">
      <OldMultiSelectReplaceMe
        isClearable
        isMulti={false}
        label="Target"
        name="target"
        onChange={handleChange}
        options={options}
        style={{
          minWidth: `${40 * 8}px`,
          maxWidth: `${50 * 8}px`,
          marginTop: '0.5rem',
        }}
        value={target}
      />
    </Box>
  )
}

export default TargetFilter
