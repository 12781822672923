import { MinimalPlayer, RegularPlayer } from 'common/avclub/audio/AudioPlayer'
import { INTERACTIVE_TYPE_AUDIO } from 'core/consts'
import { noop } from 'fp/utils'
import { useContext, useMemo } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const Audio = () => {
  const {
    completed,
    contentWrappingAllowed,
    interactiveData: { float, minimal = false, url },
    markComplete,
    uploadsMap,
  } = useContext(interactiveContext)

  const handlePlay = useMemo(
    () => (completed ? noop : markComplete),
    [completed, markComplete],
  )
  const Player = minimal ? MinimalPlayer : RegularPlayer
  return (
    <Player
      {...{
        contentWrappingAllowed,
        float,
        minimal,
        onPlay: handlePlay,
      }}
      url={uploadsMap?.audio?.url || url}
    />
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_AUDIO,
}

export default Audio
