import type { ContentType, IContent } from 'common/@types/common'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { CONTENT_TYPE_BLOCK, CONTENT_TYPE_COURSE } from 'core/consts'
import useContent from 'hooks/useContent'
import useToggleState from 'hooks/useToggleState'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { actions } from 'reducers/content'
import { getParentOfContentByType } from 'selectors/contentViewer'
import type { AuthorContextValue } from './@types/author.d.ts'

const authorContext = createContext<AuthorContextValue | null>(null)

type Props = {
  children: React.ReactNode
  creating?: boolean
  editing?: boolean
}

const AuthorProvider = (props: Props) => {
  const { children, creating = false, editing = false } = props
  const dispatch = useDispatch()

  const { contentId, contentSubType, contentType } = useParams<{
    contentId: string
    contentSubType?: string
    contentType: ContentType
  }>()

  const [debugging, toggleDebugger] = useToggleState(false)

  const { up } = useContainerQuery()
  const split = up('md')

  const content = useContent({
    contentId,
    contentType,
  })

  const blockId = content?.children?.find(
    child => child.contentType === CONTENT_TYPE_BLOCK,
  )?.id

  const block: IContent = useContent({
    contentId: blockId,
    contentType: CONTENT_TYPE_BLOCK,
  }) as IContent

  const course = useSelector(
    getParentOfContentByType({
      contentId,
      contentType,
      parentContentType: CONTENT_TYPE_COURSE,
    }),
  ) as IContent

  useEffect(() => {
    if (course?.id) {
      dispatch(
        actions.fetchContentById({
          contentId: course?.id,
          contentType: CONTENT_TYPE_COURSE,
          queryParams: {
            childDepth: 999,
          },
        }),
      )
    }
  }, [course?.id, dispatch])

  const value: AuthorContextValue = useMemo(() => {
    const body = block?.data?.body

    return {
      block,
      content,
      courseId: course?.id,
      creating,
      debugging,
      editing,
      formProps: {
        blockId,
        body,
        contentId,
        contentSubType,
        contentType,
        name: content?.name,
        uploadsMap: content?.uploadsMap,
        sharedWithSchool: content?.sharedWithSchool ?? true,
        sharedWithDistrict: content?.sharedWithDistrict ?? true,
      },
      split,
      toggleDebugger,
    }
  }, [
    block,
    blockId,
    content,
    contentId,
    contentSubType,
    contentType,
    course?.id,
    creating,
    debugging,
    editing,
    split,
    toggleDebugger,
  ])

  return (
    <authorContext.Provider value={value}>{children}</authorContext.Provider>
  )
}

const useAuthorContext = () => {
  const context = useContext(authorContext)
  if (!context) {
    throw new Error('useAuthorContext must be used within an AuthorProvider')
  }
  return context
}

export { useAuthorContext, AuthorProvider }
