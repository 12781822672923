import MenuItem from '@mui/material/MenuItem'
import HFSelect from 'common/formControls/selects/HFSelect'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import { contentShape } from 'core/shapes'
import { orderBy } from 'fp/arrays'
import withContentList from 'hoc/withContentList'
import PropTypes from 'prop-types'

const CourseSelect = ({ items, contentType, ...rest }) => {
  const sortedCourses = orderBy('name')(items)

  return (
    <HFSelect {...rest}>
      {sortedCourses.map(course => (
        <MenuItem
          key={course.id}
          value={course.name}>
          {course.name}
        </MenuItem>
      ))}
    </HFSelect>
  )
}

CourseSelect.propTypes = {
  items: PropTypes.arrayOf(contentShape).isRequired,
  contentType: PropTypes.string, // Not used, but gets passed in from withContentList
}

const CourseSelectWithContent = withContentList(CourseSelect, {
  contentType: CONTENT_TYPE_COURSE,
})

export default CourseSelectWithContent
