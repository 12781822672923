import { CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { contentTypeShape } from 'core/shapes'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import { useSelector } from 'react-redux'
import { getCurrentSectionIsTabbed } from 'selectors/contentViewer'
import Source from '../../Source'
import Subsection from '../Subsection'
import TabbedSection from '../TabbedSection'

const TabbedSectionOrContent = ({ contentType }) => {
  const isPinned = useIsPinnedContent()
  const isTabbedSection = useSelector(getCurrentSectionIsTabbed({ isPinned }))

  if (isTabbedSection) {
    return <TabbedSection contentType={contentType} />
  }

  if (contentType === CONTENT_TYPE_SOURCE) {
    return <Source />
  }

  if (contentType === CONTENT_TYPE_SUBSECTION) {
    return <Subsection />
  }

  return null
}

TabbedSectionOrContent.propTypes = {
  contentType: contentTypeShape.isRequired,
}

export default TabbedSectionOrContent
