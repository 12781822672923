import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { isEmptyString } from 'fp/strings'
import withConfirm from 'hoc/withConfirm'
import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'

const RemoveButton = withConfirm(Button)
const FormActions = ({ name, onRemove, onSave }) => {
  const value = useWatch({ name })

  return (
    <DialogActions>
      <RemoveButton
        color="secondary"
        confirmationMessage="Remove this point from the diagram?"
        confirmationTitle="Remove?"
        DialogProps={{ disableEnforceFocus: true }}
        disabled={isEmptyString(value)}
        onClick={onRemove}>
        Remove
      </RemoveButton>

      <Button
        color="secondary"
        onClick={onSave(value)}
        variant="primary">
        Done
      </Button>
    </DialogActions>
  )
}

FormActions.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default FormActions
