import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { arraySequence } from 'fp/arrays'
import { fallbackTo, identity, isDefined, when } from 'fp/utils'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { compose } from 'redux'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import TextFields from './TextFields'
import VocabMultipleChoice from './VocabMultipleChoice'

const { NORMAL, WORD_CLOUD_CHOOSE_VOCAB_WORDS } = interactiveVariants

const Styled = styled(Box, { name: 'WordCloud-EntryForm' })(
  ({ theme: { palette } }) => ({
    margin: '3rem auto 0',
    maxWidth: featuredContentMaxWidth,
    li: {
      legend: {
        visibility: 'hidden',

        span: {
          display: 'none',
        },
      },
      input: { backgroundColor: palette.background.default },
    },

    '.MuiInputLabel-asterisk': {
      display: 'none',
    },
  }),
)

const EntryForm = () => {
  const {
    contentId,
    interactionData: { phrases },
    interactiveData: { numPhrases, variant = NORMAL },
    isGrading,
    onInteract,
    previewing,
  } = useContext(interactiveContext)

  const emptyPhrases = useMemo(
    () => arraySequence(numPhrases).map(() => ''),
    [numPhrases],
  )
  const [localPhrases, setLocalPhrases] = useState(phrases || emptyPhrases)

  useLayoutEffect(() => {
    when(isGrading, setLocalPhrases, phrases || emptyPhrases)
  }, [emptyPhrases, isGrading, phrases])

  const handleChange = useCallback(
    ({ target }) => {
      const idx = target.dataset?.cardinal

      const newPhrases = compose(
        isDefined(idx)
          ? v => {
              const result = [...localPhrases]
              result[idx] = v
              return result
            }
          : identity,
        fallbackTo(''),
        v => (v === null ? '' : v),
      )(target.value)

      onInteract({ phrases: newPhrases })
      setLocalPhrases(newPhrases)
    },
    [localPhrases, onInteract],
  )

  return (
    <Styled>
      {variant === WORD_CLOUD_CHOOSE_VOCAB_WORDS ? (
        <VocabMultipleChoice
          {...{
            contentId,
            numPhrases,
            phrases: localPhrases,
            onChange: handleChange,
          }}
        />
      ) : (
        <TextFields
          {...{
            numPhrases,
            phrases: localPhrases,
            previewing,
            onChange: handleChange,
          }}
        />
      )}
    </Styled>
  )
}

export default EntryForm
