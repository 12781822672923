import ItemWithFeedback from 'common/layout/ItemWithFeedback'
import Html from 'common/text/Html'
import { get } from 'fp/objects'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { itemShape, responseItemShape } from '../utils'
import SelectedResponse from './SelectedResponse'

const Text = props => {
  const {
    dispatch,
    item,
    item: { id, isStatic, value },
    readOnly,
    responseItem,
    ...rest
  } = props
  const { scoreData } = useContext(interactiveContext)

  const itemScoringResult = get(`result.byId.${id}`)(scoreData)

  return isStatic ? (
    <Html body={value} />
  ) : readOnly ? (
    <ItemWithFeedback scoringResult={itemScoringResult}>
      <Html body={responseItem?.value || '(blank)'} />
    </ItemWithFeedback>
  ) : (
    <SelectedResponse
      dispatch={dispatch}
      item={item}
      responseItem={responseItem}
      {...rest}
    />
  )
}

Text.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: itemShape.isRequired,
  readOnly: PropTypes.bool.isRequired,
  responseItem: responseItemShape,
}

export default Text
