import {
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_VOCABULARY,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import { push } from 'fp/arrays'
import { matches, when } from 'fp/utils'
import withContent from 'hoc/withContent'
import useContent from 'hooks/useContent'
import BreadcrumbsRenderer, {
  DIRECTIVE_NEEDS_SUB_CONTENT_TYPE,
} from 'hss/sections/Breadcrumbs/BreadcrumbsRenderer'
import { useEffect, useState } from 'react'
import { contentBuilderUrl, curriculumUrl, libraryUrl } from 'routing/consts'
import { labels } from '../../../ContentBuilder/consts'
import { buildContentUrl } from '../../../ContentBuilder/utils'

const carveOutsForLibrary = [
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_PAGESET,
  CONTENT_TYPE_VOCABULARY,
]

const maybeAddInteractive = (acc, content) => {
  const { contentType, id, name } = content

  if (contentType === CONTENT_TYPE_INTERACTIVE) {
    acc.push({
      breadcrumb: 'Interactive',
      title: name || id,
      key: {
        directive: DIRECTIVE_NEEDS_SUB_CONTENT_TYPE,
        url: `${contentBuilderUrl}/${contentType}/__subContentType__/${id}`,
        contentType,
        contentId: id,
      },
    })
  }
}

const getBreadcrumbs = (acc, content = {}, isTabbed = false) => {
  const { contentSubType, contentType, id, name, parent, teacherEdition } =
    content

  maybeAddInteractive(acc, content)

  if (contentSubType && contentType !== CONTENT_TYPE_VOCABULARY) {
    acc.push({
      breadcrumb: labels[contentSubType],
      contentType,
      id,
      teacherEdition,
      title: name || id,
      key: buildContentUrl({ contentType, id }),
    })
  } else if (carveOutsForLibrary.includes(contentType)) {
    acc.push({
      breadcrumb:
        parent?.contentType === CONTENT_TYPE_SECTION &&
        contentType === CONTENT_TYPE_SECTION
          ? 'DBI Section'
          : labels[contentType],
      contentType,
      id,
      teacherEdition,
      title: name || id,
      key: buildContentUrl({ contentType, id }),
    })
  } else if (contentType !== CONTENT_TYPE_INTERACTIVE) {
    if (contentType === CONTENT_TYPE_SUBSECTION && isTabbed) {
      acc.push({
        breadcrumb: 'Tab',
        contentType,
        id,
        teacherEdition,
        title: name || id,
        key: buildContentUrl({ contentType, id }),
      })
    } else {
      acc.push({
        breadcrumb:
          parent?.contentType === CONTENT_TYPE_SECTION &&
          contentType === CONTENT_TYPE_SECTION
            ? 'DBI Section'
            : labels[contentType],
        contentType,
        id,
        teacherEdition,
        title: name || id,
        key: buildContentUrl({ contentType, id }),
      })
    }
  }
  when(parent, getBreadcrumbs, acc, parent, isTabbed)
  return acc
}

const CurriculumBuilderBreadcrumbs = ({ content }) => {
  const { contentType } = content || {}

  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [sectionId, setSectionId] = useState()
  const [isTabbed, setIsTabbed] = useState(false)

  const section = useContent({
    contentType: CONTENT_TYPE_SECTION,
    contentId: sectionId,
    /**
     * We won't always have sectionId, and when we don't, we don't want useContent
     * to go trying to guess at the content id based on the url and such.  We
     * can't conditionally run this hook, but disabling fetching has the same effect.
     */
    disableFetch: true,
  })

  useEffect(() => {
    const rootBreadcrumb = carveOutsForLibrary.includes(contentType)
      ? {
          breadcrumb: 'Library',
          key: libraryUrl,
        }
      : {
          breadcrumb: 'Curriculum',
          key: curriculumUrl,
        }

    const built = push(rootBreadcrumb)(
      getBreadcrumbs([], content || {}, isTabbed),
    ).reverse()

    const { id } =
      built.find(matches('contentType', CONTENT_TYPE_SECTION)) || {}
    setSectionId(id)
    setIsTabbed(section?.data?.tabbed)

    setBreadcrumbs(built)
  }, [content, contentType, isTabbed, section?.data])

  return content ? <BreadcrumbsRenderer breadcrumbs={breadcrumbs} /> : null
}

CurriculumBuilderBreadcrumbs.propTypes = {
  content: contentShape,
}

const BreadcrumbsWithContent = withContent(CurriculumBuilderBreadcrumbs, {
  busy: 'silent',
  queryParams: { childDepth: 2 },
})

export default BreadcrumbsWithContent
