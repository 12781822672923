import {
  INTERACTION_STATE_COMPLETED,
  INTERACTION_TYPE_PAGE_VIEW,
} from 'core/consts'
import { isUndefined } from 'fp/utils'
import useIsInViewport from 'hooks/useIsInViewport'
import { isPastMaxSubmitDate } from 'hss/utils'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as interactiveActions } from 'reducers/interactions'
import { getCurrentViewContent } from 'selectors/contentViewer'
import { getContentHasBeenViewed } from 'selectors/interactions'
import {
  getAssignmentBehindUserAssignment,
  getUserAssignment,
} from 'selectors/userAssignments'

/**
 * This records a 'page-view' user interaction.
 *
 * An instance of this component is placed near the bottom of the window.
 * A 'page-view' user interaction is recorded when the component instance first
 * enters the viewport.
 *
 * A blanking period exists during which time no interactions are recorded.
 * This is to account for page transitions whenever new content is being loaded.
 * False positives would occur if the component was already on-screen while the
 * url and subsection were changing.  The blanking period gives the browser time
 * to scroll back to the top and let the content push the recorder component back
 * down the screen.
 */

const BLANKING_TIMEOUT = 1500

const RecordPageView = () => {
  const ref = useRef()
  const dispatch = useDispatch()
  const inViewport = useIsInViewport(ref)
  const timeoutRef = useRef(null)

  const { id: contentId, id: contextContentId } =
    useSelector(getCurrentViewContent) || {}
  const { id: userAssignmentId, submittedDate } =
    useSelector(getUserAssignment) || {}

  const assignment = useSelector(getAssignmentBehindUserAssignment)
  const hasBeenViewed = useSelector(getContentHasBeenViewed({ contentId }))
  const [inBlankingPeriod, setInBlankingPeriod] = useState(true)
  const [previousContentId, setPreviousContentId] = useState(undefined)
  const isPastSubmissionDate = isPastMaxSubmitDate(assignment)

  useEffect(() => {
    if (contentId !== previousContentId) {
      setPreviousContentId(contentId)
      setInBlankingPeriod(true)
      timeoutRef.current = setTimeout(() => {
        setInBlankingPeriod(false)
      }, BLANKING_TIMEOUT)
    }
    /**
     * DO NOT CLEAR THIS TIMEOUT IN A RETURN FUNCTION!
     * I know it seems like we should, but that defeats the whole purpose of the
     * blanking period.
     */
  }, [contentId, previousContentId])

  useEffect(() => {
    if (
      contentId &&
      inViewport &&
      !hasBeenViewed &&
      !inBlankingPeriod &&
      isUndefined(submittedDate) &&
      !isPastSubmissionDate
    ) {
      setInBlankingPeriod(true)
      dispatch(
        interactiveActions.postPageView({
          contentId,
          contextContentId,
          interactionData: {},
          interactionType: INTERACTION_TYPE_PAGE_VIEW,
          state: INTERACTION_STATE_COMPLETED,
          userAssignmentId,
        }),
      )
    }
  }, [
    contentId,
    contextContentId,
    dispatch,
    hasBeenViewed,
    inBlankingPeriod,
    isPastSubmissionDate,
    inViewport,
    submittedDate,
    userAssignmentId,
  ])

  return <span ref={ref} />
}

export default RecordPageView
