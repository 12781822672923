import { useTheme } from '@mui/material/styles'
import Headline from 'common/text/Headline'
import { get, set } from 'fp/objects'
import { toInt } from 'fp/strings'
import { matches } from 'fp/utils'
import { produce } from 'immer'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { isTestEnv } from 'selectors/index'
import Segment from './Segment'

const SegmentColors = ({ onChange, preferences }) => {
  const segmentColors = get('gradeColors.segmentColors')(preferences)
  const {
    palette: { gradebook },
  } = useTheme()

  const onColorChange =
    idx =>
    ({ colorId }) => {
      onChange(set(`gradeColors.segmentColors.${idx}.colorId`, colorId))
    }

  const handleChange = idx => value => {
    const [begin, end] = isTestEnv()
      ? value.split(',').map(toInt)
      : /* istanbul ignore next */
        value
    onChange(
      produce(draft => {
        draft.gradeColors.segmentColors[idx].begin = begin
        draft.gradeColors.segmentColors[idx].end = end
      }),
    )
  }

  const onDelete = idx => () => {
    onChange(
      produce(draft => {
        draft.gradeColors.segmentColors.splice(idx, 1)
      }),
    )
  }

  const fenceMin = useCallback(
    idx => (idx > 0 ? segmentColors[idx - 1].end + 1 : 0),
    [segmentColors],
  )

  const fenceMax = useCallback(
    idx =>
      idx < segmentColors.length - 1 ? segmentColors[idx + 1].begin - 1 : 100,
    [segmentColors],
  )

  return (
    <Headline
      mb={5}
      mt={1}
      textAlign="left"
      title="Segment Colors"
      weight={300}>
      <div className="segments">
        {segmentColors.map(({ begin, colorId, end }, idx) => (
          <div
            key={idx}
            style={{ marginTop: 20 }}>
            <Segment
              begin={begin}
              className={`colorId-${colorId}`}
              colorId={colorId}
              colorName={gradebook.find(matches('colorId', colorId))?.label}
              end={end}
              fenceMax={fenceMax(idx)}
              fenceMin={fenceMin(idx)}
              idx={idx}
              onChange={handleChange(idx)}
              onColorChange={onColorChange(idx)}
              onDelete={onDelete(idx)}
            />
          </div>
        ))}
      </div>
    </Headline>
  )
}

SegmentColors.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default SegmentColors
