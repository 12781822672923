import { DND_TYPE_CALLOUT } from 'core/consts'
import { imageTitleDragCalloutShape } from 'core/shapes'
import { useContext } from 'react'
import { useDrag } from 'react-dnd'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import DragItem from '../DragItem'

const OptionSwatch = ({ callout: { label, id } }) => {
  const { submittable } = useContext(interactiveContext)
  const [, dragRef] = useDrag({
    item: { id, label },
    type: DND_TYPE_CALLOUT,
    canDrag: submittable,
  })

  return <DragItem ref={dragRef}>{label}</DragItem>
}

OptionSwatch.propTypes = {
  callout: imageTitleDragCalloutShape.isRequired,
}

export default OptionSwatch
