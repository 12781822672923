import Link from 'common/navigation/links/Link'
import { ASSIGNMENT_STARTED, ASSIGNMENT_UNSTARTED } from 'core/consts'
import { asFloatWithoutTrailingZeros } from 'fp/numbers'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import {
  getTeacherAssignmentRoute,
  getTeacherAssignmentUserRoute,
} from 'routing/consts'
import { gradebookContext } from './GradebookContextProvider'
import RequiresGradingIndicator from './RequiresGradingIndicator'

const AssignmentScoreCell = ({
  assignmentId,
  contentType,
  cell: { renderValue },
  scoreAccessorFn,
}) => {
  const { view } = useContext(gradebookContext)
  const userAssignment = renderValue()
  const {
    gradingStatus,
    id: userAssignmentId,
    requiresGrading,
    userId,
  } = userAssignment

  const scoreValue = scoreAccessorFn(userAssignment)
  if (
    gradingStatus === ASSIGNMENT_UNSTARTED ||
    scoreValue === undefined ||
    scoreValue === null
  )
    return null

  if (gradingStatus === ASSIGNMENT_STARTED) {
    return (
      <Link to={getTeacherAssignmentRoute({ contentType, id: assignmentId })}>
        —
      </Link>
    )
  }

  return (
    <Link
      to={getTeacherAssignmentUserRoute({
        assignmentId,
        contentType,
        userAssignmentId,
        userId,
      })}>
      {view === 'scores' ? asFloatWithoutTrailingZeros(scoreValue) : scoreValue}
      {Boolean(requiresGrading) && <RequiresGradingIndicator />}
    </Link>
  )
}

AssignmentScoreCell.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  cell: PropTypes.shape({
    renderValue: PropTypes.func.isRequired,
  }).isRequired,
  scoreAccessorFn: PropTypes.func.isRequired,
}

export default AssignmentScoreCell
