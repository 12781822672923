import Stack from '@mui/system/Stack'
import cl from 'classnames'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import Headline from 'common/text/Headline'
import Html from 'common/text/Html'
import { CONTENT_TYPE_INTERACTIVE, CONTENT_TYPE_PAGE } from 'core/consts'
import { entityIdShape } from 'core/shapes'
import { isDefined } from 'fp/utils'
import useContent from 'hooks/useContent'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getBlocksForSubsectionOrEcho } from 'selectors/contentViewer'
import Block from './Block'
import StyledContainer from './StyledContainer'

const PageRenderer = props => {
  const { id, variant = interactiveVariants.NORMAL } = props

  const page = useContent({
    contentType: CONTENT_TYPE_PAGE,
    contentId: id,
    queryParams: { childDepth: 3 },
  })
  const {
    data: { imageBleed },
  } = page

  const blocks = Maybe.fromFalsy(page)
    .map(getBlocksForSubsectionOrEcho)
    .map(useSelector)
    .orJust([])

  // assuming the first interactive we come across is the image
  const imageBlock = blocks.find(
    ({ children }) =>
      children.filter(
        ({ contentType }) => contentType === CONTENT_TYPE_INTERACTIVE,
      ).length > 0,
  )

  // if the image is in the first block, then it's visually on the left, else right
  const hasImage = isDefined(imageBlock)
  const isImageFirst = hasImage && blocks.indexOf(imageBlock) === 0
  const image = useContent({
    contentId: imageBlock?.children[0].id,
    contentType: CONTENT_TYPE_INTERACTIVE,
  })
  const nonImageBlocks = blocks.filter(
    ({ id: imageBlockId }) => imageBlockId !== imageBlock?.id,
  )

  const isSmallScreen = useContainerQuery().down('lg')
  return (
    <StyledContainer
      alignItems="center"
      className={cl(variant, {
        'small-screen': isSmallScreen,
        'padding-left': isImageFirst && !imageBleed,
        'bleed-width': imageBleed === 'width',
        'bleed-height': imageBleed === 'height' && !isSmallScreen,
      })}
      direction={
        isSmallScreen ? 'column' : isImageFirst ? 'row' : 'row-reverse'
      }>
      {Boolean(hasImage) && (
        <Block
          id={imageBlock.id}
          key={imageBlock.id}
          variant="timeline"
        />
      )}

      <Stack
        justifyContent="flex-end"
        pb={isSmallScreen ? 2 : 0}
        px={3}>
        <Headline title={page.name} />
        {nonImageBlocks.map(block => (
          <Block
            id={block.id}
            key={block.id}
            variant="timeline"
          />
        ))}
        {isDefined(image?.data?.caption) && (
          <div
            aria-hidden="true"
            className="caption">
            <Html body={image.data.caption} />
          </div>
        )}
      </Stack>
    </StyledContainer>
  )
}

PageRenderer.propTypes = {
  id: entityIdShape.isRequired,
  variant: PropTypes.oneOf([
    interactiveVariants.NORMAL,
    interactiveVariants.TIMELINE_ECON,
  ]),
}

export default PageRenderer
