import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { entityIdShape } from 'core/shapes'
import { findObj } from 'fp/arrays'
import { equals } from 'fp/objects'
import { isDefined, matches } from 'fp/utils'
import withConfirm from 'hoc/withConfirm'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { XCircle } from 'react-feather'
import Dialog from './Dialog'
import HeaderLink from './HeaderLink'
import Container from './SVGContainer'
import VennSections from './VennSections'
import {
  orderedSections,
  threeCirclePositioning,
  twoCirclePositioning,
} from './config'

const RemoveButton = withConfirm(IconButton)

const Content = props => {
  const {
    circles,
    contentId = '',
    hideSampleResponse = true,
    onChange,
    readOnly = false,
    sections,
    studentBottomCircle = false,
    studentHeadlineEditing = false,
  } = props
  const canCirclesBeAdded = studentBottomCircle && !readOnly
  const canHeadlinesBeEdited = studentHeadlineEditing && !readOnly
  const [selectedSection, setSection] = useState()

  const handleRemoveCircle = useCallback(() => {
    onChange('circles', 2)
  }, [onChange])

  const handleAddCircle = useCallback(() => {
    onChange('circles', 3)
  }, [onChange])

  const positioning = useMemo(
    () => (circles === 2 ? twoCirclePositioning() : threeCirclePositioning()),
    [circles],
  )

  const handleClick = useCallback(
    ({ circleIds }) =>
      e => {
        e?.preventDefault()
        const sectionIndex = sections.findIndex(sec =>
          equals(sec.circleIds)(circleIds),
        )
        setSection(sectionIndex)
      },
    [sections],
  )

  const getSectionByCircleIds = useCallback(
    circleIds =>
      sections.find(({ circleIds: cIds }) => equals(circleIds)(cIds)),
    [sections],
  )

  const leftRight = ['left', 'right'].map(key =>
    orderedSections.find(matches('key', key)),
  )
  const bottomSection = findObj('key', 'bottom')(orderedSections)

  useEffect(() => {
    if (
      (circles === 3 && sections.length !== 7) ||
      (circles === 2 && sections.length !== 3)
    ) {
      onChange(
        'sections',
        circles === 3
          ? orderedSections.map(({ circleIds }) => ({
              header: getSectionByCircleIds(circleIds)?.header || '',
              response: getSectionByCircleIds(circleIds)?.response || '',
              circleIds,
            }))
          : sections.filter(({ circleIds }) => !circleIds.includes(3)),
      )
    }
  }, [circles, getSectionByCircleIds, onChange, sections])

  const { ariaDescription, circles: circlePositions, viewBox } = positioning

  return (
    <div style={{ position: 'relative' }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between">
        {leftRight.map(s => {
          const { header } = getSectionByCircleIds(s.circleIds)
          return (
            <HeaderLink
              header={header}
              key={s.key}
              onClick={handleClick(s)}
              readOnly={!canHeadlinesBeEdited}
            />
          )
        })}
      </Stack>

      <Container>
        <svg
          aria-describedby="dgo-venn-desc"
          aria-labelledby="dgo-venn-title"
          role="group"
          viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}>
          <title id="dgo-venn-title">Venn Diagram Image.</title>
          <desc id="dgo-venn-desc">
            {ariaDescription}
            {'\n'}
            Each section may contain text.
            {Boolean(readOnly) &&
              '\nNavigate further to hear the text in each section.'}
          </desc>

          <defs>
            {Object.keys(positioning.clipPaths).map(cp => (
              // biome-ignore lint/a11y/noAriaHiddenOnFocusable: <explanation>
              <clipPath
                aria-hidden="true"
                id={`${contentId}-${cp}`}
                key={cp}
              >
                <path d={positioning.clipPaths[cp]} />
              </clipPath>
            ))}
          </defs>

          <VennSections
            {...{
              circles,
              contentId,
              onClick: handleClick,
              positioning,
              readOnly,
              sections,
            }}
          />

          {circlePositions.map(circle => (
            <g key={circle.center.x}>
              {/* biome-ignore lint/a11y/noAriaHiddenOnFocusable: <explanation> */}
              <circle
                aria-hidden="true"
                className="outline"
                cx={circle.center.x}
                cy={circle.center.y}
                r={circle.radius}
                strokeWidth={0.5}
              />
            </g>
          ))}
        </svg>

        {isDefined(selectedSection) && (
          <Dialog
            canHeadlinesBeEdited={canHeadlinesBeEdited}
            hideSampleResponse={hideSampleResponse}
            index={selectedSection}
            onChange={onChange}
            onClose={() => {
              setSection(null)
            }}
            open
            readOnly={readOnly}
            section={sections[selectedSection]}
          />
        )}
      </Container>

      {circles === 3 && canCirclesBeAdded ? (
        <>
          <RemoveButton
            aria-label="Remove Bottom Circle"
            confirmationMessage="You are about to remove the bottom section. Are you sure you want to do this?"
            confirmationTitle="Remove?"
            onClick={handleRemoveCircle}
            style={{ position: 'absolute', bottom: 70, right: '15%' }}>
            <XCircle />
          </RemoveButton>
          <HeaderLink
            header={getSectionByCircleIds(bottomSection.circleIds)?.header}
            onClick={handleClick(bottomSection)}
            readOnly={!canHeadlinesBeEdited}
          />
        </>
      ) : (
        canCirclesBeAdded && (
          <Button
            color="secondary"
            onClick={handleAddCircle}
            variant="tertiary">
            Add Bottom Circle
          </Button>
        )
      )}
    </div>
  )
}
Content.propTypes = {
  circles: PropTypes.number.isRequired,
  contentId: entityIdShape,
  onChange: PropTypes.func,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      circleIds: PropTypes.array.isRequired,
      header: PropTypes.string,
      response: PropTypes.string,
      sampleResponse: PropTypes.string,
    }),
  ).isRequired,
  hideSampleResponse: PropTypes.bool,
  readOnly: PropTypes.bool,
  studentHeadlineEditing: PropTypes.bool,
  studentBottomCircle: PropTypes.bool,
}

export default Content
