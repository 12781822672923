export const pacingOptions = [
  {
    key: 'a',
    value: 'Balanced Approach',
  },
  {
    key: 'b',
    value: 'Inquiry-focused Approach',
  },
  {
    key: 'c',
    value: 'Narrative-focused Approach',
  },
]

export const groupings = [
  {
    key: 'WG',
    value: 'Whole Group',
  },
  {
    key: 'SG',
    value: 'Small Group',
  },
  {
    key: 'IND',
    value: 'Individual',
  },
]
