import Box from '@mui/material/Box'
import { componentShape } from 'core/shapes'

const CustomIcon = ({ ButtonIcon, children }) =>
  ButtonIcon ? <ButtonIcon /> : <Box width="24px">{children}</Box>

CustomIcon.propTypes = {
  ButtonIcon: componentShape,
  children: componentShape,
}

export default CustomIcon
