import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Rating from 'common/rubrics/Rating'
import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import { getAt } from 'fp/arrays'
import { equals, get } from 'fp/objects'
import { whenPresent } from 'fp/utils'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { featuredContentMaxWidth } from 'styling/theming/base'

const optionWidth = 32

const ZeroRating = styled(Rating)(({ theme: { palette } }) => ({
  marginRight: 24,
  '& .MuiRating-iconFilled': {
    color: palette.grey[0],
  },
  '& .MuiRating-iconHover': {
    color: palette.grey[0],
  },
}))

const RegularRating = styled(Rating)(({ theme: { palette } }) => ({
  gap: 8,
  '& .MuiRating-iconFilled': {
    color: palette.cobalt[0],
  },
  '& .MuiRating-iconHover': {
    color: palette.cobalt[0],
  },
}))

const Rubric = props => {
  const { onChange, readOnly = false, values: valuesProp } = props
  const {
    gradingEnabled,
    interactive: { contentSubType, rubric },
  } = useContext(interactiveContext) || {}
  const {
    children,
    data: { maxScore, startAtZero },
  } = rubric || { children: [], data: {} }
  const [values, setValues] = useState()

  const forceReadOnly = readOnly || gradingEnabled === false
  const isEcho = contentSubType?.startsWith('echo')

  const rows = useMemo(() => {
    const data = children.map(get('data'))
    if (startAtZero) {
      return data.map(row => ({
        zeroRow: {
          options: row.options?.slice(0, 1) || [],
          title: row.title || '',
        },
        regularRow: {
          options: row.options?.slice(1) || [],
          title: row.title || '',
        },
      }))
    }
    return data.map(row => ({
      regularRow: {
        options: row.options || [],
        title: row.title || '',
      },
    }))
  }, [children, startAtZero])

  useEffect(() => {
    setValues(valuesProp || new Array(rows.length))
  }, [rows.length, valuesProp])

  const maxRatingWidth = `${maxScore * optionWidth}px`

  const getScores = rowIdx => rows[rowIdx].regularRow.options.map(r => r.score)

  // this is used to only show score headings if they are different from the previous row
  const showScores = rowIdx =>
    rowIdx === 0 || !equals(getScores(rowIdx - 1))(getScores(rowIdx))

  const handleChange = useCallback(
    (rowIdx, rowValue) => {
      const newValues = [...values]
      newValues[rowIdx] = rowValue
      setValues(newValues)
      whenPresent(onChange, newValues)
    },
    [onChange, values],
  )

  return (
    <Box
      margin="0 auto"
      maxWidth={featuredContentMaxWidth}>
      {rows.map((row, rowIdx) => (
        <div key={rowIdx}>
          <List style={{ paddingTop: 0 }}>
            <ListItem style={{ padding: 0 }}>
              <Box
                display="flex"
                width="100%">
                <Typography
                  flex="1"
                  sx={{ alignSelf: 'end' }}
                  variant="paragraph">
                  {row.regularRow.title}
                </Typography>
                {Boolean(startAtZero) && (
                  <ZeroRating
                    data-testid={`${contentSubType}-zero-rating`}
                    onChange={
                      onChange ? val => handleChange(rowIdx, val) : null
                    }
                    readOnly={forceReadOnly}
                    row={row.zeroRow}
                    showScores={showScores(rowIdx)}
                    value={getAt(values, rowIdx)}
                    variant={isEcho ? 'star' : 'square'}
                  />
                )}
                <Box width={maxRatingWidth}>
                  <RegularRating
                    data-testid={`${contentSubType}-rating`}
                    onChange={
                      onChange ? val => handleChange(rowIdx, val) : null
                    }
                    readOnly={forceReadOnly}
                    row={row.regularRow}
                    showScores={showScores(rowIdx)}
                    value={getAt(values, rowIdx)}
                    variant={isEcho ? 'star' : 'square'}
                  />
                </Box>
              </Box>
            </ListItem>
          </List>
        </div>
      ))}
    </Box>
  )
}

export const detachedInteractionOptions = {
  contentSubType: CONTENT_TYPE_RUBRIC,
}

Rubric.propTypes = {
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.string),
}

export default Rubric
