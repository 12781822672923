import { withOptions } from '@comfy/redux-selectors'
import {
  ALL_SUBMITTABLE_INTERACTIVES,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SCAFFOLD,
} from 'core/consts'
import { filter, push, reverse } from 'fp/arrays'
import { set } from 'fp/objects'
import { compose } from 'redux'

const horizontalVariants = ['chapter-recap', 'research-link']
const EOL = { EOL: true }

const associateScaffolds =
  (isEcho, { presenterModeEnabled }) =>
  definitions => {
    /**
     * The idea here is to walk through the blocks from bottom to top, searching
     * for scaffolds.
     *
     * Standalone scaffolds and blocks that are not scaffolds are simply left alone.
     *
     * Attached scaffolds are shoved into a buffer while we then switch over to
     * searching for an interactive that can support scaffolds.  When one is found,
     * the buffered scaffolds are all assigned to it.  So basically all scaffolds
     * rollup into the nearest supported interactive that preceded them.
     *
     * NOTES:
     *   Echoes are a special case since the scaffolds are stored as children.  We
     *   pull those scaffolds out of the flow here.  When Echoes render, they need
     *   to place these scaffolds outside of the tabbed children flow, but only made
     *   active for the "respond" tab.
     */
    let buffer = []

    const result = definitions.map(definition => {
      const {
        block,
        block: {
          children,
          contentType,
          data: { standalone },
        },
      } = definition

      if (contentType === CONTENT_TYPE_SCAFFOLD) {
        if (isEcho) return null
        if (standalone && presenterModeEnabled) return null
        if (standalone) return definition

        buffer.push(block)
        return null
      }

      const availableInteractive = children.find(
        child =>
          child.contentType === CONTENT_TYPE_INTERACTIVE &&
          ALL_SUBMITTABLE_INTERACTIVES.includes(child.contentSubType),
      )

      if (availableInteractive) {
        availableInteractive.attachedScaffolds = [...buffer]
        buffer = []
      }

      return definition
    })

    if (buffer.length) {
      // biome-ignore lint/suspicious/noConsole: intended
      console.error(`
      !!!!!! Invariant state detected !!!!!!

      One or more non-standalone scaffolds exist that have no attachable
      interactives above them.  These orphaned scaffolds have been hidden
      from display.
    `)
    }

    return result
  }

export const processForHorizontalWrappers = withOptions(
  ({ contentType, presenterModeEnabled }) =>
    compose(
      blocks => {
        let wrapped = []
        let wrapping = false
        const result = []

        for (const block of blocks) {
          const shouldWrap = horizontalVariants.includes(
            block?.block?.data?.variant,
          )

          if (shouldWrap) {
            if (!wrapping) {
              wrapping = true
              wrapped = []
            }
            wrapped.push(set('teRowSpan', 1)(block))
          } else {
            if (wrapping) {
              wrapping = false
              result.push(wrapped)
            }
            if (block !== EOL) {
              result.push(block)
            }
          }
        }
        return result
      },
      push(EOL),
      reverse,
      filter(Boolean),
      associateScaffolds(contentType === CONTENT_TYPE_ECHO, {
        presenterModeEnabled,
      }),
      reverse,
    ),
)
