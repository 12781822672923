import MenuItem from '@mui/material/MenuItem'
import MenuButton from 'common/menus/MenuButton'
import Link from 'common/navigation/links/Link'
import LinkButton from 'common/navigation/links/LinkButton'
import { PercentageTableCell } from 'common/tables/DataTable/Cells'
import { ASSIGNMENT_SUBMITTED, CONTENT_TYPE_ASSESSMENT } from 'core/consts'
import { get } from 'fp/objects'
import withProps from 'hoc/withProps'
import { formatDateStrShort } from 'locale/i18n'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { assignmentEditorUrl, getTeacherAssignmentRoute } from 'routing/consts'
import { getAssignmentsGradebook } from 'selectors/gradebook'
import AssignmentScoreCell from './AssignmentScoreCell'
import GradebookCellWrapper from './GradebookCellWrapper'
import { gradebookContext } from './GradebookContextProvider'
import GradebookDataTable from './GradebookDataTable'
import { lastNameFirstName } from './utils'

const staticColumns = [
  {
    accessorFn: lastNameFirstName,
    header: 'Student',
    id: 'student',
    isCellHeader: true,
  },
  {
    accessorFn: ({ maxScore, score, gradingStatus }) =>
      gradingStatus === ASSIGNMENT_SUBMITTED ? score / maxScore : 0,
    cell: PercentageTableCell,
    header: 'Overall Grade',
    id: 'overallGrade',
  },
]

const headDataRows = [
  {
    accessorFn: ({ target: { firstName, name, lastName } }) =>
      name || `${lastName}, ${firstName}`,
    name: 'Target',
  },
  {
    accessorFn: compose(formatDateStrShort, get('startDate')),
    name: 'Start Date',
  },
  {
    accessorKey: 'maxScore',
    name: 'Max Score',
  },
]

const MenuItemLinkButton = withProps(MenuItem, { component: LinkButton })

const createAssignmentColumn = scoreAccessorFn => assignment => ({
  accessorFn: ({ id: userId, userAssignmentsByAssignmentId }) => ({
    userId,
    ...get(assignment.id)(userAssignmentsByAssignmentId),
  }),
  cell: withProps(AssignmentScoreCell, {
    assignmentId: assignment.id,
    scoreAccessorFn,
    contentType: assignment.contentType,
  }),
  cellProps: { className: 'gradebook-cell', cellWrapper: GradebookCellWrapper },
  data: assignment,
  header: ({
    column: {
      columnDef: {
        data: { id, name, contentType },
      },
    },
  }) => (
    <>
      <Link
        title={name}
        to={getTeacherAssignmentRoute({ contentType, id })}>
        {name}
      </Link>
      <div className="menu">
        <MenuButton
          color="secondary"
          label={`Actions for ${name}`}>
          <MenuItemLinkButton
            to={getTeacherAssignmentRoute({ contentType, id })}>
            View Report
          </MenuItemLinkButton>

          {contentType !== CONTENT_TYPE_ASSESSMENT && (
            <MenuItemLinkButton to={`${assignmentEditorUrl}/${id}`}>
              View Assignment
            </MenuItemLinkButton>
          )}

          <MenuItemLinkButton to={`${assignmentEditorUrl}/${id}/settings`}>
            Edit Assignment
          </MenuItemLinkButton>
        </MenuButton>
      </div>
    </>
  ),
  id: assignment.id,
  maxSize: 140,
})

const GradebookAssignmentsDataTable = ({ scoreAccessorFn }) => {
  const { showAssignmentDetails, userAssignmentIds } =
    useContext(gradebookContext)
  const { assignments, requiresGrading, students } = useSelector(
    getAssignmentsGradebook({ userAssignmentIds }),
  )

  const superColumns = assignments.map(createAssignmentColumn(scoreAccessorFn))
  const columns = staticColumns.concat(superColumns)

  return assignments?.length && students?.length ? (
    <GradebookDataTable
      columns={columns}
      data={students}
      disableCardView
      headDataRows={showAssignmentDetails ? headDataRows : []}
      requiresGrading={requiresGrading}
      variant="gradebook"
    />
  ) : null
}

GradebookAssignmentsDataTable.propTypes = {
  scoreAccessorFn: PropTypes.func.isRequired,
}

export default GradebookAssignmentsDataTable
