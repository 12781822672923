import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { Save } from 'react-feather'

const SaveButton = styled(
  props => (
    <div>
      <Button
        color="secondary"
        endIcon={<Save />}
        variant="primary"
        {...props}>
        Save Changes
      </Button>
    </div>
  ),
  { name: 'settings-SaveButton' },
)(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    margin: rem(8, 0),
  }),
)

export default SaveButton
