import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import Headline from 'common/text/Headline'
import VocabularyItem from 'common/vocabulary/VocabularyItem'
import { VOCAB_TERM_TYPE_SKILL } from 'core/consts'
import { contentShape, vocabTypeShape } from 'core/shapes'
import { filter } from 'fp/arrays'
import { get, notEquals } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import withProps from 'hoc/withProps'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getAggregatedVocabFromContentChildren } from 'selectors/vocabulary'
import { title, variantShape } from './utils'

const getVocabularyForContent = ({ content, vocabType }) =>
  compose(
    filter(compose(notEquals(VOCAB_TERM_TYPE_SKILL), get('data.termType'))),
    fallbackTo([]),
    get(`${vocabType}VocabPhrases`),
    fallbackTo({}),
  )(content)

const VocabularyForType = ({
  className,
  content,
  variant = 'aggregate',
  vocabType,
}) => {
  const aggregateStore = useSelector(
    getAggregatedVocabFromContentChildren({ content, vocabType }),
  )
  const selfStore = getVocabularyForContent({ content, vocabType })

  const isSmallScreen = useContainerQuery().down('md')

  const vocabulary = variant === 'aggregate' ? aggregateStore : selfStore

  if (!vocabulary.length) return null

  return (
    <div>
      {variant === 'aggregate' ? (
        <Headline
          className={className}
          title={title[vocabType]}
          variant="list-header"
        />
      ) : (
        <strong>{title[vocabType]}: </strong>
      )}

      <List variant={isSmallScreen || variant !== 'self' ? 'csv' : 'csv-right'}>
        {vocabulary.map((vocabContent, idx) => (
          <Typography
            component="li"
            key={vocabContent.id || idx}
            variant="small">
            <VocabularyItem content={vocabContent} />
          </Typography>
        ))}
      </List>
    </div>
  )
}

VocabularyForType.propTypes = {
  content: contentShape.isRequired,
  variant: variantShape,
  vocabType: vocabTypeShape.isRequired,
}
export const AcademicVocabulary = withProps(VocabularyForType, {
  vocabType: 'academic',
})
export const ContentVocabulary = withProps(VocabularyForType, {
  vocabType: 'content',
})
