import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import withSearchProvider from './searchConfig'

const initialSorting = [
  {
    id: 'target.name',
    desc: false,
  },
]

const Districts = () => (
  <CommonTableSearch
    initialSorting={initialSorting}
    title="Districts"
  />
)

const Searchable = withSearchProvider(Districts)

export default Searchable
