import { INTERACTION_STATE_COMPLETED } from 'core/consts'
import { mapValues, set } from 'fp/objects'
import { isUndefined } from 'fp/utils'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAssignmentStudents } from 'selectors/assignments'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { interactiveContext } from '../InteractiveProvider'
import { gradeIndicators } from './utils'

const useGradingSummary = () => {
  const { assignmentId } = useParams()
  const userAssignments = useSelector(getAssignmentStudents({ assignmentId }))
  const { contentId } = useContext(interactiveContext) || {}
  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  const getStatus = (state, scoreData) => {
    const { requiresGrading, score } = scoreData || {}

    if (state !== INTERACTION_STATE_COMPLETED) return 'incomplete'
    if (requiresGrading) return 'gradingRequired'
    if (isUndefined(score)) return 'complete'
    if (score >= 1) return 'correct'
    return 'incorrect'
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies(getStatus): render issue
  return useMemo(() => {
    const statusByUserId = (allInteractions || []).reduce(
      (prev, { state, scoreData, userId }) =>
        set(userId, getStatus(state, scoreData))(prev),
      {},
    )

    return (userAssignments || []).reduce(
      (result, userAssignment) => {
        const status = statusByUserId[userAssignment.userId] || 'notStarted'
        return set(status, [...result[status], userAssignment])(result)
      },
      mapValues(() => [])(gradeIndicators),
    )
  }, [allInteractions, userAssignments])
}

export default useGradingSummary
