import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import { get, set } from 'fp/objects'
import PropTypes from 'prop-types'

const VideoRecording = ({ onChange, preferences }) => {
  const videoRecordingCountdown = get('videoRecordingCountdown')(preferences)

  const handleChange = () => {
    onChange(set('videoRecordingCountdown', !videoRecordingCountdown))
  }

  return (
    <SwitchFormControl
      checked={videoRecordingCountdown}
      helperText="Show a short countdown before recording video, instead of starting immediately"
      label="Countdown"
      onChange={handleChange}
    />
  )
}

VideoRecording.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default VideoRecording
