import TeachBanner from 'hss/ContentViewer/Banner/TeachBanner'

const AnswerKey = () => (
  <>
    <TeachBanner title="Answer Key" />
    Answer Key TK
  </>
)

export default AnswerKey
