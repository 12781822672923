import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import type React from 'react'
import {
  // BookOpen,
  File,
  // Layers,
  // Sidebar,
} from 'react-feather'
import {
  // CONTENT_TYPE_CHAPTER,
  // CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
} from 'shared/consts'
import { importantPx } from 'styling/theming/base/mixins'

type AuthoringType = {
  label: string
  contentType: string
  contentSubType?: string
  icon: React.JSX.Element
}

export const authoringTypes: Record<string, AuthoringType> = {
  singlePageSource: {
    label: 'Source', // 'Single Page Source',
    contentType: CONTENT_TYPE_SOURCE,
    // contentSubType: CONTENT_SUB_TYPE_STANDALONE,
    icon: <File />,
  },
  // structuredSource: {
  //   label: 'Structured Source',
  //   contentType: CONTENT_TYPE_SOURCE,
  //   contentSubType: 'structured',
  //   icon: <Layers />,
  // },
  // chapter: {
  //   label: 'Chapter',
  //   contentType: CONTENT_TYPE_CHAPTER,
  //   icon: <BookOpen />,
  // },
  // echo: {
  //   label: 'Echo',
  //   contentType: CONTENT_TYPE_ECHO,
  //   icon: <Sidebar style={{ transform: 'scaleX(-1)' }} />,
  // },
}

type Kind = keyof typeof authoringTypes

const ItemRenderer = ({ kind }: { kind: Kind }) => {
  if (!authoringTypes[kind]) {
    throw new Error(`Invalid kind: ${kind}`)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        gap: 1,
        svg: { width: 50, height: 50 },
      }}>
      {authoringTypes[kind].icon}
      <Box
        sx={{
          fontSize: importantPx(12),
          textAlign: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {authoringTypes[kind].label}
      </Box>
    </Box>
  )
}

type AuthoringTypeSelectorProps = {
  selectedType: Kind
  setSelectedType: (kind: Kind) => void
}

const AuthoringTypeSelector = ({
  selectedType,
  setSelectedType,
}: AuthoringTypeSelectorProps) => (
  <List
    sx={{
      display: 'flex',
      flexFlow: 'row wrap',
      width: '100%',
      justifyContent: 'space-around',
    }}>
    {Object.keys(authoringTypes).map(key => (
      <ListItem
        button
        key={key}
        onClick={() => setSelectedType(key)}
        selected={selectedType === key}
        sx={{ minWidth: 150, maxWidth: 150, aspectRatio: 1 }}>
        <ItemRenderer kind={key} />
      </ListItem>
    ))}
  </List>
)

export default AuthoringTypeSelector
