import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { componentShape } from 'core/shapes'
import { asPercentageString, average } from 'fp/numbers'
import { getScore } from 'projections/interactions'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { interactiveContext } from '../../InteractiveProvider'

const AverageScoreAggregate = ({ children }) => {
  const { contentId } = useContext(interactiveContext)
  const { assignmentId } = useParams()

  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )
  const averageScore = useMemo(
    () =>
      average(...allInteractions.map(({ scoreData }) => getScore(scoreData))),
    [allInteractions],
  )

  return (
    <Stack>
      {Boolean(allInteractions.length) && (
        <Typography
          component="p"
          variant="paragraph-semibold">
          Average score: {asPercentageString(averageScore)}
        </Typography>
      )}
      {children}
    </Stack>
  )
}
AverageScoreAggregate.propTypes = {
  children: componentShape.isRequired,
}

export default AverageScoreAggregate
