import Headline from 'common/text/Headline'
import withSaveUserPreferences from '../withSaveUserPreferences'
import AudioDescriptions from './AudioDescriptions'
import Images from './Images'
import Themes from './Themes'

const Accessibility = withSaveUserPreferences(({ onChange, preferences }) => (
  <Headline
    mb={12}
    textTransform="capitalize"
    title="Accessibility">
    <Themes
      onChange={onChange}
      preferences={preferences}
    />

    <Images
      onChange={onChange}
      preferences={preferences}
    />

    <AudioDescriptions
      onChange={onChange}
      preferences={preferences}
    />
  </Headline>
))

export default Accessibility
