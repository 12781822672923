import useToggleState from 'hooks/useToggleState'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import SubmitDialog from 'hss/sections/Assignments/SubmitDialog'
import { isPastMaxSubmitDate } from 'hss/utils'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getContextualAssignment } from 'selectors/assignments'
import { getUserAssignmentById } from 'selectors/userAssignments'
import { getUserById } from 'selectors/users'
import { interactiveContext } from '../../InteractiveProvider'
import SubmitButton from './SubmitButton'

const SubmitButtonForSingleStudent = ({ submitAssignment }) => {
  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}
  const { completed, markComplete } = useContext(interactiveContext) || {}

  const [isSubmitDialogOpen, toggleIsSubmitDialogOpen] = useToggleState()
  const { submittedDate, userId } =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const { firstName, lastName } = useSelector(getUserById({ userId })) || {}
  const assignment = useSelector(getContextualAssignment) || {}

  const canBeSubmitted = Boolean(
    (submitAssignment || !completed) &&
      !submittedDate &&
      !isPastMaxSubmitDate(assignment),
  )

  const handleSubmit = () => {
    if (submitAssignment) {
      toggleIsSubmitDialogOpen()
    } else {
      markComplete()
    }
  }

  return (
    <>
      <SubmitButton
        {...{
          canBeSubmitted,
          handleClick: handleSubmit,
          submitAssignment,
        }}
        studentName={`${firstName} ${lastName}`}
      />
      {Boolean(isSubmitDialogOpen) && (
        <SubmitDialog onClose={toggleIsSubmitDialogOpen} />
      )}
    </>
  )
}

SubmitButtonForSingleStudent.propTypes = {
  submitAssignment: PropTypes.bool.isRequired,
}

export default SubmitButtonForSingleStudent
