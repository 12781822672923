import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Draggable from 'common/Draggable'
import Dialog from 'common/dialogs/Dialog'
import AudioVisualizer from 'hss/sections/contentBlocks/interactives/AudioRecording/AudioVisualizer'
import { msToTime } from 'locale/i18n'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { StopCircle } from 'react-feather'

const PaperComponent = forwardRef((props, ref) => (
  <Draggable initialCoord={{ x: 20, y: 20 }}>
    <Paper {...{ ...props, ref }} />
  </Draggable>
))

const RecordingDialog = props => {
  const {
    handleRecordingStop,
    maxLength,
    numSecondsDisplay,
    open,
    visualizerAudioData,
  } = props

  return (
    <Dialog
      aria-labelledby="draggable-dialog-title"
      onClose={handleRecordingStop}
      open={open}
      PaperComponent={PaperComponent}
      showCloseButton>
      <DialogTitle
        id="draggable-dialog-title"
        style={{ cursor: 'move' }}>
        Recording
      </DialogTitle>

      <DialogContent sx={{ margin: 'auto', padding: '0 35px' }}>
        {Boolean(visualizerAudioData) && (
          <>
            <div aria-hidden="true">
              <span>{numSecondsDisplay}</span>
              <span> / </span>
              <span>{msToTime(maxLength * 1000)}</span>
            </div>
            <AudioVisualizer audioData={visualizerAudioData} />
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-start' }}>
        <Button
          color="secondary"
          onClick={handleRecordingStop}
          startIcon={<StopCircle />}
          variant="secondary">
          Stop
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RecordingDialog.propTypes = {
  handleRecordingStop: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  numSecondsDisplay: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  visualizerAudioData: PropTypes.object,
}

export default RecordingDialog
