import AppBusy from 'common/indicators/AppBusy'
import { omit, set } from 'fp/objects'
import useCurrentUser from 'hooks/useCurrentUser'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import { useState } from 'react'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import SaveButton from './SaveButton'

const withSaveUserPreferences = WrappedComponent => {
  const Enhanced = () => {
    const { user } = useCurrentUser()

    const [preferences, setPreferences] = useState(user.preferences)

    const [dispatch, status] = useReduxCallback({
      actionType: actionTypes.USER_SAVE,
    })
    const handleSave = () => {
      dispatch({
        payload: compose(
          omit('userPrivileges'),
          set('preferences', preferences),
        )(user),
      })
    }

    return (
      <>
        <WrappedComponent
          onChange={setPreferences}
          preferences={preferences}
        />
        <SaveButton onClick={handleSave} />
        <AppBusy open={status === BUSY} />
      </>
    )
  }
  return Enhanced
}

export default withSaveUserPreferences
