import Button from '@mui/material/Button'
import { listShape } from 'common/formControls/lists/BasicList'
import withList from 'common/formControls/lists/withList'
import Centered from 'common/layout/Centered'
import { ArrowDown } from 'react-feather'

const SequenceItems = ({
  ItemRenderer,
  items,
  addItem,
  disabled,
  readOnly,
  ...rest
}) => (
  <>
    <ol>
      {items.map((item, idx) => (
        <div key={item.id}>
          <ItemRenderer
            disabled={disabled}
            index={idx}
            item={item}
            readOnly={readOnly}
            value={item}
            {...rest}
          />
          <Centered>{items.length !== idx + 1 && <ArrowDown />}</Centered>
        </div>
      ))}
    </ol>
    {!readOnly && (
      <Button
        aria-label="Add item to the end of the list above."
        onClick={() => addItem({ content: '', header: '' })}>
        Add item
      </Button>
    )}
  </>
)

SequenceItems.propTypes = listShape

const StackedSequenceList = withList(SequenceItems)

export { SequenceItems }
export default StackedSequenceList
