import AuthorContent from './AuthorContent'
import CreateContent from './CreateContent'

const AddToLibrary = () => (
  <div>
    <AuthorContent />

    <CreateContent />
  </div>
)

export default AddToLibrary
