import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { omit } from 'fp/objects'
import routeConfig, {
  chapterChildrenRouteConfig,
} from 'hss/ContentViewer/routeConfig'
import { Routes } from 'react-router-dom'
import { configToRoutes } from 'routing/TraverseRoute'
import Submit from './Submit'

const entryIsHiddenFromStudent = ([, route]) => route.hideFromStudent

const routes = configToRoutes({
  multiPageSource: routeConfig.multiPageSource,
  ...omit(entryIsHiddenFromStudent)(chapterChildrenRouteConfig),
  submit: {
    element: <Submit />,
    path: 'submit',
  },
})

const AssignmentRoutes = () => (
  <ErrorBoundary moduleName="AssignmentRoutes">
    <Routes>{routes}</Routes>
  </ErrorBoundary>
)

export default AssignmentRoutes
