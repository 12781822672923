import { styled } from '@mui/material/styles'
import cl from 'classnames'
import SVGText from 'common/text/SVGText'
import { isEmptyString } from 'fp/strings'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import ClickableElement from '../../VennDiagram/ClickableElement'
import config from '../config'

const StyledText = styled(SVGText, { name: 'SVGNote' })(
  ({ theme: { palette } }) => ({
    cursor: 'pointer',
    '&:hover': {
      fill: palette.accent.main,
    },

    '.placeholder': {
      fontStyle: 'italic',
    },
  }),
)

const SVGNote = props => {
  const {
    ariaLabel,
    children,
    focused = false,
    height,
    moveable = false,
    offsetX,
    onClick,
    onKeyUp,
    readOnly = false,
    textAnchor = 'start',
    verticalAnchor = 'start',
    width,
    x,
    y,
    ...rest
  } = props

  const placeholder = readOnly ? '(No response)' : 'Add thought...'

  const ref = useRef()

  const { height: elementHeight, width: elementWidth } = useResizeDetector({
    targetRef: ref,
  })
  const [offsetXValue, setOffsetXValue] = useState(0)
  const [maxWidth, setMaxWidth] = useStateWithDynamicDefault(width)

  useEffect(() => {
    if (offsetX && elementHeight) {
      setOffsetXValue(offsetX(elementHeight))
    }
  }, [elementHeight, offsetX])

  useEffect(() => {
    if (
      elementWidth + Math.abs(offsetXValue) > width &&
      elementWidth - Math.abs(offsetXValue) < width
    ) {
      // readjust max width here if offset makes it larger than max width
      setMaxWidth(elementWidth - Math.abs(offsetXValue))
    }
  }, [elementWidth, offsetXValue, setMaxWidth, width])

  useEffect(() => {
    if (focused) {
      ref.current?.focus()
    }
  }, [focused])
  return readOnly ? (
    <text
      textAnchor={textAnchor}
      x={x + offsetXValue}
      y={y}
      {...rest}>
      {children || placeholder}
    </text>
  ) : (
    <ClickableElement
      onClick={onClick}
      type="g"
      WrapperProps={{
        'aria-label': ariaLabel,
        onKeyUp,
        role: 'button',
        passedRef: ref,
      }}>
      <StyledText
        className={cl({ placeholder: isEmptyString(children) })}
        maxHeight={height}
        maxWidth={maxWidth}
        textAnchor={textAnchor}
        verticalAnchor={verticalAnchor}
        x={x + offsetXValue}
        y={y}
        {...rest}>
        {children || placeholder}
      </StyledText>

      {Boolean(moveable) && (
        <text
          className="reorder"
          fontSize={config.fontSize * 0.85}
          textAnchor={textAnchor}
          x={x}
          y={y - config.fontSize - config.lineSpacing}>
          use ↔ to reorder
        </text>
      )}
    </ClickableElement>
  )
}

SVGNote.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.string,
  focused: PropTypes.bool,
  height: PropTypes.number,
  moveable: PropTypes.bool,
  offsetX: PropTypes.func,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  readOnly: PropTypes.bool,
  textAnchor: PropTypes.oneOf(['start', 'middle', 'end']),
  verticalAnchor: PropTypes.oneOf(['start', 'middle', 'end']),
  width: PropTypes.number,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
}

export default SVGNote
