import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import TabList from 'common/layout/TabList'
import { useMemo, useState } from 'react'
import Pane from '../Pane'
import AnnotationsTab from './AnnotationsTab'
import FeedbackTab from './FeedbackTab'
import NotebookTab from './NotebookTab'

const FEEDBACK = 'feedback'
const NOTEBOOK = 'notebook'
const ANNOTATIONS = 'annotations'

const FeedbackTabs = {
  [FEEDBACK]: FeedbackTab,
  [NOTEBOOK]: NotebookTab,
  [ANNOTATIONS]: AnnotationsTab,
}

const Feedback = () => {
  const [currentTab, setCurrentTab] = useState('feedback')
  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue)
  }

  const CurrentTab = useMemo(() => FeedbackTabs[currentTab], [currentTab])

  return (
    <Pane title="Assignment Feedback">
      <TabContext
        minSize="sm"
        value={currentTab}>
        <TabList
          aria-label="content and attributes"
          minSize="sm"
          onChange={handleTabChange}>
          <Tab
            label={FEEDBACK}
            value={FEEDBACK}
          />

          <Tab
            label={NOTEBOOK}
            value={NOTEBOOK}
          />

          <Tab
            label={ANNOTATIONS}
            value={ANNOTATIONS}
          />
        </TabList>
      </TabContext>
      <CurrentTab />
    </Pane>
  )
}

export default Feedback
