import DataTable from 'common/tables/DataTable'
import {
  LinkToTableCell,
  RowActionsMenu,
  RowDetailsToggle,
  SimpleCell,
  asDetail,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import {
  createdByCell,
  modifiedDateCell,
  nextDueDateCell,
  numStudentsCell,
  numTeachersCell,
  openAssignmentsCell,
  viewGoogleIntegrationCell,
  viewGroupGradebookCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import { FEATURE_FLAG_POST_MVP } from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import { filter, map } from 'fp/arrays'
import { get, omit } from 'fp/objects'
import { prefix } from 'fp/strings'
import { matchesOneOf, not, pipe } from 'fp/utils'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import { groupsUrl } from 'routing/consts'

const tableId = 'clGroupsList'

const buildColumns = has => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  const viewUrl = ({ id }) => `${groupsUrl}/${id}`

  const columns = [
    asPrimary(
      SimpleCell({
        accessorFn: get('name'),
        cell: LinkToTableCell(compose(prefix(`${groupsUrl}/`), get('id'))),
        header: 'Name',
        id: 'target.name',
        meta: { sortFieldName: 'name' },
      }),
    ),

    openAssignmentsCell,

    viewGroupGradebookCell,

    viewGoogleIntegrationCell,

    modifiedDateCell,

    numStudentsCell,

    createdByCell,

    asDetail(nextDueDateCell),

    asDetail(numTeachersCell),

    RowActionsMenu(() => [
      {
        label: 'View',
        action: { navigate: viewUrl },
      },
    ]),
  ]

  return postMVP
    ? [
        ...columns,

        RowDetailsToggle(),
      ]
    : [
        ...pipe(
          filter(
            compose(
              not,
              matchesOneOf('id', [
                'openAssignments',
                'integrations',
                'modifiedDate',
                'status',
                'createdBy',
                'nextDueDate',
              ]),
            ),
          ),
          map(omit('isDetailColumn')),
        )(columns),
      ]
}

const searchConfig = (has, limit) => ({
  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.orderBy('name', 'asc'),
  )(assert()),

  pluralLabel: 'Groups',

  restEndpoint: restEndpoint.groups,

  searchFields: ['name'],

  singleLabel: 'Group',
})

const withSearchProvider = WrappedComponent => {
  const Enhanced = initialTestingState => {
    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table">
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
