import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { stateRouting } from 'selectors/index'

const useIsWaiting = urlToWaitFor => {
  const { pathname } = useLocation()
  const { nextLocation } = useSelector(stateRouting)
  return (
    nextLocation &&
    !pathname.endsWith(nextLocation.pathname) &&
    urlToWaitFor.endsWith(nextLocation.pathname)
  )
}

export default useIsWaiting
