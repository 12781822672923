import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import SubmitButtonForSingleStudent from './SubmitButtonForSingleStudent'

const SubmitButton = ({ submitAssignment = false }) => {
  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}

  return userAssignmentId ? (
    <SubmitButtonForSingleStudent submitAssignment={submitAssignment} />
  ) : null
}

SubmitButton.propTypes = {
  submitAssignment: PropTypes.bool,
}

export default SubmitButton
