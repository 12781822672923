import {
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  // QUESTION_TYPE_ANNOTATE,
  // QUESTION_TYPE_AUDIO,
  // QUESTION_TYPE_CHART,
  // QUESTION_TYPE_CHOICE_MATRIX,
  // QUESTION_TYPE_CLOZE,
  // QUESTION_TYPE_EBSR,
  // QUESTION_TYPE_ESSAY,
  // QUESTION_TYPE_GROUP_AND_SORT,
  // QUESTION_TYPE_INSTRUCTIONS,
  // QUESTION_TYPE_MULTIPLE_CHOICE,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import PropTypes from 'prop-types'

export const SEARCH_TYPE_ASSESSMENT = 'assessment'
export const SEARCH_TYPE_CLASSROOM = 'classroom'
export const SEARCH_TYPE_LIBRARY = 'library'

export const searchTypeShape = PropTypes.oneOf([
  SEARCH_TYPE_ASSESSMENT,
  SEARCH_TYPE_CLASSROOM,
  SEARCH_TYPE_LIBRARY,
])

// ******** DO NOT SORT ********
// export const ASSESS_QUESTION_TYPES = [
//   QUESTION_TYPE_INSTRUCTIONS,
//   QUESTION_TYPE_ANNOTATE,
//   QUESTION_TYPE_AUDIO,
//   QUESTION_TYPE_CHART,
//   QUESTION_TYPE_CHOICE_MATRIX,
//   QUESTION_TYPE_CLOZE,
//   QUESTION_TYPE_EBSR,
//   QUESTION_TYPE_ESSAY,
//   QUESTION_TYPE_GROUP_AND_SORT,
//   QUESTION_TYPE_MULTIPLE_CHOICE,
// ]

export const NON_ASSIGNABLE_CONTENT_TYPES = [
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
]

export const viewModeShape = PropTypes.oneOf(['grid', 'list'])

export const cardContentRendererShape = {
  content: contentShape,
  contentCreator: PropTypes.bool.isRequired,
  handlePreview: PropTypes.func.isRequired,
}
