import { Collapse, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from 'common/formControls/switches/Checkbox'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useToggleState from 'hooks/useToggleState'
import { useCallback, useContext } from 'react'
import { Sliders } from 'react-feather'
import {
  ABILITY_SELF_CONTENT_AUTHORING,
  CONTENT_ORIGIN_CREATED_BY_SELF,
  CONTENT_ORIGIN_CREATED_BY_SYSTEM,
  CONTENT_ORIGIN_SHARED,
  FEATURE_FLAG_CONTENT_AUTHORING,
} from 'shared/consts'
import { searchContext } from '../SearchProvider'

const CREATED_BY_FILTERS = [
  { label: 'Added by me', value: CONTENT_ORIGIN_CREATED_BY_SELF },
  { label: 'Added by my district', value: CONTENT_ORIGIN_SHARED },
  { label: 'Added by Traverse', value: CONTENT_ORIGIN_CREATED_BY_SYSTEM },
]

const AdvancedFilters = withAbilityCheck(() => {
  const { alterContentOriginModifiers, dataset } = useContext(searchContext)

  const { down } = useContainerQuery()

  const [advancedFiltersShown, toggleAdvancedFiltersShown] = useToggleState()

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      alterContentOriginModifiers(event.target.value)()
    },
    [alterContentOriginModifiers],
  )

  const squery = get('metadata.squery')(dataset)
  const selectedOrigins =
    inspect(squery).get.modifier('forContentOrigin').is() || []

  const isContentAuthoringLive = useAbilityCheck(FEATURE_FLAG_CONTENT_AUTHORING)
  if (!isContentAuthoringLive) return null

  return (
    <Stack
      gap={4}
      my={4}>
      <Box>
        <Button
          onClick={toggleAdvancedFiltersShown}
          startIcon={<Sliders />}
          variant={advancedFiltersShown ? 'primary' : 'secondary'}
          data-subvariant="filter"
          color="secondary">
          Advanced Filters
        </Button>
      </Box>

      <Collapse in={Boolean(advancedFiltersShown)}>
        <Stack direction={down('md') ? 'column' : 'row'}>
          {CREATED_BY_FILTERS.map(({ label, value }) => (
            <Checkbox
              key={`advanced-filters-${value}`}
              label={label}
              checked={selectedOrigins.includes(value)}
              onChange={handleOnChange}
              value={value}
              data-color="secondary"
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}, ABILITY_SELF_CONTENT_AUTHORING)

export default AdvancedFilters
