import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import {
  ASSIGNMENT_TARGET_GROUP,
  ASSIGNMENT_TARGET_STUDENT,
  FEATURE_FLAG_POST_MVP,
  INTERACTION_STATE_COMPLETED,
} from 'core/consts'
import { parseISO } from 'date-fns'
import { formatWithOptions } from 'date-fns/fp'
import { filter, map } from 'fp/arrays'
import { asPercentageString, average } from 'fp/numbers'
import { get } from 'fp/objects'
import { fallbackTo, identity, isDefined, matches, not } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { singleAssignmentGradebook } from 'projections/gradebook'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getAssignmentStudents } from 'selectors/assignments'
import { getAssignmentsGradebook } from 'selectors/gradebook'
import {
  getInteractionsForUserAssignments,
  getReviewedInteractions,
} from 'selectors/interactions'
import { rem } from 'styling/theming/base/mixins'

const minColumnWidth = '18rem'

const asDate = compose(formatWithOptions({}, 'P'), parseISO)
const asPercentage = compose(fallbackTo('—'), asPercentageString)
const firstAndLastName = ({ firstName, lastName }) => `${firstName} ${lastName}`

const assignmentTargetRenderers = {
  [ASSIGNMENT_TARGET_GROUP]: get('name'),
  [ASSIGNMENT_TARGET_STUDENT]: firstAndLastName,
}

const Attribute = ({ name, render = identity, value }) => (
  <>
    <Typography
      component="div"
      variant="attribute-name">
      {name}
    </Typography>

    {isDefined(value) ? render(value) : '—'}
  </>
)
Attribute.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func,
  value: PropTypes.any,
}

const Details = ({ children, summary = 'Details', ...rest }) => (
  <Box
    component="details"
    mb={5}
    minWidth={250}
    {...rest}>
    <summary>{summary}</summary>
    {children}
  </Box>
)
Details.propTypes = {
  children: PropTypes.node.isRequired,
  summary: PropTypes.string,
}

const HeroActions = ({ assignment }) => {
  const isSmall = useContainerQuery().down('md')
  const postMVP = useAbilityCheck(FEATURE_FLAG_POST_MVP)

  const userAssignments =
    useSelector(getAssignmentStudents({ assignmentId: assignment.id })) || []
  const userAssignmentIds = userAssignments.map(get('id'))

  const allInteractions = useSelector(
    getInteractionsForUserAssignments({ userAssignmentIds }),
  )
  const reviewedInteractions =
    useSelector(getReviewedInteractions({ userAssignmentIds })) || []
  const { assignment: gradedAssignment } = compose(
    singleAssignmentGradebook,
    useSelector,
    getAssignmentsGradebook,
  )({ userAssignmentIds })

  const Wrapper = isSmall ? Details : Box

  const attributes = filter(Boolean)([
    {
      name: 'Target',
      render: assignmentTargetRenderers[assignment.targetTypeId],
      value: assignment.target,
    },
    {
      name: 'Start',
      render: asDate,
      value: assignment.startDate,
    },
    {
      name: 'End',
      render: asDate,
      value: assignment.endDate,
    },
    {
      name: 'Completion',
      render: asPercentage,
      value: compose(
        average,
        map(compose(fallbackTo(0), get('progress'))),
      )(userAssignments),
    },
    {
      name: 'Max Score',
      value: gradedAssignment?.maxScore,
    },
    {
      name: 'Avg. Interactive Score',
      render: asPercentage,
      value: compose(
        average,
        filter(isDefined),
        map(get('scoreData.score')),
        filter(compose(not, get('requiresGrading'))),
        filter(matches('state', INTERACTION_STATE_COMPLETED)),
      )(allInteractions),
    },
    postMVP && {
      name: 'Avg. Assessment Score',
      render: asPercentage,
      value: assignment.averageAssessmentScore,
    },
    {
      name: 'My Avg. Review',
      render: asPercentage,
      value: compose(
        average,
        map(get('scoreData.score')),
      )(reviewedInteractions),
    },
    postMVP && {
      name: 'Avg. Peer Score',
      value: assignment.averagePeerScore,
    },
    {
      name: 'Created By',
      render: firstAndLastName,
      value: assignment.createdBy,
    },
    {
      name: 'Created On',
      render: asDate,
      value: assignment.createdDate,
    },
  ])

  return assignment ? (
    <Wrapper {...(isSmall ? { summary: 'Assignment Details' } : null)}>
      <Box
        component="ul"
        display="grid"
        gap={1}
        gridTemplateColumns={`repeat(auto-fill, minmax(${minColumnWidth}, 1fr))`}
        sx={{
          listStyle: 'none',
          margin: rem(0, 0, 3),
          paddingInlineStart: 0,
        }}>
        {attributes.map(attribute => (
          <Box
            component="li"
            key={attribute.name}>
            <Attribute {...attribute} />
          </Box>
        ))}
      </Box>
    </Wrapper>
  ) : null
}

HeroActions.propTypes = {
  assignment: PropTypes.object,
}

export default HeroActions
