import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import HFRichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import FormSection from 'common/layout/FormSection'
import { when } from 'fp/utils'
import AnswerKey from 'hss/AssignmentEditor/AnswerKey'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const getFieldName = field => `data.settings.${field}`

const TestDetails = () => {
  const { setValue } = useFormContext()

  const [timeLimit] = useWatch({ name: [getFieldName('timeLimit')] })

  useEffect(() => {
    when(!timeLimit, setValue, getFieldName('timeLimitVisible'), false)
  }, [setValue, timeLimit])

  return (
    <Stack
      direction="column"
      gap={4}
      mt={2}>
      <div>
        <HFRichTextEdit
          label="Instructions"
          name={getFieldName('instructions')}
        />
        <Typography
          display="block"
          variant="footnote">
          These instructions will appear before the student begins the test.
        </Typography>
      </div>
      <FormSection title="Answer Key">
        <AnswerKey />
      </FormSection>
      <FormSection title="Time Limit">
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between">
          <HFNumberField
            label="Time Limit (minutes)"
            min={0}
            name={getFieldName('timeLimit')}
            style={{ width: 'auto' }}
          />

          <HFSwitch
            disabled={!timeLimit}
            label="Show Timer to Students"
            name={getFieldName('timeLimitVisible')}
            style={{ width: 'auto' }}
          />
        </Box>
      </FormSection>
    </Stack>
  )
}

export default TestDetails
