import Annotatable from 'common/annotations/Annotatable'
import ScrollToTop from 'common/navigation/ScrollToTop'
import { CONTENT_TYPE_SOURCE } from 'core/consts'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import { Fragment, useContext } from 'react'
import RouteTransition from 'routing/RouteTransition'
import LearnBanner from '../Banner/LearnBanner'
import ContentBlocks from '../Chapter/Subsection/ContentBlocks'
import Footnotes from '../Chapter/Subsection/Footnotes'
import Hero from '../Chapter/Subsection/Hero'
import Navigation from '../Chapter/Subsection/Navigation'
import SubsectionHeadline from '../Chapter/Subsection/SubsectionHeadline'
import {
  ContentViewerProvider,
  contentViewerContext,
} from '../ContentViewerProvider'

const Source = () => {
  const { subsection: source } = useContext(contentViewerContext)
  const contextContentId = source?.id

  // Don't use RouteTransition for pinned content, otherwise it would
  // appear to reload every time you navigate between pages on the left side.
  const isPinned = useIsPinnedContent()
  const MaybeRouteTransition = isPinned ? Fragment : RouteTransition

  return contextContentId ? (
    <>
      <LearnBanner title="Source" />

      <ScrollToTop />

      <Annotatable
        contextContentId={contextContentId}
        style={{ minHeight: '75vh' }}>
        <MaybeRouteTransition>
          <Hero />

          <SubsectionHeadline />

          <ContentBlocks />

          <Footnotes />
        </MaybeRouteTransition>
      </Annotatable>

      <Navigation />
    </>
  ) : null
}

const WithProvider = () => (
  <ContentViewerProvider
    contentType={CONTENT_TYPE_SOURCE}
    // these need to be listed from right to left, in the order that they may
    // appear within the URL.  The first one found has precedence.
    paramName={['sourceId', 'contentId']}
  >
    <Source />
  </ContentViewerProvider>
)

export default WithProvider
