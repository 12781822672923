import Annotatable from 'common/annotations/Annotatable'
import ScrollToTop from 'common/navigation/ScrollToTop'
import { StyledHeadline } from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import { contentTypeShape } from 'core/shapes'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import useScrollToTopOnRouteChange from 'hooks/useScrollToTopOnRouteChange'
import LearnBanner from 'hss/ContentViewer/Banner/LearnBanner'
import { Fragment, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import RouteTransition from 'routing/RouteTransition'
import { getCurrentSection } from 'selectors/contentViewer'
import {
  ContentViewerProvider,
  contentViewerContext,
} from '../../ContentViewerProvider'
import Footnotes from '../Subsection/Footnotes'
import Navigation from '../Subsection/Navigation'
import ContentBlocks from './ContentBlocks'
import Tabs from './Tabs'

const TabbedSection = () => {
  const isPinned = useIsPinnedContent()
  const { name: sectionName } =
    useSelector(getCurrentSection({ isPinned })) || {}
  const scrollRef = useRef()
  useScrollToTopOnRouteChange(scrollRef)

  const {
    subsection: { id: contextContentId, name: subsectionName },
  } = useContext(contentViewerContext)

  const headline = isPinned ? subsectionName : sectionName

  // Don't use RouteTransition for pinned content, otherwise it would
  // appear to reload every time you navigate between pages on the left side.
  const MaybeRouteTransition = isPinned ? Fragment : RouteTransition

  return (
    Boolean(headline) && (
      <div ref={scrollRef}>
        <LearnBanner />

        {!isPinned && <ScrollToTop querySelector=".layout-pane-primary" />}

        <Annotatable
          contextContentId={contextContentId}
          style={{ minHeight: '75vh' }}>
          <StyledHeadline
            contained
            mb={3}
            mt={4}
            size={5}>
            {headline}
          </StyledHeadline>

          {!isPinned && <Tabs />}

          <MaybeRouteTransition>
            <HeadlineLevelOffset>
              <ContentBlocks />

              <Footnotes />
            </HeadlineLevelOffset>
          </MaybeRouteTransition>
        </Annotatable>

        <Navigation />
      </div>
    )
  )
}

const WithProvider = ({ contentType }) => (
  <ContentViewerProvider
    contentType={contentType}
    paramName={['subsectionId', 'sourceId']}>
    <TabbedSection />
  </ContentViewerProvider>
)

WithProvider.propTypes = {
  contentType: contentTypeShape.isRequired,
}

export default WithProvider
