import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ButtonPlayer } from 'common/avclub/audio/AudioPlayer'
import Headline from 'common/text/Headline'
import {
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_TIMELINE,
  isEchoFeature,
} from 'core/consts'
import { isEmptyString } from 'fp/strings'
import LinkToEditor from 'hss/ContentViewer/Chapter/Block/LinkToEditor'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { interactiveContext } from '../InteractiveProvider'

const Preamble = ({ displayConfig }) => {
  const { preamble } = displayConfig

  const {
    contentWrappingAllowed,
    interactive,
    interactive: { contentSubType, name },
    interactiveData: { float, frameless, interactiveInstructions },
    previewing,
    uploadsMap: { interactiveInstructionsAudio },
  } = useContext(interactiveContext)

  const showInstructions = [
    INTERACTIVE_TYPE_ANIMATOR,
    INTERACTIVE_TYPE_AUDIO,
    INTERACTIVE_TYPE_FLIPBOOK,
    INTERACTIVE_TYPE_CHART,
    INTERACTIVE_TYPE_TIMELINE,
  ].includes(contentSubType)

  const isEcho = isEchoFeature(contentSubType)

  const containsContent =
    !(
      isEmptyString(name) &&
      (isEcho || isEmptyString(interactiveInstructions))
    ) || interactive.applicationStandards?.length > 0
  // || TODO: how to check for vocab length?

  if (!(preamble && containsContent)) {
    return null
  }

  return (
    <Box width="100%">
      {!isEmptyString(name) && (
        <Headline
          size={4}
          textTransform="uppercase"
          title={name}
        />
      )}
      {Boolean(
        !previewing && (!contentWrappingAllowed || float === 'none'),
      ) && <LinkToEditor content={interactive} />}

      {Boolean(
        (!frameless || showInstructions) &&
          !isEmptyString(interactiveInstructions),
      ) && (
        <Grid
          container
          justifyContent="space-between">
          <Grid
            item
            py={1}
            xs={12}>
            <div>{interactiveInstructions}</div>
            {Boolean(interactiveInstructionsAudio) && (
              <ButtonPlayer url={interactiveInstructionsAudio.url} />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

Preamble.propTypes = {
  displayConfig: PropTypes.object.isRequired,
}

export default Preamble
