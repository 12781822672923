import { useTheme } from '@mui/material/styles'
import { callWith } from 'fp/call'
import { get } from 'fp/objects'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useContext, useMemo } from 'react'
import { compose } from 'redux'
import { defaultFeatureGroups } from './consts'

const useFeatureGroupPalette = featureGroupName => {
  const { palette } = useTheme()
  const {
    interactiveData: { config },
  } = useContext(interactiveContext)
  const featureGroups = config.featureGroups || defaultFeatureGroups

  return useMemo(() => {
    const {
      featureGroupNameColor,
      pacingOptionShades,
      paletteColor: paletteColorName,
    } = get(featureGroupName)(featureGroups) || {}
    const paletteColor = get(paletteColorName)(palette)

    return {
      featureGroupNameColor: featureGroupNameColor || get('0')(paletteColor),
      getColorForPacingOption: compose(
        callWith(paletteColor),
        get,
        String,
        callWith(pacingOptionShades),
        get,
      ),
    }
    // biome-ignore lint/correctness/useExhaustiveDependencies(featureGroupName): TODO:
  }, [featureGroupName, featureGroups, palette])
}

export default useFeatureGroupPalette
