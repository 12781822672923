import { styled, useTheme } from '@mui/material/styles'
import { fallsWithin } from 'fp/numbers'
import { get } from 'fp/objects'
import { matches } from 'fp/utils'
import useCurrentUser from 'hooks/useCurrentUser'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { compose } from 'redux'
import { getStandardsMasteryGradebook } from 'selectors/gradebook'
import { gradebookContext } from './GradebookContextProvider'

const Container = styled('div', {
  name: 'Gradebook-StandardsMastery-Container',
})(() => ({
  overflow: 'scroll hidden',
  height: '60vh',
  minHeight: 400,
}))

const StandardsMasteryView = () => {
  const { userAssignmentIds = [] } = useContext(gradebookContext)
  const standards = useSelector(
    getStandardsMasteryGradebook({ userAssignmentIds }),
  )
  const {
    palette: { gradebook },
  } = useTheme()
  const {
    user: {
      preferences: {
        gradeColors: { segmentColors },
      },
    },
  } = useCurrentUser()

  const getFillColor = standard =>
    compose(
      get('value'),
      colorId => gradebook.find(matches('colorId', colorId)),
      get('colorId'),
      average =>
        segmentColors.find(({ begin, end }) =>
          fallsWithin(average * 100, begin, end),
        ),
      get('average'),
    )(standard)

  const standardsWithGradebookColors = standards.map(standard => ({
    ...standard,
    fill: getFillColor(standard),
  }))

  return (
    <Container>
      <ResponsiveContainer
        height="100%"
        minHeight={400}
        width={standards.length * 70}>
        <BarChart
          data={standardsWithGradebookColors}
          margin={{ top: 50, bottom: 120, left: 20 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            angle={-45}
            dataKey="shortCode"
            interval={0}
            textAnchor="end"
          />
          <YAxis
            domain={[0, 1]}
            interval={0}
            scale="linear"
            tickCount={10}
            tickFormatter={value =>
              new Intl.NumberFormat('en-US', { style: 'percent' }).format(value)
            }
          />
          <Bar dataKey="average" />
        </BarChart>
      </ResponsiveContainer>
    </Container>
  )
}

export default StandardsMasteryView
