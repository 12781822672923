import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import { ABILITY_CONTENT_RESTRICTION } from 'core/consts'
import { contentShape } from 'core/shapes'
import { get } from 'fp/objects'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { Icons, fallback, labels } from './ContentRestrictionControls'

const ContentRestrictionIndicator = withAbilityCheck(
  ({ className, content }) => {
    const type = get('contentRestriction.type', { fallback })(content)

    return type !== fallback ? (
      <Tooltip
        className={className}
        title={labels[type].description}>
        <Icon
          aria-label={labels[type].description}
          role="img">
          {Icons[type]}
        </Icon>
      </Tooltip>
    ) : null
  },
  ABILITY_CONTENT_RESTRICTION,
)

ContentRestrictionIndicator.propTypes = {
  content: contentShape.isRequired,
}

export default ContentRestrictionIndicator
