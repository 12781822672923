import Box from '@mui/material/Box'
import { findObj } from 'fp/arrays'
import { set } from 'fp/objects'
import { toInt } from 'fp/strings'
import { pipe } from 'fp/utils'
import useColorSet from 'hooks/useColorSet'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { size } from 'polished'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import EditableText from 'react-editext-with-ref'
import { Check, X } from 'react-feather'
import Moveable from 'react-moveable'
import { px, rem } from 'styling/theming/base/mixins'

const TextControl = () => {
  const {
    completed,
    interactionData,
    interactiveData: { characterLimit },
    onInteract,
  } = useContext(interactiveContext)

  const containerRef = useRef()
  const targetRef = useRef()
  const moveableRef = useRef()
  const [contentEditable, setContentEditable] = useState(false)
  const [focused, setFocused] = useState(false)
  const [bounds, setBounds] = useState()
  const colorId = toInt(interactionData.colorId)
  const colors = useColorSet('pictureThisTextBackground')
  const { contrastText = 'white', value: color = 'black' } = findObj(
    'colorId',
    colorId,
  )(colors)
  const { fontSize } = interactionData

  useEffect(() => {
    const { style } = targetRef.current
    style.width = px(interactionData.width)
    style.height = px(interactionData.height)
    style.transform = interactionData.transform
  }, [interactionData.height, interactionData.transform, interactionData.width])

  // biome-ignore lint/correctness/useExhaustiveDependencies(containerRef.current): why?
  useEffect(() => {
    const { height, width } =
      containerRef.current?.getBoundingClientRect() || {}
    if (height > 0 && width > 0) {
      setBounds({
        top: 0,
        right: width,
        bottom: height,
        left: 0,
      })
    }
  }, [containerRef.current])

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (target !== targetRef.current) {
        setContentEditable(false)
        setFocused(false)
      }
    }

    const handleKeyDown = ({ key }) => {
      if (key === 'Escape' && focused) {
        setFocused(false)
      }
    }

    document.addEventListener('click', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [focused])

  const gatherPosition = useCallback(() => {
    if (!completed) {
      const { style } = targetRef.current
      onInteract(
        pipe(
          set('interactionData.width', style.width),
          set('interactionData.height', style.height),
          set('interactionData.transform', style.transform),
        ),
      )
    }
  }, [completed, onInteract])

  const handleDrag = useCallback(
    ({ target, transform }) => {
      target.style.transform = transform
      gatherPosition()
    },
    [gatherPosition],
  )

  const handleResize = useCallback(
    ({ target, width, height, delta }) => {
      if (delta[0]) {
        target.style.width = px(width)
      }
      if (delta[1]) {
        target.style.height = px(height)
      }
      gatherPosition()
    },
    [gatherPosition],
  )

  const handleRotate = useCallback(
    ({ target, transform }) => {
      target.style.transform = transform
      gatherPosition()
    },
    [gatherPosition],
  )

  const handleMessageChanged = useCallback(
    value => {
      onInteract(set('interactionData.message', value))
    },
    [onInteract],
  )

  const handleClick = useCallback(({ target }) => {
    if (target === targetRef?.current) {
      setFocused(true)
    }
  }, [])

  const handleDoubleClick = useCallback(
    ({ target }) => {
      if (target === targetRef?.current) setContentEditable(!completed)
    },
    [completed],
  )

  const interactable = focused && !contentEditable && !completed

  return (
    <Box
      className={`font-size-${fontSize}`}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      ref={containerRef}
      sx={{
        aspectRatio: '9 / 16',
        width: '100%',
        '> div': {
          backgroundColor: color,
          color: contrastText,
        },
        userSelect: contentEditable ? 'unset' : 'none',
        '&.font-size-small': { fontSize: rem(1.4) },
        '&.font-size-medium': { fontSize: rem(1.8) },
        '&.font-size-large': { fontSize: rem(2.4) },
        '.text-overlay': {
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          wordBreak: 'break-all',
        },
        '.moveable-origin': { display: 'none' },
        '.moveable-line': { display: 'none' },

        '[class*="Editext__buttons_container"]': {
          display: 'none',
        },

        'div[editext="view"]': {
          cursor: 'pointer',
        },

        'div[editext="edit-container"]': {
          '[class*="Editext__buttons_container"]': {
            display: 'flex',
          },
        },

        button: {
          borderRadius: '50%',
          border: '2px solid',
          borderColor: 'contrastText',
          color: 'border.dark',
          backgroundColor: 'contrastText',
          ...size(32),
          marginRight: 0.5,
          svg: {
            ...size(24),
            position: 'relative',
            left: -3.75,
            top: 1,
            color: 'currentColor',
          },
          '&:hover': {
            color: 'contrastText',
            backgroundColor: 'transparent',
          },
        },
      }}>
      <EditableText
        cancelButtonClassName="cancel-button"
        cancelButtonContent={<X />}
        cancelOnEscape
        className="text-overlay"
        disabled={!contentEditable}
        editOnViewClick
        inputProps={{ maxLength: characterLimit }}
        onSave={handleMessageChanged}
        ref={targetRef}
        renderValue={value => value || 'Type your message here!'}
        saveButtonClassName="save-button"
        saveButtonContent={<Check />}
        submitOnEnter
        value={interactionData.message}
      />

      <Moveable
        bounds={bounds}
        draggable={interactable}
        onDrag={handleDrag}
        onResize={handleResize}
        onRotate={handleRotate}
        origin
        ref={moveableRef}
        resizable={interactable}
        rotatable={interactable}
        snappable={interactable}
        target={focused ? targetRef?.current : null}
      />
    </Box>
  )
}

export default TextControl
