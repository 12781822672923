import ClassroomEditBreadcrumbs from 'hss/sections/Breadcrumbs/variants/ClassroomEdit'
import NavBreadcrumbs from 'hss/sections/Breadcrumbs/variants/Nav'
import { useRoutes } from 'react-router-dom'
import { classroomUrl, contentBuilderUrl } from 'routing/consts'
import ContentBuilderBreadcrumbs from 'sections/Breadcrumbs/variants/ContentBuilder'
import CurriculumBuilderBreadcrumbs from 'sections/Breadcrumbs/variants/CurriculumBuilder'

const config = {
  classroom: {
    element: <ClassroomEditBreadcrumbs />,
    path: `${classroomUrl}/:contentType/:contentType/:contentId/*`,
  },

  curriculum: {
    element: <CurriculumBuilderBreadcrumbs />,
    path: `${contentBuilderUrl}/curriculum/:contentType/:contentId`,
  },

  content: {
    element: <ContentBuilderBreadcrumbs />,
    path: `${contentBuilderUrl}/:contentGroup/:contentType/:contentId`,
  },

  rubric: {
    element: <ContentBuilderBreadcrumbs />,
    path: `${contentBuilderUrl}/:contentType/:contentId`,
  },

  nav: {
    path: '/*',
    element: <NavBreadcrumbs />,
  },
}

const Breadcrumbs = () => {
  const output = useRoutes(Object.values(config))

  return <div>{output}</div>
}

export default Breadcrumbs
