import { filter, flatten, map, orderBy, push, reduce, shuffle } from 'fp/arrays'
import { omit, set } from 'fp/objects'
import { matches, pipe } from 'fp/utils'
import { produce } from 'immer'
import { compose } from 'redux'

const reduceSelections = (acc, obj) => ({
  ...acc,
  [obj.droppedId]: push(obj.id)(acc[obj.droppedId]),
})

export const getNewSelections = compose(
  reduce(reduceSelections, {}),
  filter(option => option.droppedId),
)

const buildCorrectSelections = compose(
  getNewSelections,
  map((item, idx) =>
    pipe(set('droppedId', item.groupId), set('displayIndex', idx))(item),
  ),
)

export const setupInitialState = (
  groups,
  items,
  studentSelections = [],
  showAnswerKey = false,
  isGrading = false,
) => {
  const selections = showAnswerKey
    ? buildCorrectSelections(items)
    : studentSelections

  const itemReducer = group =>
    pipe(
      filter(item => item.groupId === group.id),
      reduce((acc, row, idx) => {
        let selectionIdx = idx
        // const selectionIdx = selections[group.id]?.findIndex(id => id === row.id)
        Object.keys(selections).forEach(key => {
          const foundIndex = selections[key].findIndex(id => id === row.id)
          if (foundIndex > -1) {
            selectionIdx = foundIndex
          }
        })

        return push({
          groupId: group.id,
          droppedId: Object.keys(selections).find(
            key => selections[key].findIndex(id => id === row.id) > -1,
          ),
          id: row.id,
          label: row.cellText,
          displayIndex: selectionIdx > -1 ? selectionIdx : idx,
        })(acc)
      }, []),
    )(items)

  const groupReducer = (acc, group) => push(itemReducer(group))(acc)

  const processedGroups = pipe(reduce(groupReducer, []), flatten)(groups)

  const droppedItems = orderBy('displayIndex')(
    processedGroups.filter(item => item.droppedId) || [],
  )
  const undroppedItems =
    isGrading || showAnswerKey
      ? orderBy('label')(processedGroups.filter(item => !item.droppedId) || [])
      : shuffle(processedGroups.filter(item => !item.droppedId) || [])

  return {
    groups,
    options: [...undroppedItems, ...droppedItems],
  }
}

const sortOptions = (a, b) => {
  if (a.droppedId === b.droppedId) {
    return a.displayIndex - b.displayIndex
  }
  return (a.droppedId || '') > (b.droppedId || '') ? 1 : -1
}

const reducer = produce((draft, action) => {
  let draggedItemIndex
  switch (action.type) {
    case 'SETUP':
      draft.options = action.options
      draft.groups = action.groups

      break
    case 'DROP-ON-GROUP':
      draggedItemIndex = draft.options.findIndex(
        matches('id', action.droppedId),
      )
      draft.options[draggedItemIndex].displayIndex =
        action.displayIndex || 999999
      draft.options[draggedItemIndex].droppedId = action.droppedOnId
      draft.options = draft.options.sort(sortOptions)

      draft.options = draft.options.map(
        option =>
          // update the displayIndex for the action.droppedOnId group only
          compose(
            map((opt, idx) =>
              pipe(set('displayIndex', idx), omit('newItem'))(opt),
            ),
            filter(matches('droppedId', action.droppedOnId)),
          )(draft.options)
            // try to return one of the records we just updated
            .find(uo => uo.id === option.id) ||
          // no update found, just return the original record
          option,
      )

      draft.options = draft.options.sort(sortOptions)

      break

    case 'DROP-ON-LIST':
      draft.options.forEach((option, idx) => {
        if (option.id === action.droppedId) {
          draft.options[idx].droppedId = null
        }
        // we need for force a re-render if they end up not dropping an item
        // (i.e. putting it back here instead of in a group)
        draft.options[idx].fakeId = new Date()
      })

      break

    /* istanbul ignore next */
    default:
      throw new Error(
        `Unknown action ${action.type} in group and sort reducer.`,
      )
  }
})

export default reducer
