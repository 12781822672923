import { get } from 'fp/objects'
import { asHtml } from 'fp/strings'
import { unless } from 'fp/utils'
import useApiFromEffect from 'hooks/useApiFromEffect'
import { useEffect, useState } from 'react'

const url = '/metadata/turnitinLatestEULA'

const EULA = () => {
  const callApi = useApiFromEffect()
  const [content, setContent] = useState()
  const [fetched, setFetched] = useState()

  useEffect(() => {
    const fetchEULA = async () => {
      setFetched(true)
      const maybe = await callApi({ url })
      maybe.map(get('html')).map(setContent)
    }

    unless(fetched, fetchEULA)
  }, [callApi, fetched])

  return <div {...asHtml(content)} />
}

export default EULA
