import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Dialog from 'common/dialogs/Dialog'
import Link from 'common/navigation/links/Link'
import Headline from 'common/text/Headline'
import { CONTENT_TYPE_ASSESSMENT } from 'core/consts'
import { find } from 'fp/arrays'
import { asPercentageString } from 'fp/numbers'
import { get } from 'fp/objects'
import { matches } from 'fp/utils'
import withProps from 'hoc/withProps'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import {
  assignmentEditorUrl,
  getTeacherAssignmentUserRoute,
} from 'routing/consts'
import { getAssignmentById, getAssignmentStudents } from 'selectors/assignments'
import { getContentById } from 'selectors/content'
import { getStandardById } from 'selectors/standards'
import RequiresGradingIndicator from './RequiresGradingIndicator'

const FlexRowWithSpaceBetween = withProps(Stack, {
  direction: 'row',
  gap: '2rem',
  justifyContent: 'space-between',
})

const UserAssignmentContentLink = ({
  assignmentId,
  requiresGrading,
  contentId,
  contentType,
  userId,
}) => {
  const content = useSelector(getContentById({ contentId, contentType }))
  const uaid = compose(
    get('id'),
    find(matches('userId', userId)),
    useSelector,
    getAssignmentStudents,
  )({ assignmentId })

  const url =
    contentType === CONTENT_TYPE_ASSESSMENT
      ? getTeacherAssignmentUserRoute({
          assignmentId,
          contentType,
          userAssignmentId: uaid,
          userId,
        })
      : `${assignmentEditorUrl}/${assignmentId}/${content?.contentType}/${content?.id}?uaid=${uaid}`

  return (
    Boolean(content) && (
      <Link to={url}>
        <Stack
          direction="row"
          gap={1}>
          {content.name}
          {Boolean(requiresGrading) && <RequiresGradingIndicator />}
        </Stack>
      </Link>
    )
  )
}

UserAssignmentContentLink.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  requiresGrading: PropTypes.bool.isRequired,
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

const AssignmentWithContentLinks = ({
  assignmentId,
  linkableContents,
  score: assignmentScore,
  userId,
}) => {
  const { name } = useSelector(getAssignmentById({ assignmentId }))

  return (
    <Box>
      <Headline
        hr
        light
        title={
          <FlexRowWithSpaceBetween>
            {name}
            <span>{asPercentageString(assignmentScore)}</span>
          </FlexRowWithSpaceBetween>
        }>
        <List>
          {linkableContents.map(content => {
            const { contentId, description, score } = content
            return (
              <ListItem
                key={contentId}
                style={{ padding: '0.5rem 0' }}>
                <FlexRowWithSpaceBetween flex={1}>
                  <Stack>
                    <UserAssignmentContentLink
                      {...{ assignmentId, ...content, userId }}
                    />
                    {description}
                  </Stack>
                  <Typography>{asPercentageString(score)}</Typography>
                </FlexRowWithSpaceBetween>
              </ListItem>
            )
          })}
        </List>
      </Headline>
    </Box>
  )
}
AssignmentWithContentLinks.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  linkableContents: PropTypes.arrayOf(
    PropTypes.shape({
      contentId: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    }),
  ).isRequired,
  userId: PropTypes.string.isRequired,
}

const StandardMasteryDialog = ({
  firstName,
  lastName,
  onClose,
  id,
  score,
  assignments,
  userId,
}) => {
  const { shortCode, statement } = useSelector(getStandardById({ id }))

  return (
    <Dialog
      onClose={onClose}
      open
      showCloseButton
      swatch
      title="Standard Mastery"
      TitleProps={{ mb: '1.5rem !important' }}>
      <DialogContent>
        <Typography>
          {[lastName, firstName].filter(Boolean).join(', ')}
        </Typography>
        <Stack
          gap={4}
          my={4}>
          <Headline
            light
            size={4}
            title={
              <FlexRowWithSpaceBetween>
                {shortCode}
                <span>{asPercentageString(score)}</span>
              </FlexRowWithSpaceBetween>
            }>
            <Typography variant="feature-paragraph">{statement}</Typography>
            {assignments.map(assignment => {
              const { assignmentId } = assignment
              return (
                <AssignmentWithContentLinks
                  key={assignmentId}
                  {...{
                    ...assignment,
                    userId,
                  }}
                />
              )
            })}
          </Headline>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

StandardMasteryDialog.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  assignments: PropTypes.arrayOf(
    PropTypes.shape({
      assignmentId: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
    }),
  ).isRequired,
  userId: PropTypes.string.isRequired,
}

export default StandardMasteryDialog
