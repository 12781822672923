import Link from 'common/navigation/links/Link'
import Headline from 'common/text/Headline'
import LoginLayout from './LoginLayout'

const Logout = () => (
  <LoginLayout>
    <Headline
      my={4}
      size={2}
      title="You have been signed out"
    />

    <Link to="/login">Sign In</Link>
  </LoginLayout>
)

export default Logout
