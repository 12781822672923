import HFTextField from 'common/formControls/textInputs/HFTextField'

// label always on top of field even when blank/empty
const LoginTextField = props => (
  <HFTextField
    InputLabelProps={{ shrink: true }}
    {...props}
    variant="filled"
  />
)

export default LoginTextField
