import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const Img = styled(
  props => {
    const { className, imageAltText, imageUrl } = props

    return (
      <img
        alt={imageAltText}
        className={className}
        src={imageUrl}
      />
    )
  },
  { name: 'ImageClicker-Img' },
)({ width: '100%' })

Img.propTypes = {
  imageAltText: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default Img
