import Dialog from 'common/dialogs/Dialog'
import { ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import { when } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useReduxCallback, { BUSY, ERROR, SUCCESS } from 'hooks/useReduxCallback'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import PropTypes from 'prop-types'
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import { getUserAssignmentById } from 'selectors/userAssignments'
import { getUserById } from 'selectors/users'
import ErrorTryAgainPrompt from './ErrorTryAgainPrompt'
import FinalSubmitPrompt from './FinalSubmitPrompt'
import Success from './Success'

const titles = {
  [SUCCESS]: 'Success!',
  [ERROR]: 'Try Again?',
}

const contentComponents = {
  [ERROR]: ErrorTryAgainPrompt,
  [SUCCESS]: Success,
}

const SubmitDialog = ({ onClose }) => {
  const { userAssignmentId: paramsUserAssignmentId } = useParams() || {}
  const isAssignmentCreator = useAbilityCheck(ABILITY_ASSIGNMENT_CREATION)
  const { currentUserAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}
  const userAssignmentId = paramsUserAssignmentId || currentUserAssignmentId
  const { userId } =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const { firstName, lastName } = useSelector(getUserById({ userId })) || {}
  const [currentResultState, setCurrentResultState] = useState('none')
  const studentName = `${firstName} ${lastName}`

  const [dispatchSubmit, status] = useReduxCallback({
    actionType: actionTypes.USER_ASSIGNMENT_SUBMIT,
  })
  const handleSubmit = useCallback(() => {
    dispatchSubmit({ userAssignmentId })
  }, [dispatchSubmit, userAssignmentId])

  useLayoutEffect(() => {
    when(status !== BUSY, setCurrentResultState, status)
  }, [status])

  const submitLabel = isAssignmentCreator
    ? `Submit assignment for ${studentName}?`
    : 'Are you ready to submit?'

  const title = titles[currentResultState] || submitLabel
  const Content = contentComponents[currentResultState] || FinalSubmitPrompt

  useEffect(() => {
    when(isAssignmentCreator && currentResultState === SUCCESS, onClose)
  }, [currentResultState, onClose, isAssignmentCreator])

  return (
    <Dialog
      onClose={onClose}
      open
      showCloseButton
      title={title}>
      <Content {...{ onSubmit: handleSubmit, onClose, status }} />
    </Dialog>
  )
}

SubmitDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default SubmitDialog
