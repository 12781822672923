import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled, useTheme } from '@mui/material/styles'
import { contentShape } from 'core/shapes'
import { not, when } from 'fp/utils'
import { proficiencyLabels } from 'projections/scaffolds'
// Many of the code branches in this file won't get hit since
// direction is always horizontal now.
// TODO: Possibly remove that code postMVP if the change is permanent
/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { ChevronsDown, ChevronsUp } from 'react-feather'
import { useSelector } from 'react-redux'
import { isStudent } from 'selectors/users'

const Wrapper = styled(Box, { name: 'Scaffolds-DrawerButtons' })(
  ({
    theme: {
      mixins: { important, px, rem },
      palette,
      shadows,
      typography,
      zIndex,
    },
  }) => ({
    'svg[viewBox="0 0 24 24"]': {
      margin: 0,
      width: px(18),
      height: px(18),
    },
    button: {
      ...typography.variants['paragraph-semibold'],
      padding: px(8, 20),
      whiteSpace: 'nowrap',
      marginTop: -2,
      border: 'none',
      boxShadow: `${shadows.borderInset} ${palette.cobalt[0]}`,
      '&:hover': {
        ...typography.variants['paragraph-semibold'],
        backgroundColor: important(palette.cobalt[4]),
        color: palette.cobalt[0],
        boxShadow: `${shadows.borderInset} ${palette.cobalt[0]}`,

        svg: {
          color: palette.cobalt[0],
          strokeWidth: 2,
        },
      },
    },
    display: 'flex',
    flexFlow: 'row nowrap',
    zIndex: zIndex.modal,
    marginBottom: rem(3),
  }),
)

const DrawerButtons = props => {
  const {
    attachedScaffolds,
    currentScaffoldIndex,
    direction,
    open,
    reverseChevronDirection = false,
    setCurrentScaffoldIndex,
    setOpen,
  } = props

  const forStudent = useSelector(isStudent)

  const handleClick = useCallback(
    idx => {
      if (idx === currentScaffoldIndex) {
        setOpen(not)
      } else {
        setCurrentScaffoldIndex(idx)
        when(!open, setOpen, true)
      }
    },
    [currentScaffoldIndex, open, setCurrentScaffoldIndex, setOpen],
  )

  const V = direction === 'vertical'

  const { palette, shadows } = useTheme()

  const active = {
    backgroundColor: palette.background.paper,
    color: palette.cobalt[0],
    boxShadow: shadows.borderInset,
  }
  const activeSpan = { color: palette.cobalt[0] }

  const Opened = reverseChevronDirection ? ChevronsDown : ChevronsUp
  const Closed = reverseChevronDirection ? ChevronsUp : ChevronsDown

  return (
    <Wrapper
      left={0}
      sx={{
        ...(V
          ? {
              flexDirection: 'row-reverse',
              transformOrigin: '0% 0%',
              transform: 'rotate(270deg) translate(-100%, -10%)',
            }
          : {
              transformOrigin: '0% 0%',
            }),
        button: { [V ? 'marginLeft' : 'marginRight']: 1 },
      }}
      top={0}>
      {attachedScaffolds
        // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: <explanation>
        .map(({ id, proficiencyIds }, idx) => (
          <Button
            color="primary"
            key={id}
            onClick={() => handleClick(idx)}
            size="small"
            style={{
              ...(idx === currentScaffoldIndex && open ? active : null),
              ...(V && open
                ? { marginTop: 4.5 }
                : V && !open
                  ? { marginTop: -5.5 }
                  : {}),
            }}
            {...{
              [V ? 'endIcon' : 'startIcon']: open ? (
                <Opened
                  style={{
                    ...(idx === currentScaffoldIndex && open
                      ? activeSpan
                      : null),
                    strokeWidth: 2,
                  }}
                />
              ) : (
                <Closed />
              ),
            }}
            variant="primary">
            {/* students only see "scaffold" as the label -- others see proficiency labels */}
            {forStudent
              ? `Scaffold ${attachedScaffolds.length > 1 ? idx + 1 : ''}`
              : proficiencyLabels(proficiencyIds)}
          </Button>
        ))}
    </Wrapper>
  )
}

DrawerButtons.propTypes = {
  attachedScaffolds: PropTypes.arrayOf(contentShape).isRequired,
  currentScaffoldIndex: PropTypes.number.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  open: PropTypes.bool.isRequired,
  reverseChevronDirection: PropTypes.bool,
  setCurrentScaffoldIndex: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default DrawerButtons
