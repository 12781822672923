import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import withSearchProvider from './searchConfig'

const initialSorting = [
  {
    id: 'lastName',
    desc: false,
  },
  {
    id: 'firstName',
    desc: false,
  },
]

const Students = () => (
  <CommonTableSearch
    initialSorting={initialSorting}
    title="Students"
  />
)

const Searchable = withSearchProvider(Students)

export default Searchable
