import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import { INTERACTION_STATE_COMPLETED } from 'core/consts'
import { toKeyedObject } from 'fp/arrays'
import { equals, get } from 'fp/objects'
import { pluralize } from 'fp/strings'
import { curryRight, identity, when } from 'fp/utils'
import useReduxPromise from 'hooks/useReduxPromise'
import { useContext, useMemo } from 'react'
import { createConfirmation } from 'react-confirm'
import { useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import {
  getAssignmentStudents,
  getContextualAssignment,
} from 'selectors/assignments'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { interactiveContext } from '../../InteractiveProvider'
import ReopenButton from './ReopenButton'

const confirm = createConfirmation(ConfirmationDialog, 0)

const isComplete = compose(equals(INTERACTION_STATE_COMPLETED), get('state'))

const ReopenButtonForAllStudents = () => {
  const { contentId } = useContext(interactiveContext)

  const assignment = useSelector(getContextualAssignment) || {}
  const { id: assignmentId } = assignment

  const assignmentInteractions = compose(
    useSelector,
    getInteractionsForAssignmentContent,
  )({ assignmentId, contentId })

  const userAssignments = useSelector(getAssignmentStudents({ assignmentId }))
  const submittedUserAssignments = userAssignments.filter(
    compose(Boolean, get('submittedDate')),
  )

  const allAreSubmitted = useMemo(
    () =>
      submittedUserAssignments.length === userAssignments.length ||
      (() => {
        const interactionsByUserAssignmentId = curryRight(
          toKeyedObject,
          'userAssignmentId',
        )(assignmentInteractions)
        const isSubmitted = ({ id, submittedDate }) =>
          submittedDate || isComplete(interactionsByUserAssignmentId[id])
        return Boolean(
          userAssignments.length && userAssignments.every(isSubmitted),
        )
      })(),
    [assignmentInteractions, submittedUserAssignments.length, userAssignments],
  )

  const reopen = useReduxPromise(actionTypes.ASSIGNMENT_CONTENT_REOPEN)
  const reopenThisContent = () => {
    reopen({ assignmentId, contentId })
  }

  const handleClick = () => {
    if (submittedUserAssignments.length) {
      confirm({
        primaryText: `Reopen ${pluralize('Student Assignment')(submittedUserAssignments.length)}?`,
        secondaryText: (
          <>
            Before this interactive can be reopened for all students,{' '}
            {pluralize('submitted student assignment')(
              submittedUserAssignments.length,
            )}{' '}
            will also need to be reopened. Any other previously unsubmitted
            interactives will also become submittable again.
          </>
        ),
        proceed: proceed => {
          when(proceed, reopenThisContent)
        },
        cancel: identity,
        confirmLabel: `Reopen ${pluralize('Student Assignment')(submittedUserAssignments.length)}`,
        cancelLabel: 'Cancel',
      })
    } else {
      reopenThisContent()
    }
  }

  return (
    allAreSubmitted && (
      <ReopenButton
        handleClick={handleClick}
        studentName="All Students"
      />
    )
  )
}

export default ReopenButtonForAllStudents
