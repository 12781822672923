/* istanbul ignore file */
import PropTypes from 'prop-types'

export const actionShape = PropTypes.shape({
  data: PropTypes.any.isRequired,
  endFrame: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  loc: PropTypes.string.isRequired,
  startFrame: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['externalLink']).isRequired,
})

export const pauseShape = PropTypes.shape({
  delaySec: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  startFrame: PropTypes.number.isRequired,
})

export const actionsShape = PropTypes.arrayOf(actionShape)

export const pausesShape = PropTypes.arrayOf(pauseShape)
