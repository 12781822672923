import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import DateTimePicker from 'common/formControls/dateAndTime/HFDateTimePicker'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import HFNumberField from 'common/formControls/textInputs/HFNumberField'
import { ASSIGNMENT_TARGET_GROUP } from 'core/consts'
import { when } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import { parseDate } from 'locale/i18n'
import { useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

const PeerReview = () => {
  const { getValues, setValue } = useFormContext()
  const reviewsRequested = getValues('data.settings.reviewsRequested')
  const allowPeerReviews =
    useWatch({ name: 'targetTypeId' }) === ASSIGNMENT_TARGET_GROUP
  const [enablePeerReviews, toggleEnablePeerReviews, setEnablePeerReviews] =
    useToggleState(Boolean(reviewsRequested))

  const tooltip = allowPeerReviews
    ? ''
    : 'You must select a group target to enable Echo peer reviews'

  useEffect(() => {
    setValue(
      'data.settings.reviewsRequested',
      enablePeerReviews ? reviewsRequested || 30 : 0,
    )
  }, [enablePeerReviews, reviewsRequested, setValue])

  useEffect(() => {
    when(!allowPeerReviews, setEnablePeerReviews, false)
  }, [allowPeerReviews, setEnablePeerReviews])

  const endDate = parseDate(useWatch({ name: 'endDate' }))

  return (
    <>
      <Tooltip title={tooltip}>
        <div>
          <SwitchFormControl
            checked={enablePeerReviews}
            disabled={!allowPeerReviews}
            helperText="Ask each student to submit a number of Echo peer reviews by the review date."
            label="Request Echo Peer Reviews"
            onChange={toggleEnablePeerReviews}
          />
        </div>
      </Tooltip>
      {Boolean(enablePeerReviews) && (
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          gap={2}>
          <HFNumberField
            label="Number of Reviews"
            min={1}
            name="data.settings.reviewsRequested"
            required
            style={{ width: 'auto' }}
          />
          <DateTimePicker
            label="Review End"
            minDateTime={endDate}
            name="data.settings.reviewEndDate"
            required
          />
        </Stack>
      )}
    </>
  )
}

export default PeerReview
