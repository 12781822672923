import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { componentShape } from 'core/shapes'
import { when } from 'fp/utils'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Grid, List, Sliders, Table } from 'react-feather'
import SearchInputControl from './Filters/Controls/SearchInputControl'
import { searchContext } from './SearchProvider'

const SearchControls = ({
  children,
  renderActions,
  renderPrimaryFilter,
  renderRightSideControls,
}) => {
  const {
    hideViewToggle,
    initialViewMode,
    primaryFiltersExpanded,
    setViewMode,
    togglePrimaryFiltersExpanded,
    viewMode,
  } = useContext(searchContext)

  const isSmallScreen = useContainerQuery().down('md')
  const isTableBased = initialViewMode === 'table'
  const willShowViewToggle = !(
    hideViewToggle ||
    (isSmallScreen && isTableBased)
  )

  const handleViewToggle = (_, value) => {
    when(value !== null, setViewMode, value)
  }

  return (
    <Stack
      direction="row"
      display="flex"
      flexWrap="wrap"
      gap={2}
      justifyContent="space-between">
      {/* LEFT SIDE */}
      <Stack
        direction="row"
        display="flex"
        flexWrap="wrap"
        gap={2}>
        {children}

        <SearchInputControl />

        {renderPrimaryFilter ? (
          <Button
            onClick={togglePrimaryFiltersExpanded}
            startIcon={<Sliders />}
            variant={primaryFiltersExpanded ? 'primary' : 'default'}>
            Filters
          </Button>
        ) : null}
      </Stack>

      {/* RIGHT SIDE */}
      <Stack
        alignItems="center"
        direction="row"
        gap={2}>
        {renderRightSideControls?.()}

        {!!willShowViewToggle && (
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={handleViewToggle}
            value={viewMode}>
            {isTableBased ? (
              <ToggleButton
                aria-label="table view"
                value="table">
                <Table />
              </ToggleButton>
            ) : (
              <ToggleButton
                aria-label="grid view"
                value="grid">
                <Grid />
              </ToggleButton>
            )}

            <ToggleButton
              aria-label="list view"
              value="list">
              <List />
            </ToggleButton>
          </ToggleButtonGroup>
        )}

        {renderActions?.()}
      </Stack>
    </Stack>
  )
}

SearchControls.propTypes = {
  children: componentShape,
  renderActions: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderRightSideControls: PropTypes.func,
}

export default SearchControls
