import { flatten, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import useLocalSetting from 'hooks/useLocalSetting'
import useToggleState from 'hooks/useToggleState'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { createContext, useContext, useMemo } from 'react'

export const gradebookContext = createContext()

const GradebookContextProvider = ({ children }) => {
  const [showAssignmentDetails, toggleShowAssignmentDetails] =
    useToggleState(false)
  const [view, setView] = useLocalSetting('gradebookView', 'scores')

  const { dataset = {} } = useContext(searchContext) || {}
  const { data } = dataset
  const userAssignmentIds = useMemo(
    () =>
      Maybe.fromUndefined(data)
        .map(map(get('userAssignments')))
        .map(flatten)
        .map(map(get('id')))
        .orUndefined(),
    [data],
  )

  const value = useDeepCompareMemo(
    () => ({
      showAssignmentDetails,
      toggleShowAssignmentDetails,
      userAssignmentIds,
      view,
      setView,
    }),
    [
      showAssignmentDetails,
      toggleShowAssignmentDetails,
      userAssignmentIds,
      view,
    ],
  )

  return (
    <gradebookContext.Provider value={value}>
      {children}
    </gradebookContext.Provider>
  )
}

GradebookContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GradebookContextProvider
