import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from 'common/dialogs/Dialog'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/userAlerts'
import { navDialogsContext } from 'routing/shells/NavShell/NavDialogsProvider'
import {
  getNewInterruptUserAlerts,
  getNewInterruptUserAlertsCount,
} from 'selectors/userAlerts'
import UserAlertContent from './UserAlertContent'

const InterruptUserAlertsDialog = () => {
  const newInterruptAlerts = useSelector(getNewInterruptUserAlerts)
  const alertCount = useSelector(getNewInterruptUserAlertsCount)

  const { interruptUserAlertsIsOpen, toggleInterruptUserAlertsIsOpen } =
    useContext(navDialogsContext)

  const dispatch = useDispatch()

  // 'interrupt' alerts should be auto-confirmed upon viewing
  const confirmAlerts = () =>
    newInterruptAlerts.forEach(alert => {
      dispatch(actions.confirmUserAlert({ id: alert.id }))
    })

  return (
    <Dialog
      onClose={() => {
        confirmAlerts()
        return toggleInterruptUserAlertsIsOpen()
      }}
      open={Boolean(alertCount && interruptUserAlertsIsOpen)}
      showCloseButton
      variant="maximized-vertically">
      <DialogTitle>Notifications</DialogTitle>
      <DialogContent>
        {newInterruptAlerts.map((alert, index) => (
          <div key={alert.id}>
            <UserAlertContent alert={alert} />
            {Boolean(index !== alertCount - 1) && <hr />}
          </div>
        ))}
      </DialogContent>
    </Dialog>
  )
}

export default InterruptUserAlertsDialog
