import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { contentShape } from 'core/shapes'
import PosterImage from 'hss/views/Card/PosterImage'
import LinkToContent from 'hss/views/Library/LinkToContent'
import { forwardRef } from 'react'

const ImageItemRenderer = forwardRef(({ content }, ref) =>
  content ? (
    <Stack ref={ref}>
      <LinkToContent content={content}>
        <PosterImage
          aspectRatio="16/9"
          content={content}
          viewMode="grid"
        />
      </LinkToContent>
      <Typography
        mt={2}
        variant="body1-semibold">
        {content?.name}
      </Typography>
    </Stack>
  ) : null,
)

ImageItemRenderer.propTypes = {
  content: contentShape.isRequired,
}

export default ImageItemRenderer
