import CollapsibleSection from 'common/layout/CollapsibleSection'
import Headline from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'

const CollapsibleInstructions = props => (
  <CollapsibleSection label={<Headline title="Instructions" />}>
    <HeadlineLevelOffset {...props} />
  </CollapsibleSection>
)

export default CollapsibleInstructions
