import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { get } from 'fp/objects'
import useToggleState from 'hooks/useToggleState'
import withCanSubmitAssignment from 'hss/ContentViewer/withCanSubmitAssignment'
import SubmitDialog from 'hss/sections/Assignments/SubmitDialog'
import PropTypes from 'prop-types'
import { Send } from 'react-feather'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAssignmentById } from 'selectors/assignments'
import { getContentHasIncompleteInteractives } from 'selectors/contentViewer'
import { getUserAssignmentById } from 'selectors/userAssignments'

const SubmitAssignmentButton = withCanSubmitAssignment(
  ({ canSubmit = false, ...rest }) => {
    const { userAssignmentId } = useParams()
    const { assignmentId } =
      useSelector(getUserAssignmentById({ userAssignmentId })) || {}
    const assignment = useSelector(getAssignmentById({ assignmentId })) || {}
    const isCompleted = !useSelector(
      getContentHasIncompleteInteractives({
        contentId: get('contentId')(assignment),
        contentType: CONTENT_TYPE_CHAPTER, // TODO: use assignment's contentType
      }),
    )

    const [isDialogOpen, toggleIsDialogOpen] = useToggleState()

    return (
      <>
        {Boolean(canSubmit) && (
          <Tooltip title="Submit Assignment">
            <IconButton
              {...rest}
              aria-label="Submit Assignment"
              color="primary"
              onClick={toggleIsDialogOpen}
              size="large"
              style={{ marginRight: 12 }}
              variant={isCompleted ? 'primary' : 'default'}>
              <Send />
            </IconButton>
          </Tooltip>
        )}
        {Boolean(isDialogOpen) && <SubmitDialog onClose={toggleIsDialogOpen} />}
      </>
    )
  },
)

SubmitAssignmentButton.propTypes = {
  canSubmit: PropTypes.bool,
}

export default SubmitAssignmentButton
