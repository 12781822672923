import { pluralize } from 'fp/strings'

export const paginationHeader = (count, offset, limit) => {
  switch (count) {
    case 0:
      return '0 Results'
    case 1:
      return '1 Result'
    default:
      return `${offset + 1}-${Math.min(offset + limit, count)} of ${pluralize(
        'Result',
      )(count)}`
  }
}
