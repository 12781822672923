import Button from '@mui/material/Button'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { a11yContext } from 'core/a11y'
import { isMobile } from 'fp/internet'
import useHoverFocus from 'hooks/useHoverFocus'
import PropTypes from 'prop-types'
import { useContext } from 'react'

const SelectResponseButton = ({ columnName, className, ...rest }) => {
  const [focusRef, isHovered, isFocused] = useHoverFocus()
  const { tabModeEnabled } = useContext(a11yContext) || {}
  const isSmallScreen = useContainerQuery().down('sm')
  const isHidden = !(
    isSmallScreen ||
    tabModeEnabled ||
    isHovered ||
    isFocused ||
    isMobile()
  )

  return (
    <Button
      className={className}
      ref={focusRef}
      {...rest}
      aria-label={`Select answer for ${columnName} based on surrounding cells`}
      style={{ opacity: isHidden ? 0 : 1, width: '100%' }}>
      Select Answer
    </Button>
  )
}

SelectResponseButton.propTypes = {
  columnName: PropTypes.string.isRequired,
}

export default SelectResponseButton
