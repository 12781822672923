import CardActions from '@mui/material/CardActions'
import LinkButton from 'common/navigation/links/LinkButton'
import {
  ABILITY_ASSIGNMENT_CREATION,
  ABILITY_CONTENT_CREATION,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { buildContentUrl } from 'projections/content'
import {
  contentBuilderUrl,
  getTeacherAssignmentCreateRoute,
} from 'routing/consts'

const Actions = ({ content }) => {
  const { contentType, id: contentId, name } = content
  const has = useAbilityChecker()

  return (
    <CardActions>
      <LinkButton
        aria-label={`View chapter, ${name}`}
        color="secondary"
        size="small"
        to={
          has(ABILITY_CONTENT_CREATION)
            ? `${contentBuilderUrl}/curriculum/${contentType}/${contentId}`
            : buildContentUrl()(content)
        }
        variant="secondary">
        View chapter
      </LinkButton>

      {!!has(ABILITY_ASSIGNMENT_CREATION) && (
        <LinkButton
          aria-label={`Assign chapter, ${name}`}
          color="secondary"
          size="small"
          to={getTeacherAssignmentCreateRoute({ contentType, id: contentId })}
          variant="primary">
          Assign
        </LinkButton>
      )}
    </CardActions>
  )
}

Actions.propTypes = {
  content: contentShape.isRequired,
}

export default Actions
