import Button from '@mui/material/Button'
import ImageBankSelector from 'common/formControls/selects/ImageBankSelector'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

const BrowseButton = ({ onInteract, ...rest }) => {
  const [open, setOpen] = useState(false)

  const handleClick = event => {
    event.preventDefault()
    setOpen(true)
  }

  const handleComplete = useCallback(
    imageBankItem => {
      onInteract(draft => {
        draft.interactionData.imageBankItem = imageBankItem
      })
    },
    [onInteract],
  )

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{ '&:active': { boxShadow: 'none' } }}
        variant="tertiary"
        {...rest}>
        Browse...
      </Button>

      {!!open && (
        <ImageBankSelector
          onClose={() => setOpen(false)}
          onComplete={handleComplete}
          open
        />
      )}
    </>
  )
}

BrowseButton.propTypes = {
  onInteract: PropTypes.func.isRequired,
}

export default BrowseButton
