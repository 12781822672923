import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { entityIdShape } from 'core/shapes'
import useContent from 'hooks/useContent'
import PropTypes from 'prop-types'
import InteractiveProvider from '../../Interactive/InteractiveProvider'
import InteractiveRenderer from '../../Interactive/InteractiveRenderer'
import Content from './Content'

const Wrapper = props => {
  const { contentId, name } = props
  const interactive = useContent({
    contentId,
    contentType: CONTENT_TYPE_INTERACTIVE,
  })

  // sometimes the interactive content hasn't loaded yet
  return interactive ? (
    <InteractiveProvider
      contentid={contentId}
      Renderer={InteractiveRenderer}>
      <Content />
    </InteractiveProvider>
  ) : (
    <u>{name}</u>
  )
}

Wrapper.propTypes = {
  contentId: entityIdShape.isRequired,
  name: PropTypes.string.isRequired,
}

export default Wrapper
