import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import Headline from 'common/text/Headline'
import { set } from 'fp/objects'
import VennDiagram from 'hss/sections/contentBlocks/interactives/VennDiagram'
import { useContext } from 'react'
import Rubric from '../../../interactives/Rubric'
import { interactiveGradingContext } from '../../InteractiveGradingProvider'
import { interactiveContext } from '../../InteractiveProvider'

const VennDiagramRubricGrader = () => {
  const {
    gradingEnabled,
    interactionData: { sections },
    interactive: { rubric },
  } = useContext(interactiveContext)

  const { scoreData, setSaveButtonDisabled, setScoreData } = useContext(
    interactiveGradingContext,
  )

  const { comment = '', rubricSelections = [] } = scoreData || {}

  const handleRubricChange = newValues => {
    setScoreData(set('rubricSelections', newValues))
    setSaveButtonDisabled(false)
  }

  const handleCommentChange = ({ target: { value } }) => {
    setScoreData(set('comment', value))
    setSaveButtonDisabled(false)
  }

  return (
    <Stack gap={4}>
      {sections?.length ? (
        <VennDiagram />
      ) : (
        <Box textAlign="center">(no response)</Box>
      )}
      <Box component="fieldset">
        <Box
          component="legend"
          style={{ maxWidth: 'none', textAlign: 'center', width: '100%' }}>
          <Headline
            hr
            title="Grading & Review"
            variant="feature-paragraph-semibold"
          />
        </Box>
        <Rubric
          onChange={handleRubricChange}
          readOnly={!gradingEnabled}
          rubric={rubric}
          values={rubricSelections}
        />
        <DebouncedTextField
          disabled={!gradingEnabled}
          label="Optional Comment"
          multiline
          name="comment"
          onChange={handleCommentChange}
          value={comment}
          variant="filled"
        />
      </Box>
    </Stack>
  )
}

export default VennDiagramRubricGrader
