import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import { position } from 'polished'
import PropTypes from 'prop-types'
import MouseMode from './MouseMode'
import Reset from './Reset'
import ZoomLevel from './ZoomLevel'

const Controls = styled(
  ({
    className,
    mouseMode,
    resetView,
    setMouseMode,
    setZoomLevel,
    zoomLevel,
  }) => (
    <Box
      className={className}
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        width: 100,
        margin: '0 auto',
      }}>
      <ZoomLevel {...{ zoomLevel, setZoomLevel }} />

      <Divider
        flexItem
        orientation="vertical"
      />

      <Reset resetView={resetView} />

      <Divider
        flexItem
        orientation="vertical"
      />

      <MouseMode {...{ mouseMode, setMouseMode, zoomLevel }} />
    </Box>
  ),
  { name: 'Controls-Controls' },
)(position('absolute', 0, 0, null, 0))

Controls.propTypes = {
  mouseMode: PropTypes.oneOf(['move', 'zoom']).isRequired,
  resetView: PropTypes.func.isRequired,
  setMouseMode: PropTypes.func.isRequired,
  setZoomLevel: PropTypes.func.isRequired,
  zoomLevel: PropTypes.oneOf([0, 1, 2]).isRequired,
}
export default Controls
