import Button from '@mui/material/Button'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'
import { entityIdShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

const InlineEditText = props => {
  const { children, id, onChange, placeholder, title, ...rest } = props
  const [editMode, setEditMode] = useState()
  const ref = useRef()

  const handleClick = () => {
    setEditMode(true)
  }
  const handleBlur = () => {
    // TODO: autosave answer should happen higher up in the form
    setEditMode(false)
  }

  useEffect(() => {
    if (editMode) {
      ref.current.focus()
      ref.current.setSelectionRange(
        ref.current.value.length,
        ref.current.value.length,
      )
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [editMode])

  return editMode ? (
    <DebouncedTextField
      fullWidth
      label=""
      multiline
      name={`header-${id}`}
      onBlur={handleBlur}
      onChange={onChange}
      placeholder={placeholder}
      ref={ref}
      rows={2}
      title={title}
      value={children}
      variant="outlined"
      {...rest}
    />
  ) : (
    <Button
      onClick={handleClick}
      title={`Click to Edit ${title}`}
      variant="tertiary">
      {children || placeholder}
    </Button>
  )
}

InlineEditText.propTypes = {
  id: entityIdShape.isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default InlineEditText
