import Box from '@mui/material/Box'
import cl from 'classnames'
import Html from 'common/text/Html'
import Image from 'sections/contentBlocks/interactives/ChartCompletion/ChartItem/Image'
import { itemShape } from 'sections/contentBlocks/interactives/ChartCompletion/utils'
import ChartCompletion from '../ChartCompletion'

const AnswerKeyChartItem = props => {
  const {
    item: { contentType, isStatic, value },
  } = props
  return (
    <Box
      className={cl({ static: isStatic })}
      component="td">
      {contentType === 'image' ? (
        <Image {...props} />
      ) : (
        <div style={{ width: '100%' }}>
          {Boolean(contentType === 'input') && 'Recommended:'}
          <Html body={value} />
        </div>
      )}
    </Box>
  )
}

AnswerKeyChartItem.propTypes = {
  item: itemShape.isRequired,
}

const ChartCompletionAnswerKey = () => (
  <ChartCompletion ChartItemRenderer={AnswerKeyChartItem} />
)

export default ChartCompletionAnswerKey
