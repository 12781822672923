import CircularProgress from '@mui/material/CircularProgress'
import LinkIconButton from 'common/navigation/links/LinkIconButton'
import useIsWaiting from 'hss/ContentViewer/Chapter/Subsection/Navigation/useIsWaiting'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const NavButton = forwardRef(({ children, disabled, to, ...rest }, ref) => {
  const isWaiting = useIsWaiting(to)

  return (
    <LinkIconButton
      aria-hidden={disabled}
      disabled={disabled}
      to={to}
      {...rest}
      ref={ref}
      style={{
        height: 50,
        width: 50,
      }}>
      {isWaiting ? <CircularProgress size={24} /> : children}
    </LinkIconButton>
  )
})

NavButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
}

export default NavButton
