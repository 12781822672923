import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import { contentShape } from 'core/shapes'
import withContentList from 'hoc/withContentList'
import background from 'hss/images/home/background-graphic.webp'
import CourseCard from 'hss/views/Library/CourseCard'
import PropTypes from 'prop-types'
import { AddButton } from './Hero'

const AllCourses = ({ items }) => (
  <Container
    style={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left 50px',
      paddingBottom: 64,
    }}
    variant="list-column">
    <Box
      display="flex"
      justifyContent="flex-end"
      pt={4.5}>
      <AddButton />
    </Box>
    <List
      dense
      disablePadding
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(342px, 1fr))',
        justifyContent: 'space-between',
        gap: 32,
        paddingTop: 32,
      }}>
      {items.map(content => (
        <ListItem
          disableGutters
          key={content.id}
          style={{
            display: 'inline-block',
          }}>
          <CourseCard content={content} />
        </ListItem>
      ))}
    </List>
  </Container>
)

AllCourses.propTypes = {
  items: PropTypes.arrayOf(contentShape).isRequired,
}

const AllCoursesWithContent = withContentList(AllCourses, {
  contentType: CONTENT_TYPE_COURSE,
})

export default AllCoursesWithContent
