import ViewInAr from '@mui/icons-material/ViewInAr'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import ListGrid, { itemRendererShape } from 'common/formControls/lists/ListGrid'
import { sortBy } from 'fp/arrays'

const ItemRenderer = ({ item }) => (
  <>
    <SvgIcon fontSize="large">
      <ViewInAr />
    </SvgIcon>
    <span>{item.label}</span>
  </>
)
ItemRenderer.propTypes = { ...itemRendererShape }

const withListGrid = ({ items: rawItems } = {}) => {
  const Enhanced = props => {
    const items = rawItems.sort(sortBy('label'))

    return (
      <Box>
        <ListGrid
          itemHeight={100}
          itemWidth={170}
          searchable
          {...{ ItemRenderer, items, ...props }}
        />
      </Box>
    )
  }

  return Enhanced
}

export default withListGrid
