import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import { Send } from 'react-feather'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSubsectionOrEchoAccentColor } from 'selectors/contentViewer'

const SubmitAssignmentButton = ({ onClick }) => {
  const { echoId, subsectionId } = useParams()
  const contentId = echoId || subsectionId
  const {
    palette,
    palette: { sectionAccents },
  } = useTheme()
  const accentColor = useSelector(
    getSubsectionOrEchoAccentColor({ contentId, sectionAccents }),
  )

  const accentStyle = {
    transition: 'all 250ms ease-in-out 0ms',
    backgroundColor: get(`${accentColor}.0`)(palette),
  }

  return (
    <Button
      component="div"
      data-subvariant="align-right"
      onClick={onClick}
      style={accentStyle}
      variant="full-size-nav">
      <div
        className="accent"
        style={accentStyle}>
        <div className="title">
          <Stack
            alignItems="center"
            direction="row"
            gap={1}>
            <Send />
            <span>Submit Assignment</span>
          </Stack>
        </div>
      </div>
    </Button>
  )
}

SubmitAssignmentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default SubmitAssignmentButton
