import { useTheme } from '@mui/material/styles'
import { mapValues, set } from 'fp/objects'
import { useMemo } from 'react'

const groupingKeyColorNames = {
  WG: 'cobalt',
  SG: 'tangerine',
  IND: 'teal',
}

const dayCellColors = {
  1: {
    colorName: 'cobalt',
    index: 4,
  },
  2: {
    colorName: 'cobalt',
    index: 3,
  },
  3: {
    colorName: 'cobalt',
    index: 2,
  },
  4: {
    colorName: 'cobalt',
    index: 1,
    useContrastText: true,
  },
  5: {
    colorName: 'cobalt',
    index: 0,
    useContrastText: true,
  },
  Homework: {
    colorName: 'grey',
    index: 4,
  },
}

const useChapterPacingGuideTheme = () => {
  const { palette } = useTheme()

  return useMemo(() => {
    const groupingKeyColors = mapValues(colorName => palette[colorName][0])(
      groupingKeyColorNames,
    )

    const dayCellStyles = mapValues(({ colorName, index, useContrastText }) => {
      const style = { background: palette[colorName][index] }
      return useContrastText
        ? set('color', palette[colorName].contrastText)(style)
        : style
    })(dayCellColors)

    return {
      dayCellValueStyle: cellValue => dayCellStyles[cellValue],
      groupingColor: key => groupingKeyColors[key],
    }
  }, [palette])
}

export default useChapterPacingGuideTheme
