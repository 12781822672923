import PropTypes from 'prop-types'
import config from '../config'
import DiagramPoint from './DiagramPoint'

const climaxConfig = {
  x: config.apex.x,
  y: config.apex.y - config.labelAboveOffsetY - config.fontSize * 1.5,
  textAnchor: 'middle',
  width: 2 * (config.resolution.endX - config.apex.x),
  verticalAnchor: 'end',
  height:
    config.apex.y -
    config.labelAboveOffsetY -
    config.fontSize * 1.5 -
    config.fontSize,
}

const Climax = ({ children, ...rest }) => (
  <g>
    {/* biome-ignore lint/a11y/noInteractiveElementToNoninteractiveRole: remove role? */}
    <text
      className="title"
      role="presentation"
      x={config.apex.x}
      y={config.apex.y - config.labelAboveOffsetY}>
      Climax
    </text>
    <DiagramPoint
      CircleProps={{
        cx: config.apex.x,
        cy: config.apex.y,
        r: config.circleRadius,
      }}
      {...climaxConfig}
      {...rest}>
      {children}
    </DiagramPoint>
  </g>
)

Climax.propTypes = {
  children: PropTypes.node,
}

export default Climax
