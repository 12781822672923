import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import RadioGroup from '@mui/material/RadioGroup'
import Typography from '@mui/material/Typography'
import Dialog from 'common/dialogs/Dialog'
import Radio from 'common/formControls/switches/Radio'
import Html from 'common/text/Html'
import { xDifference } from 'fp/arrays'
import { isUndefined, matches } from 'fp/utils'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import PropTypes from 'prop-types'
import ImageRenderer from './ChartItem/ImageRenderer'
import { actions, findByRowColumn, itemShape, responseItemShape } from './utils'

const OptionRenderer = ({ item, item: { contentType, value } }) => {
  switch (contentType) {
    case 'image':
      return <ImageRenderer item={item} />

    default:
      return <Html body={value} />
  }
}

OptionRenderer.propTypes = {
  item: itemShape.isRequired,
}

const OptionsDialog = props => {
  const {
    dispatch,
    onClose,
    response,
    selectedColumn,
    selectedItem,
    totalOptions,
    ...rest
  } = props
  const [selectedOption, setSelectedOption] = useStateWithDynamicDefault(
    findByRowColumn(selectedItem.row, selectedItem.column)(response)?.id,
  )

  const availableOptions = totalOptions.filter(({ id }) => {
    const responseItem = response.find(matches('id', id))
    // possible options for an item include any response not found or the option is the currently selected
    return (
      isUndefined(responseItem) ||
      (responseItem.row === selectedItem.row &&
        responseItem.column === selectedItem.column)
    )
  })

  const usedOptions = xDifference(availableOptions)(totalOptions)
  const handleClick = () => {
    dispatch({
      type: actions.DROP_ITEM,
      item: {
        id: selectedOption,
        row: selectedItem.row,
        column: selectedItem.column,
      },
    })
    onClose()
  }

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      showCloseButton
      sx={{ img: { maxWidth: 150 }, 'span p': { marginTop: 0 } }}
      title={`Select answer for "${selectedColumn}"`}>
      <DialogContent>
        <RadioGroup
          onChange={({ target: { value } }) => {
            setSelectedOption(value)
          }}
          value={selectedOption || ''}>
          {availableOptions.map((option, idx) => (
            <Radio
              color={selectedOption === option.id ? 'primary' : 'secondary'}
              data-testid={`option-${idx}`}
              key={option.id}
              label={<OptionRenderer item={option} />}
              my={1}
              p={2}
              sx={{
                width: '100%',
                border: '1px solid',
                borderColor:
                  selectedOption === option.id ? 'cobalt.1' : 'border.0',
                margin: '10px 0',
                padding: '10px 0',
              }}
              value={option.id}
            />
          ))}
        </RadioGroup>
        {usedOptions.length > 0 && (
          <>
            <Typography
              component="p"
              variant="paragraph-semibold">
              Previously selected answer(s)
            </Typography>
            <List>
              {usedOptions.map(option => (
                <Box
                  border={1}
                  borderColor="border.0"
                  component="li"
                  key={option.id}
                  my={1}
                  p={2}>
                  <OptionRenderer item={option} />
                </Box>
              ))}
            </List>
          </>
        )}
        <Button
          data-testid="option-submit"
          disabled={isUndefined(selectedOption)}
          onClick={handleClick}
          style={{ margin: '10px auto 20px', display: 'block' }}>
          Select answer
        </Button>
      </DialogContent>
    </Dialog>
  )
}

OptionsDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  response: PropTypes.arrayOf(responseItemShape).isRequired,
  selectedColumn: PropTypes.string.isRequired,
  selectedItem: itemShape.isRequired,
  totalOptions: PropTypes.arrayOf(itemShape).isRequired,
}

export default OptionsDialog
