import { styled } from '@mui/material/styles'
import TextField from 'common/formControls/textInputs/TextField'
import withProps from 'hoc/withProps'

const StyledTextField = styled(TextField)(
  ({
    theme: {
      mixins: { rem },
    },
  }) => ({
    marginTop: 0,
    padding: rem(0, 0, 0.5),
  }),
)

const TableTextField = withProps(StyledTextField, {
  multiline: true,
  variant: 'standard',
})

export default TableTextField
