import { CONTENT_TYPE_VOCABULARY } from 'core/consts'
import ContentBreadcrumb from 'hss/sections/Breadcrumbs/ContentBreadcrumb'
import PropTypes from 'prop-types'
import { createElement } from 'react'
import {
  assessmentUrl,
  classroomUrl,
  curriculumUrl,
  libraryUrl,
  settingsUrl,
} from 'routing/consts'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import BreadcrumbsRenderer from '../BreadcrumbsRenderer'

const AnyContentType = ({ match }) => {
  const {
    params: { contentType },
  } = match
  return createElement(ContentBreadcrumb(contentType), { contentType, match })
}
AnyContentType.propTypes = {
  match: PropTypes.object.isRequired,
}

const breadcrumbConfig = [
  // ---------------------------------------------------------------------------
  // Assessment
  // ---------------------------------------------------------------------------

  { path: `${assessmentUrl}`, breadcrumb: 'Assess' },

  // ---------------------------------------------------------------------------
  // Classroom
  // ---------------------------------------------------------------------------

  { path: `${classroomUrl}/assignments`, breadcrumb: 'Assignments' },

  { path: `${classroomUrl}/assignments/:assignmentId`, breadcrumb: 'Report' },

  {
    path: `${classroomUrl}/assignments/:assignmentId/edit`,
    breadcrumb: 'Edit Assignment',
  },

  { path: `${classroomUrl}/districts`, breadcrumb: 'Districts' },

  { path: `${classroomUrl}/districts/create`, breadcrumb: 'Create' },

  { path: `${classroomUrl}/districts/:districtId`, breadcrumb: 'Members' },

  { path: `${classroomUrl}/groups`, breadcrumb: 'Groups' },

  { path: `${classroomUrl}/groups/create`, breadcrumb: 'Create' },

  { path: `${classroomUrl}/groups/:groupsId`, breadcrumb: 'Members' },

  { path: `${classroomUrl}/schools`, breadcrumb: 'Schools' },

  { path: `${classroomUrl}/schools/:schoolId`, breadcrumb: 'School' },

  { path: `${classroomUrl}/schools/create`, breadcrumb: 'Create' },

  { path: `${classroomUrl}/students`, breadcrumb: 'Students' },

  { path: `${classroomUrl}/students/:studentId`, breadcrumb: 'Report' },

  // ---------------------------------------------------------------------------
  // Curriculum
  // ---------------------------------------------------------------------------

  { path: curriculumUrl, breadcrumb: 'Curriculum' },

  { path: `${curriculumUrl}/search`, breadcrumb: 'Search' },

  {
    path: `${curriculumUrl}/:contentType/:contentId`,
    breadcrumb: AnyContentType,
  },

  // ---------------------------------------------------------------------------
  // Library
  // ---------------------------------------------------------------------------

  { path: libraryUrl, breadcrumb: 'Library' },

  { path: `${libraryUrl}/search`, breadcrumb: 'Search' },

  {
    path: `${libraryUrl}/${CONTENT_TYPE_VOCABULARY}/:vocabId`,
    breadcrumb: 'Definition',
  },

  // ---------------------------------------------------------------------------
  // Settings
  // ---------------------------------------------------------------------------

  { path: settingsUrl, breadcrumb: 'Settings' },

  { path: `${settingsUrl}/account`, breadcrumb: 'Account' },

  { path: `${settingsUrl}/assignments`, breadcrumb: 'Assignments & Grading' },

  { path: `${settingsUrl}/accessibility`, breadcrumb: 'Accessibility' },

  { path: `${settingsUrl}/integrations`, breadcrumb: 'Integrations' },

  { path: `${settingsUrl}/notifications`, breadcrumb: 'Notifications' },

  // ---------------------------------------------------------------------------
]

const NavBreadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(breadcrumbConfig, {
    disableDefaults: true,
  })

  return <BreadcrumbsRenderer breadcrumbs={breadcrumbs} />
}

export default NavBreadcrumbs
