import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import MenuButton from 'common/menus/MenuButton'
import {
  ABILITY_PREVIEW_AS_STUDENT,
  ABILITY_TEACHER_INTERFACE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
} from 'core/consts'
import { isDefined, matchesOneOf, not } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import ContentRestrictionControls from 'hss/views/Curriculum/ContentRestrictionControls'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import InsertPinButton from 'routing/TraverseRoute/Pinning/InsertPinButton'
import InsertPinMenuItem from 'routing/TraverseRoute/Pinning/InsertPinMenuItem'
import RemovePinButton from 'routing/TraverseRoute/Pinning/RemovePinButton'
import useIsLargeEnoughForPinning from 'routing/TraverseRoute/useIsLargeEnoughForPinning'
import LogoutMenuItems from 'routing/shells/LogoutMenuItems'
import { getViewerTopLevelContent } from 'selectors/contentViewer'
import { getPinnedLocation } from 'selectors/routing'
import { contentViewerContext } from '../../ContentViewerProvider'
import AssignContentMenuItem from './AssignContentMenuItem'
import ContentInsertionMenuItem from './ContentInsertion/ContentInsertionMenuItem'
import EditCurrentContentMenuItem from './EditCurrentContentMenuItem'
import ExitContentViewMenuItem from './ExitContentViewMenuItem'
import PresenterModeMenuItem from './PresenterModeMenuItem'
import PresenterModeToggleButton from './PresenterModeToggleButton'
import SimplifiedUserMenu from './SimplifiedUserMenu'
import SubmitAssignmentButton from './SubmitAssignmentButton'
import TeacherEditionMenuItem from './TeacherEditionMenuItem'

const RightSide = () => {
  const isLargeEnoughForPinning = useIsLargeEnoughForPinning()
  const couldUseMenu = useAbilityCheck(
    ABILITY_TEACHER_INTERFACE,
    ABILITY_PREVIEW_AS_STUDENT,
  )
  const pinnedLocation = useSelector(getPinnedLocation)
  const { subsection } = useContext(contentViewerContext) || {}
  const isEcho = subsection?.contentType === CONTENT_TYPE_ECHO
  const isPinnableContentType = compose(
    not,
    matchesOneOf('contentType', [CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE]),
  )(subsection)
  const topLevelContent = useSelector(getViewerTopLevelContent) || subsection

  const bodyVariant = isEcho ? 'body2' : 'body1'
  const buttonProps = {
    color: isEcho ? 'primary' : 'secondary',
    'data-bodyvariant': bodyVariant,
  }

  const isEditingAssignment = Boolean(useContext(assignmentEditContext))
  const isPinnedContent = useIsPinnedContent()

  const currentlyPinned = isDefined(pinnedLocation) && isPinnedContent
  const renderAsMenu = couldUseMenu && !currentlyPinned

  return (
    <Stack direction="row">
      <PresenterModeToggleButton {...buttonProps} />

      <ContentRestrictionControls content={subsection} />

      {renderAsMenu ? (
        <MenuButton
          {...buttonProps}
          data-testid="user-menu"
          label="Viewer options menu">
          <InsertPinMenuItem />
          <PresenterModeMenuItem />
          <TeacherEditionMenuItem />

          <ContentInsertionMenuItem />

          <Divider />

          <AssignContentMenuItem />
          <EditCurrentContentMenuItem />
          <ExitContentViewMenuItem content={topLevelContent} />

          <Divider />

          <LogoutMenuItems />
        </MenuButton>
      ) : (
        <>
          {!currentlyPinned && <SubmitAssignmentButton {...buttonProps} />}

          {isLargeEnoughForPinning &&
          !isEditingAssignment &&
          isPinnableContentType ? (
            <div>
              {currentlyPinned ? <RemovePinButton /> : <InsertPinButton />}
              {!currentlyPinned && <SimplifiedUserMenu {...buttonProps} />}
            </div>
          ) : (
            <SimplifiedUserMenu {...buttonProps} />
          )}
        </>
      )}
    </Stack>
  )
}

export default RightSide
