import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { componentShape } from 'core/shapes'
import { get } from 'fp/objects'
import { isString } from 'fp/strings'
import withContainerQuerySize from 'hoc/withContainerQuerySize'
import PropTypes from 'prop-types'
import { Children, createElement, isValidElement } from 'react'

export const listVariant = {
  'callout-question': 'standard',
  'numbered-card': 'grid-3',
  'ordered-feature-list': 'grid-2',
  'source-header': 'source-header',
  unstyled: 'unstyled',
}

const listItemVariant = {
  'numbered-card': 'numbered-card',
  'ordered-feature-list': 'objectives',
  'source-header': 'source-header',
  unstyled: 'unstyled',
}

const ContainerList = withContainerQuerySize(List)

const ListElement = ({ children, component, variant }) => {
  const items = Children.map(children, child => {
    if (!isValidElement(child) || child.type !== 'li') {
      return null
    }
    // we *may* need to further process the children (for links and such)
    return <span>{child.props.children}</span>
  })

  const first = Children.toArray(children).filter(s => !isString(s))[0]

  const itemsVariant = get('data-variant')(first?.props || {})

  const variantToUse = listVariant[itemsVariant || variant] || 'standard'

  if (variantToUse === 'standard')
    return createElement(component, { className: 'standard' }, children)

  return (
    <ContainerList
      component={component}
      variant={variantToUse}>
      {items.map((item, idx) => (
        <ListItem
          key={idx}
          variant={listItemVariant[itemsVariant || variant]}>
          {item}
        </ListItem>
      ))}
    </ContainerList>
  )
}

ListElement.propTypes = {
  children: componentShape.isRequired,
  component: PropTypes.oneOf(['ol', 'ul']).isRequired,
  variant: PropTypes.oneOf(['', 'none', ...Object.keys(listVariant)]),
}

export default ListElement
