import Button from '@mui/material/Button'
import AudioPlayer from 'common/avclub/audio/AudioPlayer'
import ConfirmButton from 'common/formControls/buttons/ConfirmButton'
import { INTERACTIVE_TYPE_AUDIO_RECORDING } from 'core/consts'
import { get, set } from 'fp/objects'
import { when } from 'fp/utils'
import useAudioRecorder from 'hooks/useAudioRecorder'
import useFileUpload from 'hooks/useFileUpload'
import { ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import { msToTime } from 'locale/i18n'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Mic, RefreshCw, Trash } from 'react-feather'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import RecordingDialog from './RecordingDialog'
import Status from './Status'

const AudioRecording = ({ maxLength = 60 }) => {
  const {
    completed,
    interaction,
    isGrading,
    onInteract,
    setSubmitEnabled,
    submittable,
  } = useContext(interactiveContext)

  const uploadsMap = get('uploadsMap')(interaction)

  const onUploadSuccess = useCallback(
    response => {
      onInteract(set('uploadsMap', { audio: response }))
    },
    [onInteract],
  )

  const { startUpload, status: uploadStatus } = useFileUpload({
    onUploadSuccess,
  })

  const [open, setOpen] = useState(false)

  const [errorMsg, setErrorMsg] = useState('')

  const handleStop = useCallback(
    file => {
      startUpload({
        file,
        uploadType: 'audio',
      })
    },
    [startUpload],
  )

  const {
    deleteRecording,
    mediaRecorderState,
    startRecording,
    stopRecording,
    visualizerAudioData,
    visualizerAudioUrl,
  } = useAudioRecorder({
    initialAudioData: undefined,
    onStop: handleStop,
  })

  const audioAssetUrl = visualizerAudioUrl || uploadsMap?.audio?.url

  useEffect(() => {
    setSubmitEnabled(
      (!mediaRecorderState || mediaRecorderState === 'inactive') &&
        (uploadStatus === SUCCESS ||
          (uploadStatus === IDLE && !!audioAssetUrl)),
    )
  }, [audioAssetUrl, mediaRecorderState, setSubmitEnabled, uploadStatus])

  useEffect(() => {
    when(
      uploadStatus === ERROR,
      setErrorMsg,
      'There was an error uploading your recording. Please try again.',
    )
  }, [uploadStatus])

  // this is what's shown in the UI but not what's tracked in the callback
  // due to react's state variables being stale in callbacks
  const [numSecondsDisplay, setNumSecondsDisplay] = useState('00:00')

  // this is what is actually used for counting as it won't get stale
  const numSecondsToCount = useRef()

  // the interval for the 60-second timer
  const intervalId = useRef()

  const handleDelete = useCallback(() => {
    onInteract(set('uploadsMap', {}))
    deleteRecording()
  }, [deleteRecording, onInteract])

  const handleRecordingStop = useCallback(() => {
    clearInterval(intervalId.current)
    setOpen(false)
    stopRecording()
  }, [stopRecording])

  const handleRecordingStart = async () => {
    setErrorMsg(undefined)
    setOpen(true)
    await startRecording()
      .then(() => {
        numSecondsToCount.current = 0
        setNumSecondsDisplay('00:00')
        intervalId.current = setInterval(() => {
          numSecondsToCount.current += 1

          setNumSecondsDisplay(msToTime(numSecondsToCount.current * 1000))

          if (numSecondsToCount.current > maxLength) {
            handleRecordingStop()
          }
        }, 1000)
      })
      .catch(e => {
        setOpen(false)
        if (e.name === 'NotAllowedError') {
          setErrorMsg(
            "In order to record, your device's settings must allow the Traverse app to access the microphone.",
          )
        } else {
          setErrorMsg(
            'Something went wrong when trying to start the recording. Please try again later.',
          )
        }
      })
  }

  return (
    <>
      {audioAssetUrl ? (
        <>
          {/* there is a recording available */}
          {Boolean(!isGrading && submittable) && (
            <Button
              disabled={completed}
              onClick={handleRecordingStart}
              startIcon={<RefreshCw />}
              sx={{
                marginBottom: 2,
                marginTop: 2,
              }}
              variant="secondary">
              Re-record
            </Button>
          )}

          <AudioPlayer
            narrow
            url={audioAssetUrl}
          />

          {Boolean(!isGrading && submittable) && (
            <ConfirmButton
              aria-label="Delete Recording"
              confirmationMessage="Are you sure you want to remove the audio?"
              confirmationTitle="Delete Media?"
              disabled={completed}
              onClick={handleDelete}
              startIcon={<Trash />}
              sx={{ marginTop: 2 }}>
              Delete
            </ConfirmButton>
          )}
        </>
      ) : isGrading ? (
        '(no response)'
      ) : (
        // there is no current recording available
        submittable && (
          <Button
            disabled={completed}
            onClick={handleRecordingStart}
            startIcon={<Mic />}
            variant="secondary">
            Record
          </Button>
        )
      )}

      <Status {...{ errorMsg, uploadStatus }} />

      <RecordingDialog
        {...{
          handleRecordingStop,
          maxLength,
          numSecondsDisplay,
          open,
          visualizerAudioData,
        }}
      />
    </>
  )
}

AudioRecording.propTypes = {
  maxLength: PropTypes.number,
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_AUDIO_RECORDING,
}

export default AudioRecording
