import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinkButton from 'common/navigation/links/LinkButton'
import EyebrowHeadline from 'common/text/EyebrowHeadline'
import { CONTENT_TYPE_SOURCE } from 'core/consts'
import { contentShape } from 'core/shapes'
import { labels } from 'hss/ContentBuilder/consts'
import { buildContentUrl } from 'projections/content'

const SlideContent = ({ content, content: { data, contentType, name } }) => {
  const text =
    contentType === CONTENT_TYPE_SOURCE
      ? `${data.creator}  • ${data.assetDate}  • ${data.genre}`
      : labels[contentType]
  const to = buildContentUrl('library')(
    content,
    null,
    content.parent,
    content.children?.[0],
  )

  return (
    <Box maxWidth="70%">
      <EyebrowHeadline
        eyebrow="Staff Pick"
        headlineVariant="h1"
        title={name}
      />
      <Typography
        component="div"
        my={2}
        variant="body1-semibold">
        {text}
      </Typography>
      <LinkButton
        color="secondary"
        /**
         * LinkButton's withNavigate HOC explicitly sets pointerEvents to 'auto' as an inline style;
         * this causes a bug upon initial load when used in Carousel content, where the button from one
         * slide links to content from another slide. Allowing the button to inherit pointerEvents from
         * the Carousel fixes the bug.
         */
        style={{ pointerEvents: 'inherit' }}
        sx={{ alignSelf: 'flex-start' }}
        target="_blank"
        to={to}
        variant="secondary">
        View
      </LinkButton>
    </Box>
  )
}

SlideContent.propTypes = {
  content: contentShape.isRequired,
}

export default SlideContent
