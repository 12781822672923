import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Html from 'common/text/Html'
import { INTERACTIVE_TYPE_HOTSPOT } from 'core/consts'
import { isString, maybeParseJSON } from 'fp/strings'
import { noop } from 'fp/utils'
import { useContext, useMemo } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import LongTextAlternative from '../Image/LongTextAlternative'
import Callout from './Callout'

const ImageContainer = styled('div', { name: 'Hotspot-ImageContainer' })({
  overflow: 'hidden',
  position: 'relative',
  userSelect: 'none',
  img: {
    width: '100%',
  },
})

const Hotspot = () => {
  const {
    completed,
    interactiveData: {
      callouts,
      caption,
      forceLongTextVisible = false,
      imageAltText,
      longTextAlternative,
    } = {},
    markComplete,
    uploadsMap,
  } = useContext(interactiveContext) || {}

  const parsedCallouts = isString(callouts)
    ? maybeParseJSON(callouts)
    : callouts

  const handleCalloutOpened = useMemo(
    () => (completed ? noop : markComplete),
    [completed, markComplete],
  )
  return (
    <Box position="relative">
      <ImageContainer>
        <figure>
          <img
            alt={imageAltText}
            src={uploadsMap.backdrop?.url}
          />
          {Boolean(caption) && (
            <figcaption>
              <Html body={caption} />
            </figcaption>
          )}
        </figure>

        {Boolean(longTextAlternative) && (
          <LongTextAlternative
            forceVisible={forceLongTextVisible}
            html={longTextAlternative}
            label="Image Details"
          />
        )}

        {parsedCallouts.map((callout, idx) => (
          <Callout
            callout={callout}
            idx={idx}
            imageUrl={uploadsMap[`imageUrl-${idx}`]?.url}
            key={callout.label}
            onOpened={handleCalloutOpened}
            totalCallouts={parsedCallouts.length}
          />
        ))}
      </ImageContainer>
    </Box>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_HOTSPOT,
}

export default Hotspot
