import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Carousel from 'common/carousels/Carousel'
import { paginatedSmallSlides } from 'common/carousels/configurations'
import Headline from 'common/text/Headline'
import { componentShape, contentShape } from 'core/shapes'
import { set } from 'fp/objects'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { compose } from 'redux'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import ImageItemRenderer from './ImageItemRenderer'

const StyledBox = styled(Box, { name: 'Carousel-Styles' })(
  ({
    theme: {
      palette,
      shadows,
      mixins: { important, importantPx, rem, transition },
    },
  }) => ({
    'ul.swiper-wrapper': {
      padding: 0,
      marginTop: rem(4),
      marginBottom: rem(6),
      width: 100,
      li: {
        ...transition(),
        transformOrigin: 'left',
        '&:hover': {
          transform: 'scale(1.2)',
          position: 'relative',
          zIndex: 9,
          transformOrigin: 'left',
          ...transition(),
          img: {
            display: 'flex',
            zIndex: 9,
            color: palette.shadow,
            boxShadow: shadows[3],
          },
        },
      },
    },

    span: {
      marginTop: importantPx(18),
    },

    button: {
      top: important('36%'),
    },
  }),
)

export const CarouselTitle = ({ title, subtitle }) => (
  <>
    {title}{' '}
    <Typography
      component="span"
      fontWeight={300}
      textTransform="none"
      variant="h3">
      {subtitle}
    </Typography>
  </>
)

CarouselTitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const ContentCarousel = ({
  configuration = paginatedSmallSlides,
  title,
  subtitle,
  items,
  ItemRenderer = ImageItemRenderer,
}) => {
  const { isSidebarShrunk } = useContext(sidebarContext)
  const slidesPerView = isSidebarShrunk ? 3.5 : 3.2
  const slideConfiguration = compose(
    set('slidesPerView', slidesPerView),
    set('md.slidesPerView', slidesPerView),
  )(configuration)

  return items.length > 0 ? (
    <StyledBox>
      <Headline
        mt={3}
        size={3}
        title={
          <CarouselTitle
            subtitle={subtitle}
            title={title}
          />
        }
      />

      <Carousel configuration={slideConfiguration}>
        {items.map((item, index) => (
          <ItemRenderer
            content={item}
            index={index}
            key={item.id}
          />
        ))}
      </Carousel>
    </StyledBox>
  ) : null
}

ContentCarousel.propTypes = {
  ItemRenderer: componentShape,
  configuration: PropTypes.object,
  items: PropTypes.arrayOf(contentShape).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ContentCarousel
