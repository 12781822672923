import { addDays, isAfter } from 'date-fns'
import { get } from 'fp/objects'
import { now } from 'locale/dates'
import { parseDate } from 'locale/i18n'

export const isPastMaxSubmitDate = assignment => {
  const endDate = get('endDate')(assignment)
  const allowLate = get('data.settings.allowLate')(assignment)

  if (!endDate) {
    return false
  }
  const parsed = parseDate(endDate)
  const maxSubmitDate = allowLate ? addDays(parsed, 60) : parsed
  return isAfter(now(), maxSubmitDate)
}
