import Box from '@mui/material/Box'
import { findObj } from 'fp/arrays'
import { toInt } from 'fp/strings'
import { fallbackTo } from 'fp/utils'
import useColorSet from 'hooks/useColorSet'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useContext, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { px, rem } from 'styling/theming/base/mixins'

const StaticTextControl = () => {
  const { interactionData } = useContext(interactiveContext)
  const { fontSize } = interactionData

  const targetRef = useRef()

  const colorId = toInt(interactionData.colorId)
  const colors = useColorSet('pictureThisTextBackground')
  const { contrastText = 'white', value: color = 'black' } = compose(
    fallbackTo({}),
    findObj('colorId', colorId),
  )(colors)

  useEffect(() => {
    const { style } = targetRef.current
    style.width = px(interactionData.width)
    style.height = px(interactionData.height)
    style.transform = interactionData.transform
  }, [interactionData.height, interactionData.transform, interactionData.width])

  return (
    <Box
      className={`font-size-${fontSize}`}
      sx={{
        '> div': {
          backgroundColor: color,
          color: contrastText,
        },
        '&.font-size-small': { fontSize: rem(1.4) },
        '&.font-size-medium': { fontSize: rem(1.8) },
        '&.font-size-large': { fontSize: rem(2.4) },
        '.text-overlay': {
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
          wordBreak: 'break-all',
        },
      }}>
      <div
        className="text-overlay"
        ref={targetRef}>
        {interactionData.message}
      </div>
    </Box>
  )
}

export default StaticTextControl
