import MenuItem from '@mui/material/MenuItem'
import MenuButton from 'common/menus/MenuButton'
import { ABILITY_CONTENT_EDITING, CONTENT_TYPE_COURSE } from 'core/consts'
import { contentShape } from 'core/shapes'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import { contentBuilderUrl } from 'routing/consts'

const Menu = withAbilityCheck(({ content: { id } }) => {
  const { navigate } = useNavigation()

  const handleEdit = () => {
    navigate(`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_COURSE}/${id}`)
  }

  return (
    <MenuButton label="Edit Menu">
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
    </MenuButton>
  )
}, ABILITY_CONTENT_EDITING)

Menu.propTypes = {
  content: contentShape.isRequired,
}

export default Menu
