import DateRangePicker from 'hss/views/Search/Filters/Controls/DateRangeFilter'
import FiltersContainer from 'hss/views/Search/Filters/FiltersContainer'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { useContext } from 'react'

const Filters = () => {
  const { primaryFiltersExpanded } = useContext(searchContext)

  return primaryFiltersExpanded ? (
    <FiltersContainer mt={5}>
      <DateRangePicker
        label="Creation Date"
        name="dateCreated"
      />
    </FiltersContainer>
  ) : null
}

export default Filters
