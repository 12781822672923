import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import BusySpinner from 'common/indicators/BusySpinner'
import Headline from 'common/text/Headline'
import { componentShape } from 'core/shapes'
import { searchContext } from 'hss/views/Search/SearchProvider'
import TableControlsAndFilters from 'hss/views/Search/TableControlsAndFilters'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import TableResults from './TableResults'

const CommonTableSearch = props => {
  const {
    children,
    initialSorting,
    renderActions,
    renderHeroActions,
    renderPrimaryFilter,
    renderRightSideControls,
    renderSearchControls,
    renderSecondaryFilter,
    renderStats,
    sx,
    title,
  } = props

  const { dataset } = useContext(searchContext)

  return dataset ? (
    <Container sx={{ mt: 3 }}>
      <Grid
        container
        sx={{ ...(children ? {} : { mb: 4 }), ...sx }}>
        <Grid
          item
          mb={4}
          sm={6}>
          <Headline
            mb={2}
            title={title}
          />

          {children}
        </Grid>

        <Grid
          item
          sm={6}
          textAlign="end">
          <Box>{renderHeroActions?.()}</Box>
        </Grid>
      </Grid>

      <TableControlsAndFilters
        {...{
          renderActions,
          renderPrimaryFilter,
          renderRightSideControls,
          renderSearchControls,
          renderSecondaryFilter,
        }}
      />
      {renderStats?.()}
      <TableResults initialSorting={initialSorting} />
    </Container>
  ) : (
    <BusySpinner />
  )
}

CommonTableSearch.propTypes = {
  children: PropTypes.element,
  initialSorting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  renderActions: PropTypes.func,
  renderHeroActions: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderRightSideControls: PropTypes.func,
  renderSearchControls: PropTypes.func,
  renderSecondaryFilter: PropTypes.func,
  renderStats: PropTypes.func,
  sx: PropTypes.object,
  title: componentShape.isRequired,
}

export default CommonTableSearch
