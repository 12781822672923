import { ECHO_FEATURE_RATE } from 'hss/ContentBuilder/consts'
import FeatureFootnotes from '../FeatureFootnotes'
import FeatureHeadline from '../FeatureHeadline'
import AllReviews from './AllReviews'
import CurrentReview from './CurrentReview'
import Preamble from './Preamble'

const feature = ECHO_FEATURE_RATE

const FeatureRate = () => (
  <>
    <FeatureHeadline title="Rate">
      <Preamble />

      <CurrentReview />

      <AllReviews />
    </FeatureHeadline>

    <FeatureFootnotes feature={feature} />
  </>
)

export default FeatureRate
