import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Html from 'common/text/Html'
import PropTypes from 'prop-types'

const StyledBox = styled(Box)({
  p: {
    marginBottom: 0,
  },
})

const Explanation = ({ explanation, isCorrectAnswer }) => (
  <StyledBox
    pb={1}
    pr={3}>
    <Typography
      color={isCorrectAnswer ? 'success.dark' : 'inherit'}
      variant="body1-semibold">
      {isCorrectAnswer ? 'Correct. ' : 'Incorrect. '}
    </Typography>
    <Html
      body={explanation || ''}
      substituteInlineBlocks
    />
  </StyledBox>
)

Explanation.propTypes = {
  explanation: PropTypes.string,
  isCorrectAnswer: PropTypes.bool.isRequired,
}

export default Explanation
