import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'

import LineP from 'apps/hss/sections/contentBlocks/interactives/LineP'
import CorrectnessIcon from 'common/indicators/CorrectnessIcon'
import { DND_TYPE_CALLOUT, DND_TYPE_CALLOUT_SWATCH } from 'core/consts'
import { imageTitleDragCalloutShape } from 'core/shapes'
import { findObj } from 'fp/arrays'
import { get } from 'fp/objects'
import { percent } from 'fp/strings'
import dashedLine from 'hss/images/controls/image-title-drag/dashed-line.svg?url'
import Point from 'hss/images/controls/image-title-drag/point.svg'
import { useContext } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import DragItem from '../DragItem'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'

// NOTE: This shares a lot of code with /hss/ContentBuilder/interactives/ImageTitleDrag/Callouts/Callout.js
// It may or may not be worth normalizing the two files.

const Container = styled(Box, {
  name: 'ImageTitleDrag-Callout',
  shouldForwardProp: prop => prop !== 'darkBackdropImage',
})(
  ({
    theme: {
      breakpoints,
      mixins: { borderS, important, px, rem },
      palette,
      shadows,
    },
    darkBackdropImage,
  }) => {
    const defaultColor = darkBackdropImage
      ? palette.common.white
      : palette.grey[0]
    const highlight = palette.cobalt[1]
    const base = {
      alignItems: 'center',
      background: palette.background.default,
      ...borderS(palette.grey[4]),
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      userSelect: 'none',
    }

    return {
      '.callout': {
        ...base,
        transform: 'translate(-50%, -50%)',
        minWidth: rem(10),
        minHeight: rem(4.8),
        [breakpoints.down('xs')]: { minWidth: rem(7), minHeight: rem(3) },
        [breakpoints.up('sm')]: { minWidth: rem(10), minHeight: rem(4.8) },
        li: {
          margin: important(0),
        },
        '&.hovering': {
          borderColor: highlight,
          backgroundColor: highlight,
        },
        '&.label': {
          border: 'none',
          minWidth: 'auto',
          li: {
            margin: important(0),
            width: percent(100),
            div: {
              alignItems: 'center',
            },
          },
        },
      },
      '.callout-line': {
        maskImage: `url(${dashedLine})`,
        backgroundColor: defaultColor,
        boxShadow: shadows.activeBorder,
        height: 2,
        '&.hovering': {
          maskImage: `url(${dashedLine})`,
          backgroundColor: highlight,
          height: 2,
          boxShadow: 'none',
        },
      },
      '.arrow': {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',

        svg: {
          borderRadius: percent(50),
        },

        circle: {
          fill: defaultColor,
        },

        '&.hovering circle': {
          fill: highlight,
        },
      },
      '.answer': {
        lineHeight: px(24),
      },
    }
  },
)

const Callout = ({
  callout,
  callouts,
  darkBackdropImage,
  onChange,
  ...rest
}) => {
  const { isGrading, submittable } = useContext(interactiveContext)
  const showAnswerKey = useIsInAnswerKeyContext() || isGrading || !submittable

  const [{ isOver }, dropRef] = useDrop({
    accept: [DND_TYPE_CALLOUT, DND_TYPE_CALLOUT_SWATCH],
    drop: ({ id }) => onChange(id, callout.id),
    canDrop: () => !showAnswerKey,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      draggedItemType: monitor.getItemType(),
    }),
  })

  const { calloutCoord, droppedId, labelCoord } = callout

  const label = Maybe.fromNull(findObj('id', droppedId)(callouts))
    .map(get('label'))
    .orNull()

  const [, dragRef] = useDrag({
    item: { id: droppedId, label },
    type: DND_TYPE_CALLOUT_SWATCH,
  })

  const [left, top] = labelCoord.split(',').map(Number.parseFloat)
  const [arrowX, arrowY] = calloutCoord.split(',').map(Number.parseFloat)
  const centerX = left
  const centerY = top

  const boxStyle = {
    top: percent(top),
    left: percent(left),
  }

  const arrowStyle = {
    top: percent(arrowY),
    left: percent(arrowX),
  }

  // TODO: Use actual state instead of these to update styles
  const isCorrect = false
  const actualAnswer = 'Greece'

  const iconColor = isCorrect ? 'success.main' : 'error.main'

  return (
    <Container darkBackdropImage={darkBackdropImage}>
      <LineP
        className={cl({ 'callout-line': true, hovering: isOver })}
        from={{ x: centerX, y: centerY }}
        hovering={isOver}
        to={{ x: arrowX, y: arrowY }}
        {...rest}
      />

      <div
        className={cl({ arrow: true, hovering: isOver })}
        style={arrowStyle}>
        <Point />
      </div>

      <div
        className={cl({ callout: true, hovering: isOver, label })}
        ref={dropRef}
        style={boxStyle}>
        {showAnswerKey ? (
          <ListItem
            data-correct={isCorrect ? 'yes' : 'no'}
            variant="answer">
            <Stack
              alignItems="center"
              direction="row"
              pl={0}
              pr={1}
              spacing={1}>
              <CorrectnessIcon
                isCorrect={isCorrect}
                stroke={iconColor}
              />
              <div className={cl({ answer: true })}>
                {label || '(Blank)'}
                {!isCorrect && <div>{`Expected: ${actualAnswer}`}</div>}
              </div>
            </Stack>
          </ListItem>
        ) : label ? (
          <DragItem
            className={cl({ hovering: isOver })}
            ref={dragRef}>
            {label}
          </DragItem>
        ) : (
          ''
        )}
      </div>
    </Container>
  )
}

Callout.propTypes = {
  callout: imageTitleDragCalloutShape.isRequired,
  callouts: PropTypes.arrayOf(imageTitleDragCalloutShape).isRequired,
  darkBackdropImage: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  parentHeight: PropTypes.number.isRequired,
  parentWidth: PropTypes.number.isRequired,
}

export default Callout
