import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Dialog from 'common/dialogs/Dialog'
import TextField from 'common/formControls/textInputs/TextField'
import { equals, set } from 'fp/objects'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { orderedSections } from './config'

const VennDiagramDialog = ({
  canHeadlinesBeEdited,
  section,
  hideSampleResponse,
  index,
  readOnly,
  onClose,
  onChange,
  ...rest
}) => {
  const { header, response, sampleResponse } = section
  const { description, key } = orderedSections.find(({ circleIds }) =>
    equals(circleIds)(section.circleIds),
  )
  const [userResponse, setUserResponse] = useState({
    header,
    response,
    circleIds: section.circleIds,
    sampleResponse,
  })

  const handleChange =
    field =>
    ({ target: { value } }) => {
      setUserResponse(prev => set(field, value)(prev))
    }

  const handleDone = useCallback(() => {
    onChange(`sections.${index}`, userResponse)
    onClose()
  }, [index, onChange, onClose, userResponse])

  return (
    <Dialog
      onClose={onClose}
      role="alertdialog"
      showCloseButton
      {...rest}
      PaperProps={{
        sx: {
          minHeight: 400,
        },
      }}
      title={`Edit ${description} Section`}>
      <DialogContent>
        {Boolean(
          ['bottom', 'left', 'right'].includes(key) && canHeadlinesBeEdited,
        ) && (
          <TextField
            label="Header"
            name="header"
            onChange={handleChange('header')}
            value={userResponse.header}
          />
        )}
        <TextField
          label="Thoughts"
          multiline
          name="thoughts"
          onChange={handleChange('response')}
          value={userResponse.response}
        />
        {Boolean(!hideSampleResponse) && (
          <TextField
            label="Sample Response"
            multiline
            name="sampleResponse"
            onChange={handleChange('sampleResponse')}
            value={userResponse.sampleResponse}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDone}
          variant="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

VennDiagramDialog.propTypes = {
  canHeadlinesBeEdited: PropTypes.bool,
  hideSampleResponse: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  section: PropTypes.shape({
    header: PropTypes.string,
    circleIds: PropTypes.array.isRequired,
    response: PropTypes.string,
    sampleResponse: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
}

export default VennDiagramDialog
