import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { mapKeys } from 'fp/objects'
import { prefix } from 'fp/strings'
import { important as allImportant, border, position } from 'polished'
import {
  containedPaddingLeft,
  containedPaddingRight,
  featuredContentMaxWidth,
} from 'styling/theming/base'
import { acuminTextSizeR, importantPx } from 'styling/theming/base/mixins'

const StyledContent = styled(Box, { name: 'ContentViewer-StyledContent' })(
  ({
    theme: {
      mixins: {
        featuredContainer,
        featuredContentContainer,
        featuredMaxWidth,
        important,
        rem,
        transition,
      },
      palette,
      shadows,
      typography,
      typography: { variants },
      zIndex,
    },
  }) => ({
    'table.echo-blocks': {
      background: palette.text.secondary,
      color: palette.primary.contrastText,
      '.content-heading': {
        color: palette.accent.dark,
        textAlign: 'center',
      },
    },

    'div[data-layouttablecontainer]': {
      containerType: 'inline-size',
      overflowX: 'auto',

      '@container (max-width: 500px)': {
        'table[data-layouttable]': {
          width: '100%',
          display: 'block',

          thead: {
            display: 'none',
          },
          tr: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            ...border('bottom', 2, 'solid', palette.border.dark),
          },
          td: {
            display: 'flex',
            justifyContent: 'space-around',
          },
          'td:before': {
            content: 'attr(data-label)',
            position: 'absolute',
            left: 0,
            width: '50%',
            fontWeight: 'bold',
          },
        },
      },
    },

    '> table:not(.preview-layout) tr:first-of-type td:first-of-type div.block-wrapper-style-target':
      {
        marginTop: rem(9.6),
        '> p:first-of-type:empty': {
          display: 'none',
        },
      },

    '.MuiTypography-advanced-heading': {
      ...typography.h2,
      ...featuredContentContainer(),
    },

    'table[data-layouttable]': {
      ...featuredContentContainer(),
      marginBottom: rem(2.2),
    },

    '.MuiContainer-root .MuiContainer-root, .MuiBox-root .MuiContainer-root': {
      paddingLeft: important(0),
      paddingRight: important(0),
    },

    // prevents table from expanding beneath drawers
    'table.block-layout': {
      tableLayout: 'fixed',
      width: '100%',
    },

    'table.block-layout, .block-layout-wrapper': {
      ...transition(),
      margin: 0,

      'td.block-layout:first-of-type:not(:only-child)': {
        '> div > div:not(.block-full-width):not([variant="ordered-feature-list"]):not([variant="source-introduction"]):not([data-toolbarcontainer="subsection"])':
          {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          },
      },

      'td.block-layout:nth-of-type(2)': {
        ...transition(),
        width: 0,
        maxWidth: 0,
        opacity: 0,
      },

      '&.displayingTeContent.sideBySideTeAllowed': {
        'td.block-layout:nth-of-type(2)': {
          opacity: 1,
          width: '33.3%',
          maxWidth: '33.3%',
          '> div.te-content': {
            marginTop: rem(9.6), // to align with starting block se content
          },
        },
      },

      '&.videosInTheaterMode .block-partially-contained': {
        paddingLeft: important(0),
        paddingRight: important(0),
        transition: 'padding 0.3s',
      },

      '&.videosInTheaterMode .video-js': {
        paddingTop: 0,
        height: 'auto',
        aspectRatio: '16 / 9',
        maxHeight: '85vh',
      },

      'td.block-layout': {
        verticalAlign: 'top',
      },
    },

    '[data-toolbarcontainer="subsection"] > div': {
      paddingRight: important(0),
    },

    '.block-layout': {
      position: 'relative', // affects positioning of admin's edit buttons
    },

    '.block-layout-wrapper': {
      position: 'relative',
      marginTop: rem(9.6),

      '> :not(.block-full-width)': {
        marginRight: 'auto',
        marginLeft: 'auto',
      },

      ':not(.videosInTheaterMode) [data-contentsubtype="video"]':
        featuredContentContainer(),

      '[data-contentsubtype="audio"]': featuredContentContainer(),

      '.block-full-width .interactive': {
        position: 'relative',
        zIndex: zIndex.appBar - 1,
        '&.boosted': {
          zIndex: 'unset',
        },
      },

      '.block-full-width.float-left': {
        float: 'left',
        maxWidth: '55%',
        minWidth: '55%',
        position: 'relative',
      },
      '.block-full-width.float-right': {
        float: 'right',
        maxWidth: '55%',
        minWidth: '55%',
        position: 'relative',
      },
      '.block-full-width.float-none': {
        clear: 'both',

        '[data-contentsubtype="chart"]': {
          marginBottom: rem(3),
        },
      },

      '.block-full-width.float-left, .block-full-width.float-right': {
        '[variant="callout-question"]': {
          width: 'unset',
        },
        '[data-contentsubtype="video"] > div': {
          marginTop: 0,
        },
        '.expandable [data-expansionbutton="true"]': {
          zIndex: zIndex.appBar - 1,
        },
        '.interactive': {
          paddingTop: 0,
        },
        '.interactive-video': {
          marginTop: 0,
          '.vjs-current-time': {
            display: 'none',
          },
          '.vjs-volume-slider': {
            maxWidth: 36,
          },
          '.ss-settings-button button': {
            paddingRight: 0,
          },
          '.ss-theater-toggle': {
            display: 'none',
          },
          '.vjs-fullscreen-control': {
            width: 24,
            marginRight: 12,
          },
          '& > div': {
            padding: 0,
          },
        },
      },

      'blockquote[data-float="left"], figure.float-left': {
        maxWidth: '55%',
        minWidth: '55%',
        position: 'relative',
        float: 'left',
      },

      'blockquote[data-float="right"], figure.float-right': {
        maxWidth: '45%',
        minWidth: '45%',
        position: 'relative',
        float: 'right',
        marginLeft: rem(3.2),
      },
    },

    '.interactive-draw-tool': {
      '.tl-container': {
        zIndex: 1,
        top: 'initial',
        left: 'initial',
        position: 'absolute',
      },
      '.tl-canvas': {
        boxShadow: shadows[5],
      },
      '.MuiToolbar-root': {
        ...position('absolute', '15%', null, null, 0),
        zIndex: 2,
        flexDirection: 'column',
        gap: 24,
        padding: 0,
        background: 'none',
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          marginLeft: important(0),
        },
        '.MuiToggleButtonGroup-root': {
          flexDirection: 'column',
          boxShadow: shadows[2],
          button: {
            marginTop: -1,
            ...border('left', 1, 'solid', palette.grey[0]),
            '&.Mui-selected:hover': {
              backgroundColor: important(palette.text.secondary),
            },
          },
        },
      },
      '.MuiToggleButton-root, .MuiButtonBase-root': {
        boxShadow: shadows[3],
      },

      '.MuiButtonBase-root': {
        alignSelf: 'baseline',
      },
    },

    'table:not(.block-layout):not([variant])': {
      borderCollapse: 'collapse',
      marginRight: 'auto',
      marginLeft: 'auto',

      caption: {
        marginBottom: 12,
      },

      th: {
        minWidth: 120,
        verticalAlign: 'middle',
        ...variants['nav-item-uppercase-semibold'],
        color: palette.text.secondary,
        background: palette.grey[5],
      },

      td: {
        verticalAlign: 'top',
      },

      'th, td': {
        ...border(1, 'solid', palette.border[0]),
        padding: 16,
      },
    },

    '.linebreak': {
      display: 'block',
      minHeight: rem(0.5),
    },
    '[data-contentsubtype="flipbook"]': {
      position: 'relative',
    },
    'ol[variant="source-header"], ul[variant="source-header"]': {
      display: 'flex',
      padding: `0 ${containedPaddingRight} 0 ${containedPaddingLeft}`,
    },

    '[variant="callout-question"]': {
      'p, li:not([variant="source-header"])': {
        ...typography.variants.callout,
      },
      '.MuiBox-root': {
        'div p': {
          marginBottom: rem(1.2),
        },
        'div ol, div ul': {
          paddingLeft: rem(6),
          '.standard': {
            paddingLeft: rem(-6),
          },
          'ol, ul': {
            marginTop: rem(1),
          },
          '& > li:last-child': {
            marginBottom: rem(1.2),
          },
        },
      },
    },

    'li:not([variant="source-header"])': {
      ...typography.variants['feature-paragraph'],
    },

    '.featured-container': {
      ...featuredContainer(),
      marginBlockStart: 0,
    },

    'p, ol, ul': {
      maxWidth: featuredContentMaxWidth,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBlockStart: 0,
    },

    'ul.standard, ol.standard': {
      paddingLeft: rem(8),
      '.standard': {
        paddingLeft: rem(-8),
      },
      p: {
        paddingLeft: 0,
        marginBottom: 0,
      },
    },

    '.block-full-width p': {
      paddingRight: 'unset',
      paddingLeft: 'unset',
    },

    'p.credit': {
      ...border('top', 1, 'solid', palette.border[1]),
      ...variants.body1,
      color: palette.text.secondary,
      paddingTop: 16,
      marginBottom: rem(4.8),
      maxWidth: importantPx(featuredContentMaxWidth),
      paddingLeft: 0,
      paddingRight: 0,
    },

    'blockquote.pullQuote': { clear: 'both' },

    'figure.quote-figure': {
      ...border('left', 2, 'solid', palette.accent.main),
      margin: rem(3.2, 'auto', 4.8),
      ...featuredContainer(),
      paddingLeft: rem(4.8),
      blockquote: {
        ...variants['feature-paragraph'],
        borderStyle: 'none',
        margin: 0,
        padding: 0,
      },
      figcaption: {
        paddingTop: 24,
      },
      '&.pullQuote': {
        position: 'relative',
        marginTop: rem(9.6),
        '&::before': {
          content: '"“"',
          position: 'absolute',
          left: rem(-0.2),
          top: rem(-5),
          color: palette.accent.main,
          ...acuminTextSizeR(9.6, '95%', 900),
        },
        blockquote: {
          ...typography.variants['pull-quote'],
          position: 'relative',
          top: rem(-4),
          textTransform: 'none',
        },
        figcaption: {
          ...typography.variants['pull-quote-credit'],
          paddingTop: 0,
        },
      },
    },

    'h2.MuiTypography-advanced-heading + ol[variant="grid-3"]': {
      // numbered-cards normally have a higher top margin, but it's removed if
      // preceded by an advanced heading
      // https://www.figma.com/file/C5osccY8Uh5aSPyriK9ZsY/hss-components?node-id=5159%3A42152
      marginTop: 0,
    },

    h6: {
      marginBottom: rem(3),
    },

    '[variant="ordered-feature-list"]': {
      paddingRight: 0,
      paddingLeft: 0,
      '.block-full-width': {
        marginRight: containedPaddingRight,
        marginLeft: containedPaddingLeft,
      },
      '.content-heading': {
        marginTop: important(0),
      },
    },

    '[data-indentfirstline="true"]': {
      textIndent: rem(4),
    },

    ol: {
      listStyleType: 'decimal',
      '> ol': {
        listStyleType: 'lower-alpha',
        '> ol': {
          listStyleType: 'lower-roman',
        },
      },

      '&[variant="grid-2"]': {
        maxWidth: importantPx(featuredContentMaxWidth),
      },

      '&[variant="grid-3"]': {
        maxWidth: importantPx(featuredContentMaxWidth),
        marginBottom: rem(9.6),
        marginTop: rem(9.6),
        li: {
          flexGrow: 1,
        },
      },
    },

    '[data-variant="research-link"]': {
      display: important('grid'),
      gridTemplateColumns: 'auto auto',
      gap: rem(4.8),
      justifyContent: important('space-between'),
      position: 'relative',

      '&[data-viewport="xs"]': {
        display: important('block'),
      },

      '& > div': {
        gap: rem(0),
      },
    },

    '.source-title': featuredContentContainer(),

    '.content-heading': {
      maxWidth: featuredContentMaxWidth,
      margin: rem(2.4, 'auto', 1.8),
    },

    '.sub-heading': featuredContentContainer(),

    '[data-variant="research-link"], [variant="research-link"]': {
      maxWidth: featuredMaxWidth,
      '.sub-heading': {
        ...allImportant(typography.h6),
        textTransform: 'none',

        a: {
          color: palette.primary.contrastText,
        },
      },
    },

    '[data-variant="chapter-recap"]': {
      '&[data-viewport="xs"] [variant="chapter-recap"]': {
        columnCount: 'auto',
      },
      '[variant="chapter-recap"]': {
        columnCount: 2,
        columnGap: rem(4),
        display: important('block'),
        margin: 'auto',
        marginBottom: rem(4),

        li: {
          fontSize: rem(1.4),
          lineHeight: rem(2),
        },
        '> .block-full-width': {
          breakAfter: 'column',
          h2: { marginTop: 0 },
          '~ .block-full-width': {
            breakAfter: 'auto',
          },
        },
        '> .block-full-width > p:empty': { display: 'none' },
        '.block-partially-contained.block-partially-contained, .MuiContainer-root':
          {
            paddingLeft: important(0),
            paddingRight: important(0),
          },

        '.content-heading': {
          ...variants['list-header'],
          marginTop: rem(2.4),
          marginBottom: rem(1.8),
        },

        '.interactive-image': {
          /**
           * TODO:
           * We should never turn off content based on their relative positions within
           * the DOM as that's too fragile and difficult to diagnose when things go wrong.
           * It's also difficult to maintain as we add future features and changes.
           *
           * It's better to place `data-something` attributes or some other, more tangible
           * way of targeting nodes.
           * I've added the `not(.image-no-gutters)` selector as a short-term fix, but it,
           * and also the bit about gutters should be refactored.
           */
          margin: 0,
          padding: 0,
          'div.styled-image-container': {
            // ignore gutters
            padding: important(0),
            marginBottom: rem(2),
          },
          figcaption: {
            display: 'none',
          },
          '.image-wrapper': {
            maxWidth: 'unset',
            width: '100%',
            background: 'none',
            '&::after': { content: 'none' }, // no swatch
          },
          img: {
            width: '100%',
          },
        },
      },
    },
    ...mapKeys(key => prefix('.')(key))(variants),
  }),
)

export default StyledContent
