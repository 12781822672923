import { INTERACTIVE_TYPE_GROUP_AND_SORT } from 'core/consts'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import Options from './Options'
import { GroupAndSortProvider } from './context'

const GroupAndSort = withQuestionPrompt(() => (
  <GroupAndSortProvider>
    <Options />
  </GroupAndSortProvider>
))

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_GROUP_AND_SORT,
}

export default GroupAndSort
