import { createTheme } from '@mui/material/styles'
import { compose } from 'redux'
import { add, breakpoints } from 'styling/theming/base'
import contentMiscBase from 'styling/theming/base/contentMisc'
import mixins from 'styling/theming/base/mixins'
import shadows from 'styling/theming/base/shadows'
import typographyBase, { fonts } from 'styling/theming/base/typography'
import components from './components'
import contentMisc from './contentMisc'
import globals from './globals'
import themePalette, { postProcess } from './palette'
import thirdParty from './thirdParty'
import typography from './typography'

const hss = compose(
  f => ({ ...f }),
  thirdParty,
  contentMisc,
  contentMiscBase,
  components,
  typography,
  typographyBase,
  globals,
  mixins,
  postProcess,
  add('name', 'hss'),
  createTheme,
  add('breakpoints', breakpoints),
  add('palette', themePalette),
  add('shadows', shadows(themePalette)),
  fonts,
)

export default hss
