import MenuItem from '@mui/material/MenuItem'
import {
  ABILITY_CONTENT_CREATION,
  ABILITY_SELF_CONTENT_AUTHORING,
} from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useNavigation from 'hooks/useNavigation'
import { labels } from 'hss/ContentBuilder/consts'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { useContext } from 'react'
import {
  contentBuilderUrl,
  getTeacherContentAuthoringRoute,
} from 'routing/consts'
import { FEATURE_FLAG_CONTENT_AUTHORING } from 'shared/consts'

const AuthoringMenuItem = withAbilityCheck(() => {
  const { navigate } = useNavigation()

  const { content } = useContext(contentViewerContext)

  const handleEdit = () => {
    navigate(getTeacherContentAuthoringRoute(content))
  }

  const isContentAuthoringLive = useAbilityCheck(FEATURE_FLAG_CONTENT_AUTHORING)
  if (!isContentAuthoringLive) return null

  return content ? (
    <MenuItem onClick={handleEdit}>
      Edit {labels[content.contentType]}...
    </MenuItem>
  ) : null
}, ABILITY_SELF_CONTENT_AUTHORING)

const BuilderMenuItem = withAbilityCheck(() => {
  const { navigate } = useNavigation()

  const { subsection } = useContext(contentViewerContext)

  const { contentType, id } = subsection || {}

  const handleEdit = () => {
    navigate(`${contentBuilderUrl}/curriculum/${contentType}/${id}`)
  }

  return subsection ? (
    <MenuItem onClick={handleEdit}>Edit {labels[contentType]}</MenuItem>
  ) : null
}, ABILITY_CONTENT_CREATION)

const EditCurrentContentMenuItem = () => (
  <>
    <AuthoringMenuItem />
    <BuilderMenuItem />
  </>
)

export default EditCurrentContentMenuItem
