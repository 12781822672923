import {
  find,
  flatten,
  incrementByKey,
  map,
  reduce,
  toKeyedObject,
} from 'fp/arrays'
import { sum } from 'fp/numbers'
import { get, mapValues, set } from 'fp/objects'
import { matches, unary } from 'fp/utils'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { interactiveContext } from '../../InteractiveProvider'

const RubricAggregate = () => {
  const {
    contentId,
    interactive: { rubric },
  } = useContext(interactiveContext)
  const { assignmentId } = useParams()

  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  const allRubricCriteriaOptions = rubric.children.flatMap(get('data.options'))
  const rubricSelectionCountsById = compose(
    mapValues(get('count')),
    unary(toKeyedObject),
    reduce(
      (acc, next) => incrementByKey(next, 'count')(acc),
      map(set('count', 0))(allRubricCriteriaOptions),
    ),
    flatten,
    map(get('scoreData.rubricSelections')),
  )(allInteractions)

  const averageRubricSelections = compose(
    map(({ options }) => {
      const sumScores = compose(
        sum,
        map(({ count = 0, score }) => count * score),
      )(options)
      const numResponses = compose(sum, map(get('count')))(options)
      const averageScore = Math.round(sumScores / numResponses)
      return compose(get('id'), find(matches('score', averageScore)))(options)
    }),
    map(({ options }) => ({
      options: options.map(option => ({
        ...option,
        count: rubricSelectionCountsById[option.id] || 0,
      })),
    })),
    map(get('data')),
    get('children'),
  )(rubric)

  return (
    <Rubric
      rubric={rubric}
      values={averageRubricSelections}
    />
  )
}

export default RubricAggregate
