import withEnterClicks from 'hoc/withEnterClicks'
import PropTypes from 'prop-types'
import { createElement, forwardRef } from 'react'

const Clickable = withEnterClicks(({ passedRef, children, ...props }) => (
  <a
    ref={passedRef}
    {...props}>
    {children}
  </a>
))

const ClickableElement = forwardRef(
  ({ onClick, type, WrapperProps, ...rest }, ref) => (
    // biome-ignore lint/a11y/useValidAriaRole: <explanation>
    <Clickable
      onClick={onClick}
      ref={ref}
      role={undefined}
      xlinkHref="#"
      {...WrapperProps}
    >
      {createElement(type, rest)}
    </Clickable>
  ),
)

ClickableElement.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  WrapperProps: PropTypes.object,
}

export default ClickableElement
