import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { StyledHeadline } from 'common/text/Headline'
import { not, when } from 'fp/utils'
import withEnterClicks from 'hoc/withEnterClicks'
import useToggleState from 'hooks/useToggleState'
import HelpCircle from 'hss/images/controls/textfield/help-circle-32.png'
import { darken, position } from 'polished'
import PropTypes from 'prop-types'
import { useRef } from 'react'

const BodyText = withEnterClicks(Box)

const StyledContainer = styled('div', { name: 'Echo-NumberCrunch' })(
  ({
    theme: {
      mixins: { borderS, important, rem, transition },
      palette,
      typography,
    },
  }) => ({
    ...borderS(palette.grey[2]),
    padding: rem(4.8),
    maxWidth: 500,
    margin: rem(10, 'auto', 0),
    textAlign: 'center',

    '.content-heading': { marginTop: important(0) },

    '.bodytext': {
      marginBottom: 0,
      position: 'relative',
      ...typography.variants['toc-title'],
      '&::after': {
        ...transition(),
        ...position('absolute', 0, 0, 0, 0),

        content: `url(${HelpCircle})`,
        backgroundColor: palette.accent.dark,
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '&:hover::after': {
        backgroundColor: darken(0.2, palette.accent.dark),
      },
    },

    '&.revealed .bodytext::after': {
      transform: 'scaleX(0)',
      transformOrigin: '0 50%',
    },
  }),
)

const NumberCrunch = props => {
  const { bodytext, number } = props
  const bodytextRef = useRef()

  /**
   * Adding this key can make it so that the bodytext stays shown once revealed
   */
  // const key = `number-crunch-${hash({ number, bodytext })}`
  const [revealed, reveal] = useToggleState(false /* , key */)

  const handleClick = () => {
    when(not(revealed), reveal)
  }

  return (
    <StyledContainer className={revealed ? 'revealed' : ''}>
      <StyledHeadline className="content-heading">Number Crunch</StyledHeadline>

      <Typography
        display="block"
        mb={4}
        variant="large-number">
        {number}
      </Typography>

      <Tooltip title={revealed ? '' : 'Click to reveal!'}>
        <BodyText
          className="bodytext"
          onClick={handleClick}
          ref={bodytextRef}>
          {bodytext}
        </BodyText>
      </Tooltip>
    </StyledContainer>
  )
}

NumberCrunch.propTypes = {
  number: PropTypes.string.isRequired,
  bodytext: PropTypes.string.isRequired,
}

export default NumberCrunch
