import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import { a11yContext } from 'core/a11y'
import { DND_TYPE_GROUP_AND_SORT_ITEM } from 'core/consts'
import { groupAndSortGroupShape } from 'core/shapes'
import { orderBy } from 'fp/arrays'
import { isMobile } from 'fp/internet'
import { equals, set } from 'fp/objects'
import { debounce, matches, when } from 'fp/utils'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'
import Option from './Option'
import StyledGroupContent from './StyledGroupContent'
import { context } from './context'

const GroupContent = ({
  group,
  groupIdx,
  groupOptions,
  headingId,
  setDialogOpen,
}) => {
  const [userHasRemovedItems, setUserHasRemovedItems] = useState(false)
  const { handleDrop, provideGrouping, showGroupTotals } = useContext(context)
  const showAnswerKey = useIsInAnswerKeyContext()
  const { isGrading, submittable } = useContext(interactiveContext)
  const [displayedGroupOptions, setDisplayedGroupOptions] = useState([])
  const { tabModeEnabled } = useContext(a11yContext) || {}
  const buttonRef = useRef()
  const droppedItemDisplayIndex = useRef()
  const lastMoveOptionParams = useRef({})

  // this is used to disable adding to full groups
  const [groupIsFull, setGroupIsFull] = useState(false)

  const numCurrentlyInGroup = groupOptions.length

  const numAllowedInGroup = group.totalItems

  // we only care about the first time they press tab
  // after that they stay in "tab-mode" with extra buttons now displayed
  // this starts off as hidden so that screen readers can still see it
  const [addToGroupClass, setAddToGroupClass] = useState('hidden')

  // mobile devices will always get the "Add to Group" buttons
  // desktops have to press the tab key first
  const isMobileDevice = isMobile()

  useEffect(() => {
    when(
      (isMobileDevice || tabModeEnabled) && addToGroupClass,
      setAddToGroupClass,
      '',
    )
  }, [isMobileDevice, tabModeEnabled, addToGroupClass])

  const allowDrop = useCallback(
    (item, draggedFrom) =>
      (!showGroupTotals ||
        numCurrentlyInGroup < numAllowedInGroup ||
        draggedFrom === groupIdx) &&
      (!provideGrouping || item.groupId === group.id),
    [
      group.id,
      groupIdx,
      numAllowedInGroup,
      numCurrentlyInGroup,
      provideGrouping,
      showGroupTotals,
    ],
  )

  const groupOptionsString = JSON.stringify(groupOptions)

  /* istanbul ignore next */
  const moveOptionCallback = useCallback(
    (dragIndex, hoverIndex, _, item, moveAfter) => {
      const currentMoveOptionParams = {
        dragIndex,
        hoverIndex,
        draggedFrom: item.draggedFrom,
        draggedItem: item.draggedItem,
      }
      if (equals(currentMoveOptionParams, true)(lastMoveOptionParams.current))
        return
      lastMoveOptionParams.current = currentMoveOptionParams

      setDisplayedGroupOptions(prevDisplayedGroupOptions => {
        // see if this item is already in this list
        // if it is, we will need to slice it out before re-inserting it down below
        const alreadyInListIdx = prevDisplayedGroupOptions.findIndex(
          matches('id', item.id),
        )

        // see if this item has already been dropped in this particular group
        // if the item has already been dropped in this group,
        // then droppedItemDisplayIndex needs to take moveAfter into account when
        // determining the new drop placement
        const alreadyDroppedInGroup =
          prevDisplayedGroupOptions.find(matches('id', item.id))?.droppedId ===
          group.id
        droppedItemDisplayIndex.current =
          hoverIndex + (moveAfter && alreadyDroppedInGroup ? 0.5 : -0.5)

        const newItem = {
          ...item,
          newItem: true,
        }
        const newOptions = [...prevDisplayedGroupOptions]
        if (alreadyInListIdx > -1) newOptions.splice(alreadyInListIdx, 1)
        newOptions.splice(hoverIndex, 0, newItem)
        return newOptions.map((o, idx) => set('displayIndex', idx)(o))
      })
    },
    [group.id],
  )

  const moveOption = useCallback(
    (dragIndex, hoverIndex, draggedFrom, item, moveAfter) => {
      if (allowDrop(item, draggedFrom)) {
        debounce(
          200,
          moveOptionCallback(
            dragIndex,
            hoverIndex,
            draggedFrom,
            item,
            moveAfter,
          ),
        )
      }
    },
    [allowDrop, moveOptionCallback],
  )

  /* istanbul ignore next */
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: DND_TYPE_GROUP_AND_SORT_ITEM,
      // a group.id of 0 means there is only one group of options, so we can always drop in that group
      canDrop: dropItem =>
        allowDrop(dropItem.draggedItem, dropItem.draggedFrom),
      drop: ({ draggedItem: { id } }) => {
        handleDrop(id, group.id, droppedItemDisplayIndex.current)
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [numAllowedInGroup, numCurrentlyInGroup, showGroupTotals],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies(groupOptionsString): TODO: why?
  useEffect(() => {
    if (!isOver) {
      droppedItemDisplayIndex.current = null
      lastMoveOptionParams.current = {}
      setDisplayedGroupOptions(orderBy('displayIndex')(groupOptions))
    }
  }, [groupOptions, groupOptionsString, isOver])

  const hoverClass = canDrop && isOver ? 'drag-hover' : ''

  useEffect(() => {
    setGroupIsFull(
      Boolean(
        showGroupTotals && displayedGroupOptions.length >= group.totalItems,
      ),
    )
  }, [group.totalItems, displayedGroupOptions.length, showGroupTotals])

  useEffect(() => {
    // if they just removed an item (group is no longer full)
    // then set the focus to the "Add to Group" button
    if (!groupIsFull && userHasRemovedItems && buttonRef.current) {
      buttonRef.current.focus()
    }
  }, [groupIsFull, userHasRemovedItems])

  const addToGroup = () => setDialogOpen(true)

  const onOptionRemove = useCallback(() => {
    if (userHasRemovedItems) {
      buttonRef.current.focus()
    } else {
      setUserHasRemovedItems(true)
    }
  }, [userHasRemovedItems])

  return (
    <StyledGroupContent
      className={hoverClass}
      data-testid={`group-${groupIdx}`}
      ref={drop}>
      <Box flexGrow="1">
        <List aria-labelledby={headingId}>
          {displayedGroupOptions.map((item, idx) => (
            <Option
              draggedFrom={groupIdx}
              idx={idx}
              item={item}
              key={item.id}
              moveOption={moveOption}
              onRemove={onOptionRemove}
              showRemoveBtn
            />
          ))}
        </List>
      </Box>
      {Boolean(!isGrading && submittable && !showAnswerKey) && (
        <Button
          className={addToGroupClass}
          disabled={groupIsFull}
          onClick={addToGroup}
          ref={buttonRef}
          size="small">
          <AddIcon /> Add to {group.heading}
        </Button>
      )}
    </StyledGroupContent>
  )
}

GroupContent.propTypes = {
  group: groupAndSortGroupShape.isRequired,
  groupIdx: PropTypes.number.isRequired,
  groupOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingId: PropTypes.string.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
}

export default GroupContent
