import Box from '@mui/material/Box'
import { INTERACTIVE_TYPE_IMAGE_CLICKER } from 'core/consts'
import { isDefined, when } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import { useCallback, useContext, useState } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Img from './Img'
import Message from './Message'

const ImageClicker = () => {
  const {
    completed,
    interactiveData: { imageAltText, messages },
    markComplete,
    uploadsMap,
  } = useContext(interactiveContext)

  const [currentStep, setCurrentStep] = useState(0)
  const [done, setDone] = useToggleState()

  const handleDone = useCallback(() => {
    setDone()
    when(!completed, markComplete)
  }, [completed, markComplete, setDone])

  return (
    <Box position="relative">
      <Img
        imageAltText={imageAltText}
        imageUrl={uploadsMap.backdrop?.url}
      />
      {done ? (
        <div>
          <strong>Done!</strong>
          <br />
          Maybe show a call to action here?
        </div>
      ) : (
        isDefined(messages[currentStep]) && (
          <Message
            currentStep={currentStep}
            key={`message-${currentStep}`}
            message={messages[currentStep]}
            setCurrentStep={setCurrentStep}
            setDone={handleDone}
            totalSteps={messages.length}
          />
        )
      )}
    </Box>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_IMAGE_CLICKER,
}

export default ImageClicker
