import TabPanel from '@mui/lab/TabPanel'
import { styled } from '@mui/material/styles'
import BusySpinner from 'common/indicators/BusySpinner'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { filter, find } from 'fp/arrays'
import { mapValues, pick } from 'fp/objects'
import { curryRight } from 'fp/utils'
import withProps from 'hoc/withProps'
import useScrollToTopOnRouteChange from 'hooks/useScrollToTopOnRouteChange'
import { mutexFeatures } from 'hss/ContentBuilder/Curriculum/Echo/utils'
import {
  ECHO_FEATURE_ACT,
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_RATE,
  ECHO_FEATURE_TALK_BACK,
  ECHO_FEATURE_TOP_TEN,
  SUBMITTABLE_ECHO_FEATURES,
} from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { Suspense, createElement, lazy, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStaff } from 'selectors/users'
import { withInteractiveProvider } from './providerUtils'

const Header = lazy(
  () =>
    import(
      /* webpackChunkName: "GradingHeader" */ 'sections/contentBlocks/Interactive/Teacher/Header'
    ),
)
const TeacherInteractiveRenderer = lazy(
  () =>
    import(
      /* webpackChunkName: "TeacherInteractiveRenderer" */ 'sections/contentBlocks/Interactive/Teacher/InteractiveRenderer'
    ),
)

const FeatureAct = lazy(
  () => import(/* webpackChunkName: "FeatureAct" */ './FeatureAct'),
)
const FeatureBlast = lazy(
  () => import(/* webpackChunkName: "FeatureBlast" */ './FeatureBlast'),
)
const FeaturePictureThis = lazy(
  () =>
    import(/* webpackChunkName: "FeaturePictureThis" */ './FeaturePictureThis'),
)
const FeaturePoll = lazy(
  () => import(/* webpackChunkName: "FeaturePoll" */ './FeaturePoll'),
)
const FeatureRate = lazy(
  () => import(/* webpackChunkName: "FeatureRate" */ './FeatureRate'),
)
const FeatureTalkBack = lazy(
  () => import(/* webpackChunkName: "FeatureTalkBack" */ './FeatureTalkBack'),
)
const FeatureTopTen = lazy(
  () => import(/* webpackChunkName: "FeatureTopTen" */ './FeatureTopTen'),
)

const Styled = styled('div', { name: 'Panels-index' })(
  ({
    theme: {
      mixins: { important, rem },
      typography,
    },
  }) => ({
    flex: 1,
    overflowY: 'auto',
    paddingBottom: rem(8),

    ...typography.variants['feature-paragraph'],

    'button[type=submit]': {
      margin: rem(4, 0, 10),
    },

    'strong[class$="Instructions-root"], label [class$="Instructions-root"]': {
      ...typography.h4,
      display: 'block',
      fontWeight: 300,
      margin: rem(3.6, 0, 2.5),
      textAlign: 'left',
    },

    [`.${ECHO_FEATURE_ACT}`]: {
      h3: {
        textTransform: important('uppercase'),
        ...typography.h5,
      },
    },

    [`.${ECHO_FEATURE_BLASTY_BLAST}`]: {
      '.DraftEditor-toolbar-container': {
        marginTop: rem(3.6),
      },
    },
  }),
)

const withStaffWrapper = WrappedComponent => {
  const Enhanced = props => (
    <>
      <Suspense fallback={<BusySpinner />}>
        <Header forEcho />
      </Suspense>
      <Suspense fallback={<BusySpinner />}>
        <TeacherInteractiveRenderer
          DefaultViewComponent={WrappedComponent}
          {...props}
        />
      </Suspense>
    </>
  )
  return Enhanced
}

const withTabPanel = feature => WrappedComponent => {
  const TabPanelComponent = withProps(TabPanel, {
    className: feature,
    value: feature,
  })
  const Enhanced = props => (
    <TabPanelComponent>
      <WrappedComponent {...props} />
    </TabPanelComponent>
  )
  return Enhanced
}

const defaultTabPanelComponent = feature =>
  compose(withTabPanel(feature), curryRight(withInteractiveProvider, feature))

const staffTabPanelComponent = feature =>
  compose(defaultTabPanelComponent(feature), withStaffWrapper)

const featureComponents = {
  [ECHO_FEATURE_ACT]: FeatureAct,
  [ECHO_FEATURE_BLASTY_BLAST]: FeatureBlast,
  [ECHO_FEATURE_PICTURE_THIS]: FeaturePictureThis,
  [ECHO_FEATURE_POLL]: FeaturePoll,
  [ECHO_FEATURE_RATE]: FeatureRate,
  [ECHO_FEATURE_TALK_BACK]: FeatureTalkBack,
  [ECHO_FEATURE_TOP_TEN]: FeatureTopTen,
}

const tabPanels = {
  default: mapValues((Component, name) =>
    defaultTabPanelComponent(name)(Component),
  )(featureComponents),
  staffOnly: compose(
    mapValues((Component, name) => staffTabPanelComponent(name)(Component)),
    pick(SUBMITTABLE_ECHO_FEATURES),
  )(featureComponents),
}

const Panels = ({ features: availableFeatures, responseType }) => {
  const scrollRef = useRef()
  useScrollToTopOnRouteChange(scrollRef)

  const hasActFeature = Boolean(
    find(feat => feat === ECHO_FEATURE_ACT)(availableFeatures),
  )
  const hasPollFeature = Boolean(
    find(feat => feat === ECHO_FEATURE_POLL)(availableFeatures),
  )

  const isCurrentUserStaff = useSelector(isStaff)
  const { assignmentId } = useParams()
  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const showStaffView =
    assignmentId && isCurrentUserStaff && !presenterModeEnabled

  const tabPanelsToRender = useMemo(() => {
    const possibleTabPanels = {
      ...tabPanels.default,
      ...(showStaffView ? tabPanels.staffOnly : {}),
    }
    return filter(Boolean)([
      !!hasActFeature && possibleTabPanels[ECHO_FEATURE_ACT],
      responseType === ECHO_FEATURE_BLASTY_BLAST &&
        possibleTabPanels[ECHO_FEATURE_BLASTY_BLAST],
      responseType === ECHO_FEATURE_PICTURE_THIS &&
        possibleTabPanels[ECHO_FEATURE_PICTURE_THIS],
      !!hasPollFeature && possibleTabPanels[ECHO_FEATURE_POLL],
      responseType === ECHO_FEATURE_TALK_BACK &&
        possibleTabPanels[ECHO_FEATURE_TALK_BACK],
      possibleTabPanels[ECHO_FEATURE_RATE],
      possibleTabPanels[ECHO_FEATURE_TOP_TEN],
    ])
  }, [hasActFeature, hasPollFeature, responseType, showStaffView])

  return (
    <Styled ref={scrollRef}>
      <HeadlineLevelOffset>
        <HeadlineStyleOffset offset={4}>
          <Suspense fallback={<BusySpinner />}>
            {tabPanelsToRender.map((Panel, index) =>
              createElement(Panel, { key: index }),
            )}
          </Suspense>
        </HeadlineStyleOffset>
      </HeadlineLevelOffset>
    </Styled>
  )
}

Panels.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  responseType: PropTypes.oneOf(mutexFeatures).isRequired,
}

export default Panels
