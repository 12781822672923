import { inspect } from 'core/store/search/squery'
import { filter, first, map } from 'fp/arrays'
import { fallbackTo } from 'fp/utils'
import { useContext, useMemo } from 'react'
import { compose } from 'redux'
import { searchContext } from './SearchProvider'

const useSearchingText = () => {
  const { searchFields, squery } = useContext(searchContext) || {}

  const searchingText = useMemo(
    () =>
      compose(
        fallbackTo(''),
        first,
        filter(Boolean),
        map(fieldName => inspect(squery).get.where(fieldName).contains()),
        fallbackTo([]),
      )(searchFields),
    [searchFields, squery],
  )

  return searchingText
}

export default useSearchingText
