import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getEchoResponse } from 'selectors/echo'
import { getUserInteractions } from 'selectors/interactions'
import { getReactionsForContent } from 'selectors/reactions'

const useEchoReactions = () => {
  const { subsection: echo } = useContext(contentViewerContext) || {}
  const { contentId, contextContentId } =
    useSelector(getEchoResponse(echo)) || {}

  const interaction =
    useSelector(getUserInteractions({ contentId, contextContentId })) || {}

  const { id: interactionId } = interaction[0] || {}

  const reactions = useSelector(getReactionsForContent({ interactionId }))

  const {
    given = [],
    pending = null,
    received = [],
    remaining = 0,
  } = reactions || {}

  return {
    given,
    pending,
    received,
    remaining,
  }
}

export default useEchoReactions
