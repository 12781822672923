import { interactiveVariants } from 'hss/ContentBuilder/consts'
import { useContext } from 'react'
import { SCORING_MODE_GNS_SORT_GIVEN_GROUPS } from 'shared/consts'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Groups from './Groups'
import OptionWell from './OptionWell'
import { context } from './context'

const Options = () => {
  const { interactiveData, isGrading, submittable } =
    useContext(interactiveContext)

  const { variant = interactiveVariants.NORMAL } = interactiveData

  const {
    scoringMode,
    state: { groups },
  } = useContext(context)

  const wellGroups =
    scoringMode === SCORING_MODE_GNS_SORT_GIVEN_GROUPS
      ? groups
      : [{ id: 0, heading: '' }]

  return (
    <>
      {Boolean(
        (submittable && !isGrading) ||
          variant === interactiveVariants.GROUP_AND_SORT_VOCAB_FAMILIARITY,
      ) &&
        wellGroups.map(group => (
          <OptionWell
            group={group}
            key={group.id}
          />
        ))}

      <Groups />
    </>
  )
}

export default Options
