import { radian } from 'fp/numbers'
import { wrap } from 'fp/strings'
import PropTypes from 'prop-types'
/**
 * NOTE!!
 *
 * This component is consumed by recharts as a function call and not as JSX.  It's
 * assigned to an internal option called 'label' and is invoked in this manner:
 *   label(options)
 *
 * ...and not as:
 *   <label {...props} />
 *
 * A subtle distinction, but it does mean that PropTypes is never invoked, meaning
 * the prop types declared below are for REFERENCE ONLY and are not being enforced!
 *
 * Another reason to maybe consider bringing in TypeScript 😒
 */

const CustomizedLabel = ({
  cx,
  cy,
  fill,
  midAngle,
  name,
  outerRadius,
  prefix = '',
  suffix = '',
  value,
  formatterConfig = {},
}) => {
  const formattedValue = new Intl.NumberFormat('en-US', formatterConfig).format(
    value,
  )
  const sin = Math.sin(-radian * midAngle)
  const cos = Math.cos(-radian * midAngle)
  const sx = cx + outerRadius * cos
  const sy = cy + outerRadius * sin
  const mx = cx + (outerRadius + 15) * cos
  const my = cy + (outerRadius + 15) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        fill="none"
        stroke="#BDBDBD"
      />
      <circle
        cx={ex}
        cy={ey}
        fill={fill}
        r={2}
        stroke="none"
      />
      <text
        fill="#333"
        textAnchor={textAnchor}
        x={ex + (cos >= 0 ? 1 : -1) * 10}
        y={ey}>
        {name}
      </text>
      <text
        dy={18}
        fill="#4F4F4F"
        textAnchor={textAnchor}
        x={ex + (cos >= 0 ? 1 : -1) * 10}
        y={ey}>
        {wrap(prefix, suffix)(formattedValue)}
      </text>
    </g>
  )
}

CustomizedLabel.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  formatterConfig: PropTypes.object.isRequired,
  fill: PropTypes.string.isRequired,
  midAngle: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  outerRadius: PropTypes.number.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.number.isRequired,
}

export default CustomizedLabel
