import PropTypes from 'prop-types'
import SVGNote from './SVGNote'

const DiagramPoint = props => {
  const { CircleProps, children, ...rest } = props

  return (
    <>
      {/* biome-ignore lint/a11y/noAriaHiddenOnFocusable: can't hide a focusable item */}
      <circle
        aria-hidden="true"
        {...CircleProps}
        data-testid="diagram-point"
      />
      <SVGNote {...rest}>{children}</SVGNote>
    </>
  )
}

DiagramPoint.propTypes = {
  children: PropTypes.node,
  CircleProps: PropTypes.object,
}

export default DiagramPoint
