import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useAnnotations from 'hooks/useAnnotations'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { useContext } from 'react'
import SubsectionAnnotations from '../Annotations/SubsectionAnnotations'

const AnnotationsTab = () => {
  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}
  const { annotations, subsectionsWithAnnotations } = useAnnotations({
    userAssignmentId,
  })

  // TODO: Give this custom table styling specific to Feedback
  return (
    <>
      <Typography
        style={{ marginTop: '40px', textTransform: 'uppercase' }}
        variant="h3">
        Student Annotations
      </Typography>
      {subsectionsWithAnnotations.length ? (
        subsectionsWithAnnotations.map((subsection, idx) => (
          <SubsectionAnnotations
            annotations={annotations[subsection.id]}
            content={subsection}
            isLast={idx === subsectionsWithAnnotations.length - 1}
            key={subsection.id}
          />
        ))
      ) : (
        <Box mt={4}>
          <p>This student has not made any annotations yet.</p>
        </Box>
      )}
    </>
  )
}

export default AnnotationsTab
