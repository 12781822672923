import { when } from 'fp/utils'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { getUserAssignmentById } from 'selectors/userAssignments'
import MenuItemButton from '../Toolbar/MenuItemButton'
import ToolbarMenu from '../Toolbar/ToolbarMenu'

const GradingToolbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const context = useContext(sidebarContext)
  const { setSidebarShrunk } = context

  const { currentUserAssignmentId: userAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}
  const searchParams = new URLSearchParams(location.search)
  const paneParam = searchParams.get('pane')
  const feedbackSelected = paneParam === 'feedback'

  const userAssignment = useSelector(
    getUserAssignmentById({ userAssignmentId }),
  )

  useEffect(() => {
    when(!userAssignment, navigate, `${location.pathname}`)
  }, [location.pathname, navigate, userAssignment])

  const handleClick = () => {
    if (feedbackSelected) {
      navigate(`${location.pathname}`)
      setSidebarShrunk(false)
    } else {
      navigate(`${location.pathname}?pane=feedback`)
      setSidebarShrunk(true)
    }
  }

  return (
    <ToolbarMenu
      disableResponsive
      sticky
      sx={{ boxShadow: 2 }}>
      <MenuItemButton
        disabled={!userAssignment}
        disableResponsive
        onClick={handleClick}
        selected={feedbackSelected}
        value="feedback"
        variant="">
        Feedback
      </MenuItemButton>
    </ToolbarMenu>
  )
}

export default GradingToolbar
