import Button from '@mui/material/Button'
import RadioGroup from '@mui/material/RadioGroup'
import { useTheme } from '@mui/material/styles'
import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import Radio from 'common/formControls/switches/Radio'
import Centered from 'common/layout/Centered'
import { ABILITY_CONTENT_EDITING } from 'core/consts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect } from 'react'
import { PieChart } from 'react-feather'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import AnswerChoiceBox from '../MultipleChoice/AnswerChoiceBox'

const MultipleChoice = withQuestionPrompt(({ onShowResultsClick }) => {
  const {
    completed,
    interactionData: { selectedValue = null },
    interactiveData: { options, prompt },
    isGrading,
    onInteract,
    setSubmitEnabled,
  } = useContext(interactiveContext)

  const [selectedValueState, setSelectedValue] =
    useStateWithDynamicDefault(selectedValue)

  const handleChange = useCallback(
    ({ target }) => {
      const newValue = target.value
      setSelectedValue(newValue)
      onInteract({ selectedValue: newValue })
    },
    [onInteract, setSelectedValue],
  )

  useEffect(() => {
    setSubmitEnabled(!!selectedValue)
  }, [selectedValue, setSubmitEnabled])

  const isContentEditor = useAbilityCheck(ABILITY_CONTENT_EDITING)

  const { palette, shadows } = useTheme()

  const selectedBoxShadow = {
    boxShadow: shadows.border,
    color: palette.boxshadowPrimary,
  }

  return (
    <ContainedFormContent m="0 auto">
      {Boolean(isGrading && !selectedValue) && (
        <Centered component="p">(no response)</Centered>
      )}

      <RadioGroup
        aria-label={prompt}
        disabled={completed || isGrading}
        onChange={handleChange}
        value={selectedValueState}>
        {options?.map(option => (
          <AnswerChoiceBox
            barColor="transparent"
            bgcolor="background.paper"
            borderLeft="8px solid"
            className="AnswerChoiceBox"
            key={option.id}
            mb={1.5}
            pl={1}
            // option.id is string because poll.js option ids are numbers.
            // This should be changed to generative numbers in the future.
            style={
              selectedValueState === `${option.id}` ? selectedBoxShadow : {}
            }
          >
            <Radio
              disabled={completed || isGrading}
              key={option.id}
              label={option.label}
              style={{
                color: palette.text.primary,
              }}
              value={option.id}
            />
          </AnswerChoiceBox>
        ))}
      </RadioGroup>

      {Boolean(isContentEditor) && (
        <Centered my={3}>
          <Button
            color="primary"
            onClick={onShowResultsClick}
            startIcon={<PieChart />}
            variant="primary">
            View Results
          </Button>
        </Centered>
      )}
    </ContainedFormContent>
  )
})

MultipleChoice.propTypes = {
  onShowResultsClick: PropTypes.func.isRequired,
}

export default MultipleChoice
