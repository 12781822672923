import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { keyframes } from '@mui/styled-engine'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'
import { contentShape } from 'core/shapes'
import { animation } from 'polished'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { containedPaddingLeft } from 'styling/theming/base'
import { useSwiperSlide } from 'swiper/react'

const slideIn = keyframes`
  0% {
    opacity: 0;
    margin-left:100px;
  }
  100% {
    margin-left:0;
  }
`
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const HeroSlide = forwardRef(
  ({ children, content: { uploadsMap }, index, SlideContainerProps }, ref) => {
    const {
      palette: { carousel },
    } = useTheme()
    const url = getImageUrlFromUploadsMap(uploadsMap, 'full')
    const { end, start } = carousel[index % 3]
    const gradient = `linear-gradient(270deg, ${start} 0%, ${end} 100%)`
    const { isActive } = useSwiperSlide() // to know when to animate fade/slide in

    return (
      <Box
        ref={ref}
        sx={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: 565,
          // If no background image, use a light grey as the background color
          backgroundColor: url ? null : 'primary.200',
          backgroundImage: url ? `url("${url}")` : null,
          alignItems: 'center',
          display: 'flex',
          color: 'primary.contrastText',
        }}>
        <Box
          sx={{
            width: '100%',
            backgroundImage: gradient,
            height: '100%',
            ...(isActive && url
              ? animation(['1s', 'ease-in-out', 'both', fadeIn, '700ms'])
              : {}),
          }}>
          <Stack
            justifyContent="center"
            sx={{
              paddingLeft: `calc(${containedPaddingLeft} * 2)`,
              height: '100%',
              animationDelay: '2s',
              ...(isActive
                ? animation(['750ms', 'ease-in-out', 'both', slideIn, '700ms'])
                : {}),
              ...SlideContainerProps,
            }}>
            {children}
          </Stack>
        </Box>
      </Box>
    )
  },
)

HeroSlide.propTypes = {
  children: PropTypes.object.isRequired,
  content: contentShape.isRequired,
  index: PropTypes.number.isRequired,
  SlideContainerProps: PropTypes.object,
}

export default HeroSlide
