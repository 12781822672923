import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Form from 'common/formControls/Form'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import Headline from 'common/text/Headline'
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import actionTypes from 'reducers/actionTypes'
import LoginTextField from './LoginTextField'

const FormContents = props => {
  const {
    errorSubmitting,
    isSubmitting,
    pageIsDisplayed,
    recoveredEmail,
    submitted,
    switchToDirectLogin,
  } = props
  const headlineRef = useRef()

  const tabIndex = useMemo(() => (pageIsDisplayed ? 0 : -1), [pageIsDisplayed])

  // biome-ignore lint/correctness/useExhaustiveDependencies(headlineRef.current): the point?
  useEffect(() => {
    if (pageIsDisplayed) {
      headlineRef.current?.focus()
    }
  }, [headlineRef.current, pageIsDisplayed])

  return (
    <Headline
      my={4}
      ref={headlineRef}
      tabIndex={-1}
      title="Recover Account">
      {submitted ? (
        <>
          {errorSubmitting ? (
            <p>
              Something went wrong when submitting your request. Please try
              again later.
            </p>
          ) : (
            <p>
              If {recoveredEmail} is in our system, we will email instructions
              to reset your password.
            </p>
          )}
          <Button
            onClick={switchToDirectLogin}
            tabIndex={tabIndex}
            variant="tertiary">
            Return to Login
          </Button>
        </>
      ) : (
        <>
          <p>Enter your email address, and we'll send you a link.</p>

          <LoginTextField
            inputProps={{ tabIndex, type: 'email' }}
            name="email"
            required
          />

          <Box
            columnGap={2}
            display="flex"
            mt={2}>
            <SubmitButton
              disabled={isSubmitting}
              tabIndex={tabIndex}>
              Recover
            </SubmitButton>
            <Button
              onClick={switchToDirectLogin}
              tabIndex={tabIndex}
              variant="tertiary">
              Back
            </Button>
          </Box>
        </>
      )}
    </Headline>
  )
}

FormContents.propTypes = {
  errorSubmitting: PropTypes.bool.isRequired,
  pageIsDisplayed: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  recoveredEmail: PropTypes.string.isRequired,
  submitted: PropTypes.bool.isRequired,
  switchToDirectLogin: PropTypes.func.isRequired,
}

const ForgotUsernamePassword = forwardRef(
  ({ pageIsDisplayed, switchToDirectLogin }, ref) => {
    const [errorSubmitting, setErrorSubmitting] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [recoveredEmail, setRecoveredEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const onSubmit = () => {
      setIsSubmitting(true)
    }

    const onSuccess = ({
      passThrough: {
        action: {
          payload: { email },
        },
      },
    }) => {
      setIsSubmitting(false)
      setRecoveredEmail(email)
      setSubmitted(true)
    }

    const onError = () => {
      setIsSubmitting(false)
      setSubmitted(true)
      setErrorSubmitting(true)
    }

    return (
      <Form
        actionType={actionTypes.USER_RECOVERY}
        defaultValues={{ email: '' }}
        name="ForgotUsernamePassword"
        onError={onError}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        ref={ref}
        suppressDirtyNavigationWarning
        suppressRequiredLabel>
        <FormContents
          errorSubmitting={errorSubmitting}
          isSubmitting={isSubmitting}
          pageIsDisplayed={pageIsDisplayed}
          recoveredEmail={recoveredEmail}
          submitted={submitted}
          switchToDirectLogin={switchToDirectLogin}
        />
      </Form>
    )
  },
)

ForgotUsernamePassword.propTypes = {
  pageIsDisplayed: PropTypes.bool.isRequired,
  switchToDirectLogin: PropTypes.func.isRequired,
}

export default ForgotUsernamePassword
