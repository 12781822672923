import Box from '@mui/material/Box'
import withList from 'common/formControls/lists/withList'
import Instructions from 'common/layout/Instructions'
import Headline from 'common/text/Headline'
import { INTERACTIVE_TYPE_STACKED_SEQUENCE } from 'core/consts'
import { useCallback, useContext } from 'react'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import QuestionPrompt from '../../Interactive/QuestionPrompt'
import Item from './Item'
import { SequenceItems } from './StackedSequenceList'

const SequenceList = withList(SequenceItems, true)

const StackedSequence = () => {
  const {
    interactionData: { items },
    interactiveData: {
      promptHtml,
      readOnly,
      responseTemplate: { items: responseTemplateItems },
    },
    name,
    onInteract,
    submittable,
  } = useContext(interactiveContext)

  const handleAdd = useCallback(
    ({ target: { value } }) => {
      onInteract({ items: value })
    },
    [onInteract],
  )

  return (
    <Box
      maxWidth={featuredContentMaxWidth}
      my={2}>
      <Instructions>
        <Headline
          textTransform="capitalize"
          title={name || 'Graphic Organizer'}
        />
        {Boolean(promptHtml) && <QuestionPrompt body={promptHtml} />}
      </Instructions>

      <SequenceList
        disabled={readOnly || !submittable}
        ItemRenderer={Item}
        items={items || responseTemplateItems}
        name="items"
        onChange={handleAdd}
        readOnly={readOnly || !submittable}
      />
    </Box>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_STACKED_SEQUENCE,
}

export default StackedSequence
