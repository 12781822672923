import { styled } from '@mui/material/styles'
import { componentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import MinimizeIconButton from 'styling/theming/base/components/MinimizeIconButton'
import ExpanderBackdrop from '../../Interactive/ExpanderBackdrop'

const ImageWrapper = styled('div', { name: 'Image-Expander' })(() => ({
  div: {
    overflow: 'inherit',
  },
  img: {
    maxHeight: '95vh',
    maxWidth: '95vw',
  },
  '&:hover div[class$="-Image-AnnotationsOverlay-root"]': {
    opacity: 1,
  },
}))

const Expander = ({ children, expanded, onClick }) => {
  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === 'Escape' && expanded) onClick()
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [expanded, onClick])

  return (
    <ExpanderBackdrop expanded={expanded}>
      <ImageWrapper>{children}</ImageWrapper>

      <MinimizeIconButton onClick={onClick} />
    </ExpanderBackdrop>
  )
}

Expander.propTypes = {
  children: componentShape.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Expander
