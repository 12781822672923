import Container from '@mui/material/Container'
import Headline from 'common/text/Headline'
import OpenTasks from './OpenTasks'

const Assignments = () => (
  <Container>
    <Headline title="Assignments">
      <OpenTasks />
    </Headline>
  </Container>
)

export default Assignments
