const config = {
  viewBox: {
    width: 800,
    height: 600,
  },
}

// General properties that depend on viewBox size
config.viewBox.midX = 0.5 * config.viewBox.width
config.paddingX = config.viewBox.width * 0.05
config.fontSize = config.viewBox.height * 0.025
config.circleRadius = config.viewBox.width * 0.005

// Spacing that depends on font size and circle radius
config.lineSpacing = config.fontSize * 0.2
config.labelAboveOffsetY = config.circleRadius * 2
config.labelBelowOffsetY = config.circleRadius * 2 + config.fontSize * 0.8
config.slantedNoteOffsetX = config.circleRadius * 2

// Positioning of the various plot elements
config.baselineY = config.viewBox.height * 0.85
config.exposition = {
  startX: config.paddingX,
  endX: config.viewBox.width * 0.18,
}
config.apex = {
  x: config.viewBox.width * 0.7,
  y: config.viewBox.height * 0.17,
}
config.resolution = {
  startX: config.viewBox.width * 0.75,
  endX: config.viewBox.width - config.paddingX,
}

// Convenience so we don't have calculate these repeatedly later
const labelConfig = [config.exposition, config.resolution]
labelConfig.forEach(segment => {
  segment.midX = segment.startX + 0.5 * (segment.endX - segment.startX)
  segment.width = segment.endX - segment.startX
})
config.apex.height = config.baselineY - config.apex.y

// Computed angle and length of the slanted lines, based on the above positioning
// These will be used to position the rising/falling labels.
const height = config.baselineY - config.apex.y
const hypotenuseLength = adjacent =>
  Math.sqrt(adjacent * adjacent + height * height)
const riseFall = x => ({
  angle: Math.atan(height / x) * (180 / Math.PI),
  length: hypotenuseLength(x),
  slope: height / x,
})
config.rise = riseFall(config.apex.x - config.exposition.endX)
config.fall = riseFall(config.resolution.startX - config.apex.x)

export default config
