import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { filter, reduce } from 'fp/arrays'
import { curry, isDefined, when, whenPresent } from 'fp/utils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { compose } from 'redux'
import config from '../config'
import DiagramPoint from './DiagramPoint'

const convertToSvgCoordinates = (svg, { x, y }) => {
  const pt = svg.createSVGPoint()
  pt.x = x
  pt.y = y
  return pt.matrixTransform(svg.getScreenCTM().inverse())
}

const StyledGroup = styled('g', { name: 'ActionPoints' })(() => ({
  moveable: {
    cursor: 'ns-resize',
  },
}))

const ActionPoints = ({
  TitleProps,
  fieldName,
  focusedPoint,
  notes,
  onClick,
  onReorder,
  readOnly = false,
  title,
  slope,
  offsetXDirection,
  ...rest
}) => {
  const handleKeyUp =
    index =>
    ({ key }) => {
      when(
        key === 'ArrowLeft' || key === 'ArrowRight',
        compose(
          onReorder,
          newIndex => ({ currentIndex: index, newIndex }),
          () =>
            key === 'ArrowLeft'
              ? Math.max(0, index - 1)
              : Math.min(
                  index + 1,
                  notes.filter(({ moveable }) => moveable).length - 1,
                ),
        ),
      )
    }

  const ActionNoteProps = ({ x, y, maxHeight, moveable }, idx) => ({
    ariaLabel: `${title} #${idx + 1}, click to edit`,
    moveable,
    maxHeight,
    x,
    y,
    width:
      fieldName === 'risingAction'
        ? x - config.exposition.startX
        : config.resolution.endX - x,
    onClick: whenPresent(onClick, {
      key: `${fieldName}.${idx}`,
      title: `${title} #${idx + 1}`,
      actionPoint: true,
      fieldName,
      value: notes,
    }),
    onKeyUp: when(!readOnly && isDefined(onReorder), handleKeyUp, idx),
    id: `${fieldName}-${idx}`,
  })

  const [previewCircle, setPreviewCircle] = useState()

  const handlePointerDown =
    point =>
    ({ pointerId, target }) => {
      target.setPointerCapture(pointerId)
      when(!readOnly && point.moveable, setPreviewCircle, {
        cx: point.x,
        cy: point.y,
      })
    }

  const handlePointerUp =
    note =>
    ({ pointerId, target }) => {
      target.releasePointerCapture(pointerId)
      /* istanbul ignore else */
      if (previewCircle) {
        const currentIndex = notes.indexOf(note)
        const reorderIndices = compose(
          newIndex => ({ currentIndex, newIndex }),
          reduce(
            (acc, curr, idx) =>
              curr.x > previewCircle.cx
                ? acc
                : idx + (idx >= currentIndex ? 0 : 1),
            0,
          ),
          filter(({ moveable }) => moveable),
        )(notes)
        when(
          reorderIndices.newIndex !== currentIndex,
          onReorder,
          reorderIndices,
        )
        setPreviewCircle(null)
      }
    }

  const handleMove =
    point =>
    ({ target, clientX, clientY }) => {
      when(
        isDefined(previewCircle),
        compose(
          setPreviewCircle,
          ({ y }) => ({
            cy: Math.max(Math.min(y, config.baselineY), config.apex.y),
            cx: point.x + offsetXDirection * ((y - point.y) / slope),
          }),
          curry(convertToSvgCoordinates)(target.closest('svg')),
        ),
        { x: clientX, y: clientY },
      )
    }

  return (
    <StyledGroup>
      {/* biome-ignore lint/a11y/noInteractiveElementToNoninteractiveRole: it suggests removing role */}
      <text
        className="title"
        role="presentation"
        {...TitleProps}>
        {title}
      </text>
      {Boolean(previewCircle) && (
        <circle
          r={config.circleRadius}
          {...previewCircle}
        />
      )}
      {notes.map((n, idx) => (
        <DiagramPoint
          CircleProps={{
            cx: n.x,
            cy: n.y,
            r: config.circleRadius,
            onPointerDown: handlePointerDown(n),
            onPointerUp: handlePointerUp(n),
            onPointerMove: handleMove(n),
            className: cl({ moveable: n.moveable }),
          }}
          key={`${fieldName}.${idx}`}
          readOnly={readOnly}
          {...ActionNoteProps(n, idx)}
          {...rest}
          focused={focusedPoint === `${fieldName}.${idx}`}>
          {n.text}
        </DiagramPoint>
      ))}
    </StyledGroup>
  )
}

ActionPoints.propTypes = {
  TitleProps: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  focusedPoint: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.object),
  offsetXDirection: PropTypes.oneOf([-1, 1]).isRequired,
  onClick: PropTypes.func,
  onReorder: PropTypes.func,
  readOnly: PropTypes.bool,
  slope: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default ActionPoints
