import { AlertTriangle } from 'react-feather'

const RequiresGradingIndicator = () => (
  <AlertTriangle
    aria-label="(Requires Grading)"
    className="grading-alert"
    role="img"
  />
)

export default RequiresGradingIndicator
