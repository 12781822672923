import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'

const DragListItem = styled(ListItem)(
  ({
    theme: {
      mixins: { borderS, important, importantRem, rem },
      palette,
      shadows,
    },
  }) => ({
    // item styles
    ...borderS(palette.grey[4]),
    background: palette.common.white,
    display: 'inline-block',
    fontSize: rem(1.5),
    height: 'auto !important',
    margin: importantRem(0.4, 1.2, 0.4, 0),
    padding: rem(1.2, 1.2, 1.2, 0.8),
    position: 'relative',
    width: 'auto',
    wordBreak: 'break-word',
    p: {
      margin: important(0),
    },
    svg: {
      color: palette.grey[2],
    },
    // draggable styles
    '&.draggable': {
      cursor: 'grab',
      '&:hover': {
        boxShadow: shadows[3],
      },
    },
    '&:hover::before': { color: palette.focused },
    // remove button styles
    button: {
      marginLeft: '0.5rem',
      padding: '0',
    },
  }),
)

const DragItem = forwardRef(({ children, readOnly = false, ...rest }, ref) => (
  <DragListItem
    component="li"
    ref={ref}
    {...rest}>
    <Box
      alignItems="center"
      display="flex"
      height="100%">
      {!readOnly && <DragIndicatorIcon />}
      <Box
        display="flex"
        flexGrow="1"
        justifyContent="space-between"
        ml={1}
        mr={0.5}>
        {children}
      </Box>
    </Box>
  </DragListItem>
))

DragItem.propTypes = {
  children: PropTypes.node,
  readOnly: PropTypes.bool,
}

export default DragItem
