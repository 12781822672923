import Typography from '@mui/material/Typography'
import Centered from 'common/layout/Centered'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { map, orderBy, toKeyedObject } from 'fp/arrays'
import { sum } from 'fp/numbers'
import { get, set } from 'fp/objects'
import { unary } from 'fp/utils'
import { useChapterVocabPhrases } from 'hooks/useChapterVocabPhrases'
import { interactiveVariants } from 'hss/ContentBuilder/consts'
import RankingList from 'hss/sections/contentBlocks/interactives/EvaluationRanking/RankingList'
import { produce } from 'immer'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { interactiveContext } from '../../InteractiveProvider'

const EvaluationRankingAggregate = () => {
  const {
    contentId,
    interactiveData: { items, variant },
  } = useContext(interactiveContext)
  const { assignmentId } = useParams()
  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  const contentVocabPhrases = useChapterVocabPhrases({
    contentId,
    contentType: CONTENT_TYPE_INTERACTIVE,
  })
  const vocabItems = contentVocabPhrases.map(({ id, name }) => ({
    id,
    label: name,
  }))
  const isVocab =
    variant === interactiveVariants.EVALUATION_RANKING_VOCAB_FAMILIARITY

  const sortedItems = useMemo(
    () =>
      compose(
        orderBy('averageResponseIndex'),
        map(({ responses, ...rest }) => ({
          ...rest,
          responses,
          averageResponseIndex: compose(sum, map(get('index')))(responses),
        })),
        Object.values,
        produce(itemsById => {
          allInteractions.forEach(
            ({ interactionData: { value: studentResponses = [] }, userId }) => {
              studentResponses.forEach(({ id: responseId }, index) => {
                itemsById[responseId].responses.push({ userId, index })
              })
            },
          )
        }),
        unary(toKeyedObject),
        map(set('responses', [])),
      )(isVocab ? vocabItems : items),
    [allInteractions, isVocab, items, vocabItems],
  )

  return allInteractions?.length ? (
    <>
      <Typography component="p">
        The list below is sorted by each item's average position across all
        responses.
      </Typography>
      <RankingList
        disabled
        items={sortedItems}
        length={items?.length}
        name="items"
        noLabel
      />
    </>
  ) : (
    <Centered component="p">(no responses)</Centered>
  )
}

export default EvaluationRankingAggregate
