import OldMultiSelectReplaceMe from 'common/formControls/selects/OldMultiSelectReplaceMe'

/**
 * This is just for show -- replace with an actual implementation when ready
 */
const ScorePicker = () => (
  <OldMultiSelectReplaceMe
    ariaLabelledby="scores"
    formControlProps={{ sx: { maxWidth: 200 } }}
    placeholder="Scores"
  />
)

export default ScorePicker
