import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import PullOutIn from 'hss/images/controls/pull-out.svg'
import { border } from 'polished'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { echoTabSize } from 'styling/theming/base'

const StyledWrapper = styled('div', { name: 'Features-NavStrip' })(
  ({
    theme: {
      mixins: { absWidth, size, transition },
      palette,
    },
  }) => ({
    backgroundColor: palette.grey[5],
    height: '100%',
    ...absWidth(echoTabSize),
    overflow: 'hidden',

    button: {
      color: palette.background.inverted,

      '& svg[data-filled="true"]': {
        display: 'none',
      },

      '& svg[data-filled="false"]': {
        display: 'block',
      },

      '&.Mui-selected': {
        '& svg[data-filled="true"]': {
          display: 'block',
        },
        '& svg[data-filled="false"]': {
          display: 'none',
        },
      },

      '&:hover': {
        '&:not(#echo-sidebar-toggle-btn)::before': {
          content: '""',
          height: '100%',
          width: '100%',
          position: 'absolute',
          ...border('right', 8, 'solid', palette.background.inverted),
        },
      },

      '&:not(.Mui-selected):hover': {
        svg: {
          color: palette.background.inverted,
        },
        '& svg[data-filled="true"]': {
          display: 'none',
        },
        '& svg[data-filled="false"]': {
          display: 'block',
        },
        '&:hover': {
          '& svg[data-filled="true"]': {
            display: 'block',
          },
          '& svg[data-filled="false"]': {
            display: 'none',
          },
        },
      },
    },

    '.drawer-expander': {
      ...size(echoTabSize - 8 * 2),
      minWidth: echoTabSize,
      svg: {
        ...transition('transform'),
        transform: 'rotate(0)',
      },
      '&:hover': {
        svg: {
          '& path': {
            strokeWidth: 2,
          },
        },
      },
      '&:not(.drawer-expanded) svg': {
        transform: 'rotate(180deg)',
      },
    },
  }),
)

const NavStrip = props => {
  const { children } = props
  const { isEchoSidebarShrunk, setIsEchoSidebarShrunk } =
    useContext(sidebarContext)

  const toggleEchoDrawerExpanded = () =>
    setIsEchoSidebarShrunk(!isEchoSidebarShrunk)

  return (
    <StyledWrapper>
      <Tooltip
        title={isEchoSidebarShrunk ? 'Expand sidebar' : 'Collapse sidebar'}>
        <IconButton
          className={cl({
            'drawer-expander': true,
            'drawer-expanded': !isEchoSidebarShrunk,
          })}
          disableRipple
          id="echo-sidebar-toggle-btn"
          onClick={toggleEchoDrawerExpanded}>
          <PullOutIn />
        </IconButton>
      </Tooltip>

      {children}
    </StyledWrapper>
  )
}

NavStrip.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavStrip
