import FiltersContainer from 'hss/views/Search/Filters/FiltersContainer'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { useContext } from 'react'

const Filters = () => {
  const { primaryFiltersExpanded } = useContext(searchContext)

  return primaryFiltersExpanded ? (
    <FiltersContainer>No filters available.</FiltersContainer>
  ) : null
}

export default Filters
