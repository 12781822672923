import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import TextField from 'common/formControls/textInputs/TextField'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { componentShape } from 'core/shapes'
import { findObj } from 'fp/arrays'
import { assertRange, round } from 'fp/numbers'
import { set } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { curryRight, isDefined } from 'fp/utils'
import { items } from 'hss/views/Library/AddToLibrary/assetTypes/Interactives'
import { useContext, useMemo, useState } from 'react'
import { compose } from 'redux'
import { interactiveGradingContext } from '../../InteractiveGradingProvider'
import { interactiveContext } from '../../InteractiveProvider'

const Container = styled('div')({
  '.MuiTextField-root': { width: 100 },
})
const ManualOverrideGrader = ({ CustomRenderer }) => {
  const {
    allowManualScoring,
    maxScoreToUse,
    overrideValue,
    saveButtonDisabled,
    scoreOverrideEnabled,
    setSaveButtonDisabled,
    setScoreData,
    setScoreOverrideEnabled,
  } = useContext(interactiveGradingContext)

  const {
    Renderer,
    gradingEnabled,
    interactive: { contentSubType, name },
    scoreData: savedScoreData,
  } = useContext(interactiveContext)

  const { score = 0 } = savedScoreData || {}

  const origDisplayScore = useMemo(
    () => round(score * maxScoreToUse),
    [maxScoreToUse, score],
  )

  const [origOverrideDisplayValue, setOrigOverrideDisplayValue] =
    useState(origDisplayScore)

  const handleOverrideToggle = ({ target: { checked } }) => {
    setOrigOverrideDisplayValue(origDisplayScore)
    setScoreOverrideEnabled(!scoreOverrideEnabled)
    setSaveButtonDisabled(false)

    if (!checked) {
      setScoreData(set('manualScoreOverride', null))
    }
  }

  const handleScoreChange = ({ target: { value } }) => {
    setOrigOverrideDisplayValue('') // controlled inputs need an empty string instead of null as a fallback display value

    const overrideScore = isEmptyString(value)
      ? null
      : compose(
          curryRight(assertRange, 0, maxScoreToUse),
          round,
          Number.parseFloat,
        )(value) / maxScoreToUse
    setSaveButtonDisabled(false)
    setScoreData(set('manualScoreOverride', overrideScore))
  }

  const label = name || findObj('contentSubType', contentSubType)(items)?.label

  const isSmallScreen = useContainerQuery().down('sm')

  const InteractiveRenderer = CustomRenderer || Renderer

  return (
    <Container>
      <InteractiveRenderer />

      {allowManualScoring ? (
        <>
          <div
            style={{
              marginTop: 24,
            }}>
            <Typography
              data-testid={`${contentSubType}-total-points`}
              variant="small-semibold">
              {`Total Points${saveButtonDisabled ? '' : ' to be '} Awarded:
                  ${
                    isDefined(overrideValue) ? overrideValue : origDisplayScore
                  }`}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
            }}>
            <SwitchFormControl
              checked={scoreOverrideEnabled}
              data-testid={`${contentSubType}-override-grade`}
              disabled={!gradingEnabled}
              helperText="When enabled, you can manually set the score of this interactive."
              label="Override grade"
              onChange={handleOverrideToggle}
            />
            {Boolean(scoreOverrideEnabled) && (
              <TextField
                inputProps={{
                  'aria-label': `Score for ${label}`,
                  min: 0,
                  max: maxScoreToUse,
                }}
                label="Score"
                name="override"
                onChange={handleScoreChange}
                type="number"
                value={
                  isDefined(overrideValue)
                    ? overrideValue
                    : origOverrideDisplayValue
                }
                variant="filled"
              />
            )}
          </div>
        </>
      ) : null}
    </Container>
  )
}

ManualOverrideGrader.propTypes = {
  CustomRenderer: componentShape,
}

export default ManualOverrideGrader
