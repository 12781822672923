import AddIcon from '@mui/icons-material/AddCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { last } from 'fp/arrays'
import { assertRange } from 'fp/numbers'
import { set } from 'fp/objects'
import { produce } from 'immer'
import PropTypes from 'prop-types'
import { adminUserPreferencesDefaults } from 'shared/schema/user'

const Actions = ({ onChange }) => {
  const handleAdd = () => {
    onChange(
      produce(draft => {
        const lastGradebookSegment = last(draft.gradeColors?.segmentColors) || {
          begin: 1,
          colorId: draft.gradeColors?.defaultColor,
          end: 1,
        }

        draft.gradeColors.segmentColors.push({
          begin: assertRange(lastGradebookSegment.end + 1, 0, 100),
          colorId: draft.gradeColors.defaultColor,
          end: 100,
        })
      }),
    )
  }

  const handleDefaults = () => {
    onChange(set('gradeColors', adminUserPreferencesDefaults.gradeColors))
  }

  return (
    <Box
      display="flex"
      mt={2}
      style={{ gap: 10 }}>
      <Tooltip
        placement="top"
        title="Add Segment">
        <Button
          color="secondary"
          data-testid="add-button"
          onClick={handleAdd}
          startIcon={<AddIcon />}
          variant="secondary">
          Add Segment
        </Button>
      </Tooltip>

      <Tooltip
        placement="top"
        title="Restore default grade color preferences">
        <Button
          color="secondary"
          data-testid="restore-button"
          onClick={handleDefaults}
          variant="tertiary">
          Restore Default
        </Button>
      </Tooltip>
    </Box>
  )
}

Actions.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default Actions
