import {
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_RUBRIC,
  INTERACTIVE_TYPE_ACTIVITY,
  INTERACTIVE_TYPE_ANIMATOR,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_AUDIO_RECORDING,
  INTERACTIVE_TYPE_CHART,
  INTERACTIVE_TYPE_CHART_COMPLETION,
  INTERACTIVE_TYPE_DGO_TABLE,
  INTERACTIVE_TYPE_DRAW_TOOL,
  INTERACTIVE_TYPE_ESSAY,
  INTERACTIVE_TYPE_EVALUATION_RANKING,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_FUN_FACTS,
  INTERACTIVE_TYPE_GROUP_AND_SORT,
  INTERACTIVE_TYPE_HOTSPOT,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_IMAGE_CLICKER,
  INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  INTERACTIVE_TYPE_IMAGE_ZOOMY,
  INTERACTIVE_TYPE_LINEAR_SCALE,
  INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  INTERACTIVE_TYPE_PACING_GUIDE,
  INTERACTIVE_TYPE_PLOT_DIAGRAM,
  INTERACTIVE_TYPE_POLL,
  INTERACTIVE_TYPE_STACKED_SEQUENCE,
  INTERACTIVE_TYPE_TIMELINE,
  INTERACTIVE_TYPE_VENN_DIAGRAM,
  INTERACTIVE_TYPE_VIDEO,
  INTERACTIVE_TYPE_WORD_CLOUD,
} from 'core/consts'
import { set } from 'fp/objects'

export const items = [
  { label: 'Activity', contentSubType: INTERACTIVE_TYPE_ACTIVITY },
  { label: 'Animator', contentSubType: INTERACTIVE_TYPE_ANIMATOR },
  { label: 'Audio', contentSubType: INTERACTIVE_TYPE_AUDIO },
  {
    label: 'Audio Recording',
    contentSubType: INTERACTIVE_TYPE_AUDIO_RECORDING,
  },
  { label: 'Chart', contentSubType: INTERACTIVE_TYPE_CHART },
  {
    label: 'Chart Completion',
    contentSubType: INTERACTIVE_TYPE_CHART_COMPLETION,
  },
  { label: 'Draw Tool', contentSubType: INTERACTIVE_TYPE_DRAW_TOOL },
  { label: 'Essay', contentSubType: INTERACTIVE_TYPE_ESSAY },
  {
    label: 'Evaluation/Ranking',
    contentSubType: INTERACTIVE_TYPE_EVALUATION_RANKING,
  },
  { label: 'Flipbook', contentSubType: INTERACTIVE_TYPE_FLIPBOOK },
  { label: 'Fun Fact', contentSubType: INTERACTIVE_TYPE_FUN_FACTS },
  { label: 'Group & Sort', contentSubType: INTERACTIVE_TYPE_GROUP_AND_SORT },
  { label: 'Hotspot', contentSubType: INTERACTIVE_TYPE_HOTSPOT },
  { label: 'Image', contentSubType: INTERACTIVE_TYPE_IMAGE },
  { label: 'Image Clicker', contentSubType: INTERACTIVE_TYPE_IMAGE_CLICKER },
  {
    label: 'Image Title Drag',
    contentSubType: INTERACTIVE_TYPE_IMAGE_TITLE_DRAG,
  },
  { label: 'Image Zoomer', contentSubType: INTERACTIVE_TYPE_IMAGE_ZOOMY },
  { label: 'Linear Scale', contentSubType: INTERACTIVE_TYPE_LINEAR_SCALE },
  {
    label: 'Multiple Choice',
    contentSubType: INTERACTIVE_TYPE_MULTIPLE_CHOICE,
  },
  { label: 'Pacing Guide', contentSubType: INTERACTIVE_TYPE_PACING_GUIDE },
  { label: 'Plot Diagram', contentSubType: INTERACTIVE_TYPE_PLOT_DIAGRAM },
  { label: 'Poll', contentSubType: INTERACTIVE_TYPE_POLL },
  { label: 'Rubric', contentType: CONTENT_TYPE_RUBRIC },
  {
    label: 'Stacked Sequence',
    contentSubType: INTERACTIVE_TYPE_STACKED_SEQUENCE,
  },
  { label: 'Table', contentSubType: INTERACTIVE_TYPE_DGO_TABLE },
  { label: 'Timeline', contentSubType: INTERACTIVE_TYPE_TIMELINE },
  { label: 'Venn Diagram', contentSubType: INTERACTIVE_TYPE_VENN_DIAGRAM },
  { label: 'Video', contentSubType: INTERACTIVE_TYPE_VIDEO },
  { label: 'Word Cloud', contentSubType: INTERACTIVE_TYPE_WORD_CLOUD },
].map(item =>
  item.contentType ? item : set('contentType', CONTENT_TYPE_INTERACTIVE)(item),
)
