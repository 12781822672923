import SearchInput from 'common/formControls/textInputs/SearchInput'
import { alter } from 'core/store/search/squery'
import { useCallback, useContext } from 'react'
import { compose } from 'redux'
import { searchContext } from '../../SearchProvider'
import useSearchingText from '../../useSearchingText'

/**
 * This is set up to handle an array of searchable fields, but currently
 * only the first one is used.
 *
 * I anticipate we may need to support more than one, but am not
 * bothering with the complete implementation until we need it.
 */

const SearchInputControl = () => {
  const { pluralLabel, searchFields, setSquery, squery } =
    useContext(searchContext)

  const value = useSearchingText()

  // biome-ignore lint/correctness/useExhaustiveDependencies: intentional
  const handleSearch = useCallback(
    newValue =>
      compose(
        setSquery,
        alter.set.where(searchFields[0]).contains(newValue),
      )(squery),
    // don't add squery here
    [setSquery],
  )

  if (!searchFields?.length) return null

  return (
    <SearchInput
      label={`Search ${pluralLabel}`}
      onChange={handleSearch}
      value={value}
      variant="search-form"
    />
  )
}

export default SearchInputControl
