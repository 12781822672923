import Box from '@mui/material/Box'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import type { Content } from 'common/@types/custom'
import {
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import { difference, filter, flatten } from 'fp/arrays'
import { get } from 'fp/objects'
import { fallbackTo, matches, matchesOneOf, not } from 'fp/utils'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
import { useCallback, useContext, useMemo } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getContentNav } from 'selectors/contentViewer'
import { assignmentEditContext } from '../assignmentEditContext'
import TreeContentComponent from './TreeContentComponent'

const canBeHidden = (item: Content): boolean =>
  matchesOneOf('contentType', [
    CONTENT_TYPE_SECTION,
    CONTENT_TYPE_SUBSECTION,
    CONTENT_TYPE_SOURCE,
  ])(item) && !matches('contentSubType', CONTENT_SUBTYPE_CHAPTER_SUMMARY)(item)

const ContentTreeItem: React.FC<Content> = ({
  children = [],
  id = '',
  name,
}) => (
  <TreeItem
    ContentComponent={TreeContentComponent}
    ContentProps={
      {
        notSelectedIcon: EyeOff,
        selectedIcon: Eye,
      } as object // TODO: Fix this type when we upgrade to MUI X v7
    }
    label={name}
    nodeId={id}>
    {children.filter(canBeHidden).map(child => (
      <ContentTreeItem
        key={child.id}
        {...child}
      />
    ))}
  </TreeItem>
)

const flattenChildren = (item: Content): Content[] => [
  item,
  ...flatten(item?.children?.map(flattenChildren)),
]

const Contents = () => {
  const contentId = useWatch({ name: 'contentId' })

  const contentNav = useSelector(
    getContentNav({
      contentId,
      leafContentTypes: SECTION_CONTENT_TYPES,
    }),
  )
  const { setValue } = useFormContext()
  const { excludedContentIds, toggleExcludeContentId } = useContext(
    assignmentEditContext,
  )

  const hidableContents = useMemo<Content[]>(
    () =>
      compose(
        filter(canBeHidden),
        filter(compose(not, matches('teacherEdition', true))),
        fallbackTo([]),
      )(contentNav),
    [contentNav],
  )

  const allContentIds = useMemo(
    () => flatten((hidableContents || []).map(flattenChildren)).map(get('id')),
    [hidableContents],
  )

  const selectedContentIds: string[] =
    difference(allContentIds)(excludedContentIds)

  useDeepCompareEffect(() => {
    // The excludedContentIds field is actually ignored by the assignment edit form when determining dirtiness.
    // But it should still be marked dirty here, because it's dirty.
    // (This code runs whenever the user toggles a content node.)
    setValue('excludedContentIds', excludedContentIds, { shouldDirty: true })
  }, [excludedContentIds, setValue])

  const handleNodeSelect = useCallback(
    (_: React.SyntheticEvent, nodeId: string) => toggleExcludeContentId(nodeId),
    [toggleExcludeContentId],
  )

  return (
    <Box mt={4}>
      {hidableContents.length ? (
        <TreeView
          aria-label="Contents"
          onNodeSelect={handleNodeSelect}
          // @ts-ignore - passing a string[] is acceptable in MUI X v6.
          //   MUI's types only accept a single string unless the multiselect prop is added.
          //   I tried adding this prop, but it led to further logic issues around multiselect
          //   in this UI.
          selected={selectedContentIds}
          // @ts-ignore TODO: TreeView does not have a `variant` prop.
          variant="content-selections"
        >
          {hidableContents.map(content => (
            <ContentTreeItem
              key={content.id}
              {...content}
            />
          ))}
        </TreeView>
      ) : (
        'This type of assignment does not contain portions that can be hidden.'
      )}
    </Box>
  )
}

Contents.formFields = [
  'excludedContentIds',
  'data.settings.includeChapterSummary',
]

export default Contents
