import { get } from 'fp/objects'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { useContext } from 'react'
import { compose } from 'redux'

const useSelectedUserAssignmentId = () =>
  compose(
    get('currentUserAssignmentId'),
    useContext,
  )(userAssignmentSelectionContext)

export default useSelectedUserAssignmentId
