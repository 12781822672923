import Form from 'common/formControls/Form'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import { equals, get } from 'fp/objects'
import { fallbackTo, pipe } from 'fp/utils'
import useNavigation from 'hooks/useNavigation'
import { useRef } from 'react'
import { useWatch } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import LoginLayout from './LoginLayout'
import LoginTextField from './LoginTextField'

const FormContents = () => {
  const newPassword = useRef({})
  newPassword.current = useWatch({ name: 'password' })
  return (
    <>
      <LoginTextField
        label="Password"
        name="password"
        required
        rules={{
          minLength: {
            value: 4,
            message: 'Password must be at least 4 characters long.',
          },
        }}
        type="password"
      />
      <LoginTextField
        label="Confirm Password"
        name="confirmPassword"
        required
        rules={{
          validate: pipe(
            get('value'),
            equals(newPassword.current),
            fallbackTo('Both passwords must match.', true),
          ),
        }}
        type="password"
      />
    </>
  )
}

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const { navigate } = useNavigation()

  const resetCode = searchParams.get('resetCode')

  const redirectToLogin = () => {
    navigate('/login')
  }
  return (
    <LoginLayout>
      <p>Choose a new password for your account</p>
      <Form
        actionType={actionTypes.USER_RESET_PASSWORD}
        defaultValues={{ password: '', resetCode }}
        name="ResetPasswordForm"
        onSuccess={redirectToLogin}
        suppressDirtyNavigationWarning
        suppressRequiredLabel>
        <FormContents />
        <SubmitButton>Reset</SubmitButton>
      </Form>
    </LoginLayout>
  )
}

export default ResetPassword
