import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import ResponsiveTable from 'common/layout/ResponsiveTable'
import Html from 'common/text/Html'
import { map, orderBy } from 'fp/arrays'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const VocabRollupContainer = styled(Container, {
  name: 'VocabRollup_Container',
})(
  ({
    theme: {
      mixins: { featuredMaxWidth },
    },
  }) => ({
    maxWidth: featuredMaxWidth,
  }),
)

const VocabRollupTable = styled(ResponsiveTable, {
  name: 'VocabRollup_VocabRollupTable',
})(
  ({
    theme: {
      mixins: { important, rem },
      palette,
    },
  }) => ({
    tableLayout: 'auto',
    caption: {
      marginBottom: rem(4),
    },
    th: {
      color: palette.text.secondary,
      background: important(palette.grey[5]),
    },
  }),
)

const CommonVocabRollup = ({ vocabPhrases }) =>
  vocabPhrases?.length ? (
    <VocabRollupContainer>
      <VocabRollupTable>
        <thead>
          <tr>
            <th>Term</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {compose(
            map(({ id, name, data: { definition } = {} }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>{definition ? <Html body={definition} /> : 'N/A'}</td>
              </tr>
            )),
            orderBy('name'),
          )(vocabPhrases)}
        </tbody>
      </VocabRollupTable>
    </VocabRollupContainer>
  ) : null

CommonVocabRollup.propTypes = {
  vocabPhrases: PropTypes.array.isRequired,
}

export default CommonVocabRollup
