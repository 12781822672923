import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET } from 'core/consts'
import { get } from 'fp/objects'
import useContent from 'hooks/useContent'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'

const Navigation = props => {
  const { onTabChange, selectedTab, totalPanes, ...rest } = props
  const { currentPage, value } = selectedTab
  const { contentType, id } = get(`content.${value}`)(rest)
  const { childrenCount } = useContent({ contentType, contentId: id })

  const handleClickNext = useCallback(() => {
    // if on a pageset, go to next page within pane
    if (
      contentType === CONTENT_TYPE_PAGESET &&
      currentPage < childrenCount - 1
    ) {
      onTabChange({
        value,
        currentPage: currentPage + 1,
        isFromPrevious: false,
      })
    } else {
      onTabChange({ value: value + 1, currentPage: 0, isFromPrevious: false })
    }
  }, [childrenCount, contentType, currentPage, onTabChange, value])

  const handleClickPrevious = useCallback(() => {
    // if on a pageset, go to prev page within pane
    if (contentType === CONTENT_TYPE_PAGESET && currentPage > 0) {
      onTabChange({ value, isFromPrevious: true, currentPage: currentPage - 1 })
    } else {
      onTabChange({
        value: value - 1,
        isFromPrevious: true,
        currentPage: childrenCount - 1,
      })
    }
  }, [childrenCount, contentType, currentPage, onTabChange, value])

  return (
    <Box
      ml={2}
      mt={2}
      position="absolute"
      zIndex="appBar">
      <IconButton
        aria-label="Previous"
        color="secondary"
        data-bodyvariant="body1"
        disabled={Boolean(
          (contentType === CONTENT_TYPE_PAGE || currentPage === 0) &&
            selectedTab.value === 0,
        )}
        onClick={handleClickPrevious}
        sx={{ marginRight: 3 }}
        variant="secondary">
        <ChevronLeft />
      </IconButton>
      <IconButton
        aria-label="Next"
        color="secondary"
        data-bodyvariant="body1"
        disabled={
          selectedTab.value === totalPanes - 1 &&
          (contentType === CONTENT_TYPE_PAGE ||
            currentPage === childrenCount - 1)
        }
        onClick={handleClickNext}
        variant="secondary">
        <ChevronRight />
      </IconButton>
    </Box>
  )
}

Navigation.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  totalPanes: PropTypes.number.isRequired,
}

export default Navigation
