import { styled } from '@mui/material/styles'
import Headline from 'common/text/Headline'
import PropTypes from 'prop-types'

const ImageSlide = styled(
  ({ className, slide }) => {
    const { description, src, title1, title2 } = slide

    return (
      <div
        className={className}
        style={{ backgroundImage: `url(${src})` }}>
        <div>
          <Headline title={title1}>
            <Headline title={title2} />
            <p>{description}</p>
          </Headline>
        </div>
      </div>
    )
  },
  {
    name: 'Flipbook-ImageSlide',
  },
)(() => ({
  height: '100%',
  position: 'absolute',
  width: '100%',
  backgroundSize: 'cover',
  // TODO: once we start using real content, right now we are just using images
  // '> div': {
  //   background: `linear-gradient(
  //     ${transparentize(0.1, palette.border[0])},
  //     ${transparentize(1, palette.border[0])}
  //   )`,
  //   borderRadius: borderRadius - 1,
  //   padding: rem(2),
  // },
}))

ImageSlide.propTypes = {
  slide: PropTypes.object.isRequired,
}

export default ImageSlide
