import Html from 'common/text/Html'
import { when } from 'fp/utils'
import useEffectOnce from 'hooks/useEffectOnce'
import { ECHO_FEATURE_ACT } from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useContext } from 'react'
import FeatureFootnotes from './FeatureFootnotes'
import FeatureHeadline from './FeatureHeadline'

const feature = ECHO_FEATURE_ACT

const FeatureAct = () => {
  const {
    completed,
    interactiveData: { bodyText },
    markComplete,
  } = useContext(interactiveContext)

  useEffectOnce(() => {
    when(!completed, markComplete)
  })

  return (
    <>
      <FeatureHeadline title="Take Informed Action">
        <Html body={bodyText} />
      </FeatureHeadline>

      <FeatureFootnotes feature={feature} />
    </>
  )
}

export default FeatureAct
