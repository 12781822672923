import Headline from 'common/text/Headline'
import Divider from '../Divider'
import SaveButton from '../SaveButton'
import Google from './Google'
import Turnitin from './Turnitin'

const Integrations = () => {
  const handleSave = () => {
    // TODO:  Fill out once the user API is complete.
  }

  return (
    <Headline
      mb={12}
      textTransform="capitalize"
      title="Integrations">
      <Headline
        size={4}
        title="Google">
        <Google />
      </Headline>

      <Divider />

      <Headline
        size={4}
        title="Turnitin">
        <Turnitin />
      </Headline>

      <SaveButton onClick={handleSave} />
    </Headline>
  )
}

export default Integrations
