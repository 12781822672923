import Stack from '@mui/material/Stack'
import MenuButton from 'common/menus/MenuButton'
import ReopenButton from 'hss/sections/contentBlocks/Interactive/Teacher/ReopenButton'
import SubmitButton from 'hss/sections/contentBlocks/Interactive/Teacher/SubmitButton'
import LogoutMenuItems from 'routing/shells/LogoutMenuItems'

const RightSide = () => (
  <Stack
    direction="row"
    spacing={2}>
    <SubmitButton submitAssignment />
    <ReopenButton reopenAssignment />
    <MenuButton
      data-testid="user-menu"
      label="Options menu">
      <LogoutMenuItems />
    </MenuButton>
  </Stack>
)

export default RightSide
