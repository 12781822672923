import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import ContainedFormContent from 'common/formControls/Form/ContainedFormContent'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { QUESTION_PROMPT_TYPE_QUESTION_HTML } from 'core/consts'
import { componentShape } from 'core/shapes'
import { map, shuffle } from 'fp/arrays'
import { increment } from 'fp/numbers'
import { set } from 'fp/objects'
import { intToLetter, suffix } from 'fp/strings'
import { identity, pipe } from 'fp/utils'
import withQuestionPrompt from 'hss/sections/contentBlocks/Interactive/withQuestionPrompt'
import { useContext, useMemo } from 'react'
import { compose } from 'redux'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import { useIsInAnswerKeyContext } from '../answerKeyUtils'

const BaseMultipleChoice = withQuestionPrompt(props => {
  const { GroupRenderer } = props

  const { interactiveData, isGrading, submittable } =
    useContext(interactiveContext)
  const showAnswerKey = useIsInAnswerKeyContext()
  const {
    answerChoices = [],
    listType = 'none',
    previewing = false,
    randomOrder = false,
  } = interactiveData

  const myAnswerChoices = useMemo(
    () =>
      compose(
        listType === 'none'
          ? identity
          : map((answerChoice, index) =>
              set(
                'label',
                compose(
                  suffix(`. ${answerChoice.label}`),
                  listType === 'letters' ? intToLetter : identity,
                  increment,
                )(index),
              )(answerChoice),
            ),
        /* istanbul ignore if */
        randomOrder ? pipe(Array.from, shuffle) : identity,
      )(answerChoices),
    [answerChoices, listType, randomOrder],
  )

  return (
    <ContainedFormContent
      m="0 auto"
      maxWidth="100%">
      <Box>
        <FormControl
          component="fieldset"
          disabled={isGrading || !submittable || showAnswerKey}
          required={!previewing}
          style={isGrading ? { pointerEvents: 'none' } : {}}>
          <ScreenReaderText>
            <FormLabel component="legend">Your Answer</FormLabel>
          </ScreenReaderText>

          <GroupRenderer
            options={myAnswerChoices}
            required={!previewing}
          />
        </FormControl>
      </Box>
    </ContainedFormContent>
  )
}, QUESTION_PROMPT_TYPE_QUESTION_HTML)

BaseMultipleChoice.propTypes = {
  GroupRenderer: componentShape.isRequired,
}

export default BaseMultipleChoice
