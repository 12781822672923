import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LinkButton from 'common/navigation/links/LinkButton'
import CardTitle from 'common/text/CardTitle'
import { entityIdShape, userAssignmentShape } from 'core/shapes'
import { asPercentageString } from 'fp/numbers'
import { capitalize } from 'fp/strings'
import { formatDateTimeStrLong } from 'locale/i18n'
import PropTypes from 'prop-types'
import { getStudentBinderRoute } from 'routing/consts'
import BinderRow from './BinderRow'
import useCardContent from './useCardContent'

const BinderContent = props => {
  const {
    assignment,
    assignment: { maxScore },
    content,
    contentType,
    id,
    name,
    score,
    submittedDate,
    userAssignment,
  } = props

  const { callToAction, cardContentType, cardTitle } = useCardContent(
    userAssignment,
    content,
    false,
  )
  const assignedBy = `${assignment.createdBy.firstName} ${assignment.createdBy.lastName}`

  return (
    <Stack
      height="100%"
      justifyContent="space-between"
      spacing={1}>
      <CardHeader
        title={
          <CardTitle
            eyebrow={capitalize(cardTitle)}
            title={name}
          />
        }
      />
      <CardContent>
        <Typography variant="small">
          <Divider />
          <Stack
            divider={<Divider />}
            spacing={1}>
            <BinderRow
              left="Assigned by"
              right={assignedBy}
            />
            <BinderRow
              left="Completed on"
              right={formatDateTimeStrLong(submittedDate)}
            />
            <BinderRow
              left="Grade"
              right={asPercentageString(score / maxScore)}
            />
          </Stack>
        </Typography>
      </CardContent>
      <CardActions>
        <LinkButton
          aria-label={`${callToAction}, ${cardContentType} ${name}`}
          color="secondary"
          size="small"
          to={getStudentBinderRoute({ contentType, id })}
          variant="secondary">
          {callToAction}
        </LinkButton>
      </CardActions>
    </Stack>
  )
}

BinderContent.propTypes = {
  assignment: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  contentType: PropTypes.string.isRequired,
  id: entityIdShape.isRequired,
  name: PropTypes.string.isRequired,
  score: PropTypes.number,
  submittedDate: PropTypes.string,
  userAssignment: userAssignmentShape.isRequired,
}

export default BinderContent
