export const pacingOptionKeys = ['a', 'b', 'c']

export const defaultPacingOptions = {
  a: {
    name: '15 Days',
    description: 'Focus on narrative or inquiry, depending on skill placement',
  },
  b: {
    name: '20 Days',
    description: 'Focus on all narrative and on inquiry with skills',
  },
  c: {
    name: '25 Days',
    description: 'Focus on narrative and inquiry',
  },
}

export const defaultFeatureGroups = {
  'Chapter Narrative': {
    paletteColor: 'cobalt',
    pacingOptionShades: { a: 4, b: 3, c: 2 },
  },
  'Chapter Inquiry': {
    featureGroupNameColor: 'purple', // WCAG or no, violet is too light on the white background
    paletteColor: 'violet',
    pacingOptionShades: { a: 4, b: 3, c: 2 },
  },
  'Chapter Summary': {
    paletteColor: 'tangerine',
    pacingOptionShades: { a: 4, b: 3, c: 2 },
  },
  special: {
    paletteColor: 'grey',
    pacingOptionShades: { a: 5, b: 4, c: 3 },
  },
}
