import Box from '@mui/material/Box'
import BusySpinner from 'common/indicators/BusySpinner'
import Html from 'common/text/Html'
import { INTERACTIVE_TYPE_FLIPBOOK } from 'core/consts'
import { maybeParseJSON } from 'fp/strings'
import { noop } from 'fp/utils'
import { Suspense, lazy, useContext, useMemo } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import Slide from './Slide'

/**
 * TODO:
 *
 * react-swipeable should be replaced by swiper (better a11y and user experience)
 *
 * Either this stock effect or a custom one could be used:
 * https://swiperjs.com/demos#effect-coverflow
 *
 * I'm not replacing it right now because it's not clear if this interactive will
 * be used in the actual product.
 */

const Carousel3D = lazy(
  () => import(/* webpackChunkName: "Carousel3D" */ 'common/carousels/3d'),
)

const Flipbook = () => {
  const { completed, interactiveData, markComplete } =
    useContext(interactiveContext)

  const {
    caption = '',
    offsetRadius = 5,
    showNavigation = true,
    slides: jsonSlides,
    transition = 'gentle',
  } = interactiveData

  const slides = useMemo(() => {
    const parsedSlides = maybeParseJSON(jsonSlides) || []
    return parsedSlides.map(slide => ({
      content: (
        <Slide
          className="flipbook-slide"
          slide={slide}
        />
      ),
    }))
  }, [jsonSlides])

  const handleSlideChanged = useMemo(
    () => (completed ? noop : markComplete),
    [completed, markComplete],
  )

  return (
    <Box>
      <Suspense fallback={<BusySpinner />}>
        <Carousel3D
          offsetRadius={offsetRadius}
          onSlideChanged={handleSlideChanged}
          showNavigation={showNavigation}
          slides={slides}
          transition={transition}
        />
      </Suspense>
      {Boolean(caption) && <Html body={caption} />}
    </Box>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_FLIPBOOK,
}

export default Flipbook
