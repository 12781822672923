import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import ChapterFeatureGroupCells from './ChapterFeatureGroupCells'

const ChapterSegment = ({
  segment: {
    chapter: { title1, title2 },
    featureGroups = [],
  },
}) => (
  <>
    <TableRow>
      <TableCell rowSpan={featureGroups.length}>
        {Boolean(title1) && (
          <>
            <Typography variant="body1-semibold">{title1}</Typography>
            {Boolean(title2) && ': '}
          </>
        )}
        {title2}
      </TableCell>

      <ChapterFeatureGroupCells {...featureGroups[0]} />
    </TableRow>

    {featureGroups.slice(1).map(featureGroup => (
      <TableRow key={featureGroup.name}>
        <ChapterFeatureGroupCells {...featureGroup} />
      </TableRow>
    ))}
  </>
)

ChapterSegment.propTypes = {
  segment: PropTypes.shape({
    chapter: PropTypes.shape({
      title1: PropTypes.string,
      title2: PropTypes.string,
    }),
    featureGroups: PropTypes.array,
  }).isRequired,
}

export default ChapterSegment
