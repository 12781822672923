import {
  CONTENT_TYPE_ASSESSMENT,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
} from 'core/consts'
import { find, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { capitalize } from 'fp/strings'
import { isDefined, isUndefined, matches } from 'fp/utils'
import { compose } from 'redux'

// Course filter utils shared between Assignments and OpenTasks

const ASSESSMENTS_KEY = 'test'
export const nonChapterSortOrder = [
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_ECHO,
  ASSESSMENTS_KEY,
]

export const assignmentsSort = (a, b) => {
  // Sort order should be:
  //  -- All course chapters first, desc alphabetically
  //  -- Other potential contentTypes, in the order of `nonChapterSortOrder`

  if (!(isDefined(a) || isDefined(b))) return 0
  if (!isDefined(b)) return -1
  if (!isDefined(a)) return 1

  const aLower = a.toLowerCase()
  const bLower = b.toLowerCase()

  const aIndex = nonChapterSortOrder.findIndex(type => aLower === type)
  const bIndex = nonChapterSortOrder.findIndex(type => bLower === type)

  const isAChapter = aIndex === -1
  const isBChapter = bIndex === -1

  // If both are non-course, use order in nonChapterSortOrder
  if (!(isAChapter || isBChapter)) {
    return aIndex - bIndex
  }

  // If both are course, alphabetize against each other
  if (isAChapter && isBChapter) {
    return a.localeCompare(b)
  }

  // If one is a course and one is not, non-course will always come after
  if (!isAChapter) {
    return 1
  }

  return -1
}

/**
 * If a non-chapter content type is assigned, a single divider should appear between
 * the last chapter's parent course title and the alphabetically first contentType display name
 */
export const filterListNeedsDivider = data => {
  const dataContent = map(get('content'))(data)
  const hasChapter = compose(
    Boolean,
    find(matches('contentType', CONTENT_TYPE_CHAPTER)),
  )(dataContent)

  // If no chapter content is assigned, no divider is needed
  if (!hasChapter) return null

  const hasEcho = compose(
    Boolean,
    find(matches('contentType', CONTENT_TYPE_ECHO)),
  )(dataContent)
  const hasSource = compose(
    Boolean,
    find(matches('contentType', CONTENT_TYPE_SOURCE)),
  )(dataContent)

  let dividerContentType
  switch (true) {
    case hasSource:
      dividerContentType = CONTENT_TYPE_SOURCE
      break
    case hasEcho:
      dividerContentType = CONTENT_TYPE_ECHO
      break
    default:
      dividerContentType = CONTENT_TYPE_ASSESSMENT
      break
  }

  return dividerContentType
}

// The key and displayName are used to populate `groupedAssignments`
const getKeyAndDisplayName = ({ contentType, parentId, parentName }) => {
  let displayName
  let groupKey

  switch (contentType) {
    case CONTENT_TYPE_CHAPTER:
      displayName = parentName
      groupKey = parentId
      break
    case CONTENT_TYPE_ASSESSMENT:
      displayName = capitalize(ASSESSMENTS_KEY)
      groupKey = ASSESSMENTS_KEY
      break
    default:
      displayName = capitalize(contentType)
      groupKey = contentType
      break
  }

  return { displayName, groupKey }
}

/**
 *
 * Group by either chapter id (for CONTENT_TYPE_CHAPTER) or by the name of the contentType for all other types
 * This grouped data is used for filtering with the <CheckboxMultiSelect />
 */

export const groupedAssignments = (assignments, dividerContentType) =>
  assignments?.reduce((acc, ua) => {
    const { content } = ua

    /* istanbul ignore next */
    if (!content) return acc

    const { displayName, groupKey } = getKeyAndDisplayName({
      contentType: content.contentType,
      parentId: content.parent?.parent?.id,
      parentName: content.parent?.parent?.name,
    })

    if (isUndefined(groupKey)) return acc

    const tempData = { ...acc }

    if (!tempData?.[groupKey]) {
      // Set the basic data for the group; displayName is shown in filter dropdown
      tempData[groupKey] = {
        assignments: [],
        displayName,
        hasDivider: content.contentType === dividerContentType,
        id: groupKey,
      }
    }

    tempData[groupKey].assignments.push(ua)
    return tempData
  }, {})
