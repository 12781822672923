import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import OptionListItem from 'hss/sections/contentBlocks/interactives/GroupAndSort/OptionListItem'
import { border } from 'polished'
import { useContext } from 'react'
import { interactiveContext } from '../../../InteractiveProvider'

const StyledOuterListItem = styled(ListItem)(({ theme: { palette } }) => ({
  display: 'block',
  margin: 0,
  padding: 0,
  ...border('top', 1, 'solid', palette.border[0]),
  '&:first-of-type': {
    borderTop: 'none',
  },
}))

const StyledInnerListItem = styled(OptionListItem)({
  margin: 0,
})

const SortableGroupContents = ({ items, renderItem }) => {
  const {
    interactive: {
      scoring: { mode: scoringMode },
    },
  } = useContext(interactiveContext)

  return items.map((itemGroup, index) => (
    <StyledOuterListItem key={index}>
      <List>
        {itemGroup.map(({ itemId, count, isCorrect }) => (
          <StyledInnerListItem
            key={itemId}
            {...{
              isCorrect,
              scoringMode,
            }}>
            {renderItem(itemId, count)}
          </StyledInnerListItem>
        ))}
      </List>
    </StyledOuterListItem>
  ))
}

export default SortableGroupContents
