import DataTable from 'common/tables/DataTable'
import {
  LinkToTableCell,
  RowActionsMenu,
  RowSelector,
  SimpleCell,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import { assert, alter } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { prefix } from 'fp/strings'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import { districtsUrl } from 'routing/consts'

const tableId = 'clDistrictsList'

const buildColumns = (/* has */) => {
  const viewUrl = ({ id }) => `${districtsUrl}/${id}`

  const nameColumn = asPrimary(
    SimpleCell({
      accessorFn: get('name'),
      cell: LinkToTableCell(compose(prefix(`${districtsUrl}/`), get('id'))),
      header: 'Name',
      id: 'target.name',
      meta: { sortFieldName: 'name' },
    }),
  )

  return [
    RowSelector({
      singleLabel: 'Select District',
      pluralLabel: 'Select all Districts',
    }),

    asPrimary(nameColumn),

    RowActionsMenu(() => [
      {
        label: 'View',
        action: { navigate: viewUrl },
      },
    ]),
  ]
}

const searchConfig = (has, limit) => ({
  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.orderBy('name', 'desc'),
  )(assert()),

  pluralLabel: 'Districts',

  restEndpoint: restEndpoint.districts,

  searchFields: ['name'],

  singleLabel: 'District',
})

const withSearchProvider = WrappedComponent => {
  const Enhanced = initialTestingState => {
    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table">
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
