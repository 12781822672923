import Button from '@mui/material/Button'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { ABILITY_CONTENT_EDITING } from 'core/consts'
import { equals, get } from 'fp/objects'
import { fallbackTo, pipe } from 'fp/utils'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { useState } from 'react'
import { useWatch } from 'react-hook-form'

const ChangePassword = () => {
  const [changingPassword, setChangingPassword] = useState(false)
  const password = useWatch({ name: 'password' })
  return changingPassword ? (
    <>
      <HFTextField
        label="Current Password"
        margin="normal"
        name="currentPassword"
        required
        type="password"
        variant="outlined"
      />
      <HFTextField
        label="New Password"
        margin="normal"
        name="password"
        required
        rules={{
          minLength: {
            value: 4,
            message: 'Password must be at least 4 characters long.',
          },
        }}
        type="password"
        variant="outlined"
      />

      <HFTextField
        label="Confirm New Password"
        margin="normal"
        name="confirmPassword"
        required
        rules={{
          validate: pipe(
            get('value'),
            equals(password),
            fallbackTo('Both passwords must match.', true),
          ),
        }}
        type="password"
        variant="outlined"
      />
    </>
  ) : (
    <Button
      color="secondary"
      onClick={() => setChangingPassword(true)}
      variant="secondary">
      Change Password
    </Button>
  )
}

// TODO: why is this a restricted component?  was it just to hide it from users for now?
const ChangePassWordWithFeatureFlag = withAbilityCheck(
  ChangePassword,
  ABILITY_CONTENT_EDITING,
)
export default ChangePassWordWithFeatureFlag
