import ItemWithFeedback from 'common/layout/ItemWithFeedback'
import { get } from 'fp/objects'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { itemShape, responseItemShape } from '../utils'
import ImageRenderer from './ImageRenderer'
import SelectedResponse from './SelectedResponse'

const Image = ({
  dispatch,
  item,
  item: { id, isStatic },
  readOnly,
  responseItem,
  ...rest
}) => {
  const { scoreData } = useContext(interactiveContext)
  const itemScoringResult = get(`result.byId.${id}`)(scoreData)
  return isStatic ? (
    <ImageRenderer item={item} />
  ) : readOnly ? (
    <ItemWithFeedback scoringResult={itemScoringResult}>
      <ImageRenderer item={item} />
    </ItemWithFeedback>
  ) : (
    responseItem && (
      <SelectedResponse
        dispatch={dispatch}
        item={item}
        responseItem={responseItem}
        {...rest}
      />
    )
  )
}

Image.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: itemShape.isRequired,
  readOnly: PropTypes.bool.isRequired,
  responseItem: responseItemShape,
}

export default Image
