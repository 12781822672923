import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { border } from 'polished'

const StyledGroupContent = styled(Box, { name: 'GroupAndSort-GroupContent' })(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    backgroundColor: palette.primary[50],
    minHeight: 150,
    ...border(1, 'solid', palette.border[0]),
    marginTop: -1,
    marginLeft: -1,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: rem(1.5),
  }),
)

export default StyledGroupContent
