import { styled } from '@mui/material/styles'
import BaseImage from 'common/indicators/Image'
import { uploadShape } from 'core/shapes'
import { get } from 'fp/objects'
import Figure from 'hss/sections/contentBlocks/interactives/Figure'
import LongTextAlternative from 'hss/sections/contentBlocks/interactives/Image/LongTextAlternative'
import { useContext, useId } from 'react'
import {
  containedPaddingLeft,
  containedPaddingRight,
} from 'styling/theming/base'
import { contentViewerContext } from '../../ContentViewerProvider'

const Hero = styled(
  ({ className }) => {
    const {
      subsection,
      subsection: {
        data: { caption, captionPosition, imageAltText, longTextAlternative },
      },
    } = useContext(contentViewerContext)

    const captionId = useId()
    const descriptionId = useId()
    const upload = get('uploadsMap.image')(subsection)

    return upload ? (
      <>
        <Figure
          caption={caption}
          captionId={captionId}
          captionPosition={captionPosition}
          hideCaption={!caption}
          useContainedPadding>
          <div className={className}>
            <BaseImage
              alt={imageAltText}
              aria-describedby={captionId}
              aria-details={longTextAlternative ? descriptionId : null}
              objectfit="cover"
              src={upload.url}
              style={{ display: 'block' }}
              width="100%"
            />
          </div>
        </Figure>
        {Boolean(longTextAlternative) && (
          <LongTextAlternative
            html={longTextAlternative}
            id={descriptionId}
            label="Image Details"
            style={{
              marginLeft: containedPaddingLeft,
              marginRight: containedPaddingRight,
            }}
          />
        )}
      </>
    ) : null
  },
  { name: 'Subsection-Hero' },
)({
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'relative',
})

Hero.propTypes = {
  upload: uploadShape,
}

export default Hero
