import Button from '@mui/material/Button'
import BusySpinner from 'common/indicators/BusySpinner'
import { componentShape } from 'core/shapes'
import { noop } from 'fp/utils'
import PropTypes from 'prop-types'
import { forwardRef, useContext } from 'react'
import { Send } from 'react-feather'
import { interactiveContext } from './InteractiveProvider'

const SubmitButton = forwardRef(
  (
    {
      onSubmitting,
      busyIndicator = false,
      children = 'Submit Your Answer',
      disabled = false,
      ...rest
    },
    ref,
  ) => {
    const {
      busy,
      completed,
      interactive: { contentSubType } = {},
      markComplete,
      submitEnabled = true,
    } = useContext(interactiveContext)

    return (
      <Button
        data-testid={`${contentSubType}-submit-button`}
        disabled={completed || disabled || !submitEnabled}
        startIcon={<Send />}
        {...rest}
        onClick={busy ? noop : markComplete}
        ref={ref}
        variant="primary">
        {children}

        {Boolean(busyIndicator && busy) && (
          <>
            &nbsp; <BusySpinner color="inverted" />
          </>
        )}
      </Button>
    )
  },
)

SubmitButton.propTypes = {
  busyIndicator: PropTypes.bool,
  children: componentShape,
  disabled: PropTypes.bool,
  onSubmitting: PropTypes.func,
}

export default SubmitButton
