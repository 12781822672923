import { contentShape } from 'core/shapes'
import { createContext, useMemo } from 'react'

export const gradingContentContext = createContext()

const GradingContentProvider = ({ content, ...rest }) => {
  const value = useMemo(() => ({ content }), [content])
  return (
    <gradingContentContext.Provider
      {...rest}
      value={value}
    />
  )
}

GradingContentProvider.propTypes = {
  content: contentShape.isRequired,
}

export default GradingContentProvider
