import ColorPicker from 'common/formControls/selects/ColorPicker'
import { set } from 'fp/objects'
import { toInt } from 'fp/strings'
import useColorSet from 'hooks/useColorSet'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { useCallback, useContext } from 'react'

const BackgroundColorSelection = () => {
  const { interactionData, onInteract } = useContext(interactiveContext)

  const colors = useColorSet('pictureThisTextBackground')
  const colorId = toInt(interactionData.colorId)

  const handleChange = useCallback(
    ({ target }) => {
      onInteract(set('interactionData.colorId', target.value))
    },
    [onInteract],
  )

  return (
    <ColorPicker
      colorId={colorId}
      colors={colors}
      label="Text Background Color"
      onChange={handleChange}
      variant="select"
    />
  )
}

export default BackgroundColorSelection
