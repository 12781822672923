import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import DatePicker from 'common/formControls/dateAndTime/DatePicker'
import Select from 'common/formControls/selects/Select'
import { alter, inspect } from 'core/store/search/squery'
import { differenceInCalendarDays, endOfDay, startOfDay } from 'date-fns'
import { get } from 'fp/objects'
import { fallbackTo, identity, matches, when } from 'fp/utils'
import useStateIsolatedFromSquery from 'hooks/useStateIsolatedFromSquery'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { daysBefore, now } from 'locale/dates'
import { parseDate } from 'locale/i18n'
import PropTypes from 'prop-types'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { compose } from 'redux'

const DateRangePicker = ({ label, name }) => {
  /**
   * The API date ranges are > and <, not >= and <=
   *
   * Use endOfDay and startOfDay to ensure the correct boundaries
   */

  const clear = alter.remove.where(name)

  const fromFromTillTo = useCallback(
    ({ fromDate, toDate, round }) => {
      const f = round ? startOfDay(fromDate) : fromDate
      const t = round ? endOfDay(toDate) : toDate

      return compose(
        fromDate ? alter.set.where(name).isAfter(f) : identity,
        toDate ? alter.set.where(name).isBefore(t) : identity,
        clear,
      )
    },
    [clear, name],
  )

  const fromNDaysTillNow = useCallback(
    (n, round = true) =>
      fromFromTillTo({
        fromDate: parseDate(daysBefore(n)),
        toDate: parseDate(now()),
        round,
      }),
    [fromFromTillTo],
  )

  const dateFilters = useMemo(
    () => [
      { value: '-1', label: 'All', setter: clear },

      {
        value: '1',
        label: 'Last 24 Hours',
        setter: fromNDaysTillNow(1, false),
      },
      { value: '7', label: 'Last Week', setter: fromNDaysTillNow(7) },
      { value: '30', label: 'Last 30 Days', setter: fromNDaysTillNow(30) },
      { value: '60', label: 'Last 60 Days', setter: fromNDaysTillNow(60) },
      { value: 'custom', label: 'Custom Range', setter: clear },
    ],
    [clear, fromNDaysTillNow],
  )

  const { setSquery, squery } = useContext(searchContext)

  const [dateFilter, setDateFilter] = useState('')

  const [fromDate, setFromDate] = useStateIsolatedFromSquery(sq =>
    inspect(sq).get.where(name).isAfter(),
  )(squery)
  const [toDate, setToDate] = useStateIsolatedFromSquery(sq =>
    inspect(sq).get.where(name).isBefore(),
  )(squery)

  const getFilter = useCallback(
    value => dateFilters.find(matches('value', value)),
    [dateFilters],
  )

  const setFiltersFromSquery = useCallback(() => {
    const squeryFrom = inspect(squery).get.where(name).isBefore()
    const squeryTo = inspect(squery).get.where(name).isAfter()
    if (!(squeryFrom && squeryTo)) return

    const filterValue = compose(
      fallbackTo('custom'),
      get('value'),
      getFilter,
    )(
      differenceInCalendarDays(
        parseDate(squeryFrom),
        parseDate(squeryTo),
      ).toString(),
    )

    when(filterValue !== dateFilter, setDateFilter, filterValue)
  }, [dateFilter, getFilter, name, squery])

  const handleDateFilterChange = useCallback(
    ({ target }) => {
      const filter = getFilter(target.value)
      setDateFilter(target.value)

      const setter = filter?.setter || fromFromTillTo({ fromDate, toDate })

      compose(setSquery, setter)(squery)
    },
    [fromDate, fromFromTillTo, getFilter, setSquery, squery, toDate],
  )

  const dateStyle = { marginTop: 0, width: '180px' }

  const applyDateRange = () => {
    compose(
      setSquery,
      fromFromTillTo({
        fromDate,
        toDate,
      }),
    )(squery)
  }

  useEffect(() => {
    setFiltersFromSquery()
  }, [setFiltersFromSquery])

  return (
    <Box
      display="flex"
      flexWrap="wrap">
      <Select
        label={label}
        margin="dense"
        name={name}
        onChange={handleDateFilterChange}
        sx={{
          minWidth: 20 * 8,
          maxWidth: 30 * 8,
          mt: 0.5,
        }}
        value={dateFilter}>
        {dateFilters.map(filter => (
          <MenuItem
            key={filter.value}
            value={filter.value}>
            {filter.label}
          </MenuItem>
        ))}
      </Select>

      {Boolean(dateFilter === 'custom') && (
        <Box
          alignItems="center"
          display="flex">
          <DatePicker
            label={null}
            maxDate={parseDate(toDate)}
            name="fromDate"
            onChange={setFromDate}
            sx={dateStyle}
            value={parseDate(fromDate)}
          />
          <Box mx={2}>To</Box>
          <DatePicker
            minDate={parseDate(fromDate)}
            name="toDate"
            onChange={setToDate}
            sx={dateStyle}
            value={parseDate(toDate)}
          />
          <Button
            color="secondary"
            disabled={!(fromDate && toDate)}
            onClick={applyDateRange}
            style={{ marginLeft: 8 * 3 }}
            variant="primary">
            Apply
          </Button>
        </Box>
      )}
    </Box>
  )
}

DateRangePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default DateRangePicker
