import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import Centered from 'common/layout/Centered'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import Headline from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../../InteractiveProvider'
import RubricAggregate from './RubricAggregate'

const MaxWidthBox = styled(Box)({
  maxWidth: featuredContentMaxWidth,
  marginLeft: 'auto',
  marginRight: 'auto',
})

const ActivityRubricAggregate = () => {
  const { contentId } = useContext(interactiveContext)
  const { assignmentId } = useParams()

  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  return (
    <Container>
      <MaxWidthBox mt={4}>
        <CollapsibleSection
          initiallyExpanded
          label={
            <Headline
              textAlign="center"
              title="Average Scores"
              variant="feature-paragraph-semibold"
            />
          }
          variant="interactive-grading">
          <HeadlineLevelOffset>
            {allInteractions.length ? (
              <RubricAggregate />
            ) : (
              <Centered component="p">(no responses)</Centered>
            )}
          </HeadlineLevelOffset>
        </CollapsibleSection>
      </MaxWidthBox>
    </Container>
  )
}

export default ActivityRubricAggregate
