import { INTERACTIVE_TYPE_PACING_GUIDE } from 'core/consts'
import { get } from 'fp/objects'
import { maybeParseJSON } from 'fp/strings'
import { useContext } from 'react'
import { compose } from 'redux'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import ChapterPacingGuide from './Chapter'
import UnitPacingGuide from './Unit'

const pacingGuideTypes = {
  chapter: ChapterPacingGuide,
  unit: UnitPacingGuide,
}

const PacingGuide = () => {
  const {
    interactiveData: { config },
  } = useContext(interactiveContext)
  const guideType = compose(get('guideType'), maybeParseJSON)(config)
  const Component = pacingGuideTypes[guideType] || pacingGuideTypes.chapter
  return <Component />
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_PACING_GUIDE,
}

export default PacingGuide
