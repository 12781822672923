import ReviewsGiven from 'common/reviews/ReviewsGiven'
import ReviewsReceived from 'common/reviews/ReviewsReceived'
import useContent from 'hooks/useContent'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getEchoRubric } from 'selectors/echo'
import { getReactionsForContent } from 'selectors/reactions'
import { CONTENT_TYPE_ECHO } from 'shared/consts'
import { interactiveContext } from '../../InteractiveProvider'

const PeerReviewsTab = () => {
  const {
    interaction: { id: interactionId, contextContentId },
  } = useContext(interactiveContext) || {}
  const echo = useContent({
    contentType: CONTENT_TYPE_ECHO,
    contentId: contextContentId,
  })

  const rubricContent = useSelector(getEchoRubric(echo)) || {}
  const rubricCriteria = rubricContent?.children[0]?.data

  const { given = [], received = [] } = useSelector(
    getReactionsForContent({ interactionId }),
  )

  return (
    <>
      <ReviewsReceived
        {...{
          echo,
          reviews: received,
          rubricCriteria,
        }}
      />

      <ReviewsGiven
        {...{
          echo,
          reviews: given,
          rubricCriteria,
        }}
      />
    </>
  )
}

export default PeerReviewsTab
