import { componentShape, contentShape } from 'core/shapes'
import MaybeHasBackground from 'hss/ContentViewer/Chapter/Block/MaybeHasBackground'
import { px } from 'styling/theming/base/mixins'

const BaseHero = ({ children, content, ...rest }) => (
  <MaybeHasBackground
    content={content}
    fullWidth
    sx={{
      minHeight: px(200),
      maxHeight: '70vh',
    }}
    {...rest}>
    {children}
  </MaybeHasBackground>
)

BaseHero.propTypes = {
  children: componentShape.isRequired,
  content: contentShape,
}

export default BaseHero
