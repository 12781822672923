import { styled } from '@mui/material/styles'
import { whenPresent } from 'fp/utils'
import PropTypes from 'prop-types'
import config from './config'
import Climax from './plotPoints/Climax'
import Exposition from './plotPoints/Exposition'
import FallingAction from './plotPoints/FallingAction'
import Resolution from './plotPoints/Resolution'
import RisingAction from './plotPoints/RisingAction'

const StyledSVG = styled('svg', { name: 'PlotDiagram-SVG' })(
  ({ theme: { palette } }) => ({
    fill: palette.text.primary,
    fontSize: 15,
    fontWeight: 400,
    '& > path': {
      stroke: palette.text.primary,
      strokeDasharray: '2,1',
      fill: 'none',
    },
    '.title': {
      fontWeight: 700,
      textAnchor: 'middle',
      textTransform: 'uppercase',
    },
    '.reorder': {
      opacity: 0,
    },
    'a:focus .reorder': {
      fontStyle: 'italic',
      opacity: 0.7,
    },
  }),
  { name: 'StoryPlotSVG' },
)

const StoryPlotSVG = ({
  focusedPoint,
  onReorder,
  id,
  onClick,
  readOnly = false,
  ...rest
}) => {
  const { climax, exposition, fallingAction, resolution, risingAction } = rest

  const handleClick = point => () => {
    whenPresent(onClick, point)
  }

  const path = `M ${config.exposition.startX} ${config.baselineY}
                H ${config.exposition.endX}
                L ${config.apex.x} ${config.apex.y}
                L ${config.resolution.startX} ${config.baselineY}
                H ${config.resolution.endX}`

  const getNotes = (startPoint, endPoint, notes) => {
    const pointsToDraw = readOnly ? notes : [...notes, '']
    const xStep = (endPoint.x - startPoint.x) / (pointsToDraw.length + 1)
    const yStep = (endPoint.y - startPoint.y) / (pointsToDraw.length + 1)
    const maxHeight = config.apex.height / pointsToDraw.length - config.fontSize

    return pointsToDraw.map((note, idx) => ({
      text: note,
      x: startPoint.x + (idx + 1) * xStep,
      y: startPoint.y + (idx + 1) * yStep,
      maxHeight,
      moveable: Boolean(!readOnly && note && notes.length > 1),
    }))
  }

  return (
    <StyledSVG
      aria-describedby={id}
      aria-label="Story Plot Diagram"
      height="100%"
      role={readOnly ? 'img' : 'group'}
      viewBox={`0 0 ${config.viewBox.width} ${config.viewBox.height}`}
      width="100%">
      <path d={path} />
      <Exposition
        ariaLabel="Click to edit Exposition"
        focused={focusedPoint === 'exposition'}
        onClick={handleClick({ key: 'exposition', value: exposition })}
        readOnly={readOnly}>
        {exposition}
      </Exposition>

      <RisingAction
        focusedPoint={focusedPoint}
        notes={getNotes(
          { x: config.exposition.endX, y: config.baselineY },
          config.apex,
          risingAction,
        )}
        onClick={handleClick}
        onReorder={whenPresent(onReorder, 'rising')}
        readOnly={readOnly}
      />

      <Climax
        ariaLabel="Click to edit Climax"
        onClick={handleClick({ key: 'climax', value: climax })}
        readOnly={readOnly}>
        {climax}
      </Climax>

      <FallingAction
        focusedPoint={focusedPoint}
        notes={getNotes(
          config.apex,
          { x: config.resolution.startX, y: config.baselineY },
          fallingAction,
        )}
        onClick={handleClick}
        onReorder={whenPresent(onReorder, 'falling')}
        readOnly={readOnly}
      />

      <Resolution
        ariaLabel="Click to edit Resolution"
        onClick={handleClick({ key: 'resolution', value: resolution })}
        readOnly={readOnly}>
        {resolution}
      </Resolution>
    </StyledSVG>
  )
}

StoryPlotSVG.propTypes = {
  focusedPoint: PropTypes.string,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onReorder: PropTypes.func,
  setNote: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default StoryPlotSVG
