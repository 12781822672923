import FormCont from './FormCont'
import LoginLayout from './LoginLayout'

const Login = () => (
  <LoginLayout>
    <FormCont mb={4} />
  </LoginLayout>
)

export default Login
