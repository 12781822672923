import {
  PointSelector,
  RectangleSelector,
} from '@studysync/react-image-annotation/lib/selectors'
import { INTERACTION_SUBTYPE_IMAGE } from 'core/consts'
import { matches } from 'fp/utils'
import PropTypes from 'prop-types'

export const selectorTypeShape = PropTypes.oneOf([
  PointSelector.TYPE,
  RectangleSelector.TYPE,
]) // only supporting rectangle at this time

export const imageAnnotationShape = PropTypes.shape({
  geometry: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    type: selectorTypeShape,
  }),
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    id: PropTypes.string,
  }),
})

export const filterAnnotations = (annotations, contentId) =>
  annotations
    .filter(matches('interactionSubType', INTERACTION_SUBTYPE_IMAGE))
    .filter(matches('contentId', contentId))
    .map(({ id, interactionData }) => ({
      ...interactionData,
      data: {
        ...interactionData.data,
        id,
      },
    }))
