import Link from 'common/navigation/links/Link'
import { contentShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { getContentViewerUrl } from 'selectors/contentViewer'

const LinkToContent = forwardRef(
  ({ children, content: { id: contentId, contentType } }, ref) => {
    const to = useSelector(getContentViewerUrl({ contentId, contentType }))

    return (
      <Link
        ref={ref}
        style={{ textDecoration: 'none' }}
        to={to}>
        {children}
      </Link>
    )
  },
)

LinkToContent.propTypes = {
  children: PropTypes.element.isRequired,
  content: contentShape.isRequired,
}

export default LinkToContent
