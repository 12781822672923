import { get } from 'fp/objects'
import { plural } from 'fp/strings'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { toWords } from 'number-to-words'
import { useContext } from 'react'
import useEchoReactions from './useEchoReactions'

const Preamble = () => {
  const { given, pending } = useEchoReactions()

  const { assignmentSettings } = useContext(contentViewerContext) || {}
  const reviewsRequested = get('reviewsRequested', { fallback: 0 })(
    assignmentSettings,
  )

  const metMinimumProvidedReviews = given.length >= reviewsRequested

  return (
    <>
      {!pending && <p>There are currently no submissions available to rate.</p>}

      {metMinimumProvidedReviews ? (
        <p>
          You've completed <strong>{toWords(given.length)}</strong> reviews. You
          can continue to review your peers until there are no more submissions.
        </p>
      ) : (
        <p>
          You have completed <strong>{toWords(given.length)}</strong>{' '}
          {plural('review')(given.length)}. You must review at least{' '}
          <strong>{toWords(reviewsRequested)}</strong>{' '}
          {plural('peer')(reviewsRequested)} and receive at least{' '}
          <strong>{toWords(reviewsRequested)}</strong>{' '}
          {plural('review')(reviewsRequested)} to make the Top 10.
        </p>
      )}
    </>
  )
}

export default Preamble
