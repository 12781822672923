import SubmitButton from 'common/formControls/buttons/SubmitButton'
import withProps from 'hoc/withProps'

const SaveButton = withProps(
  SubmitButton,
  // biome-ignore lint/suspicious/noExplicitAny: temp
  { children: 'Save', sx: { ml: 4, minWidth: 120 } as any },
)

export default SaveButton
