import Box from '@mui/material/Box'
import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import { interactiveContext } from './InteractiveProvider'
import SubmitButton from './SubmitButton'

const SubmissionWrapper = ({ children }) => {
  const {
    interactiveData: { allowSubmission },
    submittable,
  } = useContext(interactiveContext)
  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  return (
    <>
      {children}

      {Boolean(allowSubmission && submittable) && (
        <Box
          mt={4}
          textAlign="center">
          <SubmitButton disabled={presenterModeEnabled} />
        </Box>
      )}
    </>
  )
}

SubmissionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubmissionWrapper
