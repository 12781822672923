import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { compose } from 'redux'
import { interactiveContext } from '../../InteractiveProvider'
import ReopenButtonForAllStudents from './ReopenButtonForAllStudents'
import ReopenButtonForSingleStudent from './ReopenButtonForSingleStudent'

const ReopenButton = ({ reopenAssignment = false }) => {
  const userAssignmentId = compose(
    get('interaction.userAssignmentId'),
    useContext,
  )(interactiveContext)

  return userAssignmentId || reopenAssignment ? (
    <ReopenButtonForSingleStudent reopenAssignment={reopenAssignment} />
  ) : (
    <ReopenButtonForAllStudents />
  )
}

ReopenButton.propTypes = {
  reopenAssignment: PropTypes.bool,
}

export default ReopenButton
