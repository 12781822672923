import Headline from 'common/text/Headline'
import { ABILITY_ASSIGNMENT_CREATION, FEATURE_FLAG_POST_MVP } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import useAbilityCheck from 'hooks/useAbilityCheck'
import Divider from '../Divider'
import withSaveUserPreferences from '../withSaveUserPreferences'
import Assignments from './Assignments'
import GradeColors from './GradeColors'
import VideoRecording from './VideoRecording'

const AssignmentsAndGrading = withSaveUserPreferences(
  ({ onChange, preferences }) => {
    const postMVP = useAbilityCheck(FEATURE_FLAG_POST_MVP)

    return (
      <Headline
        mb={12}
        textTransform="capitalize"
        title="Assignments & Grading">
        <Assignments
          onChange={onChange}
          preferences={preferences}
        />
        {Boolean(postMVP) && (
          <>
            <Headline
              gutterBottom
              size={4}
              title="Video Recording">
              <VideoRecording
                onChange={onChange}
                preferences={preferences}
              />
            </Headline>
            <Divider />
          </>
        )}
        <Headline
          gutterBottom
          size={4}
          title="Grade Colors">
          <GradeColors
            onChange={onChange}
            preferences={preferences}
          />
        </Headline>
      </Headline>
    )
  },
)

const AssignmentsAndGradingWithFlag = withAbilityCheck(AssignmentsAndGrading, [
  ABILITY_ASSIGNMENT_CREATION,
])
export default AssignmentsAndGradingWithFlag
