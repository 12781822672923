import Box from '@mui/material/Box'
import Headline from 'common/text/Headline'
import Html from 'common/text/Html'
import { isEmpty } from 'fp/arrays'
import { get } from 'fp/objects'
import withProps from 'hoc/withProps'
import { ECHO_FEATURE_PICTURE_THIS } from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import QuestionPrompt from 'hss/sections/contentBlocks/Interactive/QuestionPrompt'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isStudent } from 'selectors/users'
import CollapsibleInstructions from '../CollapsibleInstructions'
import FeatureFootnotes from '../FeatureFootnotes'
import FeatureHeadline from '../FeatureHeadline'
import SubmitButton from '../SubmitButton'
import BackgroundColorSelection from './BackgroundColorSelection'
import FilterSelection from './FilterSelection'
import FontSizeSelection from './FontSizeSelection'
import ImageRenderer from './ImageRenderer'

const feature = ECHO_FEATURE_PICTURE_THIS

const initialState = {
  colorId: 1,
  filter: 'none',
  fontSize: 'medium',
  height: 102,
  message: '',
  transform: 'translate(75px, 70px) rotate(0deg)',
  width: 256,
}

const FeaturePictureThis = () => {
  const {
    completed,
    interactionData,
    interactiveData: { interactiveInstructions, prompt, studentInstructions },
    isGrading,
    onInteract,
    submittable,
  } = useContext(interactiveContext)

  const userIsStudent = useSelector(isStudent)

  const [initialized, setInitialized] = useState(!isEmpty(interactionData))

  useEffect(() => {
    if (!(initialized || isGrading)) {
      onInteract(initialState)
      setInitialized(true)
    }
  }, [initialized, isGrading, onInteract])

  const url = get('imageBankItem.url')(interactionData)

  const InstructionsWrapper = isGrading ? CollapsibleInstructions : Fragment
  const ResponseWrapper = isGrading
    ? withProps(Headline, { my: 4, title: 'Student Response' })
    : Fragment

  return (
    <>
      <FeatureHeadline title="Respond">
        <Box mb={4}>
          <InstructionsWrapper>
            <Html body={interactiveInstructions} />

            <QuestionPrompt body={prompt} />

            <Html body={studentInstructions} />
          </InstructionsWrapper>
        </Box>

        <ResponseWrapper>
          <ImageRenderer readOnly={isGrading || !submittable} />
        </ResponseWrapper>

        {Boolean(url && submittable) && (
          <>
            <FontSizeSelection />

            <BackgroundColorSelection />

            <FilterSelection />
          </>
        )}
      </FeatureHeadline>

      {Boolean(submittable) && (
        <SubmitButton
          disabled={completed || !url || !userIsStudent}
          style={{
            marginTop: 32,
          }}>
          Submit your image
        </SubmitButton>
      )}

      <FeatureFootnotes feature={feature} />
    </>
  )
}

export default FeaturePictureThis
