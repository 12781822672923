import Container from '@mui/material/Container'
import Headline from 'common/text/Headline'
import ScreenReaderText from 'common/text/ScreenReaderText'
import withContent from 'hoc/withContent'
import TeachBanner from 'hss/ContentViewer/Banner/TeachBanner'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import RouteTransition from 'routing/RouteTransition'
import { getContextualAssignment } from 'selectors/assignments'
import AssignmentForm from './AssignmentForm'

const Edit = withContent(({ content = {} }) => {
  const existingAssignment = useSelector(getContextualAssignment)
  const { id: contentId, name } = content || {}
  const newAssignment = useRef({ contentId, name })
  const assignment = existingAssignment || newAssignment.current
  const title = existingAssignment ? 'Edit Assignment' : 'Create Assignment'

  return (
    <RouteTransition>
      <TeachBanner title="Assignment Settings" />
      <Container>
        <Headline title={<ScreenReaderText>{title}</ScreenReaderText>}>
          <AssignmentForm assignment={assignment} />
        </Headline>
      </Container>
    </RouteTransition>
  )
})

export default Edit
