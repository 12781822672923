import { styled } from '@mui/material/styles'
import { imageClickerMessageShape } from 'core/shapes'
import { suffix, toInt } from 'fp/strings'
import { when } from 'fp/utils'
import useHoverFocus from 'hooks/useHoverFocus'
import { Maybe } from 'monet'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { animated, useSpring } from 'react-spring'
import { animationByName, animationExists } from './animations'

const percent = suffix('%')

const Message = styled(
  props => {
    const {
      className,
      currentStep,
      message: { coord, animation, maxWidth, message },
      setCurrentStep,
      setDone,
      totalSteps,
    } = props

    const [ref, isHovered, isFocused] = useHoverFocus()

    const [springStyle] = useSpring(
      Maybe.fromNull(animation)
        .map(nameOrConfig =>
          animationExists(nameOrConfig)
            ? animationByName(nameOrConfig, isFocused || isHovered)
            : nameOrConfig,
        )
        .orJust({}),
      [isFocused, isHovered],
    )

    const style = useMemo(() => {
      const [x, y] = coord.split(',').map(toInt).map(percent)
      return {
        maxWidth: maxWidth ? percent(maxWidth) : 'unset',
        left: x,
        top: y,
        ...springStyle,
      }
    }, [coord, maxWidth, springStyle])

    const handleClick = useCallback(() => {
      when(currentStep < totalSteps - 1, setCurrentStep, currentStep + 1)
      when(currentStep === totalSteps - 1, setDone)
    }, [currentStep, setCurrentStep, setDone, totalSteps])

    return (
      <animated.button
        className={className}
        onClick={handleClick}
        ref={ref}
        style={style}>
        {message}
      </animated.button>
    )
  },
  { name: 'ImageClicker-Message' },
)(
  ({
    theme: {
      breakpoints,
      mixins: { rem },
    },
  }) => ({
    color: 'white',
    cursor: 'pointer',
    position: 'absolute',
    textShadow: '2px 1px 2px rgba(0,0,0,0.7)',
    [breakpoints.down('xs')]: { fontSize: rem(1.5) },
    [breakpoints.up('sm')]: { fontSize: rem(2.5) },
    [breakpoints.up('md')]: { fontSize: rem(2.8) },
    [breakpoints.up('lg')]: { fontSize: rem(3) },
    [breakpoints.up('xl')]: { fontSize: rem(3.2) },
  }),
)

Message.propTypes = {
  currentStep: PropTypes.number.isRequired,
  message: imageClickerMessageShape.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setDone: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired,
}

export default Message
