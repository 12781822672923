import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import ExternalLink from 'common/navigation/links/ExternalLink'
import HelpCenterLink from 'common/navigation/links/HelpCenterLink'
import Headline from 'common/text/Headline'
import backgroundShape from 'hss/images/home/background-shape.png'
import { CarouselTitle } from './ContentCarousel'

const classroomLinks = [
  { text: 'Getting Started Course', href: '#', guideId: 12 },
  { text: 'Assignments', href: '#', guideId: 107 },
  { text: 'Differentiation and Scaffolding', href: '#', guideId: 42 },
  { text: 'Program Guide', href: '#', guideId: 108 },
  {
    text: 'Sparking Inquiry Orientation',
    Renderer: ExternalLink,
    href: '//edu.imaginelearning.com/inquiry-orientation-free-pd-course',
  },
]

const foundationLinks = [
  { text: 'Approach to Instruction', href: '#', guideId: 25 },
  { text: 'Enduring Themes', href: '#', guideId: 8 },
  { text: 'Inquiry Guide', href: '#', guideId: 110 },
  { text: 'Scope and Sequence', href: '#', guideId: 109 },
  { text: 'Skill Guide', href: '#', guideId: 111 },
]

const communityLinks = [
  {
    text: 'Visit Imagine Learning',
    href: '//www.imaginelearning.com/products/traverse/',
  },
]

const StyledBackground = styled(Container, { name: 'DropDownButton-Icon' })(
  ({
    theme: {
      mixins: { interTextSizeR, px, rem },
      palette,
    },
  }) => ({
    background: `url(${backgroundShape}), ${palette.emerald.gradient}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    color: palette.primary.contrastText,
    paddingTop: rem(4),
    paddingBottom: rem(4),

    a: {
      color: palette.primary.contrastText,
      textDecoration: 'none',
      ...interTextSizeR(1.6, px(28), 400),
      marginBottom: rem(0.5),
      '&:last-child': { marginBottom: 0 },
      '&:hover': {
        textDecoration: 'underline',
        textUnderlineOffset: rem(0.4),
        color: palette.primary.contrastText,
      },
    },
  }),
)

const GetToKnowTraverse = () => (
  <StyledBackground>
    <Headline
      mb={4}
      size={3}
      title={
        <CarouselTitle
          subtitle="Helpful Links."
          title="Get to Know Traverse."
        />
      }
    />
    <Stack
      direction="row"
      flexWrap="wrap"
      gap={4}
      justifyContent="space-between"
      mr={8}>
      <Stack alignItems="flex-start">
        <Headline
          hr
          title=" Create a Traverse Classroom"
          variant="list-header"
        />
        {classroomLinks.map(({ text, Renderer = HelpCenterLink, ...rest }) => (
          <Renderer
            key={text}
            {...rest}>
            {text}
          </Renderer>
        ))}
      </Stack>
      <Stack alignItems="flex-start">
        <Headline
          hr
          title=" Explore Traverse Foundations"
          variant="list-header"
        />
        {foundationLinks.map(({ text, ...rest }) => (
          <HelpCenterLink
            key={text}
            {...rest}>
            {text}
          </HelpCenterLink>
        ))}
      </Stack>
      <Stack alignItems="flex-start">
        <Headline
          hr
          title=" Join a Traverse Community"
          variant="list-header"
        />
        {communityLinks.map(({ href, text }) => (
          <ExternalLink
            href={href}
            key={text}>
            {text}
          </ExternalLink>
        ))}
      </Stack>
    </Stack>
  </StyledBackground>
)

export default GetToKnowTraverse
