import { CONTENT_TYPE_RUBRIC } from 'core/consts'
import Preview from 'hss/ContentBuilder/interactives/Form/Preview'
import { getRegisteredInteractives } from 'hss/sections/contentBlocks/Interactive/utils'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'
import { searchContext } from 'hss/views/Search/SearchProvider'
import { useContext } from 'react'

const LibraryPreview = () => {
  const { selectedPreview, setSelectedPreview } = useContext(searchContext)
  const { contentSubType, data, ...rest } = selectedPreview || {}
  const registeredInteractives = getRegisteredInteractives()

  const Interactive =
    selectedPreview?.contentType === CONTENT_TYPE_RUBRIC
      ? Rubric
      : registeredInteractives[contentSubType]

  const handleClose = () => {
    setSelectedPreview(null)
  }

  return selectedPreview ? (
    <Preview
      additionalInteractiveProps={{ contentSubType, ...rest, ...data }}
      Interactive={Interactive}
      onClose={handleClose}
      open
    />
  ) : null
}

export default LibraryPreview
