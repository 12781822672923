import { contentShape } from 'core/shapes'
import PropTypes from 'prop-types'

const BlockWrapperStyleTarget = ({ block, children }) => {
  const {
    contentSubType,
    contentType,
    data: { variant } = {},
  } = block

  return (
    <div
      className="block-wrapper-style-target"
      data-contentsubtype={contentSubType}
      data-contenttype={contentType}
      data-variant={variant}>
      {children}
    </div>
  )
}

BlockWrapperStyleTarget.propTypes = {
  block: contentShape.isRequired,
  children: PropTypes.node.isRequired,
}

export default BlockWrapperStyleTarget
