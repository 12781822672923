import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const StyledAnswerChoiceBox = styled(Box)(
  ({
    theme: {
      mixins: { px, rem, transition },
      palette,
      shadows,
    },
  }) => ({
    width: '100%',
    ...transition(),
    '&:not(:last-child)': {
      marginBottom: rem(1.5),
    },
    svg: {
      overflow: 'visible',
    },
    '&:hover': {
      backgroundColor: palette.cobalt[4],
      boxShadow: shadows.border,
      color: palette.boxshadowPrimary,
      svg: {
        fill: palette.common.white,
        strokeWidth: 2,
        path: {
          fill: 'none',
        },
        rect: {
          color: palette.cobalt[0],
        },
      },
    },
    '&:active': {
      ...transition(),
      backgroundColor: palette.cobalt[4],
      boxShadow: `${shadows.activeBorder} ${palette.boxshadowPrimary}, ${shadows.border} ${palette.boxshadowPrimary}`,
      color: palette.cobalt[0],
    },
    '.MuiFormControlLabel-root': {
      padding: px(12, 24, 12, 12),
      marginRight: 0,
      marginLeft: -16,
      display: 'flex',
      flexDirection: 'row',
      '.MuiTypography-root': {
        width: '100%',
      },
    },
  }),
)

const AnswerChoiceBox = ({ barColor, ...rest }) => (
  <StyledAnswerChoiceBox
    bgcolor="common.white"
    borderLeft="8px solid"
    borderLeftColor={barColor}
    pl={1}
    {...rest}
  />
)

AnswerChoiceBox.propTypes = {
  barColor: PropTypes.string.isRequired,
}

export default AnswerChoiceBox
