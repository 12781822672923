import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Select from 'common/formControls/selects/Select'
import { getAt, map, orderBy } from 'fp/arrays'
import { get } from 'fp/objects'
import { curryRight, fallbackTo, identity, isDefined, matches } from 'fp/utils'
import { userAssignmentSelectionContext } from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getAssignmentStudents } from 'selectors/assignments'
import { interactiveContext } from '../InteractiveProvider'
import useGradingSummary from './useGradingSummary'
import { gradeIndicators } from './utils'

const StudentAssignmentPicker = props => {
  const { assignmentId } = useParams()
  const gradingSummary = useGradingSummary()
  const { interaction } = useContext(interactiveContext) || {}
  const userAssignments = useSelector(
    getAssignmentStudents({ assignmentId, interaction }),
  )

  const sortedUserAssignments = useMemo(
    () =>
      compose(
        orderBy('displayName'),
        map(ua => ({
          ...ua,
          displayName: `${ua.user.lastName}, ${ua.user.firstName}`,
        })),
      )(userAssignments),
    [userAssignments],
  )

  const [selectedUserAssignmentIndex, setSelectedUserAssignmentIndex] =
    useState()
  const { currentUserAssignmentId, setCurrentUserAssignmentId } = useContext(
    userAssignmentSelectionContext,
  )

  useLayoutEffect(() => {
    const newSelectedIndex = (sortedUserAssignments || []).findIndex(
      matches('id', currentUserAssignmentId),
    )
    setSelectedUserAssignmentIndex(
      newSelectedIndex === -1 ? 'all' : newSelectedIndex,
    )
  }, [currentUserAssignmentId, sortedUserAssignments])

  const handleSelectedIndex = newSelectedIndex => {
    compose(
      setCurrentUserAssignmentId,
      get('id'),
      fallbackTo({}),
      curryRight(getAt, newSelectedIndex),
    )(sortedUserAssignments)
  }

  const handleSelectPrevious = () => {
    handleSelectedIndex(
      currentUserAssignmentId
        ? selectedUserAssignmentIndex - 1
        : sortedUserAssignments.length - 1,
    )
  }

  const handleSelectNext = () => {
    handleSelectedIndex(
      currentUserAssignmentId
        ? selectedUserAssignmentIndex >= sortedUserAssignments.length
          ? null
          : selectedUserAssignmentIndex + 1
        : 0,
    )
  }

  const getIcon = id => {
    if (!interaction) return null

    const [status] = Object.entries(gradingSummary)
      .filter(([, summaryUserAssignments]) =>
        summaryUserAssignments.find(matches('id', id)),
      )
      .find(identity)
    const { Icon, color, label } = gradeIndicators[status]
    return (
      <Box
        color={color}
        display="flex">
        <Icon aria-label={label} />
      </Box>
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      {...props}>
      <IconButton
        aria-label="Previous Response"
        onClick={handleSelectPrevious}>
        <ArrowLeft />
      </IconButton>
      <Select
        hideLabel
        label="Selected User Assignment"
        name="selectedUserAssignment"
        onChange={compose(handleSelectedIndex, Number, get('target.value'))}
        style={{ flex: 1, marginTop: 0, width: '100%' }}
        value={
          isDefined(selectedUserAssignmentIndex)
            ? String(selectedUserAssignmentIndex)
            : ''
        }>
        <MenuItem value="all">All Responses</MenuItem>
        {(sortedUserAssignments || []).map(({ id, displayName }, index) => (
          <MenuItem
            key={id}
            value={index}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              width="100%">
              {displayName}
              {getIcon(id)}
            </Stack>
          </MenuItem>
        ))}
      </Select>
      <IconButton
        aria-label="Next Response"
        onClick={handleSelectNext}>
        <ArrowRight />
      </IconButton>
    </Stack>
  )
}

export default StudentAssignmentPicker
