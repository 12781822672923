import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import BrowseButton from './BrowseButton'

const Button = styled(BrowseButton, {
  name: 'FeaturePictureThis-ImageSelectionPlaceholder',
})(
  ({
    theme: {
      mixins: { borderS },
      palette,
      typography,
    },
  }) => ({
    ...borderS(palette.border[0]),
    aspectRatio: '9 / 16',
    width: '100%',
    ...typography.variants.paragraph,
    color: palette.border.light,
    cursor: 'pointer',
    '&:hover': {
      ...borderS(palette.border[0]),
      background: 'transparent',
      textDecoration: 'underline',
    },
  }),
)

const ImageSelectionPlaceholder = ({ onInteract }) => (
  <Button onInteract={onInteract} />
)

ImageSelectionPlaceholder.propTypes = {
  onInteract: PropTypes.func.isRequired,
}

export default ImageSelectionPlaceholder
