import Container from '@mui/material/Container'
import Headline from 'common/text/Headline'
import Assignments from './Assignments'

const Binder = () => (
  <Container>
    <Headline title="Binder">
      <Assignments />
    </Headline>
  </Container>
)

export default Binder
