import { curryRight } from 'fp/utils'
import withFetchedItem from 'hoc/withFetchedItem'
import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import PropTypes from 'prop-types'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import withSearchProvider from './searchConfig'

const Report = ({ item }) => <CommonTableSearch title={item.name} />

Report.propTypes = { item: PropTypes.object.isRequired }

const ReportOnUser = compose(
  withSearchProvider,
  curryRight(withFetchedItem, {
    actionType: actionTypes.DISTRICT_FETCH,
  }),
)(Report)

export default ReportOnUser
