import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_VOCABULARY,
} from 'core/consts'

export const items = [
  { contentType: CONTENT_TYPE_ECHO, label: 'Echo' },
  { contentType: CONTENT_TYPE_SOURCE, label: 'Source' },
  { contentType: CONTENT_TYPE_VOCABULARY, label: 'Vocabulary' },
]
