import PropTypes from 'prop-types'
import config from '../config'
import DiagramPoint from './DiagramPoint'

const expositionConfig = {
  x: config.exposition.startX,
  y: config.baselineY + config.labelBelowOffsetY,
  width: config.viewBox.midX - config.exposition.startX,
  height: config.viewBox.height - config.baselineY - config.labelBelowOffsetY,
  verticalAnchor: 'start',
}
const Exposition = ({ onClick, children, ...rest }) => (
  <g>
    {/* biome-ignore lint/a11y/noInteractiveElementToNoninteractiveRole: TODO: check on that */}
    <text
      className="title"
      role="presentation"
      x={config.exposition.midX}
      y={config.baselineY - config.labelAboveOffsetY}>
      Exposition
    </text>
    <DiagramPoint
      CircleProps={{
        cx: config.exposition.midX,
        cy: config.baselineY,
        r: config.circleRadius,
      }}
      {...expositionConfig}
      {...rest}
      onClick={onClick}>
      {children}
    </DiagramPoint>
  </g>
)

Exposition.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default Exposition
