import SplitHeadline from 'common/text/SplitHeadline'
import { inspect } from 'core/store/search/squery'
import { get } from 'fp/objects'
import { pluralize } from 'fp/strings'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { searchContext } from '../SearchProvider'

export const defaultRenderHeadline = ({ dataset, squery }) => {
  const { modifiers } = squery || {}
  const count = get('metadata.count')(dataset) || 0
  const selectedFilters = inspect(squery).get.where('contentType').in()
  const selectedSubContentFilters = inspect(squery)
    .get.where('contentSubType')
    .in()

  const searching =
    modifiers?.keywordSearch ||
    selectedFilters?.length ||
    selectedSubContentFilters?.length

  const noun = searching ? 'result' : 'item'

  return `
    ${pluralize(noun)(count)}
    ${modifiers?.keywordSearch ? `for "${modifiers?.keywordSearch}"` : ''}`
}

const ResultsHeader = props => {
  const { children, headlineProps, renderActions, renderHeadline } = props

  const { contentTypes, dataset } = useContext(searchContext)
  const squery = get('metadata.squery')(dataset)
  const { limit, modifiers, offset } = squery || {}
  const count = get('metadata.count')(dataset) || 0
  const selectedFilters = inspect(squery).get.where('contentType').in()
  const selectedSubContentFilters = inspect(squery)
    .get.where('contentSubType')
    .in()
  const allSelected = selectedFilters.length === contentTypes.length

  const renderProps = {
    allSelected,
    count,
    dataset,
    limit,
    modifiers,
    offset,
    selectedFilters,
    selectedSubContentFilters,
    squery,
  }

  const headline = renderHeadline(renderProps)
  const actions = renderActions?.(renderProps) || <span />

  return (
    <SplitHeadline
      gutterBottom
      left={headline}
      leftProps={{ size: 4 }}
      {...headlineProps}
      right={actions}>
      {children}
    </SplitHeadline>
  )
}

ResultsHeader.propTypes = {
  children: PropTypes.node.isRequired,
  headlineProps: PropTypes.object,
  renderHeadline: PropTypes.func.isRequired,
  renderActions: PropTypes.func,
}

export default ResultsHeader
