import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { pacingOptionsShape } from 'core/shapes'
import PropTypes from 'prop-types'
import PacingOptionCells from './PacingOptionCells'

const SpecialSegment = ({ segment: { pacingOptions, specialSegmentName } }) => (
  <TableRow>
    <TableCell
      className="special-segment"
      colSpan={2}>
      {specialSegmentName}
    </TableCell>
    <PacingOptionCells
      featureGroupName="special"
      pacingOptions={pacingOptions}
    />
  </TableRow>
)

SpecialSegment.propTypes = {
  segment: PropTypes.shape({
    pacingOptions: pacingOptionsShape,
    specialSegmentName: PropTypes.string,
  }).isRequired,
}

export default SpecialSegment
