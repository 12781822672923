import DoubleArrow from '@mui/icons-material/DoubleArrow'
import VerticalAlignBottomRounded from '@mui/icons-material/VerticalAlignBottomRounded'
import VerticalAlignTopRounded from '@mui/icons-material/VerticalAlignTopRounded'
import Divider from '@mui/material/Divider'
import ListSubheader from '@mui/material/ListSubheader'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import {
  ABILITY_ASSIGNMENT_CREATION,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { useState } from 'react'
import { Plus } from 'react-feather'
import { useSelector } from 'react-redux'
import {
  getAnyAncestorIsChapterSummary,
  getAnyAncestorIsTe,
  getCurrentSection,
  getCurrentViewContent,
  getInsertedAncestor,
} from 'selectors/contentViewer'
import ContentInsertionDialog from './ContentInsertionDialog'

const MenuHeader = styled(ListSubheader, {
  name: 'ContentInsertion-ListSubheader',
})(
  ({
    theme: {
      mixins: { rem },
      palette,
    },
  }) => ({
    userSelect: 'none',
    fontWeight: 300,
    background: palette.border[2],
    padding: 0,
    textAlign: 'center',
    span: {
      display: 'block',
      background: palette.border[1],
      lineHeight: 1.8,
    },
    div: {
      padding: rem(0, 1),
    },
  }),
)

const ContentInsertionMenuItem = withAbilityCheck(() => {
  const current = useSelector(getCurrentViewContent)

  const parentSection = useSelector(getCurrentSection())
  const ancestorWasInserted = useSelector(getInsertedAncestor(current))
  const ancestorIsTE = useSelector(
    getAnyAncestorIsTe({ ...current, topLevel: true }),
  )
  const ancestorIsChapterSummary = useSelector(
    getAnyAncestorIsChapterSummary({ content: current }),
  )

  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [placement, setPlacement] = useState('below')
  const [level, setLevel] = useState('sibling')

  const handleClose = () => setSubmenuAnchorEl(null)

  const handleSubmenuOpen = event => {
    event.stopPropagation() // prevent parent menu from closing
    setSubmenuAnchorEl(event.currentTarget)
  }

  const handleClick = event => {
    handleClose()

    setPlacement(event.currentTarget.getAttribute('data-placement'))
    setLevel(event.currentTarget.getAttribute('data-level'))

    setIsDialogOpen(true)
  }

  const handleDialogClose = () => setIsDialogOpen(false)

  const sanityCheck = [
    !ancestorIsChapterSummary,
    !ancestorIsTE,
    !ancestorWasInserted,
  ].every(Boolean)

  const canInsertPage =
    sanityCheck &&
    [CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION].includes(
      current?.contentType,
    )
  const canInsertSection =
    sanityCheck && current?.parent?.contentType === CONTENT_TYPE_SECTION

  if (!(canInsertPage || canInsertSection)) return null

  return (
    <>
      <Divider />

      <MenuItem onClick={handleSubmenuOpen}>
        <Plus /> &nbsp; Insert Content...
      </MenuItem>

      <Menu
        anchorEl={submenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        onClose={handleClose}
        open={Boolean(submenuAnchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {Boolean(canInsertPage) && [
          <MenuHeader key="h1">
            <Typography variant="attribute-name">current page</Typography>
            <div>“{current?.name}”</div>
          </MenuHeader>,

          <MenuItem
            data-level="sibling"
            data-placement="above"
            key="m1"
            onClick={handleClick}>
            <VerticalAlignTopRounded /> &nbsp; Insert Before...
          </MenuItem>,

          <MenuItem
            data-level="sibling"
            data-placement="below"
            key="m2"
            onClick={handleClick}>
            <VerticalAlignBottomRounded /> &nbsp; Insert After...
          </MenuItem>,
        ]}

        {Boolean(canInsertSection) && [
          <MenuHeader key="h2">
            <Typography variant="attribute-name">current section</Typography>
            <div>“{parentSection?.name}”</div>
          </MenuHeader>,

          <MenuItem
            data-level="parent"
            data-placement="above"
            key="m3"
            onClick={handleClick}>
            <DoubleArrow style={{ transform: 'rotate(-90deg)' }} /> &nbsp;
            Insert Above...
          </MenuItem>,

          <MenuItem
            data-level="parent"
            data-placement="below"
            key="m4"
            onClick={handleClick}>
            <DoubleArrow style={{ transform: 'rotate(90deg)' }} /> &nbsp; Insert
            Below...
          </MenuItem>,
        ]}
      </Menu>

      <ContentInsertionDialog
        level={level}
        onClose={handleDialogClose}
        open={isDialogOpen}
        placement={placement}
        relativeContent={current}
      />
    </>
  )
}, ABILITY_ASSIGNMENT_CREATION)

export default ContentInsertionMenuItem
