import Form from 'common/formControls/Form'
import SubmitButton from 'common/formControls/buttons/SubmitButton'
import ContainerQuery from 'common/layout/ContainerQuery'
import { deepMerge } from 'fp/objects'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import actionTypes from 'reducers/actionTypes'
import { assignmentEditorUrl } from 'routing/consts'
import Sections from './Sections'
import { baseAssignment } from './utils'

const AssignmentForm = ({ assignment }) => {
  const defaultValues = useMemo(
    () => deepMerge(baseAssignment, assignment),
    [assignment],
  )

  const isNewAssignment = !assignment.id

  const navigate = useNavigate()
  const handleSuccess = useCallback(
    ({ response: { id } }) => {
      if (isNewAssignment) {
        navigate(`${assignmentEditorUrl}/${id}/settings`)
      }
    },
    [isNewAssignment, navigate],
  )

  return (
    <ContainerQuery>
      <Form
        actionType={actionTypes.ASSIGNMENT_SAVE}
        defaultValues={defaultValues}
        mb={20}
        name="AssignmentForm"
        onSuccess={handleSuccess}
        preventEnterSubmits
        suppressDirtyNavigationWarning={[
          // Allow the user to view the effects of these settings on other pages without being required to save first.
          'data.settings.leveledNarrativeText',
          'excludedContentIds',
        ]}>
        <Sections isNewAssignment={isNewAssignment} />

        <SubmitButton>{isNewAssignment ? 'Assign' : 'Save'}</SubmitButton>
      </Form>
    </ContainerQuery>
  )
}

AssignmentForm.propTypes = {
  assignment: PropTypes.object.isRequired,
}

export default AssignmentForm
