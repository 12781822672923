import Check from '@mui/icons-material/Check'
import Warning from 'common/layout/Warning'
import { useSelector } from 'react-redux'
import { isStaff } from 'selectors/users'
import Options from './Options'
import Plagiarism from './Plagiarism'

const Accepted = props => {
  const mentor = useSelector(isStaff)
  return (
    <>
      <Warning icon={<Check />}>
        You have accepted the current Turnitin EULA.
      </Warning>

      {Boolean(mentor) && (
        <>
          <Plagiarism {...props} />
          <Options {...props} />
        </>
      )}
    </>
  )
}

export default Accepted
