import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import CardTitle from 'common/text/CardTitle'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { contentShape } from 'core/shapes'
import { noop } from 'fp/utils'
import withProps from 'hoc/withProps'
import useContent from 'hooks/useContent'
import Card from 'hss/views/Card'
import PosterImage from 'hss/views/Card/PosterImage'
import PropTypes from 'prop-types'
import Actions from './Actions'
import Footer from './Footer'

const ContentRenderer = ({ content }) => {
  const {
    data: { blurb, subtitle },
    name,
  } = content

  return (
    <Stack sx={{ flex: 1 }}>
      <CardHeader
        title={
          <CardTitle
            eyebrow={subtitle}
            title={name}
          />
        }
      />

      <CardContent>{blurb}</CardContent>

      <Actions content={content} />

      <Footer content={content} />
    </Stack>
  )
}

ContentRenderer.propTypes = {
  content: contentShape.isRequired,
}

const ChapterCard = ({ contentId }) => {
  const content = useContent({ contentType: CONTENT_TYPE_CHAPTER, contentId })

  const Renderer = withProps(Card, {
    content,
    contentCreator: false,
    ContentRenderer,
    handlePreview: noop,
    ImageRenderer: withProps(PosterImage, {
      includeLinkToContent: true,
      viewMode: 'list',
    }),
    listModeDirection: 'rtl',
    listModeImageAspectRatio: '1/1',
    viewMode: 'list',
  })
  return <Renderer />
}

ChapterCard.propTypes = {
  contentId: PropTypes.string.isRequired,
}

export default ChapterCard
