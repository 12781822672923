import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import SplitHeadline from 'common/text/SplitHeadline'
import { get } from 'fp/objects'
import ActiveFilters from 'hss/views/Search/Filters/ActiveFilters'
import SearchControls from 'hss/views/Search/SearchControls'
import { searchContext } from 'hss/views/Search/SearchProvider'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { paginationHeader } from './utils'

const Filters = ({
  renderAdvancedFilter,
  renderPrimaryFilter,
  renderSecondaryFilter,
  suppressEmptyResult,
}) => {
  const { dataset } = useContext(searchContext)
  const squery = get('metadata.squery')(dataset)
  const { limit, offset } = squery || {}
  const count = get('metadata.count')(dataset) || 0

  const hasFilters =
    renderPrimaryFilter || renderSecondaryFilter || renderAdvancedFilter

  return (
    <>
      {!!hasFilters && (
        <>
          <Typography
            gutterBottom
            variant="body1-semibold">
            Filter by type
          </Typography>

          {renderPrimaryFilter?.({ dataset, squery })}

          {Boolean(renderPrimaryFilter && renderSecondaryFilter) && <Divider />}

          {renderSecondaryFilter?.()}

          {renderAdvancedFilter?.()}

          <Divider />
        </>
      )}

      {count || !suppressEmptyResult ? (
        <SplitHeadline
          left={paginationHeader(count, offset, limit)}
          leftProps={{ size: 5 }}
          right={<SearchControls />}>
          {!!hasFilters && <ActiveFilters />}
        </SplitHeadline>
      ) : null}
    </>
  )
}

Filters.propTypes = {
  renderAdvancedFilter: PropTypes.func,
  renderPrimaryFilter: PropTypes.func,
  renderSecondaryFilter: PropTypes.func,
  suppressEmptyResult: PropTypes.bool,
}

export default Filters
