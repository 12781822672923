import Box from '@mui/material/Box'
import CheckboxMultiSelect from 'common/formControls/selects/CheckboxMultiSelect'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import SplitHeadline from 'common/text/SplitHeadline'
import { pluralize } from 'fp/strings'
import useEffectOnce from 'hooks/useEffectOnce'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userAssignmentActions } from 'reducers/userAssignments'
import {
  getClosedUserAssignmentsWithParentContent,
  getListedUserAssignmentIds,
} from 'selectors/userAssignments'
import AssignmentCard from '../AssignmentCards/AssignmentCard'
import {
  assignmentsSort,
  filterListNeedsDivider,
  groupedAssignments,
  nonChapterSortOrder,
} from '../Assignments/utils'

const Assignments = () => {
  const isLargeViewport = useContainerQuery().up('lg')

  const dispatch = useDispatch()
  useEffectOnce(() => {
    dispatch(userAssignmentActions.fetchClosedAssignments())
  })
  const userAssignments = useSelector(getClosedUserAssignmentsWithParentContent)
  const listedUserAssignmentIds = useSelector(getListedUserAssignmentIds)
  const userAssignmentsDisplayOrder = useMemo(
    () => [listedUserAssignmentIds, nonChapterSortOrder].flat().filter(Boolean),
    [listedUserAssignmentIds],
  )

  const [selectedCourses, setSelectedCourses] = useState([]) // Course ids selected in filter dropdown
  const handleSetSelectedCourses = ({ target }) =>
    setSelectedCourses(target.value)

  const [visibleAssignments, setVisibleAssignments] = useState([])
  const groupedUserAssignments = useMemo(
    () =>
      groupedAssignments(
        userAssignments,
        filterListNeedsDivider(userAssignments),
      ),
    [userAssignments],
  )

  const numAssignments = visibleAssignments.length
  const hasResultsTitle = `1-${visibleAssignments.length} of `

  // Update visible assignments when the user makes a change to the filter
  useEffect(() => {
    if (selectedCourses?.length) {
      // biome-ignore lint/complexity/useFlatMap: TODO: flat(1) ?
      const filteredAssignments = selectedCourses
        .map(id => groupedUserAssignments?.[id].assignments)
        .flat(1)
        .sort((a, b) => {
          // Order of assignments should be consistent with initial, unfiltered order
          const aIndex = userAssignmentsDisplayOrder.findIndex(
            index => a.id === index,
          )
          const bIndex = userAssignmentsDisplayOrder.findIndex(
            index => b.id === index,
          )
          return aIndex - bIndex
        })
      setVisibleAssignments(filteredAssignments)
    } else {
      // If no courses are selected, default to initial viewing settings (view all)
      setVisibleAssignments(userAssignments)
    }
  }, [
    groupedUserAssignments,
    selectedCourses,
    userAssignments,
    userAssignmentsDisplayOrder,
  ])

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}>
      <SplitHeadline
        left={`${numAssignments ? hasResultsTitle : ''}${pluralize('result')(numAssignments)}`}
        leftProps={{ size: 4, textTransform: 'lowercase' }}
        mb={1}
        right={
          <Box style={{ width: 220 }}>
            <CheckboxMultiSelect
              data={groupedUserAssignments}
              disabled={Object.entries(groupedUserAssignments).length < 2}
              handleSelected={handleSetSelectedCourses}
              name="courseFilterSelect"
              sortFn={assignmentsSort}
            />
          </Box>
        }
        rightProps={{ style: { textAlign: 'right' } }}
        style={{ alignItems: 'end' }}>
        {Boolean(numAssignments) && (
          <Box
            display="grid"
            // Setting column gap to 2% prevents visual jittering of card width when cards are filtered
            gap="24px 2%"
            gridTemplateColumns={
              /* istanbul ignore next */
              numAssignments === 1 && isLargeViewport
                ? '49%'
                : 'repeat(auto-fit, minmax(342px, 1fr))'
            }
            sx={{
              '& .MuiPaper-root': { height: 'unset' },
              '.MuiStack-root': { margin: 0 },
            }}>
            {visibleAssignments.map(userAssignment => (
              <AssignmentCard
                gridFlexVariant="vertical-flex"
                isOpen={false}
                key={userAssignment.id}
                userAssignment={userAssignment}
                viewMode="grid"
              />
            ))}
          </Box>
        )}
      </SplitHeadline>
    </Box>
  )
}

export default Assignments
