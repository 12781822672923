import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { toInt } from 'fp/strings'
import { useState } from 'react'
import DirectLoginForm from './DirectLoginForm'
import ForgotUsernamePassword from './ForgotUsernamePassword'

const modes = {
  DIRECT_LOGIN: 2,
  FORGOT_PASSWORD: 3,
}
const slideEasing = ({ transitions: { easing } }) => ({
  enter: easing.easeOut,
  exit: easing.sharp,
})

const FormCont = props => {
  const [mode, setMode] = useState(modes.DIRECT_LOGIN)
  const theme = useTheme()
  const easing = slideEasing(theme)
  const switchToDirectLogin = () => setMode(modes.DIRECT_LOGIN)
  const switchToForgotPassword = () => setMode(modes.FORGOT_PASSWORD)

  const modeComponents = {
    [modes.DIRECT_LOGIN]: {
      Component: DirectLoginForm,
      switchToForgotPassword,
    },
    [modes.FORGOT_PASSWORD]: {
      Component: ForgotUsernamePassword,
      switchToDirectLogin,
    },
  }

  return (
    <Box {...props}>
      <ErrorBoundary moduleName="FormCont">
        {Object.keys(modeComponents).map(modeKey => {
          const { Component, ...rest } = modeComponents[modeKey]

          return (
            mode === toInt(modeKey) && (
              <Slide
                direction="right"
                easing={easing}
                in={mode === toInt(modeKey)}
                key={mode}
                timeout={{
                  enter: 1000,
                }}>
                <Component
                  {...rest}
                  pageIsDisplayed
                />
              </Slide>
            )
          )
        })}
      </ErrorBoundary>
    </Box>
  )
}

export default FormCont
