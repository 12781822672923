import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import useIsMounted from 'hooks/useIsMounted'
import { border } from 'polished'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useFocusTrap } from 'react-use-focus-trap'

const StyledContainer = styled('div', { name: 'AnnotatableImage-TextEditor' })(
  ({
    theme: {
      mixins: { importantPx },
    },
  }) => ({
    minHeight: 100,
    '> textarea': {
      borderStyle: 'none',
      padding: importantPx(4),
      height: 100,
    },
  }),
)

const ButtonBar = styled('div', { name: 'AnnotatableImage-ButtonBar' })(
  ({ theme: { palette } }) => ({
    '> *': {
      width: '50%',
    },
    ...border('top', 1, 'solid', palette.grey[4]),
  }),
)

const TextEditor = ({ onCancel, onChange, onSubmit, value }) => {
  const ref = useRef()

  const [focusTrapRef] = useFocusTrap()

  const mounted = useIsMounted()

  useEffect(() => {
    if (mounted) ref.current.focus()
  }, [mounted])

  return (
    <div ref={focusTrapRef}>
      <StyledContainer>
        <textarea
          onChange={onChange}
          placeholder="Type to add a note..."
          ref={ref}
          value={value}
        />
      </StyledContainer>

      {!!value && (
        <ButtonBar direction="row">
          <Button
            color="secondary"
            onClick={onCancel}
            // tabIndex={0}
            variant="tertiary"
          >
            Cancel
          </Button>

          <Button
            color="primary"
            onClick={onSubmit}
            // tabIndex={0}
            variant="tertiary"
          >
            Done
          </Button>
        </ButtonBar>
      )}
    </div>
  )
}

TextEditor.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default TextEditor
