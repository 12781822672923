import { isUndefined, noop } from 'fp/utils'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { userAssignmentActions } from 'reducers/userAssignments'
import {
  getPercentageOfContentCompleted,
  getViewerTopLevelContent,
  includeProgress,
} from 'selectors/contentViewer'
import { getUserAssignmentById } from 'selectors/userAssignments'

const ProgressTracker = () => {
  const relevant = useSelector(includeProgress)
  const { userAssignmentId } = useParams()
  const userAssignment =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const { progress: userAssignmentProgress } = userAssignment

  const dispatch = useDispatch()
  const updateProgress = useCallback(
    progress => {
      // server stores with less precision than the getPercentageOfContentCompleted selector
      if (
        progress &&
        (isUndefined(userAssignmentProgress) ||
          Math.abs(progress - userAssignmentProgress) > 0.0001)
      ) {
        dispatch(
          userAssignmentActions.updateProgress({ userAssignmentId, progress }),
        )
      }
    },
    [dispatch, userAssignmentId, userAssignmentProgress],
  )

  const handleProgressChange = useMemo(
    () => (relevant && userAssignmentId ? updateProgress : noop),
    [relevant, updateProgress, userAssignmentId],
  )

  const content = useSelector(getViewerTopLevelContent)
  const calculatedProgress =
    useSelector(
      getPercentageOfContentCompleted({
        contentId: content?.id,
        contentType: content?.contentType,
      }),
    ) / 100.0

  useEffect(() => {
    handleProgressChange(calculatedProgress)
  }, [calculatedProgress, handleProgressChange])

  return null
}

export default ProgressTracker
