import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Well from 'common/layout/Well'
import { DND_TYPE_GROUP_AND_SORT_ITEM } from 'core/consts'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { useDrop } from 'react-dnd'
import Option from './Option'
import { context } from './context'

const OptionWell = ({ group }) => {
  const {
    dispatch,
    provideGrouping,
    showGroupTotals,
    state: { options },
  } = useContext(context)

  const handleDrop = useCallback(
    ({ draggedItem: { id } }) => {
      dispatch({ type: 'DROP-ON-LIST', droppedId: id })
    },
    [dispatch],
  )

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: DND_TYPE_GROUP_AND_SORT_ITEM,
    canDrop: dropItem =>
      !provideGrouping || dropItem.draggedItem.groupId === group.id,
    drop: handleDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const hoverClass = canDrop && isOver ? 'drag-hover' : ''

  const optionsInGroup = options.filter(
    o => group.id === 0 || o.groupId === group.id,
  )
  const numItems = optionsInGroup.length
  const droppedItemCount = optionsInGroup.filter(
    option => option.droppedId,
  ).length
  const availableOptions = ` Available Options (${numItems - droppedItemCount} of ${numItems})`

  return (
    <>
      <Box mb={2}>
        <Typography variant="body1-semibold">
          {group.heading}
          {Boolean(showGroupTotals) && <span>{availableOptions}</span>}
        </Typography>
      </Box>

      <Well
        className={hoverClass}
        data-testid="well">
        <div
          data-testid="well-target"
          ref={dropRef}
          style={{ width: '100%', minHeight: '5rem' }}>
          <List margin="0 auto">
            {optionsInGroup
              .filter(option => !option.droppedId)
              .map((item, idx) => (
                <Option
                  draggedFrom={-1}
                  idx={idx}
                  item={item}
                  key={item.id}
                />
              ))}
          </List>
        </div>
      </Well>
    </>
  )
}

OptionWell.propTypes = {
  group: PropTypes.any.isRequired,
}

export default OptionWell
