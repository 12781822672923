import MuiTabPanel, {
  type TabPanelProps as MuiTabPanelProps,
} from '@mui/lab/TabPanel'
import Container from '@mui/material/Container'
import SplitPane from 'common/layout/SplitPane'
import withProps from 'hoc/withProps'
import { absHeight } from 'styling/theming/base/mixins'
import { useAuthorContext } from '../AuthorProvider'
import AttributesPanel from './AttributesPanel'
import ContentPanel from './ContentPanel'
import PreviewPanel from './PreviewPanel'
import type { TabValue } from './Tabs'

const TabPanel = withProps(MuiTabPanel, {
  variant: 'condensed',
}) as React.FC<MuiTabPanelProps>

type TabPanelsProps = {
  currentTab: TabValue
}

const TabPanels = ({ currentTab }: TabPanelsProps) => {
  const { split } = useAuthorContext()

  return split ? (
    <Container
      sx={{
        ...absHeight('calc(100vh - 160px)'),
        '.splitter-layout': {
          height: '100%',
        },
      }}
      variant="author-split-pane">
      <SplitPane
        splitterProps={{
          style: {
            marginLeft: 0,
            marginRight: 20,
          },
        }}>
        {currentTab === '#attributes' ? <AttributesPanel /> : <ContentPanel />}

        <PreviewPanel />
      </SplitPane>
    </Container>
  ) : (
    <Container>
      <TabPanel value="#attributes">
        <AttributesPanel />
      </TabPanel>

      <TabPanel value="#content">
        <ContentPanel />
      </TabPanel>

      <TabPanel value="#preview">
        <PreviewPanel />
      </TabPanel>
    </Container>
  )
}

export default TabPanels
