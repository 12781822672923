import Stack from '@mui/material/Stack'
import HFDateTimePicker from 'common/formControls/dateAndTime/HFDateTimePicker'
import HFSwitch from 'common/formControls/switches/HFSwitch'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import TextField from 'common/formControls/textInputs/TextField'
import { ASSIGNMENT_TARGET_GROUP, ASSIGNMENT_TARGET_STUDENT } from 'core/consts'
import addMinutes from 'date-fns/addMinutes'
import { get } from 'fp/objects'
import { strip } from 'fp/strings'
import { parseDate } from 'locale/i18n'
import PropTypes from 'prop-types'
import { useFormContext, useWatch } from 'react-hook-form'
import TargetSelectField from './TargetSelectField'

const General = ({ isAssessment = false }) => {
  const [id, targetTypeId, target] = useFormContext().getValues([
    'id',
    'targetTypeId',
    'target',
  ])
  const isNewAssignment = !id

  const saveAsAssignmentTemplate = useWatch({
    name: 'saveAsAssignmentTemplate',
  })
  const startDate = parseDate(useWatch({ name: 'startDate' }))

  const getTargetName = {
    [ASSIGNMENT_TARGET_GROUP]: get('name'),
    [ASSIGNMENT_TARGET_STUDENT]: ({ firstName, lastName }) =>
      `${firstName} ${lastName}`,
  }
  const targetName = isNewAssignment
    ? null
    : getTargetName[targetTypeId](target)

  return (
    <Stack
      direction="column"
      gap={2}
      mt={2}>
      <HFTextField
        label="Title"
        name="name"
        required
      />
      {/* {Boolean(isNewAssignment && !isAssessment) && (
        <HFSwitch name="saveAsAssignmentTemplate" />
      )} */}
      {!saveAsAssignmentTemplate && (
        <>
          {isNewAssignment ? (
            <TargetSelectField
              label="Assign To"
              name="targets"
              required
            />
          ) : (
            <TextField
              disabled
              label="Assigned To"
              name="targetName"
              value={targetName}
            />
          )}
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            gap={2}>
            <HFDateTimePicker
              label="Start Date"
              name="startDate"
              required
            />

            <HFDateTimePicker
              label="Due Date"
              minDateTime={addMinutes(startDate, 1)}
              name="endDate"
              required
            />
          </Stack>

          {!isAssessment && (
            <HFSwitch
              helperText="Allow students to submit their assignment up to 60 days after the due date."
              label="Allow Late"
              name="data.settings.allowLate"
            />
          )}

          <HFSwitch
            helperText={strip`
              Allow students to see this assignment.
              (Students who have already submitted this assignment will see it in their binders regardless of this setting.)
            `}
            label="Active"
            name="isActive"
          />
        </>
      )}
    </Stack>
  )
}

General.propTypes = {
  isAssessment: PropTypes.bool,
}

export default General
