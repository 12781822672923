import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { ECHO_FEATURE_POLL } from 'hss/ContentBuilder/consts'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import Poll from 'hss/sections/contentBlocks/interactives/Poll'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { isStudent } from 'selectors/users'
import FeatureFootnotes from './FeatureFootnotes'
import FeatureHeadline from './FeatureHeadline'
import SubmitButton from './SubmitButton'

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  '.AnswerChoiceBox': {
    backgroundColor: palette.grey[5],
    '&:hover': { backgroundColor: palette.cobalt[4] },
  },
}))

const feature = ECHO_FEATURE_POLL

const FeaturePoll = () => {
  const {
    interactionData: { selectedValue: value = null },
  } = useContext(interactiveContext)
  const userIsStudent = useSelector(isStudent)

  return (
    <>
      <FeatureHeadline
        mb={2}
        title="Poll">
        <StyledBox mb={3}>
          <Poll />
        </StyledBox>

        <SubmitButton disabled={!(value && userIsStudent)} />
      </FeatureHeadline>

      <FeatureFootnotes feature={feature} />
    </>
  )
}

export default FeaturePoll
