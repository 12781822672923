import {
  CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import { componentShape } from 'core/shapes'
import { flatten, isEmpty, map, reduce } from 'fp/arrays'
import { equals, filterKeyedObject, get, hasProperty, set } from 'fp/objects'
import { fallbackTo, matches } from 'fp/utils'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import { produce } from 'immer'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getContextualAssignment } from 'selectors/assignments'
import { getContentNav } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'

const assignmentEditContext = createContext()
const arrayToLookup = reduce((result, next) => set(next, true)(result), {})

const findContent = (id, nodes) => {
  if (!nodes?.length) {
    return null
  }
  const match = nodes.find(matches('id', id))
  return (
    match ||
    compose(
      children => findContent(id, children),
      flatten,
      map(get('children')),
    )(nodes)
  )
}

const useAutoHiddenContentIds = sections =>
  useMemo(() => {
    const contentRestrictions = {}
    const flattenChildren = item => [
      item,
      ...flatten(item?.children?.map(flattenChildren)),
    ]
    for (const section of sections) {
      const children = flattenChildren(section)
        .filter(hasProperty('contentRestriction'))
        .filter(
          compose(
            //
            equals(CONTENT_RESTRICTION_TYPE_HIDDEN_BY_DEFAULT),
            get('contentRestriction.type'),
          ),
        )
      for (const child of children) {
        contentRestrictions[child.id] = child.contentRestriction
      }
    }

    return Object.keys(contentRestrictions)
    // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: fix this!!  causes extra renders
  }, [sections])

const AssignmentEditContextProvider = ({ children: providerChildren }) => {
  const assignment = useSelector(getContextualAssignment) || {}
  const { excludedContentIds: initiallyExcludedContentIds } = assignment

  const initiallyIncludeChapterSummary = get(
    'data.settings.includeChapterSummary',
  )(assignment)
  const initialLeveledNarrativeTextSetting = get(
    'data.settings.leveledNarrativeText',
  )(assignment)

  const [includeChapterSummary, setIncludeChapterSummary] = useState(
    initiallyIncludeChapterSummary,
  )
  const [leveledNarrativeTextSetting, setLeveledNarrativeTextSetting] =
    useState(initialLeveledNarrativeTextSetting)

  const [excludedContentIdLookup, setExcludedContentIdLookup] = useState(
    compose(arrayToLookup, fallbackTo([]))(initiallyExcludedContentIds),
  )

  const { contentId } = useSelector(getContentViewerParams()) || {}
  const sections = useSelector(
    getContentNav({
      contentId,
      leafContentTypes: SECTION_CONTENT_TYPES,
    }),
  )

  const updateExcludedContentIds = useCallback(
    newExcludedContentIds => {
      setExcludedContentIdLookup(
        produce(newExcludedContentIds, draft => {
          const setSelectionBasedOnChildren = ({ children, id }) => {
            if (children?.length) {
              for (const child of children) {
                setSelectionBasedOnChildren(child)
              }
              const selectedChildren = children.filter(
                ({ id: childId }) => draft[childId],
              )
              draft[id] = selectedChildren.length === children.length
            }
          }
          for (const section of sections || []) {
            setSelectionBasedOnChildren(section)
          }
        }),
      )
    },
    [sections],
  )

  const isContentIdExcluded = useCallback(
    id => !!excludedContentIdLookup[id],
    [excludedContentIdLookup],
  )

  const excludedContentIds = useDeepCompareMemo(
    () => Object.keys(filterKeyedObject(excludedContentIdLookup, Boolean)),
    [excludedContentIdLookup],
  )

  const selectContent = useCallback(
    (id, select) => {
      updateExcludedContentIds(
        produce(excludedContentIdLookup, draft => {
          const updateChildrenRecursive = ({ children = [] }) => {
            for (const child of children) {
              draft[child.id] = select
              updateChildrenRecursive(child)
            }
          }
          draft[id] = select
          updateChildrenRecursive(findContent(id, sections) || {})
        }),
      )
    },
    [excludedContentIdLookup, sections, updateExcludedContentIds],
  )

  const toggleExcludeContentId = useCallback(
    id => {
      selectContent(id, !excludedContentIdLookup[id])
    },
    [excludedContentIdLookup, selectContent],
  )

  const initialized = useRef(false)
  const initiallyHiddenContentIds = useAutoHiddenContentIds(sections)
  useEffect(() => {
    // biome-ignore lint/complexity/useSimplifiedLogicExpression:
    if (!isEmpty(sections) && !assignment.id && !initialized.current) {
      initialized.current = true
      for (const id of initiallyHiddenContentIds) {
        selectContent(id, true)
      }
    }
  }, [assignment.id, initiallyHiddenContentIds, sections, selectContent])

  const value = useMemo(
    () => ({
      excludedContentIds,
      includeChapterSummary,
      isContentIdExcluded,
      leveledNarrativeTextSetting,
      sections,
      setIncludeChapterSummary,
      setLeveledNarrativeTextSetting,
      toggleExcludeContentId,
    }),
    [
      excludedContentIds,
      includeChapterSummary,
      isContentIdExcluded,
      leveledNarrativeTextSetting,
      sections,
      toggleExcludeContentId,
    ],
  )

  return (
    <assignmentEditContext.Provider value={value}>
      {providerChildren}
    </assignmentEditContext.Provider>
  )
}

AssignmentEditContextProvider.propTypes = {
  assignment: PropTypes.shape({
    contentId: PropTypes.string,
    excludedContentIds: PropTypes.array,
  }),
  children: componentShape.isRequired,
}

export { assignmentEditContext, AssignmentEditContextProvider }
