import CommonTableSearch from 'hss/views/Search/CommonTableSearch'
import withSearchProvider from './searchConfig'

const initialSorting = [
  {
    id: 'name',
    desc: false,
  },
]

const Schools = () => (
  <CommonTableSearch
    initialSorting={initialSorting}
    title="Schools"
  />
)

const Searchable = withSearchProvider(Schools)

export default Searchable
