import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Dialog from 'common/dialogs/Dialog'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import useInterval from 'hooks/useInterval'
import useSession from 'hooks/useSession'
import { now } from 'locale/dates'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { actions as sessionActions } from 'reducers/session'
import { compose } from 'redux'

const timeLabel = seconds =>
  `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, '0')}`

const TimeoutWarning = () => {
  const dispatch = useDispatch()
  const {
    actions,
    session: { endsAt, startsAt, warnAt },
  } = useSession()
  const keepAlive = compose(dispatch, actions.keepAlive)

  const handleLogout = () => dispatch(sessionActions.logout())

  const [timeLeft, setTimeLeft] = useState(differenceInSeconds(endsAt, now()))

  useInterval(() => {
    if (timeLeft > 0) {
      setTimeLeft(timeLeft - 1)
    }
  }, 1000)

  useEffect(() => {
    setTimeLeft(differenceInSeconds(endsAt, now()))
  }, [endsAt])

  return (
    <Dialog
      open
      showCloseButton
      sx={{
        textAlign: 'center',
      }}
      tag="sessionEnding"
      title="Session Ending Soon"
      TitleProps={{
        mb: 3,
        variant: 'h3',
      }}>
      <DialogContent>
        <Typography variant="h1">{timeLabel(timeLeft)}</Typography>

        <Typography
          mb={0}
          mt={3}
          paragraph
          variant="feature-paragraph">
          This is your{' '}
          <strong>{differenceInMinutes(endsAt, warnAt)} minute</strong>{' '}
          countdown, after being inactive for{' '}
          <strong>{differenceInMinutes(warnAt, startsAt)} minutes</strong>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleLogout}
          variant="secondary">
          Logout
        </Button>
        <Button
          color="secondary"
          onClick={keepAlive}
          variant="primary">
          Keep Working
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TimeoutWarning
