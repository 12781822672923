import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import BusySpinner from 'common/indicators/BusySpinner'
import { reduxCallbackStatusShape } from 'core/shapes'
import { BUSY } from 'hooks/useReduxCallback'
import AlertTriangle from 'hss/images/controls/textfield/alert-triangle.png'
import PropTypes from 'prop-types'

const Status = ({ errorMsg, uploadStatus }) => {
  const { typography } = useTheme()

  return (
    <>
      {Boolean(errorMsg) && (
        <Box mt={2}>
          <Box
            color="error.main"
            sx={{
              ...typography.variants.small,
              fontWeight: 300,
              display: 'inline-flex',
              '&::before': {
                content: `url(${AlertTriangle})`,
                marginRight: 2,
              },
            }}>
            {errorMsg}
          </Box>
        </Box>
      )}

      {uploadStatus === BUSY && (
        <Box mt={2}>
          <BusySpinner />
          Uploading...
        </Box>
      )}
    </>
  )
}

Status.propTypes = {
  errorMsg: PropTypes.string,
  uploadStatus: reduxCallbackStatusShape.isRequired,
}

export default Status
