import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Popper from 'common/indicators/Popper'
import VocabularyPopperContent from 'common/vocabulary/VocabularyPopperContent'
import { CONTENT_TYPE_VOCABULARY } from 'core/consts'
import { componentShape, numberOrString } from 'core/shapes'
import useContent from 'hooks/useContent'
import { memo } from 'react'

const paperStyle = {
  minWidth: 495,
}

const VocabPhraseButton = styled(
  ({ children, onClick, ...rest }) => {
    const handleClick = e => {
      // Don't try to navigate.
      e.preventDefault()
      onClick(e)
    }

    return (
      <>
        {/*
        Normally this ought to be a <button> element. Unfortunately,
        that would've prevented the user from making an annotation that includes the vocab phrase.
        Using an <a> element instead will allow annotation to work in addition to the popper behavior.
        Adding an empty href is sufficient to make the element focusable and also clickable via the Enter key.
      */}
        {}
        <Typography
          component="a"
          href=""
          onClick={handleClick}
          role="button"
          variant="link"
          {...rest}>
          {/*
            Looks like highlighting key words is one intended use of the <b> element?
            https://html.spec.whatwg.org/multipage/text-level-semantics.html#the-b-element
        */}
          <b>{children}</b>
        </Typography>
      </>
    )
  },
  { name: 'VocabPhrase-VocabPhraseButton' },
)(() => ({
  color: 'inherit',
  b: {
    font: 'inherit',
  },
}))

const VocabPhrase = memo(
  ({ children, vocabId }) => {
    const vocabPhrase = useContent({
      contentType: CONTENT_TYPE_VOCABULARY,
      contentId: vocabId,
    })

    return (
      <Popper paperStyle={paperStyle}>
        <Popper.Control>
          <VocabPhraseButton>{children}</VocabPhraseButton>
        </Popper.Control>

        <Popper.Content>
          <VocabularyPopperContent vocabPhrase={vocabPhrase} />
        </Popper.Content>
      </Popper>
    )
    /**
     * I _believe_ it's safe to ignore children here because the only prop that
     * changes is vocabId, which is used to fetch the vocabPhrase.
     *
     * The only place is might matter is in the content builder.
     */
  },
  (prevProps, nextProps) => prevProps.vocabId === nextProps.vocabId,
)

VocabPhrase.propTypes = {
  children: componentShape.isRequired,
  vocabId: numberOrString.isRequired,
}

export default VocabPhrase
