import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Image from 'apps/common/indicators/Image'
import Html from 'common/text/Html'
import { hotspotCalloutShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { useId } from 'react'
import { animated, useSpring } from 'react-spring'
import LongTextAlternative from '../Image/LongTextAlternative'

const CalloutBody = props => {
  const {
    callout: {
      centered,
      description,
      imageAltText,
      imagePosition,
      label,
      longTextAlternative,
    },
    imageUrl,
  } = props

  const style = useSpring({
    config: {
      mass: 1,
      tension: 180,
      friction: 14,
    },
    from: {
      opacity: 0,
      scale: 0.8,
    },
    to: {
      opacity: 1,
      scale: 1.0,
    },
  })

  const descriptionId = useId()

  const imageStyle =
    imagePosition === 'left' ? { minHeight: 200, maxWidth: 200 } : {}

  const boxStyle = imagePosition === 'left' ? {} : { width: 320 }

  const flexDirection = imagePosition === 'left' ? 'row' : 'column'

  const textAlign = centered ? 'center' : 'left'

  const StyledCalloutContent = styled(Box)(() => ({
    ...boxStyle,
    display: 'flex',
    flexDirection,
    '> img': {
      ...imageStyle,
      objectFit: 'cover',
      height: '100%',
    },
    '> .MuiBox-root': {
      padding: '2rem',
      textAlign,
      width: '100%',
      // label style
      '.MuiBox-root': {
        fontWeight: 'bold',
        marginBottom: '0.5rem',
      },
      p: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  }))

  return (
    <animated.div style={style}>
      <StyledCalloutContent>
        {Boolean(imageUrl) && (
          <>
            <Image
              alt={imageAltText}
              aria-describedby={longTextAlternative ? descriptionId : null}
              src={imageUrl}
            />
            {Boolean(longTextAlternative) && (
              <LongTextAlternative
                html={longTextAlternative}
                id={descriptionId}
                label="Image Details"
              />
            )}
          </>
        )}
        <Box>
          <Box>{label}</Box>
          <Html body={description} />
        </Box>
      </StyledCalloutContent>
    </animated.div>
  )
}

CalloutBody.propTypes = {
  callout: hotspotCalloutShape.isRequired,
  imageUrl: PropTypes.string,
}

export default CalloutBody
