import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import Slider from 'common/formControls/sliders/Slider'
import { findMaxValue } from 'fp/arrays'
import { when } from 'fp/utils'
import useEffectOnce from 'hooks/useEffectOnce'
import { useCallback, useContext, useLayoutEffect, useState } from 'react'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const EntryForm = () => {
  const {
    completed,
    interactionData: { selectedValue },
    interactiveData: {
      initialDefaultValue = 5,
      helperText,
      marks = [
        { value: 1, label: 'Not Important' },
        { value: 10, label: 'Most Important' },
      ],
    },
    isGrading,
    onInteract,
  } = useContext(interactiveContext)

  const [localSelectedValue, setLocalSelectedValue] = useState(
    selectedValue || initialDefaultValue,
  )

  const maxSliderValue = Math.min(findMaxValue('value')(marks), 10)

  useEffectOnce(() => {
    when(!(selectedValue || completed || isGrading), onInteract, {
      selectedValue: initialDefaultValue,
    })
  })

  const handleChange = useCallback(
    ({ target }) => {
      setLocalSelectedValue(target.value)
      onInteract({ selectedValue: target.value })
    },
    [onInteract],
  )

  useLayoutEffect(() => {
    when(isGrading, setLocalSelectedValue, selectedValue)
  }, [isGrading, selectedValue])

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        mb={2}
        mt={6}>
        <FormHelperText>{helperText}</FormHelperText>
      </Box>

      <Slider
        disabled={isGrading}
        marks={marks}
        max={maxSliderValue}
        min={1}
        name="scale"
        onChange={handleChange}
        readOnly={isGrading}
        step={1}
        value={localSelectedValue}
        valueLabelDisplay="auto"
      />
    </>
  )
}

export default EntryForm
