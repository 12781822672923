import Button from '@mui/material/Button'
import { get } from 'fp/objects'
import useSpeech from 'hooks/useSpeech'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { Volume2 } from 'react-feather'

const TestSpeechSettings = ({ preferences }) => {
  const browserSpeechEnabled = get('useBrowserSpeech')(preferences)
  const rate = get('audioDescriptionRate')(preferences)
  const volume = get('audioDescriptionVolume')(preferences)

  const { say } = useSpeech()

  const test = useCallback(() => {
    say('This is a test of the browser speech settings.', rate, volume)
  }, [rate, say, volume])

  return (
    <Button
      color="secondary"
      disabled={!browserSpeechEnabled}
      endIcon={<Volume2 />}
      onClick={test}
      style={{ marginTop: '2rem' }}
      variant="secondary">
      Test Speech Settings
    </Button>
  )
}

TestSpeechSettings.propTypes = {
  preferences: PropTypes.object.isRequired,
}

export default TestSpeechSettings
