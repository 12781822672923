import TabContext from '@mui/lab/TabContext'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import TabList from 'common/layout/TabList'
import { interactiveContext } from 'hss/sections/contentBlocks/Interactive/InteractiveProvider'
import { allowPeerReviews } from 'projections/assignments'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getContextualAssignment } from 'selectors/assignments'
import PeerReviewsTab from './PeerReviewsTab'
import StudentResponseRubricGrader from './StudentResponseRubricGrader'

const STUDENT_RESPONSE = 'response'
const PEER_REVIEWS = 'reviews'

const ResponseTabs = {
  [STUDENT_RESPONSE]: StudentResponseRubricGrader,
  [PEER_REVIEWS]: PeerReviewsTab,
}

const EchoResponseRubricGrader = () => {
  const {
    interactive: { contentSubType },
  } = useContext(interactiveContext)
  const peerReviewsAllowed = compose(
    allowPeerReviews,
    useSelector,
  )(getContextualAssignment)

  const [currentTab, setCurrentTab] = useState(STUDENT_RESPONSE)
  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue)
  }

  const isEcho = contentSubType?.startsWith('echo')

  const CurrentTab = useMemo(() => ResponseTabs[currentTab], [currentTab])

  return peerReviewsAllowed ? (
    <Stack
      gap={4}
      sx={isEcho ? { marginTop: 3 } : {}}>
      <TabContext
        minSize="sm"
        value={currentTab}>
        <TabList
          minSize="sm"
          onChange={handleTabChange}>
          <Tab
            label={STUDENT_RESPONSE}
            value={STUDENT_RESPONSE}
          />

          <Tab
            label={PEER_REVIEWS}
            value={PEER_REVIEWS}
          />
        </TabList>
      </TabContext>
      <CurrentTab />
    </Stack>
  ) : (
    <StudentResponseRubricGrader />
  )
}

export default EchoResponseRubricGrader
