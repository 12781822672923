import PropTypes from 'prop-types'
import config from '../config'
import DiagramPoint from './DiagramPoint'

const resolutionConfig = {
  textAnchor: 'end',
  verticalAnchor: 'start',
  x: config.resolution.endX,
  y: config.baselineY + config.labelBelowOffsetY,
  width: config.resolution.endX - config.viewBox.midX,
  height: config.viewBox.height - config.baselineY - config.labelBelowOffsetY,
}
const Resolution = ({ onClick, children, ...rest }) => (
  <g>
    {/* biome-ignore lint/a11y/noInteractiveElementToNoninteractiveRole: TODO: look into */}
    <text
      className="title"
      role="presentation"
      x={config.resolution.midX}
      y={config.baselineY - config.labelAboveOffsetY}>
      Resolution
    </text>
    <DiagramPoint
      CircleProps={{
        cx: config.resolution.midX,
        cy: config.baselineY,
        r: config.circleRadius,
      }}
      {...resolutionConfig}
      {...rest}
      onClick={onClick}>
      {children}
    </DiagramPoint>
  </g>
)

Resolution.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default Resolution
