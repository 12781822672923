import Centered from 'common/layout/Centered'
import { asPercentageString } from 'fp/numbers'
import { getScore } from 'projections/interactions'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { getUserById } from 'selectors/users'
import { interactiveContext } from '../../InteractiveProvider'

const ScoreRow = ({ interaction: { scoreData, userId } }) => {
  const { firstName, lastName } = useSelector(getUserById({ userId })) || {}
  const { requiresGrading = false } = scoreData
  const score = getScore(scoreData)
  const studentName = `${firstName} ${lastName}`.trim()

  return (
    <tr>
      <td>{studentName}</td>
      <td>
        {requiresGrading ? '(requires grading)' : asPercentageString(score)}
      </td>
    </tr>
  )
}
ScoreRow.propTypes = {
  interaction: PropTypes.shape({
    scoreData: PropTypes.shape({
      manualScoreOverride: PropTypes.number,
      requiresGrading: PropTypes.bool,
      score: PropTypes.number,
    }),
    userId: PropTypes.string.isRequired,
  }).isRequired,
}

const ScoreTable = () => {
  const { contentId } = useContext(interactiveContext)
  const { assignmentId } = useParams()

  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  return allInteractions?.length ? (
    <table>
      <thead>
        <tr>
          <th>Student</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        {allInteractions.map(interaction => (
          <ScoreRow
            interaction={interaction}
            key={interaction.id}
          />
        ))}
      </tbody>
    </table>
  ) : (
    <Centered component="p">(no responses)</Centered>
  )
}

export default ScoreTable
