import Pagination from 'common/tables/DataTable/Pagination'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { searchContext } from '../SearchProvider'

const TableResults = ({ initialSorting }) => {
  const {
    ItemRenderer: Table,
    columns,
    dataset,
    setSquery,
    squery,
    tableId,
    viewMode,
  } = useContext(searchContext)

  return (
    <>
      <Table
        columns={columns || []}
        data={dataset?.data}
        id={tableId}
        initialSorting={initialSorting}
        setSquery={setSquery}
        squery={squery}
        viewMode={viewMode}
      />

      <Pagination
        {...{
          dataset,
          setSquery,
          squery,
          tableId,
        }}
      />
    </>
  )
}

export default TableResults

TableResults.propTypes = {
  initialSorting: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
}
