import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Link from '@mui/material/Link'
import Dialog from 'common/dialogs/Dialog'
import Centered from 'common/layout/Centered'
import DataTable from 'common/tables/DataTable'
import { SimpleCell, SimpleDateCell } from 'common/tables/DataTable/Cells'
import Pagination from 'common/tables/DataTable/Pagination'
import { assert, alter } from 'core/store/search/squery'
import { useContext, useEffect, useRef, useState } from 'react'
import { ArrowLeft } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/userAlerts'
import { compose } from 'redux'
import { navDialogsContext } from 'routing/shells/NavShell/NavDialogsProvider'
import { stateUserAlerts } from 'selectors/index'
import {
  getAllUserAlerts,
  getUserAlertsFetchedStatus,
} from 'selectors/userAlerts'
import ProfileUserAlert from './ProfileUserAlert'
import UserAlertContent from './UserAlertContent'

const columns = ({ viewAlert }) => [
  SimpleCell({
    header: 'Title',
    id: 'title',
    // Each Title cell, when clicked, will display the full alert content
    cell: ({ cell, row }) => (
      <Link
        component="button"
        onClick={() => viewAlert({ ...row.original })}
        sx={{ textAlign: 'left' }}
        variant="primary">
        {cell.getValue()}
      </Link>
    ),
    minSize: 200,
  }),
  SimpleCell({ header: 'Type', id: 'type' }),
  SimpleDateCell({ header: 'Date', id: 'dateCreated' }),
]

const initialSorting = [
  {
    id: 'dateCreated',
    desc: true,
  },
]

const initialSquery = compose(
  alter.set.limit(10),
  alter.set.orderBy('dateCreated', 'desc'),
)(assert())

const NotificationsDialog = () => {
  const { toggleNotificationsIsOpen } = useContext(navDialogsContext)

  const alertsFetched = useSelector(getUserAlertsFetchedStatus({ type: 'all' }))
  const { all: dataset } = useSelector(stateUserAlerts) // used for pagination
  const data = useSelector(getAllUserAlerts)

  const tableId = 'notifications-dialog-table'

  // Notifications dialog can either show a single user alert (based on alert 'id') or a table of all notifications
  const [notificationsContent, setNotificationsContent] = useState('table')

  const [squery, setSquery] = useState(initialSquery)
  const containerRef = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.fetchUserAlerts({ queryParams: squery }))
  }, [dispatch, squery])

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => {
        // reset dialog to 'table' view
        setNotificationsContent('table')
        return toggleNotificationsIsOpen()
      }}
      open
      showCloseButton
      variant="maximized-vertically">
      <DialogTitle>Notifications</DialogTitle>

      <DialogContent
        style={
          notificationsContent === 'table' ? { paddingBottom: '3rem' } : null
        }>
        <div ref={containerRef}>
          {alertsFetched ? (
            notificationsContent === 'table' ? (
              <>
                <DataTable
                  columns={columns({ viewAlert: setNotificationsContent })}
                  containerProps={{ noMarginTop: true }}
                  data={data || []}
                  id={tableId}
                  initialSorting={initialSorting}
                  noDataText="There are no notifications to display."
                />
                {Boolean(data.length) && (
                  <Pagination
                    {...{
                      dataset,
                      setSquery,
                      squery,
                      tableId,
                    }}
                  />
                )}
              </>
            ) : notificationsContent.mode === 'profile' ? (
              <ProfileUserAlert alert={notificationsContent} />
            ) : (
              <UserAlertContent alert={notificationsContent} />
            )
          ) : (
            <Centered>
              <CircularProgress size={48} />
            </Centered>
          )}
        </div>
      </DialogContent>

      {notificationsContent !== 'table' && (
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setNotificationsContent('table')}
            variant="secondary">
            <span
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <ArrowLeft size={16} />
              Back to all Notifications
            </span>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default NotificationsDialog
