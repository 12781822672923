import { useCallback } from 'react'
import config from '../config'
import ActionPoints from './ActionPoints'

const FallingAction = props => {
  const TitleProps = {
    x: config.resolution.startX - 0.5 * config.fall.length,
    y: config.baselineY + config.labelBelowOffsetY,
    transform: `rotate(${config.fall.angle} ${config.resolution.startX} ${config.baselineY})`,
  }
  const offsetX = useCallback(
    height => config.slantedNoteOffsetX + (0.5 * height) / config.fall.slope,
    [],
  )

  const ActionNoteProps = {
    textAnchor: 'start',
    verticalAnchor: 'middle',
  }

  return (
    <ActionPoints
      fieldName="fallingAction"
      offsetX={offsetX}
      offsetXDirection={1}
      slope={config.fall.slope}
      title="Falling Action"
      TitleProps={TitleProps}
      {...ActionNoteProps}
      {...props}
    />
  )
}

export default FallingAction
