import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import StyledGroupContent from 'hss/sections/contentBlocks/interactives/GroupAndSort/StyledGroupContent'
import PropTypes from 'prop-types'
import { useId } from 'react'

const Group = ({ children, heading }) => {
  const headingId = useId()

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%">
      <Box
        id={headingId}
        mb={2}
        textAlign="center">
        <Typography variant="body1-semibold">{heading}</Typography>
      </Box>

      <StyledGroupContent>
        <Box flexGrow="1">
          <List aria-labelledby={headingId}>{children}</List>
        </Box>
      </StyledGroupContent>
    </Box>
  )
}

Group.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
}

export default Group
