import { styled, useTheme } from '@mui/material/styles'
import Annotatable from 'common/annotations/Annotatable'
import ContainerQuery from 'common/layout/ContainerQuery'
import ScrollToTop from 'common/navigation/ScrollToTop'
import SkipToBottomNavigation from 'common/navigation/SkipToBottomNavigation'
import SkipToEchoSidebar from 'common/navigation/SkipToEchoSidebar'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import { CONTENT_TYPE_ECHO, TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { get } from 'fp/objects'
import LearnBanner from 'hss/ContentViewer/Banner/LearnBanner'
import {
  ContentViewerProvider,
  contentViewerContext,
} from 'hss/ContentViewer/ContentViewerProvider'
import PropTypes from 'prop-types'
import { useContext, useEffect, useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import RouteTransition from 'routing/RouteTransition'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import { getLocalSetting } from 'selectors/localSettings'
import ContentBlocks from '../Subsection/ContentBlocks'
import Footnotes from '../Subsection/Footnotes'
import Hero from '../Subsection/Hero'
import Navigation from '../Subsection/Navigation'
import SubsectionHeadline from '../Subsection/SubsectionHeadline'
import EchoScaffolds from './EchoScaffolds'
import EchoSidebar from './EchoSidebar'

const SubsectionStyleOverrides = styled('div', { name: 'Echo-StyleOverrides' })(
  ({
    theme: {
      mixins: { important },
      palette,
    },
  }) => ({
    overflow: 'hidden auto',
    height: '100vh',

    flexGrow: 1,

    background: important(palette.text.secondary),
    color: palette.primary.contrastText,

    '.container-swatch': {
      '&::before': {
        backgroundColor: important(palette.echo.decorative),
      },
    },

    '.tr-typography': {
      '&::after': {
        borderColor: important(palette.echo.decorative),
      },
    },

    '.MuiAppBar-root .MuiToolbar-root': {
      background: important(palette.text.secondary),
      color: palette.primary.contrastText,
    },

    '.MuiToolbar-subsection': {
      backgroundColor: important('transparent'),
      button: {
        borderColor: palette.border[3],
        color: important(palette.primary.contrastText),
        backgroundColor: important(palette.echo.background),
        span: {
          color: important(palette.echo.toc),
        },
        '&:hover': {
          span: {
            color: important(palette.echo.tocDark),
          },
        },
        '&.Mui-selected': {
          backgroundColor: important(palette.echo.selected),
          color: important(palette.text.primary),
          span: {
            color: important(palette.echo.tocDark),
          },
        },
      },
    },

    '.content-heading': {
      color: palette.echo.text,
      textAlign: 'center',
    },

    '.te-content': {
      color: palette.text.primary,
      '.content-heading': {
        color: palette.text.primary,
        textAlign: 'left',
      },
    },
  }),
)

const Echo = () => {
  const { subsection } = useContext(contentViewerContext)
  const { isEchoScaffoldOpen } = useContext(sidebarContext)
  const contextContentId = subsection?.id
  const ref = useRef()
  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  useLayoutEffect(() => {
    /**
     * The main viewport and the scaffolds share the same scroll container.
     * When the scaffolds are opened, make sure the scaffold content is visible
     * within the viewport
     */
    if (ref.current && isEchoScaffoldOpen) {
      ref.current.scrollTop = 0
    }
  }, [isEchoScaffoldOpen])

  const { setIsEchoActive } = useContext(sidebarContext)

  useEffect(() => {
    setIsEchoActive(true)
    return () => {
      setIsEchoActive(false)
    }
  }, [setIsEchoActive])

  const busySpinnerColor = get('palette.primary.contrastText')(useTheme())

  return contextContentId ? (
    <>
      <SubsectionStyleOverrides ref={ref}>
        <ContainerQuery>
          {!presenterModeEnabled && <EchoScaffolds />}

          <LearnBanner />

          <ScrollToTop querySelector=".layout-pane-primary" />

          <Annotatable
            contextContentId={contextContentId}
            style={{ minHeight: '75vh' }}>
            <RouteTransition busySpinnerColor={busySpinnerColor}>
              <Hero />

              <SubsectionHeadline />

              <HeadlineLevelOffset>
                <ContentBlocks />

                <Footnotes />

                <SkipToEchoSidebar />

                <Navigation mt={0} />
              </HeadlineLevelOffset>
            </RouteTransition>
          </Annotatable>
        </ContainerQuery>
      </SubsectionStyleOverrides>

      <EchoSidebar />

      <SkipToBottomNavigation />
    </>
  ) : null
}

const WithProvider = ({ paramName = 'echoId' }) => (
  <ContentViewerProvider
    contentType={CONTENT_TYPE_ECHO}
    paramName={paramName}>
    <Echo />
  </ContentViewerProvider>
)
WithProvider.propTypes = {
  paramName: PropTypes.string,
}

/* istanbul ignore next line */
export default WithProvider
