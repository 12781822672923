import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Centered from 'common/layout/Centered'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import Headline from 'common/text/Headline'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import Html from 'common/text/Html'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getInteractionsForAssignmentContent } from 'selectors/interactions'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from '../../InteractiveProvider'
import RubricAggregate from './RubricAggregate'

const MaxWidthStack = styled(Stack)({
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: featuredContentMaxWidth,
})

const Section = ({ children, title }) => (
  <CollapsibleSection
    initiallyExpanded
    label={
      <Headline
        textAlign="center"
        title={title}
        variant="feature-paragraph-semibold"
      />
    }
    variant="interactive-grading">
    <HeadlineLevelOffset>{children}</HeadlineLevelOffset>
  </CollapsibleSection>
)
Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

const TextResponseRubricAggregate = () => {
  const {
    contentId,
    interactiveData: { sampleAnswer },
  } = useContext(interactiveContext)
  const { assignmentId } = useParams()

  const allInteractions = useSelector(
    getInteractionsForAssignmentContent({ contentId, assignmentId }),
  )

  return (
    <Container>
      <MaxWidthStack gap={4}>
        {Boolean(sampleAnswer) && (
          <Section title="Sample answer">
            <Html body={sampleAnswer} />
          </Section>
        )}

        <Section title="Average Scores">
          {allInteractions.length ? (
            <RubricAggregate />
          ) : (
            <Centered component="p">(no responses)</Centered>
          )}
        </Section>
      </MaxWidthStack>
    </Container>
  )
}

export default TextResponseRubricAggregate
