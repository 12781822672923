import List from '@mui/material/List'
import { DND_TYPE_CALLOUT_SWATCH } from 'core/consts'
import { imageTitleDragCalloutShape } from 'core/shapes'
import { get } from 'fp/objects'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { interactiveContext } from '../../Interactive/InteractiveProvider'
import OptionSwatch from './OptionSwatch'

const Options = ({ callouts, onChange }) => {
  const { submittable } = useContext(interactiveContext)

  const [/* { isOver, draggedItemType } */ , dropRef] = useDrop({
    accept: DND_TYPE_CALLOUT_SWATCH,
    drop: ({ id }) => onChange(id, null),
    canDrop: () => submittable,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      draggedItemType: monitor.getItemType(),
    }),
  })

  const usedIds = callouts.map(get('droppedId')).filter(Boolean)

  return (
    <div
      ref={dropRef}
      style={{ width: '100%', minHeight: '5rem' }}>
      <List>
        {callouts
          .filter(({ id }) => !usedIds.includes(id))
          .map(callout => (
            <OptionSwatch
              callout={callout}
              key={callout.id}
            />
          ))}
      </List>
    </div>
  )
}

Options.propTypes = {
  callouts: PropTypes.arrayOf(imageTitleDragCalloutShape).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Options
