import Dialog from 'common/dialogs/Dialog'
import InternalLinkTab from 'common/formControls/textInputs/RichTextEdit/plugins/linkPlugin/InternalLinkTab'
import {
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
} from 'core/consts'
import { contentShape } from 'core/shapes'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import useNavigation from 'hooks/useNavigation'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import actionTypes from 'reducers/actionTypes'
import { compose } from 'redux'
import { getCurrentSectionIsTabbed } from 'selectors/contentViewer'

const contentTypes = {
  sibling: {
    [`${CONTENT_TYPE_SUBSECTION}_tabbed`]: [
      // Created a special key here because we want to limit
      // the ability to insert echoes into tabbed sections
      CONTENT_TYPE_SOURCE,
      CONTENT_TYPE_SUBSECTION,
    ],
    default: [CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION],
  },
  parent: {
    default: [CONTENT_TYPE_SECTION],
  },
}

const ContentInsertionDialog = props => {
  const { level, onClose, open, placement, relativeContent } = props
  const { navigate } = useNavigation()
  const dispatch = useDispatch()
  const isPinned = useIsPinnedContent()
  const isTabbedSection = useSelector(getCurrentSectionIsTabbed({ isPinned }))

  const handleSelectionMade = content => {
    const { contentType, id } = content

    dispatch({
      type: actionTypes.CONTENT_INSERTION_ALTERATION,
      payload: {
        insertionContentId: content.id,
        level,
        placement,
        relativeContent,
      },
    })

    if (contentType === CONTENT_TYPE_SECTION) {
      // Navigating to a specific section gets weird,
      // so avoiding auto-navigation for now in this case.
      onClose()
      return
    }

    navigate(`../${contentType}/${id}`)

    onClose()
  }

  const relativeContentType = compose(
    fallbackTo('default'),
    contentType =>
      contentType === CONTENT_TYPE_SUBSECTION && isTabbedSection
        ? `${CONTENT_TYPE_SUBSECTION}_tabbed`
        : undefined,
    get('contentType'),
  )(relativeContent)

  const supportedContentTypes = compose(
    // If the relative content type does not exist in the current level,
    // then fallback to the default content types for said level
    fallbackTo(get(`${level}.default`)(contentTypes)),
    get(relativeContentType),
    get(level),
  )(contentTypes)

  return (
    <Dialog
      dense
      disableAutoFocus
      maxWidth="sm"
      onClose={onClose}
      open={open}
      showCloseButton
      title="Select Content to Insert"
      TitleProps={{ mb: 3 }}
      variant="maximized-vertically">
      <InternalLinkTab
        contentTypes={supportedContentTypes}
        mode="content-insertion"
        onComplete={handleSelectionMade}
      />
    </Dialog>
  )
}

ContentInsertionDialog.propTypes = {
  level: PropTypes.oneOf(['parent', 'sibling']).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf(['above', 'below']).isRequired,
  relativeContent: contentShape,
}

export default ContentInsertionDialog
