import DialogContent from '@mui/material/DialogContent'
import type { ContentType } from 'common/@types/common'
import Dialog from 'common/dialogs/Dialog'
import PropTypes from 'prop-types'
import { createElement, useEffect, useState } from 'react'
import All from './assetTypes/All'

export interface SelectedAssetTypeArg {
  contentType: ContentType
  contentSubType: string // TODO: Create and adopt contentSubType type
  label: string
}
interface SelectAssetTypeDialogProps {
  open: boolean
  onProceed: (selectedAssetType: SelectedAssetTypeArg) => void
  onClose: () => void
}

const SelectAssetTypeDialog: React.FC<SelectAssetTypeDialogProps> = props => {
  const { onClose, onProceed, open } = props
  const [selectedAssetType, setSelectedAssetType] = useState(null)

  useEffect(() => {
    if (selectedAssetType && open) {
      onClose()
      setTimeout(() => onProceed(selectedAssetType))
    }
  }, [onClose, onProceed, open, selectedAssetType])

  return (
    <Dialog
      disableAutoFocus
      onClose={onClose}
      open={open}
      showCloseButton
      swatch
      title="Add to Library"
      variant="maximized-vertically">
      <DialogContent>
        {createElement(All, {
          itemHeight: 80,
          itemWidth: 163,
          ListProps: {
            style: {
              overflowY: 'auto',
              maxHeight: '75vh',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              justifyItems: 'center',
              justifyContent: 'space-between',
            },
          },
          onSelect: setSelectedAssetType,
        })}
      </DialogContent>
    </Dialog>
  )
}

SelectAssetTypeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onProceed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SelectAssetTypeDialog
