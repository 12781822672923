import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
// import LexicalRenderer from 'common/text/LexicalRenderer'
import HeadlineStyleOffset from 'common/text/HeadlineStyleOffset'
import SplitHeadline from 'common/text/SplitHeadline'
import { CONTENT_STATE_PUBLISHED, TEACHER_EDITION_PREFIX } from 'core/consts'
import { omit, set } from 'fp/objects'
import withProps from 'hoc/withProps'
import Block from 'hss/ContentViewer/Chapter/Block'
import StyledContent from 'hss/ContentViewer/StyledContent'
import { Fragment } from 'react'
import { useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getParentFromContentId } from 'selectors/contentViewer'
import { isDevEnv, isPreviewEnv } from 'selectors/index'
import { CONTENT_TYPE_ECHO } from 'shared/consts'
import AccentColorProvider from 'styling/theming/AccentColorProvider'
import { useAuthorContext } from '../AuthorProvider'

const currentContentType: string = 'se'

const PreviewPanel = () => {
  const all = useWatch()

  const { toggleDebugger } = useAuthorContext()

  const isTe = currentContentType === 'te' // see builder preview

  const data = {
    body: all.body,
    fullWidth: all.fullWidth,
    variant: isTe ? '' : all.variant,
  }

  const isDev = useSelector(isDevEnv)
  const isStaging = useSelector(isPreviewEnv)

  const content = compose(
    set('children', []),
    set('contentRestriction', null),
    set('contentState', CONTENT_STATE_PUBLISHED),
    set('data', data),
    set('uploadsMap', {}),
    set('vocabContent', []),
    set('vocabContentIds', []),
    omit('body', `${TEACHER_EDITION_PREFIX}body`, 'fullWidth'),
  )(all)

  const { contentType: parentContentType }: { contentType: string } =
    useSelector(
      getParentFromContentId({
        contentId: content.id,
        contentType: content.contentType,
      }),
    ) || {}

  type WrapperComponent = typeof AccentColorProvider | typeof Fragment

  const Wrapper: WrapperComponent =
    parentContentType === CONTENT_TYPE_ECHO
      ? withProps(AccentColorProvider, { accentColor: 'canary' })
      : Fragment

  return (
    <>
      <HeadlineStyleOffset offset={3}>
        <SplitHeadline
          left="Preview"
          mt={0}
          mb={0}
          right={
            isDev || isStaging ? (
              <Button
                onClick={toggleDebugger}
                style={{
                  float: 'left',
                  padding: 0,
                  textDecoration: 'underline',
                  marginRight: 15,
                  opacity: 0.5,
                }}
                variant="tertiary">
                toggle debugger
              </Button>
            ) : (
              <div />
            )
          }
        />
      </HeadlineStyleOffset>

      {/* <LexicalRenderer
        displayDebugger={debugging}
        editorState={editorState}
      /> */}

      <Typography
        display="block"
        mb={1}
        pr={2}
        borderBottom={'1px solid'}
        borderColor="border.0"
        textAlign="right"
        variant="footnote">
        * - content wrapping is not depicted within preview
      </Typography>

      <Wrapper>
        <StyledContent>
          <table
            className={`block-layout preview-layout ${parentContentType}-blocks`}
            role="presentation">
            <tbody>
              <Block
                blockBundle={{ block: content, seRowSpan: 1, teRowSpan: 0 }}
                disableFullWidth={isTe}
                isLast
                previewing
              />
            </tbody>
          </table>
        </StyledContent>
      </Wrapper>
    </>
  )
}

export default PreviewPanel
