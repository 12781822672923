import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Headline from 'common/text/Headline'
import {
  CONTENT_TYPE_RUBRIC,
  INTERACTIVE_TYPE_ACTIVITY,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import { map } from 'fp/arrays'
import { get } from 'fp/objects'
import { isDefined, matches } from 'fp/utils'
import Rubric from 'hss/sections/contentBlocks/interactives/Rubric'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStudent } from 'selectors/users'
import { featuredContentMaxWidth } from 'styling/theming/base'
import { interactiveContext } from './InteractiveProvider'

const RubricFeedback = () => {
  const {
    completed,
    interactive: { contentSubType, rubric },
    scoreData,
  } = useContext(interactiveContext)
  const { comment, requiresGrading, rubricSelections } = scoreData || {}
  const userIsStudent = useSelector(isStudent)

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )

  if (presenterModeEnabled) return null

  return (
    <>
      {Boolean(
        completed && !requiresGrading && (rubricSelections?.length || comment),
      ) && (
        <Box mt={6}>
          {Boolean(rubricSelections?.length) &&
            rubric.children.map(
              ({ id, data: { title, options } }, rowIndex) => {
                const { description, score } = options.find(
                  matches('id', rubricSelections[rowIndex]),
                )
                const maxScore = compose(
                  scores => Math.max(...scores),
                  map(get('score')),
                )(options)

                return (
                  <p key={id}>
                    <Typography variant="body1-semibold">
                      {title}: {score}/{maxScore}
                    </Typography>
                    <br />
                    {description}
                  </p>
                )
              },
            )}

          {Boolean(comment) && (
            <p>
              <Typography variant="body1-semibold">
                Teacher Comments:{' '}
              </Typography>
              {comment}
            </p>
          )}
        </Box>
      )}
      {Boolean(
        rubric &&
          userIsStudent &&
          (requiresGrading || !isDefined(requiresGrading)) &&
          ![CONTENT_TYPE_RUBRIC, INTERACTIVE_TYPE_ACTIVITY].includes(
            contentSubType,
          ),
      ) && (
        <Box
          component="fieldset"
          maxWidth={featuredContentMaxWidth}
          mt={4}
          mx="auto">
          <Box
            component="legend"
            style={{ maxWidth: 'none', textAlign: 'center', width: '100%' }}>
            <Headline
              hr
              title="Rubric"
              variant="feature-paragraph-semibold"
            />
          </Box>
          <Rubric
            readOnly
            rubric={rubric}
          />
        </Box>
      )}
    </>
  )
}

export default RubricFeedback
