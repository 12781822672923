import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import Link from 'common/navigation/links/Link'
import Headline from 'common/text/Headline'
import { ABILITY_ASSIGNMENT_CREATION, CONTENT_TYPE_CHAPTER } from 'core/consts'
import { contentShape } from 'core/shapes'
import { flatten, map } from 'fp/arrays'
import { get } from 'fp/objects'
import { pluralize } from 'fp/strings'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { BUSY, ERROR, IDLE, SUCCESS } from 'hooks/useReduxCallback'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Send } from 'react-feather'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { getAssignmentById } from 'selectors/assignments'
import { getIncompleteInteractivesContentNav } from 'selectors/contentViewer'
import { getUserAssignmentById } from 'selectors/userAssignments'

const leavesOnly = compose(
  flatten,
  map(item => (item.children?.length ? leavesOnly(item.children) : item)),
)

const IncompleteSection = ({ children, name: sectionName, onNav }) =>
  children?.length ? (
    <Headline
      hr
      title={sectionName}
      variant="feature-paragraph-semibold">
      <List>
        {leavesOnly(children).map(({ contentType, id, name }) => (
          <ListItem key={id}>
            <Link
              onClick={onNav}
              to={`../${contentType}/${id}`}>
              {name}
            </Link>
          </ListItem>
        ))}
      </List>
    </Headline>
  ) : null

IncompleteSection.propTypes = {
  children: PropTypes.arrayOf(contentShape).isRequired,
  name: PropTypes.string.isRequired,
  onNav: PropTypes.func.isRequired,
}

const FinalSubmitPrompt = ({ onSubmit, onClose, status }) => {
  const { userAssignmentId } = useParams()
  const isAssignmentCreator = useAbilityCheck(ABILITY_ASSIGNMENT_CREATION)
  const { assignmentId } =
    useSelector(getUserAssignmentById({ userAssignmentId })) || {}
  const assignment = useSelector(getAssignmentById({ assignmentId })) || {}
  const incompleteChapterNav = useSelector(
    getIncompleteInteractivesContentNav({
      contentId: get('contentId')(assignment),
      contentType: CONTENT_TYPE_CHAPTER,
    }),
  )
  const numberOfIncompleteLeaves = leavesOnly(incompleteChapterNav).length

  const cancelButtonRef = useRef()
  const handleSubmit = () => {
    cancelButtonRef.current.focus() // because we shouldn't leave focus on a disabled button
    onSubmit()
  }

  const submitText = isAssignmentCreator
    ? 'After selecting “Final Submit”, the student will no longer be able to change their responses.'
    : 'After selecting “Final Submit”, you will no longer be able to change your responses.'

  return (
    <>
      <DialogContent>
        <p style={{ textAlign: 'center', marginBottom: 0 }}>
          {Boolean(numberOfIncompleteLeaves) &&
            `The following ${pluralize('page has', 'pages have')(numberOfIncompleteLeaves)} not been completed. `}
          {submitText}
        </p>
        {Boolean(numberOfIncompleteLeaves) && (
          <Stack gap={4}>
            {incompleteChapterNav.map(item => (
              <Box key={item.id}>
                <IncompleteSection
                  {...item}
                  onNav={onClose}
                />
              </Box>
            ))}
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          ref={cancelButtonRef}
          variant="secondary">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={status === BUSY}
          onClick={handleSubmit}
          startIcon={<Send />}
          variant="primary">
          Final Submit
        </Button>
      </DialogActions>
    </>
  )
}

FinalSubmitPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  status: PropTypes.oneOf([BUSY, ERROR, IDLE, SUCCESS]).isRequired,
}

export default FinalSubmitPrompt
