import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'
import Stack from '@mui/system/Stack'
import Link from 'common/navigation/links/Link'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { contentShape } from 'core/shapes'
import withAbilityCheck from 'hoc/withAbilityCheck'
import withContent from 'hoc/withContent'
import CardIcon from 'hss/views/Card/CardIcon'
import PosterImage from 'hss/views/Card/PosterImage'
import { forwardRef } from 'react'
import { contentViewerUrl } from 'routing/consts'

const contentType = CONTENT_TYPE_CHAPTER

const ItemRenderer = forwardRef(({ content }, ref) => {
  const {
    data: { subtitle },
    id: contentId,
    name,
  } = content
  const ChapterCard = withAbilityCheck(Card, undefined, content)
  const linkUrl = `${contentViewerUrl}/${contentType}/${contentId}`

  return (
    <Link
      style={{ textDecoration: 'none', display: 'inline-block' }}
      to={linkUrl}>
      <ChapterCard variant="simple">
        <Box
          position="relative"
          ref={ref}>
          <PosterImage
            aspectRatio="16/9"
            content={content}
            viewMode="grid"
          />
          <CardIcon
            contentSubType={content.contentSubType}
            contentType={content.contentType}
          />
        </Box>

        <Stack>
          <CardContent>
            <Typography
              gutterBottom
              variant="small-semibold">
              {name}
            </Typography>

            <Typography
              component="p"
              variant="small">
              {subtitle}
            </Typography>
          </CardContent>
        </Stack>
      </ChapterCard>
    </Link>
  )
})

ItemRenderer.propTypes = {
  content: contentShape.isRequired,
}

const WithContent = withContent(ItemRenderer, { contentType })

export default WithContent
