import {
  ABILITY_ADMINISTRATION_INTERFACE,
  ABILITY_ASSESSMENT_INTERFACE,
  ABILITY_ASSIGNMENT_CREATION,
  ABILITY_CONTENT_CREATION,
  ABILITY_CONTENT_EDITING,
  ABILITY_CONTENT_RESTRICTION,
  ABILITY_CURRICULUM_ACCESS,
  ABILITY_DISTRICT_ADMINISTRATION,
  ABILITY_LIBRARY_ACCESS,
  ABILITY_PREVIEW_AS_STUDENT,
  ABILITY_RECEIVE_USER_ALERTS,
  ABILITY_SELF_CONTENT_AUTHORING,
  ABILITY_STUDENT_INTERFACE,
  ABILITY_TEACHER_INTERFACE,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_CLASSROOM,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS,
  FEATURE_FLAG_POST_MVP,
  PRIVILEGES,
  PRIVILEGE_CONTENT_CREATOR,
  PRIVILEGE_CONTENT_EDITOR,
  PRIVILEGE_CURRICULUM_MANAGER,
  PRIVILEGE_DISTRICT_ADMIN,
  PRIVILEGE_SUPER_ADMIN,
  ROLE_STAFF,
  ROLE_STUDENT,
  ROLE_SYSTEM,
  SUB_PRIVILEGE_CURRICULUM,
} from 'core/consts'
import { toKeyedObject } from 'fp/arrays'
import { contentViewerUrl, curriculumUrl } from 'routing/consts'
import { isDevEnv, isPreviewEnv, isTestEnv } from 'selectors/index'
import {
  FEATURE_FLAG_CONTENT_AUTHORING,
  FEATURE_FLAG_INLINE_IMAGE,
} from 'shared/consts'

const userAccessConfig = Object.fromEntries(
  Object.entries({
    /** **************************************************************************
     *                                                                           *
     *                               ABILITY FLAGS                               *
     *                                                                           *
     *************************************************************************** */

    [ABILITY_ADMINISTRATION_INTERFACE]: {
      roles: {
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_ASSESSMENT_INTERFACE]: {
      roles: {
        [ROLE_STAFF]: true,
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_ASSIGNMENT_CREATION]: {
      roles: {
        [ROLE_STAFF]: true,
      },
    },

    [ABILITY_SELF_CONTENT_AUTHORING]: {
      roles: {
        [ROLE_STAFF]: true,
      },
    },

    /**
     * Access to the content builder for creation and editing of 1st party content
     */
    [ABILITY_CONTENT_CREATION]: {
      privileges: {
        [PRIVILEGE_CONTENT_CREATOR]: true,
        [PRIVILEGE_CONTENT_EDITOR]: true,
        [PRIVILEGE_CURRICULUM_MANAGER]: true,
      },
    },

    [ABILITY_CONTENT_EDITING]: {
      privileges: {
        [PRIVILEGE_CONTENT_CREATOR]: true,
        [PRIVILEGE_CONTENT_EDITOR]: true,
        [PRIVILEGE_CURRICULUM_MANAGER]: true,
      },
    },

    [ABILITY_CONTENT_RESTRICTION]: {
      privileges: {
        [PRIVILEGE_DISTRICT_ADMIN]: {
          subPrivilege: SUB_PRIVILEGE_CURRICULUM,
        },
      },
      exclusions: {
        onlyWhenRouteMatches: [`${contentViewerUrl}/*`, `${curriculumUrl}/*`],
      },
    },

    [ABILITY_CURRICULUM_ACCESS]: {
      roles: {
        [ROLE_STAFF]: true,
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_DISTRICT_ADMINISTRATION]: {
      privileges: {
        [PRIVILEGE_DISTRICT_ADMIN]: true,
      },
      roles: {
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_LIBRARY_ACCESS]: {
      roles: {
        [ROLE_STAFF]: true,
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_PREVIEW_AS_STUDENT]: {
      roles: {
        [ROLE_STAFF]: true,
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_RECEIVE_USER_ALERTS]: {
      roles: {
        [ROLE_STAFF]: true,
        [ROLE_SYSTEM]: true,
      },
    },

    [ABILITY_STUDENT_INTERFACE]: {
      roles: {
        [ROLE_STUDENT]: true,
      },
    },

    [ABILITY_TEACHER_INTERFACE]: {
      roles: {
        [ROLE_STAFF]: true,
        [ROLE_SYSTEM]: true,
      },
    },

    /** **************************************************************************
     *                                                                           *
     *                               FEATURE FLAGS                               *
     *                                                                           *
     *************************************************************************** */

    [FEATURE_FLAG_GOOGLE_INTEGRATIONS]: {
      roles: {
        [ROLE_SYSTEM]: false,
      },
      default: true,
    },

    [FEATURE_FLAG_CONTENT_AUTHORING]: {
      default: isDevEnv() || isTestEnv() || isPreviewEnv(),
    },

    [FEATURE_FLAG_INLINE_IMAGE]: {
      default: false,
    },

    [FEATURE_FLAG_GOOGLE_INTEGRATIONS_CLASSROOM]: {
      roles: {
        [ROLE_SYSTEM]: false,
      },
    },

    [FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS]: {
      roles: {
        [ROLE_SYSTEM]: false,
      },
    },

    [FEATURE_FLAG_POST_MVP]: {
      /**
       * This has started to become a catch-all flag for "turn this on later" features.
       * Consider creating a new feature-specific flag before using this one.
       */
      default: false,
    },
    // MARK: START test data
    ...(isTestEnv()
      ? {
          emptyOnlyWhenRouteMatches: {
            exclusions: {
              onlyWhenRouteMatches: [],
            },
            default: true,
          },
          emptyNotWhenRouteMatches: {
            exclusions: {
              emptyNotWhenRouteMatches: [],
            },
            default: true,
          },
          missingOnlyWhenRouteMatches: {
            exclusions: {
              onlyWhenRouteMatches: undefined,
            },
            default: true,
          },
          missingNotWhenRouteMatches: {
            exclusions: {
              emptyNotWhenRouteMatches: undefined,
            },
            default: true,
          },
          notWhenRouteMatches: {
            exclusions: {
              notWhenRouteMatches: [`${contentViewerUrl}/*`],
            },
            default: true,
          },
        }
      : null),
    // MARK: END test data
  }).map(([key, entry]) => {
    const privileges = toKeyedObject(PRIVILEGES)

    /**
     * If a feature contains a system role, then it also is available to super admins
     */
    if (
      Object.keys(entry.privileges || {}).some(
        priv => privileges[priv]?.roleId === ROLE_SYSTEM,
      )
    ) {
      Object.assign(entry.privileges, { [PRIVILEGE_SUPER_ADMIN]: true })
    }

    return [key, entry]
  }),
)

export default userAccessConfig
