import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import Html from 'common/text/Html'
import { componentShape } from 'core/shapes'
import { isEmptyString } from 'fp/strings'
import PropTypes from 'prop-types'
import {
  containedPaddingLeft,
  containedPaddingRight,
} from 'styling/theming/base'
import { rem } from 'styling/theming/base/mixins'

const StyledContainer = styled('div', { name: 'Figure-StyledContainer' })(
  ({
    theme: {
      typography: { variants },
      mixins: { important },
      palette,
    },
  }) => ({
    marginTop: important(0),
    figure: {
      backgroundColor: palette.common.white,
      color: palette.grey[0],
      textAlign: 'left',

      p: {
        marginTop: 0,
        ...variants.caption,
      },

      '&.caption-left, &.caption-right': {
        display: 'flex',
        width: '100%',
        '.image-wrapper': {
          maxWidth: '66%',
          width: 'auto',
        },
        figcaption: {
          minWidth: '33%',
          backgroundColor: palette.common.white,
        },
      },

      '&.caption-bottom': {
        width: '100%',
        '.image-wrapper': {
          width: 'auto',
        },
        figcaption: {
          backgroundColor: palette.common.white,
          display: 'inline-block',
        },
      },

      '&.caption-left': {
        flexDirection: 'row-reverse',
      },

      '&.caption-right': {
        flexDirection: 'row',
      },
    },

    '.caption': {
      margin: rem(2),
    },
  }),
)

const Figure = props => {
  const {
    caption,
    captionHeader,
    captionId,
    captionPosition = 'bottom',
    children,
    hideCaption = false,
    useContainedPadding = false,
    ...rest
  } = props

  const backgroundStyles = { padding: rem(0.5) }
  const captionPositionClass = `caption-${captionPosition || 'bottom'}`
  const captionStyle = useContainedPadding
    ? {
        marginLeft: containedPaddingLeft,
        marginRight: containedPaddingRight,
        ...backgroundStyles,
      }
    : backgroundStyles

  return (
    <StyledContainer>
      <Box
        className={cl('caption-wrapper', {
          [captionPositionClass]: !hideCaption,
        })}
        component="figure"
        {...rest}>
        {captionPosition !== 'top' && children}

        {!isEmptyString(caption) && (
          <figcaption
            className={cl({ hidden: hideCaption })}
            id={captionId}
            style={captionStyle}>
            <div className="caption">
              {Boolean(captionHeader) && (
                <Typography variant="body1-semibold">
                  {captionHeader}
                </Typography>
              )}
              <Typography variant="caption">
                <Html body={caption} />
              </Typography>
            </div>
          </figcaption>
        )}

        {captionPosition === 'top' && children}
      </Box>
    </StyledContainer>
  )
}

Figure.propTypes = {
  caption: PropTypes.string,
  captionHeader: PropTypes.string,
  captionId: PropTypes.string,
  captionPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left', '']),
  children: componentShape.isRequired,
  hideCaption: PropTypes.bool,
  useContainedPadding: PropTypes.bool,
}

export default Figure
