/**
 * NOTE: This is meant only for percentage based locations.
 */

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { coordShape } from 'core/shapes'
import { border } from 'polished'
import PropTypes from 'prop-types'

const BaseLine = styled(Box, { name: 'interactives-BaseLine' })(
  ({ theme: { palette } }) => ({
    ...border('bottom', 1, 'solid', palette.common.black),
    height: 0,
    position: 'absolute',
    '&.hovering': {
      borderColor: palette.common.purple,
      boxShadow: `0 0 30px 2px ${palette.common.purple}`,
    },
  }),
)

const LineP = props => {
  const { hovering = false, parentHeight, parentWidth } = props
  const reversed = props.to.x < props.from.x

  const fromPrime = {
    x: parentWidth * (props.from.x / 100),
    y: parentHeight * (props.from.y / 100),
  }
  const toPrime = {
    x: parentWidth * (props.to.x / 100),
    y: parentHeight * (props.to.y / 100),
  }

  const from = reversed ? toPrime : fromPrime
  const to = reversed ? fromPrime : toPrime

  const len = Math.sqrt((from.x - to.x) ** 2 + (from.y - to.y) ** 2)
  const angle = Math.atan((from.y - to.y) / (from.x - to.x))

  const style = {
    left: from.x,
    top: from.y,
    width: len,
    transform: `rotate(${angle}rad)`,
    transformOrigin: 'top left',
  }

  return (
    <BaseLine
      className={cl(props.className, { hovering })}
      style={style}
    />
  )
}

LineP.propTypes = {
  from: coordShape.isRequired,
  hovering: PropTypes.bool,
  parentHeight: PropTypes.number.isRequired,
  parentWidth: PropTypes.number.isRequired,
  to: coordShape.isRequired,
}

export default LineP
