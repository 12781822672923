import Box from '@mui/material/Box'
import cl from 'classnames'
import { Annotations, Player, Transcript } from 'common/avclub/video'
import { videoPlayerContext } from 'common/avclub/video/context'
import AnnotationTicks from 'common/avclub/video/lib/controls/AnnotationTicks'
import PropTypes from 'prop-types'
import { useContext } from 'react'

const Content = ({ allowAnnotations }) => {
  const { annotationsOn } = useContext(videoPlayerContext)

  return (
    <>
      <Box backgroundColor="common.black">
        <Box
          className={cl({
            'video-js-wrapper': true,
            'vjs-annotations-on': annotationsOn,
          })}
          display="flex">
          {Boolean(allowAnnotations) && <AnnotationTicks />}
          <Player />
          {Boolean(allowAnnotations) && <Annotations />}
        </Box>
      </Box>
      <Transcript />
    </>
  )
}

Content.propTypes = {
  allowAnnotations: PropTypes.bool.isRequired,
}

export default Content
