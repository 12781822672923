import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import ScreenReaderText from 'common/text/ScreenReaderText'
import useIsMounted from 'hooks/useIsMounted'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import TextEditor from './TextEditor'
import { imageAnnotationShape } from './utils'

const StyledContainer = styled(Box, {
  name: 'AnnotatableImage-AnnotationEditor',
})(
  ({
    theme: {
      mixins: { transition },
      zIndex,
    },
  }) => ({
    background: 'white',
    borderRadius: 2,
    marginTop: 16,
    transformOrigin: 'top left',
    position: 'absolute',
    opacity: 0,
    overflow: 'hidden',
    zIndex: zIndex.tooltip + 1,
    ...transition('opacity', 210),
  }),
)

const AnnotationEditor = props => {
  const { annotation, cancelEdit, onChange, onSubmit } = props

  const mounted = useIsMounted()

  const { geometry, zoom } = annotation

  const left = zoom ? zoom.geometry.x : geometry.x
  const top = zoom
    ? zoom.geometry.y + zoom.geometry.height
    : geometry.y + geometry.height

  const handleChange = useCallback(
    ({ target }) => {
      onChange({
        ...annotation,
        data: {
          ...annotation.data,
          text: target.value,
        },
      })
    },
    [annotation, onChange],
  )

  return (
    <StyledContainer
      sx={{
        left: `${left}%`,
        top: `${top}%`,
        opacity: Number(mounted),
      }}>
      <ScreenReaderText>Start of pop-up content</ScreenReaderText>

      <TextEditor
        onCancel={cancelEdit}
        onChange={handleChange}
        onSubmit={onSubmit}
        value={annotation.data?.text}
      />

      <ScreenReaderText>End of pop-up content</ScreenReaderText>
    </StyledContainer>
  )
}

AnnotationEditor.propTypes = {
  annotation: imageAnnotationShape.isRequired,
  cancelEdit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default AnnotationEditor
