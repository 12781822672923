import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { ABILITY_ADMINISTRATION_INTERFACE } from 'core/consts'
import { reduce } from 'fp/arrays'
import { isMobile } from 'fp/internet'
import { assertRange, fallsWithin } from 'fp/numbers'
import { get } from 'fp/objects'
import { when, whenPresent } from 'fp/utils'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { useDeepCompareEffect } from 'hooks/useDeepCompare'
/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useElementScrollPosition from 'react-element-scroll-hook'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { compose } from 'redux'
import Action from './Action'
import Scrubber from './Scrubber'
import { actionsShape, pausesShape } from './utils'
// Imports for implementation of animator scoll indicators
// import {
//   transparentize,
// } from 'polished'
// import ScrollKeyboard from './ScrollKeyboard'
// import ScrollMouse from './ScrollMouse'

const availableScrollHeight = 1000000

const Container = styled(Box, { name: 'Animator-Player' })(
  ({
    theme: {
      mixins: { importantPx },
    },
  }) => ({
    position: 'relative',
    // For implementation of animator scoll indicators - darkens background
    // '&::before': {
    //   backgroundColor: transparentize(0.2, palette.primary[900]),
    //   content: '"“"',
    //   position: 'absolute',
    //   width: '100%',
    //   height: '100%',
    //   zIndex: 1,
    // },

    '.outerContainer': {
      position: 'relative',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: 1,
      },
    },

    '.innerContainer': {
      minHeight: importantPx(availableScrollHeight),
      position: 'relative',

      top: 0,
      bottom: 0,
      maxHeight: '100vh',
      maxWidth: '100vw',
      width: '100%',
    },

    '.videoContainer': {
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      width: '100%',

      video: {
        maxWidth: '100%',
        width: '100%',
      },
    },

    '.debug': { span: { paddingRight: '1rem' } },
  }),
)

const Player = props => {
  const {
    ContainerProps,
    VideoProps,
    actions,
    className,
    debug = false,
    fps,
    onFrameChange,
    pauses: rawPauses,
    src,
  } = props

  const videoRef = useRef()
  const [duration, setDuration] = useState(0)
  const [currentFrame, setCurrentFrame] = useState(0)
  const [videoFrame, setVideoFrame] = useState(0)
  const [scrollEventCounter, setScrollEventCounter] = useState(0)
  const [scrollInfo, setScrollElementRef, scrollElementRef] =
    useElementScrollPosition()
  const userIsAdmin = useAbilityCheck(ABILITY_ADMINISTRATION_INTERFACE)
  const isTouch = isMobile()

  useEffect(() => {
    if (scrollElementRef?.current) {
      scrollElementRef.current.scrollTop = availableScrollHeight / 2
    }
  }, [scrollElementRef])

  const handleLoaded = useCallback(() => {
    setDuration(videoRef?.current?.duration || 0)
  }, [])
  const ready = useMemo(() => duration > 0, [duration])

  const pauses = useMemo(() => {
    let cumulativePauseFrames = 0

    return rawPauses.map(({ id: pauseId, startFrame, delaySec }) => {
      const pauseFrames = Math.round(delaySec * fps)
      const result = {
        id: pauseId,
        delaySec,
        startFrame: startFrame + cumulativePauseFrames,
        endFrame: startFrame + cumulativePauseFrames + pauseFrames,
      }
      cumulativePauseFrames += pauseFrames
      return result
    })
  }, [fps, rawPauses])

  const totalFrameCount = useMemo(
    () =>
      compose(
        Math.ceil,
        totalLengthWithPauses => totalLengthWithPauses * fps,
        reduce((acc, { delaySec }) => acc + delaySec, duration),
      )(pauses),
    [duration, fps, pauses],
  )

  const settingCurrentFrame = useCallback(
    frame => {
      const newFrame = assertRange(frame, 1, totalFrameCount)
      setCurrentFrame(newFrame)
      whenPresent(onFrameChange, newFrame, totalFrameCount)
    },
    [onFrameChange, totalFrameCount],
  )

  const handleClick = direction => e => {
    e.stopPropagation()
    const currentPauseIndex = pauses.findIndex(({ startFrame, endFrame }) =>
      fallsWithin(currentFrame, startFrame, endFrame),
    )

    let nextPause = currentPauseIndex + direction

    const atStart = currentFrame === 0 && direction === 1
    const atEnd = currentFrame === totalFrameCount && direction === -1
    const goToStart = direction === -1 && nextPause < 0 && !atEnd
    const goToEnd = direction === 1 && nextPause >= pauses.length

    nextPause = atStart ? 0 : atEnd ? pauses.length - 1 : nextPause

    const nextFrame = goToStart
      ? 0
      : goToEnd
        ? totalFrameCount
        : get(`${nextPause}.endFrame`)(pauses)

    setCurrentFrame(nextFrame)
  }

  useDeepCompareEffect(() => {
    when(
      scrollInfo.y.direction !== 0 && scrollEventCounter > 1,
      settingCurrentFrame,
      currentFrame + scrollInfo.y.direction,
    )
    setScrollEventCounter(scrollEventCounter + 1)
  }, [scrollInfo])

  useEffect(() => {
    if (ready) {
      const inPause = pauses.find(({ startFrame, endFrame }) =>
        fallsWithin(currentFrame, startFrame, endFrame),
      )
      const passedPauseFrames = pauses
        .filter(({ endFrame }) => endFrame < currentFrame)
        .reduce(
          (acc, { startFrame, endFrame }) => acc + (endFrame - startFrame),
          0,
        )

      videoRef.current.currentTime =
        ((inPause ? inPause.startFrame : currentFrame) - passedPauseFrames) /
        fps

      setVideoFrame(Math.round(videoRef?.current?.currentTime || 0 * fps))
    }
  }, [currentFrame, fps, pauses, ready])

  return (
    <Container className={className}>
      {/* For implementation of animator scoll indicators
      <ScrollKeyboard />
      <ScrollMouse /> */}
      <div
        className="outerContainer"
        ref={setScrollElementRef}
        style={{ height: `${videoRef.current?.offsetHeight || 0}px` }}>
        <div
          className="innerContainer"
          {...ContainerProps}>
          .
        </div>
      </div>

      <div className="videoContainer">
        {}
        <video
          onLoadedMetadata={handleLoaded}
          playsInline
          preload="auto"
          ref={videoRef}
          src={src}
          tabIndex="0"
          {...VideoProps}
        />

        <Scrubber
          currentFrame={currentFrame}
          frameCount={totalFrameCount}
          pauses={pauses}
          setCurrentFrame={settingCurrentFrame}
        />

        {actions.map(action => (
          <Action
            action={action}
            currentFrame={currentFrame}
            frameCount={totalFrameCount}
            key={action.id}
          />
        ))}

        {Boolean(debug && userIsAdmin) && (
          <div className="debug">
            <b>Duration:</b> <span>{duration.toFixed(2)}s</span>
            <b>Frames:</b>{' '}
            <span>
              {Math.round(duration * fps)}/{totalFrameCount}
            </span>
            <b>Current frame:</b>{' '}
            <span>
              {videoFrame}/{currentFrame}
            </span>
            <b>Pauses:</b> <span>{pauses.length}</span>
            <b>Actions:</b> <span>{actions.length}</span>
          </div>
        )}
      </div>
      {Boolean(isTouch) && (
        <>
          <IconButton
            aria-label="Previous"
            disabled={currentFrame === 0}
            onClick={handleClick(-1)}
            variant="tertiary">
            <ChevronLeft />
          </IconButton>
          <IconButton
            aria-label="Next"
            disabled={currentFrame === totalFrameCount}
            onClick={handleClick(1)}
            variant="tertiary">
            <ChevronRight />
          </IconButton>
        </>
      )}
    </Container>
  )
}

Player.propTypes = {
  actions: actionsShape.isRequired,
  ContainerProps: PropTypes.object,
  debug: PropTypes.bool,
  fps: PropTypes.number.isRequired,
  onFrameChange: PropTypes.func,
  pauses: pausesShape.isRequired,
  src: PropTypes.string.isRequired,
  VideoProps: PropTypes.object,
}

export default Player
