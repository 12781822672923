import cl from 'classnames'
import Headline from 'common/text/Headline'
import { get, set } from 'fp/objects'
import PropTypes from 'prop-types'
import Segment from './Segment'

const DefaultColor = ({ onChange, preferences }) => {
  const defaultColorId = get('gradeColors.defaultColor')(preferences)

  const onColorChange = ({ colorId }) => {
    onChange(set('gradeColors.defaultColor', colorId))
  }

  return (
    <div className={cl('defaultSegment', 'segments')}>
      <Headline
        mb={5}
        textAlign="left"
        title="Default Color"
        weight={300}
      />

      <Segment
        begin={0}
        className={`colorId-${defaultColorId}`}
        colorId={defaultColorId}
        disabled
        end={100}
        idx={-1}
        onColorChange={onColorChange}
      />
    </div>
  )
}

DefaultColor.propTypes = {
  onChange: PropTypes.func.isRequired,
  preferences: PropTypes.object.isRequired,
}

export default DefaultColor
