import Box from '@mui/material/Box'
import BusySpinner from 'common/indicators/BusySpinner'
import { INTERACTIVE_TYPE_ANIMATOR } from 'core/consts'
import { maybeParseJSON } from 'fp/strings'
import { when } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
/* istanbul ignore file */
import { Suspense, lazy, useCallback, useContext, useEffect } from 'react'
import MaximizeIconButton from 'styling/theming/base/components/MaximizeIconButton'
import MinimizeIconButton from 'styling/theming/base/components/MinimizeIconButton'
import ExpanderBackdrop from '../../Interactive/ExpanderBackdrop'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const Player = lazy(() => import(/* webpackChunkName: "Animator" */ './Player'))

const Animator = () => {
  const {
    completed,
    interactiveData: { actions = [], completionTarget, pauses = [], ...rest },
    markComplete,
    setBoosted,
  } = useContext(interactiveContext)

  const [expanded, toggleExpanded] = useToggleState(false)
  const parsedActions = maybeParseJSON(actions)
  const parsedPauses = maybeParseJSON(pauses)

  useEffect(() => {
    setBoosted(expanded)
  }, [expanded, setBoosted])

  const handleClick = () => when(!expanded, toggleExpanded)

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === 'Escape' && expanded) toggleExpanded()
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [expanded, toggleExpanded])

  const onFrameChange = useCallback(
    (currentFrame, totalFrames) => {
      if (!completed) {
        const completionPercentage = (currentFrame / totalFrames) * 100
        when(completionPercentage >= completionTarget, markComplete)
      }
    },
    [completed, completionTarget, markComplete],
  )

  const playerProps = {
    actions: parsedActions,
    onFrameChange,
    pauses: parsedPauses,
    ...rest,
  }

  return (
    <Suspense fallback={<BusySpinner />}>
      <Box
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'background.paper',
            overflowY: 'scroll',
          },
        }}>
        <MaximizeIconButton />

        <ExpanderBackdrop expanded={expanded}>
          <Box
            sx={{
              cursor: 'default',
              top: 0,
              left: 0,
              maxWidth: '100%',
              position: 'absolute',
              width: '100vw',
              zIndex: theme => theme.zIndex.drawer + 1,
            }}>
            <MinimizeIconButton
              onClick={toggleExpanded}
              sx={{
                position: 'absolute',
                top: 20,
                right: 20,
                backgroundColor: 'background.paper',
                opacity: 1,
                zIndex: theme => theme.zIndex.drawer + 1,
                '&:hover': { backgroundColor: 'background.paper' },
              }}
            />
            <Player {...playerProps} />
          </Box>
        </ExpanderBackdrop>
        {!expanded && <Player {...playerProps} />}
      </Box>
    </Suspense>
  )
}

export const detachedInteractionOptions = {
  contentSubType: INTERACTIVE_TYPE_ANIMATOR,
}

export default Animator
