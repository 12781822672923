import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ExternalLink from 'common/navigation/links/ExternalLink'
import { useContext } from 'react'
import { navDialogsContext } from 'routing/shells/NavShell/NavDialogsProvider'

const StyledStack = styled(Stack, { name: 'Block-Backdrop' })(
  ({
    theme: {
      mixins: { important },
      typography,
    },
  }) => ({
    flexWrap: 'wrap',
    ...typography.variants.small,

    span: {
      marginTop: important(0),
    },
  }),
)

const footerLinks = [
  {
    text: 'End User Terms of Service',
    url: 'https://imaginelearning.com/privacy',
  },
  { text: 'Privacy Policy', url: 'https://imaginelearning.com/privacy' },
]

const FooterLinks = () => {
  const { toggleContactFormDialogIsOpen: showContactForm } =
    useContext(navDialogsContext) || {}
  return (
    <StyledStack
      alignItems="baseline"
      direction="row"
      divider={<span>|</span>}
      justifyContent="center"
      mt={1}
      spacing={1}>
      {footerLinks.map(({ text, url }) => (
        <ExternalLink
          href={url}
          key={text}
          variant="link">
          {text}
        </ExternalLink>
      ))}
      <Button
        onClick={() => {
          showContactForm()
        }}
        variant="mock-link">
        <Typography variant="small">Contact</Typography>
      </Button>
    </StyledStack>
  )
}

export default FooterLinks
