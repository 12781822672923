import DialogContent from '@mui/material/DialogContent'
import Dialog from 'common/dialogs/Dialog'
import Form from 'common/formControls/Form'
import { HFDebouncedTextField } from 'common/formControls/textInputs/HFTextField'
import { camelCaseToWords, titleCase } from 'fp/strings'
import PropTypes from 'prop-types'
import FormActions from './FormActions'

const PlotDiagramDialog = props => {
  const { handleRemove, handleSave, note, ...rest } = props
  const defaultValues = note.actionPoint
    ? { [note.fieldName]: note.value.map(({ text }) => text) }
    : { [note.key]: note.value }

  return (
    <Dialog
      role="alertdialog"
      showCloseButton
      title={note.title || titleCase(camelCaseToWords(note.key))}
      {...rest}>
      <Form
        defaultValues={defaultValues}
        sx={{ alignSelf: 'center', width: '100%' }}>
        <DialogContent>
          <HFDebouncedTextField
            label="Response"
            name={note.key}
            variant="outlined"
          />
        </DialogContent>
        <FormActions
          isActionPoint={note.actionPoint}
          name={note.key}
          onRemove={handleRemove}
          onSave={handleSave}
        />
      </Form>
    </Dialog>
  )
}

PlotDiagramDialog.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  note: PropTypes.shape({
    actionPoint: PropTypes.bool,
    fieldName: PropTypes.string,
    key: PropTypes.string.isRequired,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
}

export default PlotDiagramDialog
