import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Icon from '@mui/material/Icon'
import Centered from 'common/layout/Centered'
import useNavigation from 'hooks/useNavigation'
import CheckCircle from 'hss/images/indicators/check-closed-circle.svg'
import { studentAssignmentsUrl } from 'routing/consts'

const Success = () => {
  const { navigate } = useNavigation()
  return (
    <>
      <DialogContent>
        <Centered>
          <Icon sx={{ color: 'success.main' }}>
            <CheckCircle />
          </Icon>

          <p style={{ margin: 0, paddingLeft: 8 }}>
            Your assignment has been submitted!
          </p>
        </Centered>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            navigate(studentAssignmentsUrl)
          }}
          variant="secondary">
          Back To Assignments
        </Button>
      </DialogActions>
    </>
  )
}

export default Success
