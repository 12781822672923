import { asPercentageString } from 'fp/numbers'
import withProps from 'hoc/withProps'
import GradebookAssignmentsDataTable from './GradebookAssignmentsDataTable'

const PercentagesView = withProps(GradebookAssignmentsDataTable, {
  scoreAccessorFn: ({ maxScore, score }) =>
    asPercentageString(score / maxScore),
})

export default PercentagesView
