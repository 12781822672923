import { TOGGLE_STATE_PRESENTER_MODE } from 'core/consts'
import { round } from 'fp/numbers'
import { isDefined, when } from 'fp/utils'
import UserAssignmentSelectionProvider, {
  userAssignmentSelectionContext,
} from 'hss/AssignmentEditor/UserAssignmentSelectionProvider'
import PropTypes from 'prop-types'
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import { isStaff } from 'selectors/users'
import { SCORING_MODE_NONE } from 'shared/consts'
import { interactiveContext } from './InteractiveProvider'

export const interactiveGradingContext = createContext()

const determineMaxScore = ({ maxScoreOverride, maxScore }) =>
  isDefined(maxScoreOverride) ? maxScoreOverride : maxScore

// To avoid duplicating score calculation code in multiple files, both teachers and students need access to this context
const ScoreGradingProvider = props => {
  const { currentUserAssignmentId } = useContext(userAssignmentSelectionContext)
  const {
    interactive: {
      scoring: { maxScore, maxScoreOverride, mode: scoringMode },
    },
  } = useContext(interactiveContext)

  const [scoreData, setScoreData] = useState({})
  const { manualScoreOverride: manualOverride } = scoreData || {}

  const [scoreOverrideEnabled, setScoreOverrideEnabled] = useState(
    isDefined(manualOverride) && manualOverride !== null,
  )
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  // if manual override is defined, then toggle the switch on
  useEffect(() => {
    when(
      isDefined(manualOverride) && manualOverride !== null,
      setScoreOverrideEnabled,
      true,
    )
  }, [manualOverride])

  // biome-ignore lint/correctness/useExhaustiveDependencies: when the user assignment changes, reset
  useEffect(() => {
    setScoreOverrideEnabled(false)
    setSaveButtonDisabled(true)
  }, [currentUserAssignmentId])

  const allowManualScoring = scoringMode && scoringMode !== SCORING_MODE_NONE

  // `maxScore` and `maxScoreOverride` are whole-point int values
  // if an override exists, we should use that instead of the regular maxScore
  const maxScoreLocal = useMemo(
    () => determineMaxScore({ maxScore, maxScoreOverride }),
    [maxScore, maxScoreOverride],
  )

  const overrideValue = useMemo(
    () =>
      isDefined(manualOverride) ? round(manualOverride * maxScoreLocal) : null,
    [manualOverride, maxScoreLocal],
  )

  const value = useMemo(
    () => ({
      allowManualScoring,
      maxScoreToUse: maxScoreLocal,
      overrideValue,
      saveButtonDisabled,
      scoreData,
      scoreOverrideEnabled,
      setSaveButtonDisabled,
      setScoreData,
      setScoreOverrideEnabled,
    }),
    [
      allowManualScoring,
      maxScoreLocal,
      overrideValue,
      saveButtonDisabled,
      scoreData,
      scoreOverrideEnabled,
    ],
  )

  return (
    <interactiveGradingContext.Provider
      value={value}
      {...props}
    />
  )
}

const InteractiveGradingProvider = props => {
  const { children } = props
  const currentUserIsStaff = useSelector(isStaff)
  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const { currentUserAssignmentId } =
    useContext(userAssignmentSelectionContext) || {}

  // Sometimes we're already in a student selection context,
  // e.g. the Chapter Grading component provides one for the whole page.
  const MaybeUserAssignmentSelectionProvider =
    useContext(userAssignmentSelectionContext) && currentUserIsStaff
      ? Fragment
      : UserAssignmentSelectionProvider

  return presenterModeEnabled ? (
    children
  ) : (
    <MaybeUserAssignmentSelectionProvider>
      <ScoreGradingProvider
        {...props}
        key={currentUserAssignmentId}
      />
    </MaybeUserAssignmentSelectionProvider>
  )
}

InteractiveGradingProvider.propTypes = {
  children: PropTypes.node,
}

export default InteractiveGradingProvider
