const baseContent = {
  applicationStandardIds: [],
  applicationStandards: [],
  contentState: 'PUBLISHED',
  contentType: 'source',
  instructionStandardIds: [],
  instructionStandards: [],
  tags: [],
  tagIds: [],
  vocabContentIds: [],
  vocabContent: [],
}
// let's shadow contentShape for this fake data for now
const articles = [
  {
    ...baseContent,
    id: '376',
    dateCreated: '2023-02-24 10:37:38.86-08',
    name: 'Spark Inquiry in Your Classroom',
    data: {
      linkUrl:
        'https://edu.imaginelearning.com/inquiry-orientation-free-pd-course',
      body: 'Learn best practices and strategies for implementing an inquiry model and what makes Traverse’s inquiry attainable. Find out where to start by completing the on-demand Inquiry Orientation Course.',
    },
    uploadsMap: {
      image: {
        url: 'https://staging-content.studysync.com/hssdev/seed-files/carousel3.jpg',
      },
    },
  },
  {
    ...baseContent,
    id: '378',
    dateCreated: '2023-02-24 10:37:38.86-08',
    name: 'Leverage Traverse Media',
    data: {
      guideId: '116',
      body: 'Explore live action videos, interactive media, and annotatable images within Traverse. Students engage with segments of The Beat, QuickTalks, The View, and more to support their classroom conversations and deepen their understanding of topics throughout each unit.',
    },
    uploadsMap: {
      image: {
        url: 'https://staging-content.studysync.com/hssdev/seed-files/hp-carousel-leverage-traverse.jpg',
      },
    },
  },

  {
    ...baseContent,
    id: '379',
    dateCreated: '2023-02-24 10:37:38.86-08',
    name: 'Trusting Inquiry with Dr. Kathy Swan',
    data: {
      body: 'Read how building a culture of classroom trust with students is a key component to accelerate inquiry-based learning in any classroom in this article from Traverse author Kathy Swan.',
      linkUrl: 'https://www.imaginelearning.com/blog/trusting-inquiry/',
    },
    uploadsMap: {
      image: {
        url: 'https://staging-content.studysync.com/hssdev/seed-files/hp-carousel-trusting-inquiry.jpg',
      },
    },
  },
]

export default articles
