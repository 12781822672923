import { CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET } from 'core/consts'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import PageRenderer from './PageRenderer'
import PagesetRenderer from './PagesetRenderer'

const Content = props => {
  const { contentType, ...rest } = props

  // Pageset is just a list of pages, so it renders a page for each child
  const Renderer = useMemo(
    () => (contentType === CONTENT_TYPE_PAGE ? PageRenderer : PagesetRenderer),
    [contentType],
  )

  return (
    <Renderer
      contentType={contentType}
      {...rest}
    />
  )
}
Content.propTypes = {
  contentType: PropTypes.oneOf([CONTENT_TYPE_PAGE, CONTENT_TYPE_PAGESET])
    .isRequired,
  style: PropTypes.object,
}
export default Content
