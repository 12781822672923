import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import StandardItem from 'common/standards/StandardItem'
import Headline from 'common/text/Headline'
import { standardShape } from 'core/shapes'
import PropTypes from 'prop-types'
import { variantShape } from './utils'

const ApplicationStandards = ({
  className,
  applicationStandards = [],
  variant = 'aggregate',
}) =>
  applicationStandards?.length ? (
    <div>
      {variant === 'aggregate' ? (
        <Headline
          className={className}
          title="Application Standards"
          variant="list-header"
        />
      ) : (
        <strong>Application Standards: </strong>
      )}{' '}
      <List variant="csv">
        {applicationStandards.map(standard => (
          <Typography
            component="li"
            key={standard.id}
            variant="small">
            <StandardItem standard={standard} />
          </Typography>
        ))}
      </List>
    </div>
  ) : null

ApplicationStandards.propTypes = {
  applicationStandards: PropTypes.arrayOf(standardShape),
  variant: variantShape,
}

export default ApplicationStandards
