import DataTable from 'common/tables/DataTable'
import {
  DetailRowHasImage,
  LinkToTableCell,
  RowActionsMenu,
  RowDetailsToggle,
  RowSelector,
  SimpleCell,
  asDetail,
  asPrimary,
} from 'common/tables/DataTable/Cells'
import {
  assignmentStatusCell,
  assignmentTargetCell,
  avgAssessmentScoreCell,
  avgInteractiveScoreCell,
  createdByCell,
  createdDateCell,
  deadlineCell,
  endDateCell,
  progressCell,
  startDateCell,
} from 'common/tables/DataTable/Cells/commonTableCells'
import usePaginationLimit, {
  setDefaultLimit,
} from 'common/tables/DataTable/usePaginationLimit'
import {
  ASSIGNMENT_TARGET_STUDENT,
  CONTENT_TYPE_ASSESSMENT,
  FEATURE_FLAG_POST_MVP,
} from 'core/consts'
import { assert, alter } from 'core/store/search/squery'
import { filter } from 'fp/arrays'
import { get } from 'fp/objects'
import { prefix, suffix } from 'fp/strings'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { isPastMaxSubmitDate } from 'hss/utils'
import { SearchProvider } from 'hss/views/Search/SearchProvider'
import { SEARCH_TYPE_CLASSROOM } from 'hss/views/Search/consts'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { compose } from 'redux'
import {
  assessmentEditorUrl,
  assignmentEditorUrl,
  getTeacherAssignmentRoute,
} from 'routing/consts'

const tableId = 'clAssignmentsList'

const buildColumns = has => {
  const postMVP = has(FEATURE_FLAG_POST_MVP)

  const getEditUrl = assignment => {
    const { prefixUrl, suffixUrl } =
      assignment.contentType === CONTENT_TYPE_ASSESSMENT
        ? { prefixUrl: assessmentEditorUrl, suffixUrl: '' }
        : { prefixUrl: assignmentEditorUrl, suffixUrl: '/settings' }
    return compose(
      suffix(suffixUrl),
      prefix(`${prefixUrl}/`),
      get('id'),
    )(assignment)
  }

  const canBeReopened = assignment =>
    assignment.targetTypeId === ASSIGNMENT_TARGET_STUDENT &&
    assignment.isUserAssignmentSubmitted &&
    !isPastMaxSubmitDate(assignment)

  const reopenUserAssignment = row => {
    if (row.userAssignmentIds.length === 1) {
      const userAssignmentId = row.userAssignmentIds[0]
      return {
        type: actionTypes.USER_ASSIGNMENT_REOPEN,
        userAssignmentId,
      }
    }
    return { type: 'noop' }
  }

  const nameColumn = asPrimary(
    SimpleCell({
      accessorFn: get('name'),
      cell: LinkToTableCell(getTeacherAssignmentRoute),
      header: 'Name',
      id: 'target.name',
      meta: { sortFieldName: 'name' },
    }),
  )

  return [
    postMVP
      ? [
          RowSelector({
            singleLabel: 'Select Assignment',
            pluralLabel: 'Select all Assignments',
          }),

          DetailRowHasImage(nameColumn, 'thumbnailUrl', 'name'),
        ]
      : nameColumn,

    assignmentTargetCell,

    deadlineCell,

    assignmentStatusCell,

    postMVP && progressCell,

    asDetail(createdDateCell),

    asDetail(startDateCell),

    asDetail(endDateCell),

    postMVP && asDetail(avgInteractiveScoreCell),

    createdByCell,

    postMVP && asDetail(avgAssessmentScoreCell),

    RowActionsMenu(({ original: assignment }) =>
      filter(Boolean)([
        {
          label: 'View',
          action: { navigate: getTeacherAssignmentRoute },
        },

        {
          label: 'Edit',
          action: { navigate: getEditUrl(assignment) },
        },

        canBeReopened(assignment) && {
          label: 'Reopen',
          action: { dispatch: reopenUserAssignment },
        },
      ]),
    ),

    RowDetailsToggle(),
  ]
}

const searchConfig = (has, limit) => ({
  columns: buildColumns(has),

  initialSquery: compose(
    setDefaultLimit(limit),
    alter.set.modifier('includeInactive').is(false),
    alter.set.orderBy('endDate', 'desc'),
    alter.set.modifier('addUserAssignmentIds').is(true),
  )(assert()),

  pluralLabel: 'Assignments',

  restEndpoint: restEndpoint.assignments,

  searchFields: ['name'],

  singleLabel: 'Assignment',
})

const withSearchProvider = WrappedComponent => {
  const Enhanced = initialTestingState => {
    const has = useAbilityChecker()
    const [limit] = usePaginationLimit(tableId)
    const config = searchConfig(has, limit)

    return (
      <SearchProvider
        initialTestingState={initialTestingState}
        ItemRenderer={DataTable}
        searchConfig={config}
        searchOnLoad
        searchType={SEARCH_TYPE_CLASSROOM}
        tableId={tableId}
        viewMode="table">
        <WrappedComponent />
      </SearchProvider>
    )
  }

  return Enhanced
}

export default withSearchProvider
