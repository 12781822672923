import Instructions from 'common/layout/Instructions'
import ImageContentHtml from 'hss/ContentViewer/Chapter/Block/ImageContentHtml'
import PropTypes from 'prop-types'

const QuestionPrompt = ({ body, ...rest }) =>
  body ? (
    <Instructions>
      <ImageContentHtml
        body={body}
        renderProps={{ disableMaximizeImage: true, hideCaption: true }}
        {...rest}
      />
    </Instructions>
  ) : null

QuestionPrompt.propTypes = {
  body: PropTypes.string,
}

export default QuestionPrompt
