import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import ReviewAccordion from 'common/reviews/ReviewAccordion'
import { interactionShape } from 'core/shapes'
import { get } from 'fp/objects'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import StarFilled from 'hss/images/controls/radio/star-filled.svg'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getEchoRubric } from 'selectors/echo'
import { getCurrentUser } from 'selectors/users'
import useStaticRenderer from '../FeatureRate/useStaticRenderer'

const Ranking = ({ ranked }) => {
  const { subsection: echo } = useContext(contentViewerContext) || {}
  const rubricContent = useSelector(getEchoRubric(echo)) || {}
  const rubricCriteria = rubricContent?.children[0]?.data
  const responseType = get('data.responseType')(echo)

  const { id: currentUserId } = useSelector(getCurrentUser)
  const { palette } = useTheme()

  const StaticRenderer = useStaticRenderer({ responseType })

  return (
    <>
      <Typography>
        Here are the top submissions from your class, ranked based on how
        everyone voted.
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        pt={2}
        px={3}>
        <Typography
          mb={0}
          variant="eyebrow">
          Name
        </Typography>

        <Stack
          direction="row"
          gap={1}
          sx={{
            'svg path': {
              fill: palette.common.yellow,
              stroke: palette.common.yellow,
            },
          }}>
          <StarFilled />

          <Typography
            mb={0}
            variant="eyebrow">
            Rating
          </Typography>
        </Stack>
      </Stack>

      {ranked.map(({ peerReaction, peerReactionAverage }, idx) => {
        const yours = currentUserId === peerReaction.userId

        return (
          <ReviewAccordion
            displayMode="numeric"
            expandable
            featured={yours}
            key={peerReaction.id}
            ratingOrRatingId={peerReactionAverage}
            readOnly
            render={() => (
              <StaticRenderer
                reaction={{ peerInteraction: peerReaction }}
                reviewed
              />
            )}
            rubricCriteria={rubricCriteria}
            title={`${idx + 1}. ${yours ? 'You' : 'Anonymous'}`}
          />
        )
      })}
    </>
  )
}

Ranking.propTypes = {
  ranked: PropTypes.arrayOf(
    PropTypes.shape({
      peerReaction: interactionShape.isRequired,
      peerReactionAverage: PropTypes.number.isRequired,
      peerReactionCount: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default Ranking
