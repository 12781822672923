import ListItem from '@mui/material/ListItem'
import Stack from '@mui/material/Stack'
import CorrectnessIcon from 'common/indicators/CorrectnessIcon'
import { isDefined, isUndefined } from 'fp/utils'
import { SCORING_MODE_NONE } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'

const OptionListItem = ({ children, isCorrect, scoringMode, ...rest }) => (
  <ListItem
    {...(isUndefined(isCorrect)
      ? null
      : { 'data-correct': isCorrect ? 'yes' : 'no' })}
    variant="answer"
    {...rest}>
    <Stack
      alignItems="center"
      direction="row"
      pl={0}
      pr={1}
      spacing={1}>
      {Boolean(scoringMode !== SCORING_MODE_NONE && isDefined(isCorrect)) && (
        <CorrectnessIcon
          isCorrect={isCorrect}
          stroke={isCorrect ? 'success.main' : 'error.main'}
        />
      )}
      {children}
    </Stack>
  </ListItem>
)

OptionListItem.propTypes = {
  children: PropTypes.node.isRequired,
  isCorrect: PropTypes.bool,
  scoringMode: PropTypes.string.isRequired,
}

export default OptionListItem
