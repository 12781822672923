import LineReader from 'common/LineReader'
import TE from 'common/indicators/TE'
import {
  CONTENT_TYPE_ECHO,
  TOGGLE_STATE_LINE_READER_VISIBLE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import MenuItemButton from 'hss/ContentViewer/Chapter/Toolbar/MenuItemButton'
import ToolbarMenu from 'hss/ContentViewer/Chapter/Toolbar/ToolbarMenu'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import PropTypes from 'prop-types'
import { useContext, useLayoutEffect } from 'react'
import { Volume2 } from 'react-feather'
import { useSelector } from 'react-redux'
import { getLocalSetting } from 'selectors/localSettings'
import AnnotationsOnOffButton from './AnnotationsOnOffButton'
import MiscMenuItems from './MiscMenuItems'

const Toolbar = ({ className, sticky = false }) => {
  const {
    audioAvailable,
    audioTrackEnabled,
    displayingLeveledContent,
    displayingSpanishContent,
    displayingTeContent,
    leveledContentToggleAvailable,
    spanishContentToggleAvailable,
    subsection,
    teContentAvailable,
    toggleAudioOnOff,
    toggleLeveledContent,
    toggleSpanishContent,
    toggleTeContent,
  } = useContext(contentViewerContext)

  const presenterModeEnabled = useSelector(
    getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  )
  const lineReaderOpen = useSelector(
    getLocalSetting(TOGGLE_STATE_LINE_READER_VISIBLE),
  )

  /**
   * It's possible that EnhancedContentHtml may produce more than one toolbar
   * during its render, and there's no easy way to deal with that directly.
   * I tried using state, providers and refs to track toolbar creation but was
   * thwarted at every attempt by the way that html-to-react does its thing.
   * I toyed with the idea of forking it, but honestly I don't think there's
   * much we could do to improve the situation.
   *
   * This fix is not pretty, but it's all I've got right now. It's possible that
   * the design folks will move the toolbar out of the block flow, in which case
   * this can all go away, but for now I'm using DOM selectors to find all the
   * toolbars and hide all but the last.  We can't just remove it from the DOM
   * (for obvious reasons) so this is a compromise for the edge case that
   * produces multiple toolbars.
   */
  useLayoutEffect(() => {
    const elements = Array.from(
      document.querySelectorAll('[data-toolbarcontainer*="subsection"]'),
    )
    elements.forEach((node, idx) => {
      const hidden = idx < elements.length - 1

      node.style.display = hidden ? 'none' : 'flex'
      node.ariaHidden = hidden ? 'true' : 'false'
    })
  }, [])

  return (
    <>
      <ToolbarMenu
        className={className}
        sticky={sticky}
        sx={{ boxShadow: 2 }}>
        {Boolean(teContentAvailable) && (
          <MenuItemButton
            disabled={presenterModeEnabled}
            onClick={toggleTeContent}
            selected={displayingTeContent}
            text="Teacher Edition (TE) Content"
            value="display-te">
            <TE
              color={
                displayingTeContent !== true
                  ? 'navigation.toc'
                  : 'navigation.tocLight'
              }
            />
          </MenuItemButton>
        )}

        {Boolean(leveledContentToggleAvailable && !presenterModeEnabled) && (
          <MenuItemButton
            onClick={toggleLeveledContent}
            selected={displayingLeveledContent}
            text="Leveled Text"
            value="display-leveled-content">
            LT
          </MenuItemButton>
        )}

        {Boolean(spanishContentToggleAvailable) && (
          <MenuItemButton
            onClick={toggleSpanishContent}
            selected={displayingSpanishContent}
            text="Spanish"
            value="display-spanish-content">
            ES
          </MenuItemButton>
        )}

        {Boolean(audioAvailable) && (
          <MenuItemButton
            ButtonIcon={Volume2}
            onClick={toggleAudioOnOff}
            selected={audioTrackEnabled}
            text="Audio"
            value="audio"
          />
        )}

        {subsection.contentType === CONTENT_TYPE_ECHO ? (
          <AnnotationsOnOffButton />
        ) : (
          <MiscMenuItems />
        )}
      </ToolbarMenu>
      {Boolean(lineReaderOpen) && <LineReader />}
    </>
  )
}

Toolbar.propTypes = {
  sticky: PropTypes.bool,
}

export default Toolbar
