import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import Checkbox from 'common/formControls/switches/Checkbox'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { filter, find, orderBy, push } from 'fp/arrays'
import { equals, notEquals } from 'fp/objects'
import { isNotEmptyString, pluralize } from 'fp/strings'
import { fallbackTo } from 'fp/utils'
import { useChapterVocabPhrases } from 'hooks/useChapterVocabPhrases'
import PropTypes from 'prop-types'
import { createElement, useCallback, useContext } from 'react'
import { compose } from 'redux'
import { interactiveContext } from '../../Interactive/InteractiveProvider'

const ItemRenderer = ({ checked = false, label, ...rest }) => (
  <Box
    bgcolor="common.white"
    mb={1.5}
    p={1.5}
    pl={1}>
    <Checkbox
      checked={checked}
      {...rest}
      label={label}
    />
  </Box>
)

ItemRenderer.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

const CheckboxGroup = ({
  allowMoreSelections,
  selectedPhrases,
  handleChange,
  options,
}) => {
  const { isGrading } = useContext(interactiveContext)

  const containerQuery = useContainerQuery()
  const columnCount = containerQuery.up('md')
    ? 3
    : containerQuery.up('sm')
      ? 2
      : 1
  return (
    <FormGroup
      sx={{
        columnCount,
        display: 'block', // not flex
        marginBottom: '2rem',
      }}>
      {options.map(option => {
        const { id, name } = option
        const checked = compose(Boolean, find(equals(name)))(selectedPhrases)
        return createElement(ItemRenderer, {
          checked,
          disabled: !(checked || allowMoreSelections) || isGrading,
          id: String(id),
          label: name,
          key: id,
          onChange: () => handleChange(name, !checked),
        })
      })}
    </FormGroup>
  )
}

CheckboxGroup.propTypes = {
  allowMoreSelections: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedPhrases: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
}

const VocabMultipleChoice = ({ contentId, numPhrases, onChange, phrases }) => {
  const contentVocabPhrases = compose(
    orderBy('name'),
    fallbackTo([]),
    useChapterVocabPhrases,
  )({ contentId, contentType: CONTENT_TYPE_INTERACTIVE })

  const selectedPhrases = phrases.filter(isNotEmptyString)

  const handleChange = useCallback(
    (vocabPhrase, isChecked) => {
      const update = isChecked
        ? push(vocabPhrase)
        : filter(notEquals(vocabPhrase))
      onChange({ target: { value: update(selectedPhrases).filter(Boolean) } })
    },
    [selectedPhrases, onChange],
  )

  return (
    <>
      <CheckboxGroup
        allowMoreSelections={numPhrases > selectedPhrases.length}
        handleChange={handleChange}
        options={contentVocabPhrases}
        selectedPhrases={selectedPhrases}
      />

      <Typography
        textAlign="center"
        variant="body1">
        Check {pluralize('box')(numPhrases)} from the list above.
      </Typography>
    </>
  )
}

VocabMultipleChoice.propTypes = {
  contentId: PropTypes.string,
  numPhrases: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  phrases: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
}

export default VocabMultipleChoice
