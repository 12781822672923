import Tab from '@mui/material/Tab'
import cl from 'classnames'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import TabList from 'common/layout/TabList'
import useNavigation from 'hooks/useNavigation'
import { filledIcons, icons } from 'hss/ContentBuilder/Curriculum/Echo/utils'
import { labels } from 'hss/ContentBuilder/consts'
import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import { sidebarContext } from 'routing/shells/SidebarProvider'

const Tabs = ({ features }) => {
  const { isEchoSidebarShrunk, setIsEchoSidebarShrunk } =
    useContext(sidebarContext)

  const {
    location: { pathname },
    navigate,
  } = useNavigation()
  const { setSuppressNextDirtyNavigationWarning } =
    useContext(additionalContext)

  const setCurrentTab = useCallback(
    newValue => {
      navigate(`${pathname}#${newValue}`, { replace: true })
    },
    [navigate, pathname],
  )

  const handleTabChange = (_, newValue) => {
    setSuppressNextDirtyNavigationWarning(true)
    setTimeout(() => {
      setCurrentTab(newValue)
    }, 100)
  }

  const handleClick = useCallback(() => {
    if (isEchoSidebarShrunk) setIsEchoSidebarShrunk(false)
  }, [isEchoSidebarShrunk, setIsEchoSidebarShrunk])

  return (
    <TabList
      className={cl({ 'is-collapsed': isEchoSidebarShrunk })}
      data-variant="echo"
      minSize="xs"
      onChange={handleTabChange}
      onClick={handleClick}
      orientation="vertical">
      {features.map(key => (
        <Tab
          key={key}
          label={
            <>
              {filledIcons[key]} {icons[key]}
              {labels[key]}
            </>
          }
          value={key}
        />
      ))}
    </TabList>
  )
}

Tabs.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Tabs
