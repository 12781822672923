import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Carousel from 'common/carousels/Carousel'
import { paginatedSmallSlides } from 'common/carousels/configurations'
import MaybeDraft from 'common/indicators/Draft'
import Html from 'common/text/Html'
import {
  ABILITY_ASSIGNMENT_CREATION,
  CONTENT_TYPE_UNIT,
  FEATURE_FLAG_CONTENT_CAROUSEL,
} from 'core/consts'
import { includes } from 'fp/arrays'
import { get } from 'fp/objects'
import withContent from 'hoc/withContent'
import useAbilityCheck from 'hooks/useAbilityCheck'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { contentStateIsDraft } from 'selectors/content'
import { getCurrentUser } from 'selectors/users'
import ItemRenderer from './Carousel/ItemRenderer'
import ChapterCard from './ChapterCard'

const Unit = withContent(
  ({ content }) => {
    const isAssignmentCreator = useAbilityCheck(ABILITY_ASSIGNMENT_CREATION)

    const demoLevel = compose(
      includes(FEATURE_FLAG_CONTENT_CAROUSEL),
      get('featureFlags'),
      useSelector,
    )(getCurrentUser)

    const isDraft = useSelector(contentStateIsDraft(content))

    return (
      <Container data-draft={isDraft ? 'true' : 'false'}>
        <Typography
          component="div"
          variant="list-header">
          {content?.name}

          <MaybeDraft content={content} />
        </Typography>

        <Typography
          mb={0}
          variant="paragraph">
          <Html
            body={content.data.caption}
            substituteInlineBlocks
          />
        </Typography>

        {/**
         * Temporary hack, for sales purposes
         */}
        {isAssignmentCreator && !demoLevel ? (
          <List>
            {content.children.map(item => (
              <ListItem key={item.id}>
                <ChapterCard
                  contentId={item.id}
                  key={item.id}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Carousel configuration={paginatedSmallSlides}>
            {content.children.map(item => (
              <ItemRenderer
                contentId={item.id}
                key={item.id}
              />
            ))}
          </Carousel>
        )}
      </Container>
    )
  },
  { contentType: CONTENT_TYPE_UNIT },
)

export default Unit
